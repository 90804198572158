import React, { useEffect, useState } from 'react';
import AccordionItemTypes from './AccordionItem.types';
import classNames from 'classnames';
import {
  Action,
  ActionContainer,
  AddItem,
  Box,
  Display,
  Dots,
  SubList,
  Title,
  SubTitle,
} from './styles';
import {
  IconAdd,
  IconAddCircle,
  IconDragHandle,
  IconEye,
  IconEyeSlash,
  IconMinusCircle,
  IconPencil,
  IconSimpleArrowSmall,
  IconTrash,
} from 'components/IconsView';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import HaltForm from 'components/Forms/HaltForm/HaltForm';

const DragHandle: any = SortableHandle(() => (
  <Dots>
    <IconDragHandle />
  </Dots>
));

const SortableGroup: any = SortableContainer(
  ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
  }
);

const AccordionItem: any = SortableElement(
  ({
    id,
    title,
    subTitle,
    secondaryTitle,
    accordionItems,
    addFuncText,
    addFunc,
    editFunc,
    deleteFunc,
    activeFunc,
    active,
    alert,
    fakeActive,
    show = false,
    switchShow,
    topTier = true,
    onSortEnd,
    parent,
    sortable,
    accordionIsOpen,
    setAccordionIsOpen,
    type,
    variation,
    onDeleteRequest,
  }: AccordionItemTypes): JSX.Element => {
    const [activeItem, setActiveItem] = useState(
      fakeActive !== undefined ? fakeActive : active
    );
    const [listData, setListData] = useState<any[]>([]);
    const [disableDelete, setDisableDelete] = useState(false);

    useEffect(() => {
      if (accordionItems) {
        // for (const entry of accordionItems) {
        //   entry.show = false;
        // }
        setListData(accordionItems);
      }
    }, [accordionItems]);

    useEffect(() => {
      if (fakeActive || fakeActive === false) {
        setActiveItem(fakeActive);
      }
    }, [fakeActive]);

    const onSort = ({
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    }) => {
      setListData((items) => {
        return arrayMove(items, oldIndex, newIndex);
      });
    };

    // if accordionItems exists, then prompt user to remove before deleting
    const onDelete = (): void => {
      if (accordionItems) {
        if (accordionItems.length > 0) {
          // Callback prop to manually toggle MigrateForm hand handle on parent
          if (id && onDeleteRequest) {
            onDeleteRequest(id);
          } else {
            setDisableDelete(true);
          }
          return;
        }
      }
      if (deleteFunc) {
        deleteFunc();
        return;
      }
    };

    const handleActive = () => {
      if (fakeActive !== false) {
        console.log('handleActive', fakeActive);
        const newActive = !activeItem;
        setActiveItem(newActive);
        if (activeFunc) {
          console.log('activeFunc');
          activeFunc(newActive);
        }

        if (parent) {
          setListData((prev) => {
            return prev.map((item) => {
              return { ...item, fakeActive: newActive };
            });
          });
        }
      }
    };

    const switchShowInner = (index: number) => {
      listData[index].show = !listData[index].show;
      setListData([...listData]);
      if (accordionIsOpen && setAccordionIsOpen) {
        if (!accordionIsOpen.includes(listData[index].id)) {
          setAccordionIsOpen([...accordionIsOpen, listData[index].id]);
        } else if (accordionIsOpen.includes(listData[index].id)) {
          const updatedAccordionIsOpen = accordionIsOpen.filter(
            (item) => item !== listData[index].id
          );
          setAccordionIsOpen(updatedAccordionIsOpen);
        }
      }
    };

    const showAsActive = () => {
      if (variation) {
        return active ? <IconMinusCircle /> : <IconAddCircle />;
      }
      return activeItem ? <IconEye /> : <IconEyeSlash />;
    };

    return (
      <Box topTier={topTier} className={classNames({ alert: alert })}>
        <Display
          active={show}
          className={classNames({
            noBorder: !addFuncText && parent,
            unsortable: !sortable,
          })}
        >
          {sortable && <DragHandle />}
          <Title
            aria-label="accordion item title"
            type="button"
            active={show}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              switchShow && switchShow();
            }}
            className={classNames({
              open: show,
              closed: !show,
              hasSecondary: !!secondaryTitle,
            })}
          >
            <span>
              {title}
              {subTitle && <SubTitle>{subTitle}</SubTitle>}
              {addFuncText ? <IconSimpleArrowSmall /> : null}
            </span>
            {secondaryTitle && (
              <span className="secondaryTitle">{secondaryTitle}</span>
            )}
          </Title>
          <ActionContainer>
            {editFunc && (
              <Action
                data-children={accordionItems && accordionItems.length}
                type="button"
                className="edit"
                onClick={editFunc}
                aria-label="edit"
                data-testid={id ? `edit-${id}` : 'modal-edit'}
              >
                <IconPencil />
              </Action>
            )}
            {activeFunc && (
              <Action
                type="button"
                className={classNames('active', {
                  disable: fakeActive === false,
                  variation: !!variation,
                })}
                onClick={handleActive}
                aria-label="active"
                data-testid={id ? `active-${id}` : 'modal-active'}
              >
                {showAsActive()}
              </Action>
            )}
            {deleteFunc && (
              <Action
                type="button"
                className="delete"
                onClick={onDelete}
                aria-label="delete"
                data-testid={id ? `delete-${id}` : 'modal-delete'}
              >
                <IconTrash />
              </Action>
            )}
          </ActionContainer>
        </Display>
        {addFuncText ? (
          <SortableGroup
            onSortEnd={(sortData: any) => {
              if (sortData.newIndex !== sortData.oldIndex) {
                // setDisableTable(true);
                onSort(sortData);
                if (onSortEnd) {
                  onSortEnd(sortData, listData, id);
                }
              }
            }}
            useDragHandle
          >
            <SubList className={classNames()} active={show}>
              {listData &&
                listData.map((item, idx) => {
                  return (
                    <AccordionItem
                      key={idx}
                      {...item}
                      parentActive={activeItem}
                      type={type}
                      sortable={!!onSortEnd}
                      index={idx}
                      topTier={false}
                      switchShow={() => switchShowInner(idx)}
                      onSortEnd={(sortData: any) => {
                        if (sortData.newIndex !== sortData.oldIndex) {
                          // setDisableTable(true);
                          // onSort(sortData);
                          if (item.onSortEnd) {
                            item.onSortEnd(sortData, item.accordionItems, id);
                          }
                        }
                      }}
                    />
                  );
                })}
              {addFunc && addFuncText && (
                <AddItem
                  tertiary={true}
                  onClick={addFunc}
                  className="additionalItem"
                  aria-label="add"
                  type="button"
                >
                  <IconAdd />
                  {addFuncText}
                </AddItem>
              )}
            </SubList>
          </SortableGroup>
        ) : null}
        {disableDelete && <HaltForm toggle={() => setDisableDelete(false)} />}
      </Box>
    );
  }
);

export default AccordionItem;
