import { gql, useMutation, useQuery } from '@apollo/client';
import { MileageRate } from 'API';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { IconAdd } from 'components/IconsView';
import LoaderView from 'components/LoaderView';
import PageTop from 'components/PageTop';
import PageTopTypes from 'components/PageTop/PageTop.types';
import SectionContainer from 'components/SectionContainer';
import dayjs from 'dayjs';
import * as mutations from 'graphql/mutations';
import { getPage, listMileageRates } from 'graphql/queries';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SlugI } from 'types/SlugListType';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import { listPages } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';
import RateRow from './Components/RateRow';
import { AddButton, Container, Form } from './styles';

export const GET_PAGE = gql(getPage);
export const LIST_PAGES = gql(listPages);
export const LIST_MILEAGERATES = gql(listMileageRates);
export const UPDATE_PAGE = gql(mutations.updatePage);
export const UPDATE_MILEAGERATE = gql(mutations.updateMileageRate);
export const CREATE_MILEAGERATE = gql(mutations.createMileageRate);
export const DELETE_MILEAGERATE = gql(mutations.deleteMileageRate);

type MileageSubmitData = {
  [key: string]: string | number;
};

interface GroupedItem {
  date?: string;
  rate?: number;
}

type GroupedData = {
  [id: string]: GroupedItem;
};
interface IProps {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
  id: string;
}

export interface MileageItem {
  id: string;
  rate: number;
  date: string;
}

const ExpenseReport = ({ toast, id }: IProps): JSX.Element => {
  console.log('--- RENDER EXPENSE REPORT ---');
  const [deleteMileageRateID, setDeleteMileageRateID] = useState('');
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [mileageRateIsDirty, setMileageRateIsDirty] = useState(false);
  const inputFormRef = useRef<HTMLFormElement>(null);
  const [updateToastShow, setUpdateToastShow] = useState(false);
  const [createToastShow, setCreateToastShow] = useState(false);
  const [deleteToastShow, setDeleteToastShow] = useState(false);
  const [mileageList, setMileageList] = useState<MileageItem[]>([]);
  const methods = useForm();

  console.log('mileageRateIsDirty', mileageRateIsDirty);
  console.log('mileageList', mileageList);
  console.log('methods.getValues', methods.getValues());

  const {
    loading,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  });
  // For meta to check against slug
  const { data: mileageRateData, refetch: mileageRateRefetch } =
    useQuery(LIST_MILEAGERATES);

  const formatRates = () => {
    const list =
      mileageRateData?.listMileageRates?.items
        ?.slice()
        .sort((a: MileageRate, b: MileageRate) => {
          if (!a.date) {
            return 1;
          }
          if (!b.date) {
            return -1;
          }
          return Date.parse(b.date) - Date.parse(a.date);
        })
        .map((m: MileageRate) => {
          return {
            id: m.id,
            date: m.date,
            rate: m.rate,
          };
        }) || [];

    setMileageList(list);
  };

  // useOnMount(() => {
  //   formatRates();
  // });

  useEffect(() => {
    formatRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mileageRateData]);

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  useEffect(() => {
    setMileageRateIsDirty(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      refetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const [createMileageRate] = useMutation(CREATE_MILEAGERATE, {
    onError: () => {
      if (!createToastShow) {
        toast.error('Error creating mileage rate');
        setCreateToastShow(true);
      }
    },
  });

  const [updateMileageRate] = useMutation(UPDATE_MILEAGERATE, {
    onError: () => {
      if (!updateToastShow) {
        toast.error('Error updating mileage rate');
        setUpdateToastShow(true);
      }
    },
  });

  const [deleteMileageRate] = useMutation(DELETE_MILEAGERATE, {
    onError: () => {
      if (!deleteToastShow) {
        toast.error('Error deleting mileage rate');
        setDeleteToastShow(true);
      }
    },
  });

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });
  };

  const onSubmitInvalid = (errors: any) => {
    console.log('onSubmitInvalid', errors);
  };

  const inputOnSubmit: SubmitHandler<MileageSubmitData> = async (props) => {
    console.log('ENTERED', props);

    if (!mileageRateIsDirty) {
      return;
    }

    // const row = props.row;
    setUpdateToastShow(false);
    setCreateToastShow(false);
    setDeleteToastShow(false);
    setMileageRateIsDirty(false);

    const groupedData: GroupedData = Object.entries(props).reduce(
      (acc, [key, value]) => {
        const [entryId, property] = key.split(/-(date|rate)$/);

        // Initialize the object if it doesn't exist
        if (!acc[entryId]) {
          acc[entryId] = {};
        }

        // Assign the value to the respective property in the grouped object
        if (property === 'date' && typeof value === 'string') {
          acc[entryId].date = value;
        } else if (property === 'rate' && typeof value === 'number') {
          acc[entryId].rate = value;
        }

        return acc;
      },
      {} as GroupedData
    );

    // console.log('groupedData', groupedData);
    // console.log(
    //   'mileageRateData?.listMileageRates?.items',
    //   mileageRateData?.listMileageRates?.items
    // );

    for (const [entryId, entryData] of Object.entries(groupedData)) {
      const { date, rate } = entryData;

      const entry = mileageRateData?.listMileageRates?.items.find(
        (m: { id: string }) => m.id === entryId
      );

      if (!entry) {
        await createMileageRate({
          variables: {
            input: {
              date: dayjs(date).toISOString(),
              rate: rate || 0,
            },
          },
        });
      } else {
        await updateMileageRate({
          variables: {
            input: {
              id: entryId,
              date: dayjs(date).toISOString(),
              rate: rate || 0,
            },
          },
        });
      }
    }

    // delete entries that no longer exist
    for (const entry of mileageRateData?.listMileageRates?.items || []) {
      if (!groupedData[entry.id]) {
        await deleteMileageRate({
          variables: {
            input: {
              id: entry.id,
            },
          },
        });
      }
    }

    toast.success('Mileage Rates Updated');

    // Refresh the page because forms on intranet are janky
    window.location.reload();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
    if (inputFormRef.current) {
      inputFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const handleTrash = (deleteId: string) => {
    setDeleteMileageRateID(deleteId);
    setMileageRateIsDirty(true);
  };

  if (loading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        slugList={slugList}
        data={data}
        setIsDirtyForm={setIsDirtyForm}
        isDirtyForm={isDirtyForm}
        mileageRateIsDirty={mileageRateIsDirty}
      />
      <SectionContainer
        title="Set Mileage Rates"
        description="Mileage rates set here will be accounted for when an employee is expensing mileage."
      >
        <Form
          noValidate
          onSubmit={methods.handleSubmit(inputOnSubmit, onSubmitInvalid)}
          ref={inputFormRef}
        >
          {mileageList
            ?.map((mileage: MileageItem) => {
              return (
                <RateRow
                  id={mileage.id}
                  key={mileage.id}
                  methods={methods}
                  moreThanOne={mileageList.length > 1}
                  handleTrash={handleTrash}
                  defaultValues={mileage}
                  mileageList={mileageList}
                />
              );
            })
            .sort()}
          <AddButton
            type="button"
            tertiary
            aria-label="Add Another Rate"
            onClick={() => {
              setMileageRateIsDirty(true);
              setMileageList((prev) => [
                ...prev,
                {
                  id: uuidv4(),
                  date: '',
                  rate: 0,
                },
              ]);
            }}
          >
            <IconAdd /> Add Another Rate
          </AddButton>
          {deleteMileageRateID ? (
            <DeleteForm
              type="mileageRateFake"
              id={'fake'}
              cancel={() => setDeleteMileageRateID('')}
              toggle={() => {
                setDeleteMileageRateID('');
                const newList = mileageList?.filter(
                  (mileage) => mileage.id !== deleteMileageRateID
                );
                setMileageList(newList);
                methods.unregister(`${deleteMileageRateID}-date`);
                methods.unregister(`${deleteMileageRateID}-rate`);
              }}
              toast={toast}
            />
          ) : null}
        </Form>
      </SectionContainer>
    </Container>
  );
};

export default ExpenseReport;
