import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { getClient } from './utils/auth';
import Amplify from 'aws-amplify';
import { FlagsProvider } from 'flagged';
import Debug from 'debug';
import { GlobalErrorContextProvider } from './utils/useGlobalError';

if (process.env.REACT_APP_DEBUG) {
  Debug.enable(process.env.REACT_APP_DEBUG);
  Debug.log = console.info.bind(console);
}

const debug = Debug('fnd:index');

fetch('/config.json')
  .then((r) => r.json())
  .then((config) => {
    Amplify.configure(config.aws);
    debug('features: %O', config.features);
    const client = getClient(config.aws);
    ReactDOM.render(
      <React.StrictMode>
        <ApolloProvider client={client}>
          <FlagsProvider features={config.features}>
            <GlobalErrorContextProvider>
              <App />
            </GlobalErrorContextProvider>
          </FlagsProvider>
        </ApolloProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
