/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const emailPTO = /* GraphQL */ `mutation EmailPTO($input: EmailPTOInput) {
  emailPTO(input: $input) {
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EmailPTOMutationVariables,
  APITypes.EmailPTOMutation
>;
export const emailExpenseReport = /* GraphQL */ `mutation EmailExpenseReport($input: EmailExpenseReportInput) {
  emailExpenseReport(input: $input) {
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EmailExpenseReportMutationVariables,
  APITypes.EmailExpenseReportMutation
>;
export const userLogout = /* GraphQL */ `mutation UserLogout($input: UserLogoutInput) {
  userLogout(input: $input) {
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UserLogoutMutationVariables,
  APITypes.UserLogoutMutation
>;
export const sortLocations = /* GraphQL */ `mutation SortLocations($input: SortLocationsInput) {
  sortLocations(input: $input) {
    items
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SortLocationsMutationVariables,
  APITypes.SortLocationsMutation
>;
export const sortItems = /* GraphQL */ `mutation SortItems($input: SortItemsInput) {
  sortItems(input: $input) {
    items
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SortItemsMutationVariables,
  APITypes.SortItemsMutation
>;
export const sortItemsV2 = /* GraphQL */ `mutation SortItemsV2($input: SortItemsV2Input) {
  sortItemsV2(input: $input) {
    items
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SortItemsV2MutationVariables,
  APITypes.SortItemsV2Mutation
>;
export const viewItems = /* GraphQL */ `mutation ViewItems($input: ViewItemsInput) {
  viewItems(input: $input) {
    items
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ViewItemsMutationVariables,
  APITypes.ViewItemsMutation
>;
export const toggleOffice = /* GraphQL */ `mutation ToggleOffice($input: ToggleOfficeInput) {
  toggleOffice(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ToggleOfficeMutationVariables,
  APITypes.ToggleOfficeMutation
>;
export const createOfficeTemplates = /* GraphQL */ `mutation CreateOfficeTemplates($input: CreateOfficeTemplatesInput) {
  createOfficeTemplates(input: $input) {
    ids
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOfficeTemplatesMutationVariables,
  APITypes.CreateOfficeTemplatesMutation
>;
export const checkOfficesToCreateTemplates = /* GraphQL */ `mutation CheckOfficesToCreateTemplates(
  $input: CheckOfficesToCreateTemplatesInput
) {
  checkOfficesToCreateTemplates(input: $input) {
    shouldRefetch
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckOfficesToCreateTemplatesMutationVariables,
  APITypes.CheckOfficesToCreateTemplatesMutation
>;
export const updateOfficeAndConnectedPages = /* GraphQL */ `mutation UpdateOfficeAndConnectedPages(
  $input: UpdateOfficeAndConnectedPagesInput
) {
  updateOfficeAndConnectedPages(input: $input) {
    ids
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOfficeAndConnectedPagesMutationVariables,
  APITypes.UpdateOfficeAndConnectedPagesMutation
>;
export const createApplication = /* GraphQL */ `mutation CreateApplication(
  $input: CreateApplicationInput!
  $condition: ModelApplicationConditionInput
) {
  createApplication(input: $input, condition: $condition) {
    id
    name
    image
    username
    password
    serial
    path
    website
    sort
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateApplicationMutationVariables,
  APITypes.CreateApplicationMutation
>;
export const updateApplication = /* GraphQL */ `mutation UpdateApplication(
  $input: UpdateApplicationInput!
  $condition: ModelApplicationConditionInput
) {
  updateApplication(input: $input, condition: $condition) {
    id
    name
    image
    username
    password
    serial
    path
    website
    sort
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateApplicationMutationVariables,
  APITypes.UpdateApplicationMutation
>;
export const deleteApplication = /* GraphQL */ `mutation DeleteApplication(
  $input: DeleteApplicationInput!
  $condition: ModelApplicationConditionInput
) {
  deleteApplication(input: $input, condition: $condition) {
    id
    name
    image
    username
    password
    serial
    path
    website
    sort
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteApplicationMutationVariables,
  APITypes.DeleteApplicationMutation
>;
export const createFAQ = /* GraphQL */ `mutation CreateFAQ(
  $input: CreateFAQInput!
  $condition: ModelFAQConditionInput
) {
  createFAQ(input: $input, condition: $condition) {
    id
    question
    answer
    sectionID
    sort
    officeIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFAQMutationVariables,
  APITypes.CreateFAQMutation
>;
export const updateFAQ = /* GraphQL */ `mutation UpdateFAQ(
  $input: UpdateFAQInput!
  $condition: ModelFAQConditionInput
) {
  updateFAQ(input: $input, condition: $condition) {
    id
    question
    answer
    sectionID
    sort
    officeIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFAQMutationVariables,
  APITypes.UpdateFAQMutation
>;
export const deleteFAQ = /* GraphQL */ `mutation DeleteFAQ(
  $input: DeleteFAQInput!
  $condition: ModelFAQConditionInput
) {
  deleteFAQ(input: $input, condition: $condition) {
    id
    question
    answer
    sectionID
    sort
    officeIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFAQMutationVariables,
  APITypes.DeleteFAQMutation
>;
export const createFAQsection = /* GraphQL */ `mutation CreateFAQsection(
  $input: CreateFAQsectionInput!
  $condition: ModelFAQsectionConditionInput
) {
  createFAQsection(input: $input, condition: $condition) {
    id
    title
    sort
    faqs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFAQsectionMutationVariables,
  APITypes.CreateFAQsectionMutation
>;
export const updateFAQsection = /* GraphQL */ `mutation UpdateFAQsection(
  $input: UpdateFAQsectionInput!
  $condition: ModelFAQsectionConditionInput
) {
  updateFAQsection(input: $input, condition: $condition) {
    id
    title
    sort
    faqs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFAQsectionMutationVariables,
  APITypes.UpdateFAQsectionMutation
>;
export const deleteFAQsection = /* GraphQL */ `mutation DeleteFAQsection(
  $input: DeleteFAQsectionInput!
  $condition: ModelFAQsectionConditionInput
) {
  deleteFAQsection(input: $input, condition: $condition) {
    id
    title
    sort
    faqs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFAQsectionMutationVariables,
  APITypes.DeleteFAQsectionMutation
>;
export const createFreelancer = /* GraphQL */ `mutation CreateFreelancer(
  $input: CreateFreelancerInput!
  $condition: ModelFreelancerConditionInput
) {
  createFreelancer(input: $input, condition: $condition) {
    id
    name
    email
    phone
    location
    portfolio
    design
    ux
    motion
    development
    photography
    video
    copywriting
    branding
    rate
    referredBy
    notes
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFreelancerMutationVariables,
  APITypes.CreateFreelancerMutation
>;
export const updateFreelancer = /* GraphQL */ `mutation UpdateFreelancer(
  $input: UpdateFreelancerInput!
  $condition: ModelFreelancerConditionInput
) {
  updateFreelancer(input: $input, condition: $condition) {
    id
    name
    email
    phone
    location
    portfolio
    design
    ux
    motion
    development
    photography
    video
    copywriting
    branding
    rate
    referredBy
    notes
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFreelancerMutationVariables,
  APITypes.UpdateFreelancerMutation
>;
export const deleteFreelancer = /* GraphQL */ `mutation DeleteFreelancer(
  $input: DeleteFreelancerInput!
  $condition: ModelFreelancerConditionInput
) {
  deleteFreelancer(input: $input, condition: $condition) {
    id
    name
    email
    phone
    location
    portfolio
    design
    ux
    motion
    development
    photography
    video
    copywriting
    branding
    rate
    referredBy
    notes
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFreelancerMutationVariables,
  APITypes.DeleteFreelancerMutation
>;
export const createGIF = /* GraphQL */ `mutation CreateGIF(
  $input: CreateGIFInput!
  $condition: ModelGIFConditionInput
) {
  createGIF(input: $input, condition: $condition) {
    id
    sort
    title
    image
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGIFMutationVariables,
  APITypes.CreateGIFMutation
>;
export const updateGIF = /* GraphQL */ `mutation UpdateGIF(
  $input: UpdateGIFInput!
  $condition: ModelGIFConditionInput
) {
  updateGIF(input: $input, condition: $condition) {
    id
    sort
    title
    image
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGIFMutationVariables,
  APITypes.UpdateGIFMutation
>;
export const deleteGIF = /* GraphQL */ `mutation DeleteGIF(
  $input: DeleteGIFInput!
  $condition: ModelGIFConditionInput
) {
  deleteGIF(input: $input, condition: $condition) {
    id
    sort
    title
    image
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGIFMutationVariables,
  APITypes.DeleteGIFMutation
>;
export const createHoliday = /* GraphQL */ `mutation CreateHoliday(
  $input: CreateHolidayInput!
  $condition: ModelHolidayConditionInput
) {
  createHoliday(input: $input, condition: $condition) {
    id
    title
    sort
    disclaimer
    startDate
    endDate
    createdAt
    updatedAt
    holidayType
    yearID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHolidayMutationVariables,
  APITypes.CreateHolidayMutation
>;
export const updateHoliday = /* GraphQL */ `mutation UpdateHoliday(
  $input: UpdateHolidayInput!
  $condition: ModelHolidayConditionInput
) {
  updateHoliday(input: $input, condition: $condition) {
    id
    title
    sort
    disclaimer
    startDate
    endDate
    createdAt
    updatedAt
    holidayType
    yearID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHolidayMutationVariables,
  APITypes.UpdateHolidayMutation
>;
export const deleteHoliday = /* GraphQL */ `mutation DeleteHoliday(
  $input: DeleteHolidayInput!
  $condition: ModelHolidayConditionInput
) {
  deleteHoliday(input: $input, condition: $condition) {
    id
    title
    sort
    disclaimer
    startDate
    endDate
    createdAt
    updatedAt
    holidayType
    yearID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHolidayMutationVariables,
  APITypes.DeleteHolidayMutation
>;
export const createMember = /* GraphQL */ `mutation CreateMember(
  $input: CreateMemberInput!
  $condition: ModelMemberConditionInput
) {
  createMember(input: $input, condition: $condition) {
    id
    title
    image
    name
    nameLow
    description
    department
    reportTo
    projects
    phone
    email
    active
    startDate
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    sort
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMemberMutationVariables,
  APITypes.CreateMemberMutation
>;
export const updateMember = /* GraphQL */ `mutation UpdateMember(
  $input: UpdateMemberInput!
  $condition: ModelMemberConditionInput
) {
  updateMember(input: $input, condition: $condition) {
    id
    title
    image
    name
    nameLow
    description
    department
    reportTo
    projects
    phone
    email
    active
    startDate
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    sort
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMemberMutationVariables,
  APITypes.UpdateMemberMutation
>;
export const deleteMember = /* GraphQL */ `mutation DeleteMember(
  $input: DeleteMemberInput!
  $condition: ModelMemberConditionInput
) {
  deleteMember(input: $input, condition: $condition) {
    id
    title
    image
    name
    nameLow
    description
    department
    reportTo
    projects
    phone
    email
    active
    startDate
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    sort
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMemberMutationVariables,
  APITypes.DeleteMemberMutation
>;
export const createMileageRate = /* GraphQL */ `mutation CreateMileageRate(
  $input: CreateMileageRateInput!
  $condition: ModelMileageRateConditionInput
) {
  createMileageRate(input: $input, condition: $condition) {
    id
    rate
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMileageRateMutationVariables,
  APITypes.CreateMileageRateMutation
>;
export const updateMileageRate = /* GraphQL */ `mutation UpdateMileageRate(
  $input: UpdateMileageRateInput!
  $condition: ModelMileageRateConditionInput
) {
  updateMileageRate(input: $input, condition: $condition) {
    id
    rate
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMileageRateMutationVariables,
  APITypes.UpdateMileageRateMutation
>;
export const deleteMileageRate = /* GraphQL */ `mutation DeleteMileageRate(
  $input: DeleteMileageRateInput!
  $condition: ModelMileageRateConditionInput
) {
  deleteMileageRate(input: $input, condition: $condition) {
    id
    rate
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMileageRateMutationVariables,
  APITypes.DeleteMileageRateMutation
>;
export const createOffice = /* GraphQL */ `mutation CreateOffice(
  $input: CreateOfficeInput!
  $condition: ModelOfficeConditionInput
) {
  createOffice(input: $input, condition: $condition) {
    id
    name
    sort
    active
    group
    timezone
    members {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOfficeMutationVariables,
  APITypes.CreateOfficeMutation
>;
export const updateOffice = /* GraphQL */ `mutation UpdateOffice(
  $input: UpdateOfficeInput!
  $condition: ModelOfficeConditionInput
) {
  updateOffice(input: $input, condition: $condition) {
    id
    name
    sort
    active
    group
    timezone
    members {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOfficeMutationVariables,
  APITypes.UpdateOfficeMutation
>;
export const deleteOffice = /* GraphQL */ `mutation DeleteOffice(
  $input: DeleteOfficeInput!
  $condition: ModelOfficeConditionInput
) {
  deleteOffice(input: $input, condition: $condition) {
    id
    name
    sort
    active
    group
    timezone
    members {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOfficeMutationVariables,
  APITypes.DeleteOfficeMutation
>;
export const createLocation = /* GraphQL */ `mutation CreateLocation(
  $input: CreateLocationInput!
  $condition: ModelLocationConditionInput
) {
  createLocation(input: $input, condition: $condition) {
    id
    type
    sort
    active
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLocationMutationVariables,
  APITypes.CreateLocationMutation
>;
export const updateLocation = /* GraphQL */ `mutation UpdateLocation(
  $input: UpdateLocationInput!
  $condition: ModelLocationConditionInput
) {
  updateLocation(input: $input, condition: $condition) {
    id
    type
    sort
    active
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLocationMutationVariables,
  APITypes.UpdateLocationMutation
>;
export const deleteLocation = /* GraphQL */ `mutation DeleteLocation(
  $input: DeleteLocationInput!
  $condition: ModelLocationConditionInput
) {
  deleteLocation(input: $input, condition: $condition) {
    id
    type
    sort
    active
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLocationMutationVariables,
  APITypes.DeleteLocationMutation
>;
export const createPage = /* GraphQL */ `mutation CreatePage(
  $input: CreatePageInput!
  $condition: ModelPageConditionInput
) {
  createPage(input: $input, condition: $condition) {
    id
    label
    status
    createdAt
    updatedAt
    title
    slug
    description
    eyebrow
    headline
    body
    inlineBody
    ctaText
    ctaLink
    headlineLow
    bodyLow
    layout
    quicklinks {
      nextToken
      __typename
    }
    referencedQuicklinks {
      nextToken
      __typename
    }
    pageId
    pages {
      nextToken
      __typename
    }
    parentPage {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    local
    publishedAt
    image
    sort
    meta
    officeId
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePageMutationVariables,
  APITypes.CreatePageMutation
>;
export const updatePage = /* GraphQL */ `mutation UpdatePage(
  $input: UpdatePageInput!
  $condition: ModelPageConditionInput
) {
  updatePage(input: $input, condition: $condition) {
    id
    label
    status
    createdAt
    updatedAt
    title
    slug
    description
    eyebrow
    headline
    body
    inlineBody
    ctaText
    ctaLink
    headlineLow
    bodyLow
    layout
    quicklinks {
      nextToken
      __typename
    }
    referencedQuicklinks {
      nextToken
      __typename
    }
    pageId
    pages {
      nextToken
      __typename
    }
    parentPage {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    local
    publishedAt
    image
    sort
    meta
    officeId
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePageMutationVariables,
  APITypes.UpdatePageMutation
>;
export const deletePage = /* GraphQL */ `mutation DeletePage(
  $input: DeletePageInput!
  $condition: ModelPageConditionInput
) {
  deletePage(input: $input, condition: $condition) {
    id
    label
    status
    createdAt
    updatedAt
    title
    slug
    description
    eyebrow
    headline
    body
    inlineBody
    ctaText
    ctaLink
    headlineLow
    bodyLow
    layout
    quicklinks {
      nextToken
      __typename
    }
    referencedQuicklinks {
      nextToken
      __typename
    }
    pageId
    pages {
      nextToken
      __typename
    }
    parentPage {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    local
    publishedAt
    image
    sort
    meta
    officeId
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePageMutationVariables,
  APITypes.DeletePageMutation
>;
export const createPolicy = /* GraphQL */ `mutation CreatePolicy(
  $input: CreatePolicyInput!
  $condition: ModelPolicyConditionInput
) {
  createPolicy(input: $input, condition: $condition) {
    id
    location
    title
    acknoledgement
    version
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePolicyMutationVariables,
  APITypes.CreatePolicyMutation
>;
export const updatePolicy = /* GraphQL */ `mutation UpdatePolicy(
  $input: UpdatePolicyInput!
  $condition: ModelPolicyConditionInput
) {
  updatePolicy(input: $input, condition: $condition) {
    id
    location
    title
    acknoledgement
    version
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePolicyMutationVariables,
  APITypes.UpdatePolicyMutation
>;
export const deletePolicy = /* GraphQL */ `mutation DeletePolicy(
  $input: DeletePolicyInput!
  $condition: ModelPolicyConditionInput
) {
  deletePolicy(input: $input, condition: $condition) {
    id
    location
    title
    acknoledgement
    version
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePolicyMutationVariables,
  APITypes.DeletePolicyMutation
>;
export const createQuickLink = /* GraphQL */ `mutation CreateQuickLink(
  $input: CreateQuickLinkInput!
  $condition: ModelQuickLinkConditionInput
) {
  createQuickLink(input: $input, condition: $condition) {
    id
    pageId
    page {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    sort
    title
    body
    linkTitle
    externalLink
    linkId
    link {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuickLinkMutationVariables,
  APITypes.CreateQuickLinkMutation
>;
export const updateQuickLink = /* GraphQL */ `mutation UpdateQuickLink(
  $input: UpdateQuickLinkInput!
  $condition: ModelQuickLinkConditionInput
) {
  updateQuickLink(input: $input, condition: $condition) {
    id
    pageId
    page {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    sort
    title
    body
    linkTitle
    externalLink
    linkId
    link {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuickLinkMutationVariables,
  APITypes.UpdateQuickLinkMutation
>;
export const deleteQuickLink = /* GraphQL */ `mutation DeleteQuickLink(
  $input: DeleteQuickLinkInput!
  $condition: ModelQuickLinkConditionInput
) {
  deleteQuickLink(input: $input, condition: $condition) {
    id
    pageId
    page {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    sort
    title
    body
    linkTitle
    externalLink
    linkId
    link {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuickLinkMutationVariables,
  APITypes.DeleteQuickLinkMutation
>;
export const createYear = /* GraphQL */ `mutation CreateYear(
  $input: CreateYearInput!
  $condition: ModelYearConditionInput
) {
  createYear(input: $input, condition: $condition) {
    id
    title
    sort
    date
    createdAt
    updatedAt
    holidays {
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateYearMutationVariables,
  APITypes.CreateYearMutation
>;
export const updateYear = /* GraphQL */ `mutation UpdateYear(
  $input: UpdateYearInput!
  $condition: ModelYearConditionInput
) {
  updateYear(input: $input, condition: $condition) {
    id
    title
    sort
    date
    createdAt
    updatedAt
    holidays {
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateYearMutationVariables,
  APITypes.UpdateYearMutation
>;
export const deleteYear = /* GraphQL */ `mutation DeleteYear(
  $input: DeleteYearInput!
  $condition: ModelYearConditionInput
) {
  deleteYear(input: $input, condition: $condition) {
    id
    title
    sort
    date
    createdAt
    updatedAt
    holidays {
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteYearMutationVariables,
  APITypes.DeleteYearMutation
>;
