import styled from 'styled-components';
import { colors } from 'shared/variables';
import { bodyCopy, H3 } from 'shared/typography';
// import { fonts, fontSize } from 'shared/typography';

export const Wrapper = styled.div`
  background-color: #f6f6f2;
  z-index: 1000;
  top: 0px;
  left: 0px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 58px 99px 58px 85px;
  grid-template-columns: minmax(auto, 920px);
  opacity: 1;
  position: relative;
  max-width: 803px;
  margin: 0 auto;
`;
export const Header = styled(H3)`
  position: relative;
  margin: 0;
  color: ${colors.brownMedium};
`;

export const Description = styled.p`
  ${bodyCopy};
  margin: 10px 0 0;
`;

export const Body = styled.div`
  margin-top: 40px;
`;

export const Close = styled.button`
  position: absolute;
  border-radius: 20px;
  right: 24px;
  top: 24px;
  padding: 10px;
  border: 2px solid ${colors.primary};
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 150ms ease;
  cursor: pointer;
  line-height: 0;

  svg path {
    transition: stroke 150ms ease;
  }

  &:hover {
    border-color: ${colors.highlight};
    svg {
      path {
        transition: all 150ms ease;
        stroke: ${colors.highlight};
      }
    }
  }
`;
export const ModalBackground = styled.div`
  position: fixed;
  background-color: green;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
`;
