import styled from 'styled-components';
import { colors, padding, border, customBezierEase } from 'shared/variables';
import { bodyCopyBold, eyebrow, resetButtonStyles } from 'shared/extends';
import Button from 'components/Button/Button';
import { size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';

interface Props {
  active: boolean;
}

export const Warning = styled.div`
  display: grid;
  gap: 20px;
`;

export const ButtonContainer = styled.div`
  display: block;
`;

export const Display = styled.div<Props>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 26px;
  align-items: center;
  padding: 24px 0 24px 9px;
  margin: 1px 0 -1px 0;
  border-bottom: ${border.listItem.border};

  &.noBorder {
    border-bottom: 0;
  }

  &.unsortable {
    grid-template-columns: 1fr auto;
  }
`;

export const SubList = styled.div<Props>`
  display: ${(props) => (props.active ? 'block' : 'none')};
  transition: height 150ms ease;
  padding-left: 3.125rem;

  border-bottom: ${border.listItem.border};

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Dots = styled.div`
  cursor: grab;
  ${size('10px', '17px')};

  svg {
    fill: ${colors.primary};
    ${size('10px', '17px')};
  }
`;

export const Title = styled.button<Props>`
  ${resetButtonStyles};
  ${eyebrow};
  cursor: initial;
  text-align: left;
  color: ${colors.brownMedium};
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr;

  svg {
    fill: ${colors.primary};
    margin-left: 7px;
    vertical-align: initial;
    ${size('13px', '9px')};
  }

  ${(props) =>
    props.active &&
    `
      svg {
        transform: rotate(180deg);
      }
  `}

  &.hasSecondary {
    grid-template-columns: 1fr minmax(auto, 1fr);
  }
`;

export const SubTitle = styled.div`
  ${fontSize(12, 12, 0)};
  text-transform: none;
  ${font('body')}
  color: ${colors.brownLight};
`;

export const ActionContainer = styled.div`
  svg {
    fill: ${colors.primary};
    transition: 0.3s ${customBezierEase};

    &:hover {
      fill: ${colors.highlight};
    }
  }
`;

export const Action = styled.button`
  ${resetButtonStyles};
  margin-right: 22px;

  &.edit > svg {
    ${size('18px')};
  }
  &.active > svg {
    ${size('26px', '14px')};

    &.inactive {
      fill: ${colors.brownLight};
      ${size('26px', '17px')};
      transition: 0.3s ${customBezierEase};

      &:hover {
        fill: ${colors.highlight};
      }
    }
  }
  &.delete > svg {
    ${size('17px', '19px')};
  }

  &.disable {
    cursor: not-allowed;
  }

  &:last-child {
    margin-right: 0;
  }

  &.variation {
    svg {
      ${size('23px')}
    }
  }
`;
export const AddItem = styled(Button)<{ tertiary: boolean }>`
  ${resetButtonStyles};
  ${bodyCopyBold};
  text-align: left;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
  text-transform: none;

  span {
    display: flex;
    align-items: center;
    transition: 0.3s ${customBezierEase};
    text-transform: none;
    padding: 24px 2px;
    ${font('body', 'bold')}
    ${fontSize(14, 20, 0)}
  }

  svg {
    margin-right: 5px;
    fill: ${colors.primary};
    transition: 0.3s ${customBezierEase};
  }

  &.parent-add {
    padding: 0 0;

    span {
      padding-left: 0;
    }
  }
`;

export const Box = styled.div<{ topTier?: boolean }>`
  background: ${colors.white};
  align-items: center;
  grid-template-rows: 1fr auto;

  ${(props) =>
    props.topTier
      ? `
    border: 1px solid ${colors.greyLightLight};
    margin-bottom: 1rem;
    padding: 0 ${padding.listItem.padding}rem;
  `
      : null}

  &.alert {
    background: ${colors.highlightBackground};
    margin: 0rem -1.25rem;
    padding: 0rem 1.25rem;

    ${Dots},
    ${Action} {
      svg {
        fill: ${colors.highlight};
      }
    }

    ${Title},
    ${SubTitle} {
      color: ${colors.highlight};
    }
  }
`;
