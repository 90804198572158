import { Office } from 'API';
import { OfficeExtended } from '../ManageOffices';

interface IFormat {
  offices: OfficeExtended[];
  accordionsSort: string[];
  sortAccordions: (
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
    items?: any[],
    id?: string
  ) => void;
  createOffice: (temp: boolean) => void;
  updateOffice: ({
    id,
    officeID,
    name,
    group,
    sort,
    active,
    timezone,
    hasChildren,
  }: {
    id: string;
    officeID: string;
    name: string;
    group: string;
    sort: number;
    active: boolean;
    timezone: string;
    hasChildren: boolean;
  }) => void;
  activeOffice: (id: string, newStatus: boolean, prev: boolean) => void;
}

export const formatDataForActiveAccordion = ({
  offices,
  accordionsSort,
  sortAccordions,
  createOffice,
  updateOffice,
  activeOffice,
}: IFormat): any => {
  const formatOffice = (office: any, index: number, officesCount: number) => {
    const activeFunc = (newStatus: boolean) =>
      activeOffice(office.id, newStatus, office.active);

    const formattedOffice = {
      id: office.id,
      title: office.name,
      index: index,
      originalIndex: office.sort,
      sortable: true,
      editFunc: () => {
        updateOffice(office);
      },
      variation: true,
      active: office.fakeActive,
    } as {
      id: any;
      title: any;
      index: number;
      originalIndex: any;
      sortable: boolean;
      editFunc: () => void;
      variation: boolean;
      active: any;
      activeFunc?: (newStatus: boolean) => void; // Add optional activeFunc property
    };

    // Only adds deactivate function if more than one office is active
    // so that there is always one active office
    if (officesCount > 1) {
      formattedOffice.activeFunc = activeFunc;
    }

    return formattedOffice;
  };
  return {
    accordionContainer:
      offices
        .slice()
        ?.filter((x: OfficeExtended) => x.fakeActive)
        ?.sort((x: Office, y: Office) => {
          return accordionsSort.indexOf(x.id) - accordionsSort.indexOf(y.id);
        })
        .map((office, index, officesArr) =>
          formatOffice(office, index, officesArr.length)
        ) || [],
    addFunc: () => {
      createOffice(true);
    },
    addFuncText: 'Add Additional Offices',
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortAccordions({ oldIndex, newIndex }, items, 'offices');
    },
  };
};

export const formatDataForInactiveAccordion = ({
  offices,
  updateOffice,
  activeOffice,
}: IFormat): any => {
  const formatOffice = (office: any, index: number) => {
    return {
      id: office.id,
      title: office.name,
      index: index,
      originalIndex: office.sort,
      editFunc: () => {
        updateOffice(office);
      },
      variation: true,
      activeFunc: (newStatus: boolean) =>
        activeOffice(office.id, newStatus, office.active),
      active: office.fakeActive,
    };
  };
  return {
    accordionContainer:
      offices
        .slice()
        ?.filter((x: OfficeExtended) => !x.fakeActive)
        ?.sort((x: Office, y: Office) => {
          if (!x.name) {
            return -1;
          }
          if (!y.name) {
            return -1;
          }
          if (x.name < y.name) {
            return -1;
          } else if (x.name > y.name) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(formatOffice) || [],
    addFuncText: 'Add Additional Offices',
  };
};
