import { Page } from 'API';
import Modal from 'containers/Modal';
import React, { useState } from 'react';
import IToast from 'types/ToastType';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  Form,
  SubmitButton,
  WYSIWYGInput,
} from './styles';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import { cleanString, postWYSIWYG } from 'utils/functions';

interface Props {
  data: Page;
  toggle: (refetch?: boolean) => void;
  toast: IToast;
}

const UPDATE_PAGE = gql(mutations.updatePage);

const TabModal = ({ data, toggle, toast }: Props): JSX.Element => {
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const methods = useForm<any>({
    defaultValues: {
      body: data?.body,
      id: data?.id,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Tab updated');
      toggle(true);
    },
    onError: () => {
      toast.error('Error updating tab');
    },
  });

  return (
    <>
      <Modal
        id="sectionModal"
        title={`Edit ${data?.office?.name}`}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('Add Form', input);

            const shortBody = postWYSIWYG(input.body);
            const cleanShortBody = cleanString(shortBody);
            await updatePage({
              variables: {
                input: {
                  id: data.id,
                  body: shortBody,
                  bodyLow: cleanShortBody.toLowerCase(),
                },
              },
            });
          })}
        >
          <WYSIWYGInput
            {...methods}
            label="Office Content"
            id="template"
            name="body"
            placeholder="Enter Tab Content Here"
            defaultValue={data?.body || ''}
            setValue={methods.setValue}
            error={methods?.formState?.errors?.body}
            waitForToolbar={true}
          />
          <ButtonContainer>
            <SubmitButton type="submit" disabled={!isDirty}>
              Save
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default TabModal;
