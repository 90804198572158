import React from 'react';
import { Container, Description, Header, Section, Title } from './styles';
import { Props } from './SectionContainer.types';

const SectionContainer = ({
  title,
  description,
  children,
  includePadding = true,
  methods,
}: Props): JSX.Element => {
  const renderChildren = (): React.ReactNode => {
    if (!children) return;
    if (!methods) {
      return children;
    }
    return React.Children.map(children, (child: React.ReactElement) => {
      return React.cloneElement(child, { methods: methods, ...methods });
    });
  };
  return (
    <Container>
      <Header>
        {title && <Title>{title}</Title>}
        {description && <Description>{description}</Description>}
      </Header>
      {includePadding ? (
        <Section>{renderChildren()}</Section>
      ) : (
        renderChildren()
      )}
    </Container>
  );
};

export default SectionContainer;
