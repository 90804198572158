import styled from 'styled-components';
import { colors } from 'shared/variables';
// import { Link } from 'react-router-dom';
type TypographyI = {
  type: {
    [key: string]: string;
  };
  weight: {
    [key: string]: number;
  };
  size: {
    [key: string]: string;
  };
  fontSize: {
    [key: string]: string;
  };
  lineHeight: {
    [key: string]: string;
  };
};

export const typography: TypographyI = {
  type: {
    primary: 'Surveyor Text, Helvetica, Arial, sans-serif',
    secondary: 'Brandon Grotesque Black, Helvetica, Arial, sans-serif',
    secondaryBold: 'Brandon Grotesque Bold, Helvetica, Arial, sans-serif',
    secondaryNav: 'Brandon Grotesque, Helvetica, Arial, sans-serif',
    body: 'TT Commons, Helvetica, Arial, sans-serif',
    bodyBold: 'TT Commons Extra Bold, Helvetica, Arial, sans-serif',
    accent: 'Espa, Helvetica, Arial, sans-serif',
    sans: 'Brandon Grotesque Bold, "Helvetica Neue", Helvetica, Arial, sans-serif',
    serif:
      'Surveyor Text, Consolas, "Liberation Mono", Menlo, Courier, monospace',
    error: 'Inconsolata, TT Commons, Helvetica, Arial, sans-serif',
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  size: {
    s1: '12',
    s2: '14',
    s3: '16',
    m1: '20',
    m2: '24',
    m3: '28',
    l1: '32',
    l2: '40',
    l3: '48',
    code: '90',
  },
  fontSize: {
    h1: '4.0625rem',
    h2: '3rem',
    h3: '1.5rem',
    h4: '1.25rem',
    h5: '1.25rem',
    h6: '0.875rem',
    callout: '1rem',
    eyebrow: '0.75rem',
    eyebrowSmall: '0.5625rem',
    bodyLarge: '1.25rem',
    body: '.875rem',
    characterQuote: '0.6875rem',
  },
  lineHeight: {
    h1: '4.1875rem',
    h2: '3.25rem',
    h3: '1.6875rem',
    h4: '1.5rem',
    h5: '1.375rem',
    h6: '1rem',
    callout: '1.5rem',
    eyebrow: '1.25rem',
    eyebrowSmall: '1.0625rem',
    bodyLarge: '1.625rem',
    body: '0.875rem',
    characterQuote: '1rem',
  },
};

export const font = (
  letterform: string,
  weight = 'regular',
  rendering = true
): string => `
  font-family: ${typography.type[letterform]};
  font-weight: ${typography.weight[weight]};
  ${
    rendering &&
    `-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    `
  }
`;

export const fonts = {
  primary: 'Surveyor Text, Helvetica, Arial, sans-serif',
  secondary: 'Brandon Grotesque Black, Helvetica, Arial, sans-serif',
  secondaryBold: 'Brandon Grotesque Bold, Helvetica, Arial, sans-serif',
  secondaryNav: 'Brandon Grotesque, Helvetica, Arial, sans-serif',
  body: 'TT Commons, Helvetica, Arial, sans-serif',
  bodyBold: 'TT Commons Extra Bold, Helvetica, Arial, sans-serif',
  accent: 'Espa, Helvetica, Arial, sans-serif',
  sans: 'Brandon Grotesque Bold, "Helvetica Neue", Helvetica, Arial, sans-serif',
  serif:
    'Surveyor Text, Consolas, "Liberation Mono", Menlo, Courier, monospace',
};

export const fontSize = (
  fontSizePx: number,
  lineHeight?: number,
  letterSpacing?: number
): string => {
  return `
    font-size: ${fontSizePx * 0.0625}rem;

    ${
      lineHeight &&
      `
      line-height: ${lineHeight * 0.0625}rem;
    `
    }

    ${
      letterSpacing &&
      `
    letter-spacing: ${letterSpacing * 0.0625}rem;
    `
    }
  `;
};

interface FluidTypeI {
  minVw: string;
  maxVw: string;
  minFontSize: string;
  maxFontSize: string;
  lineHeightRatio: string;
}

export const fluidType = ({
  minVw,
  maxVw,
  minFontSize,
  maxFontSize,
  lineHeightRatio,
}: FluidTypeI): string => {
  const u1 = minVw.match(/[a-zA-Z]+/g) || '';
  const u2 = maxVw.match(/[a-zA-Z]+/g) || '';
  const u3 = minFontSize.match(/[a-zA-Z]+/g) || '';
  const u4 = maxFontSize.match(/[a-zA-Z]+/g) || '';

  if (u1[0] === u2[0] && u1[0] === u3[0] && u1[0] === u4[0]) {
    return `
        font-size: ${minFontSize};
        line-height: ${parseInt(minFontSize) * parseInt(lineHeightRatio)}
        @media screen and (min-width: ${minVw}) {
          font-size: calc(${minFontSize} + ${
      parseInt(maxFontSize, 10) - parseInt(minFontSize, 10)
    } * ((100vw - ${minVw}) / ${parseInt(maxVw, 10) - parseInt(minVw, 10)}));
          line-height: calc((${minFontSize} + ${
      parseInt(maxFontSize, 10) - parseInt(minFontSize, 10)
    } * ((100vw - ${minVw}) / ${
      parseInt(maxVw, 10) - parseInt(minVw, 10)
    })) * ${lineHeightRatio});
        }

        @media screen and (min-width: ${maxVw}) {
          font-size: ${maxFontSize};
          line-height: ${parseInt(maxFontSize) * parseInt(lineHeightRatio)}
        }
    `;
  }
  console.log(
    `Detected mixed units. Please use the same units for all parameters. ${u1} , ${u2} , ${u3} , ${u4}`
  );
  return '';
};

export const H1 = styled.h1`
  font-family: ${fonts.primary};
  ${fontSize(65, 67, -0.2)};
  color: ${colors.brownLight};
`;

export const H2 = styled.h2`
  font-family: ${fonts.primary};
  ${fontSize(48, 52, -0.2)};
  color: ${colors.brownLight};
`;

export const H3 = styled.h3`
  font-family: ${fonts.primary};
  ${fontSize(24, 27, 0)};
  color: ${colors.brownLight};
`;

export const H4 = styled.h4`
  font-family: ${fonts.primary};
  font-weight: 300;
  ${fontSize(20, 24, 0)};
  color: ${colors.brownLight};
`;

export const H5 = styled.h5`
  font-family: ${fonts.sans};
  ${fontSize(20, 22, 0)};
  color: ${colors.brownLight};
  font-weight: normal;
`;

export const Callout = styled.i`
  font-family: ${fonts.primary};
  ${fontSize(16, 24, -0.2)};
  color: ${colors.brownLight};
  font-style: italic;
`;

export const Eyebrow = styled.span<{ small?: boolean }>`
  font-family: ${fonts.secondary};
  ${fontSize(12, 20, 1.4)};
  color: ${colors.brownLight};
  text-transform: uppercase;

  ${(props) =>
    props.small
      ? `
      ${fontSize(9, 17, 1.75)};
  `
      : `
      ${fontSize(12, 20, 1.4)};
  `}
`;

export const Body = styled.span<{ large?: boolean; bold?: boolean }>`
  font-family: ${fonts.body};
  color: ${colors.brownMedium};

  ${(props) =>
    props.large
      ? `
      ${fontSize(20, 26, 0)};
  `
      : `
      ${fontSize(14, 20, 0)};
  `}

  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const bodyCopy = (): string => `
  font-family: ${fonts.body};
  color: ${colors.brownMedium};
  ${fontSize(14, 20, 0)};
`;

export const Quote = styled.span`
  font-family: ${fonts.accent};
  color: ${colors.brownLight};
  ${fontSize(11, 16, 0.4)};
  text-transform: uppercase;
`;
