import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';

export const getClient = (
  awsConfig: unknown
): ApolloClient<NormalizedCacheObject> => {
  const auth = {
    // @ts-ignore
    type: awsConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      const session = await Auth.currentSession();
      const jwt = session.getIdToken().getJwtToken();
      //const json = parseJwt(jwt);
      //debug(json);
      //console.log('jwt: ', jwt);
      return jwt;
    },
  };

  // @ts-ignore
  const url = awsConfig.aws_appsync_graphqlEndpoint;
  // @ts-ignore
  const region = awsConfig.aws_appsync_region;
  const link = ApolloLink.from([
    // @ts-ignore
    createAuthLink({ url, region, auth }),
    // @ts-ignore
    createSubscriptionHandshakeLink({ url, region, auth }),
  ]);

  const cache = new InMemoryCache({
    typePolicies: {
      Page: {
        fields: {
          pages: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });
  const client = new ApolloClient({
    link,
    cache: cache,
    // @ts-ignore
    disableOffline: true,
  });

  return client;
};
