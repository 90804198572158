import { Office } from 'API';
import Modal from 'containers/Modal';
import React, { useMemo, useEffect, useState } from 'react';
// import slugify from 'slugify';
import * as queries from 'graphql/queries';
import { validateMapLink } from 'utils/functions';
import TextInput from 'components/FormComponents/TextInput';
import IToast from 'types/ToastType';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import { ButtonContainer, CancelButton, Form, SubmitButton } from './styles';
import {
  // Pacman,
  FormContainer,
  StatusContainer,
  Subtext,
} from './styles-url-field';
import { ReactComponent as Close } from 'images/icons/fnd_close.svg';
import { ReactComponent as Check } from 'images/icons/fnd_check.svg';
import { useQuery, gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';

interface Props {
  data: Office;
  toggle: (refetch?: boolean) => void;
  toast: IToast;
}

const CREATE_PAGE = gql(mutations.createPage);
const LIST_PAGES_BY_PAGE_ID = gql(queries.listPagesByPageId);
const UPDATE_PAGE = gql(mutations.updatePage);

const TabModal = ({ data, toggle, toast }: Props): JSX.Element => {
  const [customError, setCustomError] = useState<boolean>(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  // const [url, setUrl] = useState('');

  const {
    data: pageData,
    // loading: pageLoading,
    refetch,
  } = useQuery(LIST_PAGES_BY_PAGE_ID, {
    variables: {
      pageId: 'page-lunch-spots',
    },
  });

  const methods = useForm<any>({
    defaultValues: {
      id: data?.id,
      // url: data?.url,
    },
  });
  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = methods;

  console.log('[TabModal] data: ', data);
  console.log('[TabModal] pageData: ', pageData);

  const pageDataOffice = useMemo(() => {
    const offices = pageData?.listPagesByPageId?.items;
    let foundOffice = null;
    if (offices) {
      foundOffice = offices.find((o: any) => o.officeId === data?.id);
    }
    return foundOffice;
  }, [data, pageData]);
  console.log('pageDataOffice: ', pageDataOffice);

  useEffect(() => {
    if (pageDataOffice) {
      const mapUrl = JSON.parse(pageDataOffice.meta)?.map || false;
      console.log('mapUrl: ', mapUrl);
      // setValue('url', mapUrl);
      const $input: any = document.querySelector('#url');
      $input.value = mapUrl;
    }
  }, [setValue, pageDataOffice]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Tab updated');
      toggle(true);
    },
    onError: () => {
      toast.error('Error updating tab');
    },
  });

  const [createPage] = useMutation(CREATE_PAGE);

  const onSubmit = (fields: any): void => {
    console.log('[onSubmit] fields: ', fields);
  };

  return (
    <>
      <Modal
        id="sectionModal"
        title={`Edit ${data?.name}`}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('[onSubmit form]', input);
            const { id } = pageDataOffice || {};
            let newPage = null;
            if (!id) {
              // Page not created yet to store this data
              // const newId = `page-${slugify(data?.id).toLowerCase()}`;
              newPage = await createPage({
                variables: {
                  input: {
                    officeId: data?.id,
                    sort: 0,
                    pageId: 'page-lunch-spots',
                    title: 'office tab',
                    label: 'office tab',
                    slug: 'lunch-spots--template',
                    status: 'active',
                  },
                },
              });
              refetch();
            }
            const idToUpdate = id || newPage?.data?.createPage?.id;
            const meta = {
              map: input.url,
            };
            await updatePage({
              variables: {
                input: {
                  id: idToUpdate,
                  meta: JSON.stringify(meta),
                },
              },
            });
          })}
        >
          <FormContainer>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                id="url"
                name="url"
                required={false}
                // defaultValue={url}
                defaultValue={''}
                placeholder="Enter URL Here"
                label="Google Map URL"
                register={register}
                setValue={(name, v) => setValue(name, v)}
                setError={(urlValue, error) => setError('url', error)}
                clearErrors={() => clearErrors('url')}
                error={!!(customError || errors?.url)}
                errorMessage="Invalid Google map embed URL."
                onBlur={(e) => {
                  const value = e.target.value;
                  setCustomError(!validateMapLink(value));
                }}
              />

              <Subtext>
                This field only accepts URLs from
                https://www.google.com/maps/d/u/0/embed.
              </Subtext>
            </form>
            <StatusContainer>
              {customError ? <Close /> : <Check />}
            </StatusContainer>
          </FormContainer>
          <ButtonContainer>
            <SubmitButton type="submit" disabled={customError || !isDirty}>
              Save
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default TabModal;
