interface IFormat {
  phrases: string[];
  addPhrase: ({ length }: { length: number }) => void;
  updatePhrase: (id: string) => void;
  deletePhrase: any;
}

export const formatDataForAccordion = ({
  phrases,
  addPhrase,
  updatePhrase,
  deletePhrase,
}: IFormat): any => {
  console.log(phrases);
  // include any as type because I can't merge Quicklink to include order
  const formatPhrase = (phrase: string | any, index: number): any => {
    return {
      id: phrase,
      title: phrase,
      index: index,
      originalIndex: 0,
      sortable: false,
      editFunc: () => {
        updatePhrase(phrase);
      },
      deleteFunc: () => {
        deletePhrase(phrase);
      },
    };
  };
  return {
    accordionContainer: phrases.map(formatPhrase) || [],
    addFuncText: 'Add Filtered Phrase',
    addFunc: () => {
      addPhrase({ length: phrases.length || 0 });
    },
  };
};
