import styled from 'styled-components';
import { colors } from 'shared/variables';
import { bodyCopy } from 'shared/typography';
import {
  bodyCopyBold,
  bodyCopyLarge,
  eyebrowSmall,
  h2,
  h3,
  h4,
  h5,
  h6,
  WYSIWYGStyles,
} from 'shared/extends';
import { size } from 'shared/mixins';

export const Wrapper = styled.div`
  &.form-input {
    max-width: 580px;
  }
  &.disabled {
    pointer-events: none;
  }
`;

export const Editor = styled.div`
  .ql-toolbar {
    > button.ql-active {
      .ql-stroke {
        stroke: ${colors.highlight};
      }
      .ql-fill {
        fill: ${colors.highlight};
      }
    }
    border: 1px solid ${colors.greyMedium};
    padding: 11px 10px;

    .ql-header {
      margin-right: 4px;

      .ql-picker-label {
        ${eyebrowSmall};
        color: ${colors.brownMedium};
      }

      &.ql-expanded {
        .ql-picker-label,
        .ql-picker-options {
          border-color: transparent;
        }
        .ql-picker-options {
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          max-height: 128px;
          padding: 16px;
          overflow-y: auto;
        }
      }

      .ql-picker-item {
        &[data-value='body'] {
          ${bodyCopy};
        }
        &[data-value='bodyBold'] {
          ${bodyCopyBold};
        }
        &[data-value='bodyLarge'] {
          ${bodyCopyLarge};
        }
        &[data-value='2'] {
          ${h2};
        }
        &[data-value='3'] {
          ${h3};
        }
        &[data-value='4'] {
          ${h4};
        }
        &[data-value='5'] {
          ${h5};
        }
        &[data-value='6'] {
          ${h6};
        }
      }
    }
    button {
      .ql-formats {
        display: inline-flex;
        align-items: center;

        > button {
          background-size: 18px 18px !important;
          margin-right: 4px;
          ${size('18px')};

          &:last-child {
            margin-right: 0;
          }

          svg {
            display: none;
          }
        }
      }
    }
  }

  #editor,
  .quill {
    .ql-container {
      border: 1px solid ${colors.greyMedium};
    }

    .ql-editor {
      height: 215px;

      ${WYSIWYGStyles};
    }

    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
      tr {
      }
      td {
        border: 1px solid ${colors.brownMedium};
        padding: 5px;
        min-height: 30px;
      }
    }

    p {
      ${bodyCopy};

      strong,
      b {
        ${bodyCopyBold};
      }

      s {
        ${bodyCopyLarge};
        text-decoration: none;
      }
    }

    .ql-table-menu {
      z-index: 2;
      background: ${colors.white};
    }
  }

  &:hover {
    .ql-toolbar {
      border-color: ${colors.brownMedium};
    }

    .quill {
      .ql-container {
        border-color: ${colors.brownMedium};
      }
    }
  }

  &.focus {
    .ql-toolbar {
      border-color: ${colors.focus};
    }

    .quill {
      .ql-container {
        border-color: ${colors.focus};
      }
    }
  }

  &.error {
    .ql-toolbar {
      border-color: ${colors.highlight};
    }

    .quill {
      .ql-container {
        border-color: ${colors.highlight};
      }
    }
  }

  &.disabled {
    .ql-editor {
      background: ${colors.greyMedium};
      pointer-events: none;
    }
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: ${colors.highlight};

    svg {
      .ql-stroke {
        stroke: ${colors.highlight};
      }
      .ql-fill {
        fill: ${colors.highlight};
      }
    }
  }
`;

export const EditorContent = styled.div`
  position: relative;
`;

export const Error = styled.p`
  ${bodyCopy};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;

export const Disclaimer = styled.span`
  ${bodyCopy};
  color: ${colors.brownLight};
  margin-top: 6px;
`;
