import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'containers/Modal';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import IToast from 'types/ToastType';
import slugify from 'slugify';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import {
  CustomTextInput,
  DeactivateButton,
  Form,
  SubmitButton,
  CancelButton,
  ButtonContainer,
  CustomSelectInput,
} from '../styles';
import { Location } from 'API';
import DeactivateForm from '../DeactivateForm/DeactivateForm';
import { OfficeExtended } from '../../ManageOffices';

export const CREATE_OFFICE = gql(mutations.createOffice);
export const CREATE_LOCATION = gql(mutations.createLocation);
export const UPDATE_OFFICE = gql(mutations.updateOffice);

interface IAddOfficeFormProps {
  toggle: (refetch?: boolean, deactivateOffice?: boolean) => void;
  toast: IToast;
  defaultValues?: {
    id: string;
    officeID: string;
    name: string;
    group: string;
    sort?: number;
    active?: boolean;
    timezone?: string;
    hasChildren: boolean;
  };
  length?: number;
  memberLocations: Location[];
  offices: OfficeExtended[];
}

const OfficeForm = ({
  toggle,
  toast,
  defaultValues,
  length,
  offices,
}: IAddOfficeFormProps): JSX.Element => {
  const [deactivatingOffice, setDeactivatingOffice] = useState<{
    id: string;
  } | null>(null);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [officeCreated, setOfficeCreated] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const { id, active } = defaultValues || {};
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isDirty },
    getValues,
  } = useForm<any>({
    defaultValues: {
      id: defaultValues?.id || '',
      name: defaultValues?.name || '',
      group: defaultValues?.group || '',
      timezone: defaultValues?.timezone || '',
    },
  });
  const defaultNameErrorMessage = 'Enter a name';
  const [nameErrorMessage, setNameErrorMessage] = useState(
    defaultNameErrorMessage
  );

  const defaultGroupErrorMessage = 'Enter a Google Group';
  const [groupErrorMessage, setGroupErrorMessage] = useState(
    defaultGroupErrorMessage
  );

  const checkObjValues = (obj: any) => {
    for (const key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const formData = getValues();
    delete formData.id;
    setIsFormFilled(checkObjValues(formData));
  });

  const [createOffice] = useMutation(CREATE_OFFICE);

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onCompleted: () => {
      if (officeCreated) {
        toast.success('Office created');
      }
      setOfficeCreated(false);
    },
    onError: () => {
      toast.error('Error creating office');
    },
  });

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted: () => {
      toast.success('Office updated');
    },
    onError: () => {
      toast.error('Error updating office');
    },
  });

  const options = useMemo(() => {
    return [
      {
        label: 'Please select a timezone',
        value: null,
      },
      {
        label: 'Pacific Time',
        value: 'PST',
        key: 'PST',
      },
      {
        label: 'Mountain Time',
        value: 'MST',
        key: 'MST',
      },
      {
        label: 'Central Time',
        value: 'CST',
        key: 'CST',
      },
      {
        label: 'Eastern Time',
        value: 'EST',
        key: 'EST',
      },
    ];
  }, []);

  // Checks the proposed active state (client side) of the office active status
  // if only 1 is currently active, disable any "deactivate" functionality from
  // accordion and modal form in order to ensure that at least 1 office is
  // active at all times.
  const fakeActiveOffices = offices.filter((office) => office.fakeActive);
  const isOnlyOneFakeActive = fakeActiveOffices.length === 1;

  return (
    <>
      <Modal
        id="officeModal"
        title={id ? 'Edit Office' : 'Add Office'}
        description="Edit the office details below."
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(async (input) => {
            // If an existing office submission, its an update
            if (id) {
              const currentOffice = offices.find((office) => office.id === id);

              /* This is checking to see if the office name is taken. If it does, it will not update office. */
              const invalidName = offices.some(
                (office) =>
                  office.name?.toLowerCase().trim() ===
                    input.name?.toLowerCase().trim() && office.id !== id
              );

              if (invalidName) {
                setNameErrorMessage('Office Already Exists');
                setError('name', { type: 'manual', message: 'testing' });
              }

              /* This is checking to see if the office google group is taken. If it does, it will not update office. */
              let invalidGroup = false;
              let invalidOfficeName = null;
              offices.forEach((office) => {
                if (
                  office.group?.toLowerCase().trim() ===
                    input.group?.toLowerCase().trim() &&
                  office.id !== id
                ) {
                  invalidGroup = true;
                  invalidOfficeName = office.name;
                }
              });

              if (invalidGroup) {
                setGroupErrorMessage(
                  `The ${invalidOfficeName} office is currently using this Google Group, please update and try again.`
                );
                setError('group', { type: 'manual', message: 'testing' });
                invalidGroup = true;
              }

              if (invalidName || invalidGroup) {
                return;
              }

              await updateOffice({
                variables: {
                  input: {
                    id,
                    name: input.name,
                    timezone: input.timezone,
                    active: currentOffice?.active,
                    group: input.group,
                  },
                },
              });
            } else {
              // If not existing, this is a create office request
              const officeID = slugify(input.name).toLowerCase();

              /* This is checking to see if the office name is taken. If it does, it will not create office. */
              const invalidName = offices.some(
                (office) =>
                  office.name?.toLowerCase().trim() ===
                  input.name?.toLowerCase().trim()
              );

              if (invalidName) {
                setNameErrorMessage('Office Already Exists');
                setError('name', { type: 'manual', message: 'testing' });
              }

              /* This is checking to see if the office google group is taken. If it does, it will not create office. */
              let invalidGroup = false;
              let invalidOfficeName = null;
              offices.forEach((office) => {
                if (
                  office.group?.toLowerCase().trim() ===
                  input.group?.toLowerCase().trim()
                ) {
                  invalidGroup = true;
                  invalidOfficeName = office.name;
                }
              });

              if (invalidGroup) {
                setGroupErrorMessage(
                  `The ${invalidOfficeName} office is currently using this Google Group, please update and try again.`
                );
                setError('group', { type: 'manual', message: 'testing' });
                invalidGroup = true;
              }

              if (invalidName || invalidGroup) {
                return;
              }

              await createOffice({
                variables: {
                  input: {
                    id: officeID,
                    name: input.name,
                    timezone: input.timezone,
                    group: input.group,
                    active: true,
                    sort: length,
                  },
                },
              });

              await createLocation({
                variables: {
                  input: {
                    officeID,
                    active: true,
                    sort: length,
                    type: 'member',
                  },
                },
              });
              setOfficeCreated(true);
            }
            toggle(true);
          })}
          className="modalForm"
        >
          <CustomTextInput
            placeholder="Enter Office Name Here"
            id="name"
            maxLength={50}
            required={true}
            hideRequired={true}
            name="name"
            label="Office Name"
            register={register}
            setError={(name, error) => setError('name', error)}
            clearErrors={() => clearErrors('name')}
            error={!!errors?.name}
            errorMessage={nameErrorMessage}
            defaultValue={defaultValues?.name || ''}
            setValue={setValue}
            onChange={() => setNameErrorMessage(defaultNameErrorMessage)}
            disclaimer="Office name displayed within the new tabbed template. FAQ, etc."
          />
          <CustomTextInput
            placeholder="Enter Google Group Here"
            id="group"
            required={true}
            hideRequired={true}
            name="group"
            label="Google Group"
            register={register}
            setError={(group, error) => setError('group', error)}
            clearErrors={() => clearErrors('group')}
            error={!!errors?.group}
            errorMessage={groupErrorMessage}
            defaultValue={defaultValues?.group || ''}
            setValue={setValue}
            disclaimer="Google group associated with the office."
          />

          <CustomSelectInput
            options={options}
            name="timezone"
            error={!!errors?.timezone}
            setError={(timezone, error) => setError('timezone', error)}
            clearErrors={() => clearErrors('timezone')}
            id="timezone"
            key="timezone"
            label="Time Zone"
            register={register}
            setValue={setValue}
            required={true}
            hideRequired={true}
            defaultValue={defaultValues?.timezone || ''}
            disclaimer="Timezone associated with the office."
            className="timezone"
          />

          <ButtonContainer>
            <SubmitButton
              type="submit"
              className="ModalSubmit"
              disabled={!isDirty || !isFormFilled}
            >
              {id ? 'Save' : 'Add Office'}
            </SubmitButton>
            <CancelButton
              type="button"
              className="Modalcancel"
              value="Cancel"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {id && !isOnlyOneFakeActive && active ? (
              <DeactivateButton
                delete
                type="button"
                onClick={() => setDeactivatingOffice({ id })}
              >
                Deactivate
              </DeactivateButton>
            ) : null}
          </ButtonContainer>
        </Form>
      </Modal>
      {deactivatingOffice && (
        <DeactivateForm
          toggle={async (getRefetch?: boolean) => {
            setDeactivatingOffice(null);
            if (getRefetch) {
              toggle(true, true);
            }
          }}
          preventUnlock={true}
        />
      )}
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default OfficeForm;
