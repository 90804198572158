import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { getHoliday, getPage } from 'graphql/queries';
import { listYears, getYear } from './queries';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Container } from './styles';
import PageTop from 'components/PageTop';
import SectionContainer from 'components/SectionContainer';
import IToast from 'types/ToastType';
import * as mutations from 'graphql/mutations';
import HolidayForm from './Forms/HolidayForm/HolidayForm';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import Accordion from 'components/Accordion';
import AddYearForm from './Forms/AddYearForm/AddYearForm';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import UpdateYearForm from './Forms/UpdateYearForm/UpdateYearForm';
import { listPages } from 'utils/queries';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_YEARS = gql(listYears);
export const GET_YEAR = gql(getYear);
export const GET_HOLIDAY = gql(getHoliday);
export const LIST_PAGES = gql(listPages);
export const UPDATE_PAGE = gql(mutations.updatePage);

interface IHoliday {
  toast: IToast;
}

const Holidays = ({ toast }: IHoliday): JSX.Element => {
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [creatingYear, setCreatingYear] = useState<number | null>(null);
  const [gettingYear, setGettingYear] = useState<null | string>(null);
  const [gettingHoliday, setGettingHoliday] = useState<null | string>(null);
  const [creatingHoliday, setCreatingHoliday] = useState<null | {
    length: number;
    id: string;
    year: number;
  }>(null);
  const [updatingHoliday, setUpdatingHoliday] = useState<number | null>(null);
  const [deleteForm, setDeleteForm] = useState<{
    id: string;
    type: string;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [accordionIsOpen, setAccordionIsOpen] = useState<any[]>([]);
  // For meta to check against slug

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const {
    loading,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-holiday',
    },
  });

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const {
    data: yearsData,
    refetch: yearsRefetch,
    loading: yearsLoading,
  } = useQuery(LIST_YEARS);

  const years = useMemo(() => {
    return yearsData?.listYears?.items;
  }, [yearsData]);

  const { data: yearData } = useQuery(GET_YEAR, {
    variables: {
      id: gettingYear,
    },
    skip: typeof gettingYear !== 'string',
  });

  const year = useMemo(() => {
    if (!gettingYear) return null;
    return yearData?.getYear;
  }, [yearData, gettingYear]);

  const { data: holidayData } = useQuery(GET_HOLIDAY, {
    variables: {
      id: gettingHoliday,
    },
    skip: typeof gettingHoliday !== 'string',
  });

  const holiday = useMemo(() => {
    if (!gettingHoliday) return null;

    return holidayData?.getHoliday || null;
  }, [gettingHoliday, holidayData]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      refetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const createHoliday = useCallback(
    (length: number, id: string, holidayYear: string) => {
      setCreatingHoliday({ length, id, year: parseInt(holidayYear) });
    },
    []
  );

  const handleGettingHoliday = (id: string, holidayYear: string) => {
    setGettingHoliday(id);
    setUpdatingHoliday(parseInt(holidayYear));
  };

  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!years) return null;
    return formatDataForAccordion({
      years,
      accordionIsOpen: accordionIsOpen,
      createYear: setCreatingYear,
      updateYear: setGettingYear,
      deleteYear: setDeleteForm,
      createHoliday,
      updateHoliday: handleGettingHoliday,
      deleteHoliday: setDeleteForm,
    });
  }, [years, accordionIsOpen, createHoliday]);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-holiday',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (loading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        data={data}
        slugList={slugList}
        setIsDirtyForm={setIsDirtyForm}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        title="Set Holiday Dates"
        description="Holiday dates set here will be accounted for when an employee is requesting paid time off."
        includePadding={false}
      >
        {yearsLoading || !accordionData ? (
          <></>
        ) : (
          <Accordion
            {...accordionData}
            accordionIsOpen={accordionIsOpen}
            setAccordionIsOpen={setAccordionIsOpen}
          />
        )}
      </SectionContainer>
      {creatingYear && (
        <AddYearForm
          toast={toast}
          toggle={() => {
            yearsRefetch();
            setCreatingYear(null);
          }}
          length={creatingYear}
          years={years}
        />
      )}
      {deleteForm && (
        <DeleteForm
          toast={toast}
          toggle={() => {
            setDeleteForm(null);
            yearsRefetch();
          }}
          id={deleteForm.id}
          type={deleteForm.type}
        />
      )}
      {year && (
        <UpdateYearForm
          years={years}
          toast={toast}
          toggle={() => {
            setGettingYear(null);
            yearsRefetch();
          }}
          defaultValues={year}
        />
      )}
      {holiday && updatingHoliday && (
        <HolidayForm
          toast={toast}
          defaultValues={holiday}
          year={updatingHoliday}
          toggle={() => {
            yearsRefetch();
            setGettingHoliday(null);
            setUpdatingHoliday(null);
          }}
        />
      )}
      {creatingHoliday && (
        <HolidayForm
          toast={toast}
          yearID={creatingHoliday.id}
          length={creatingHoliday.length}
          year={creatingHoliday.year}
          toggle={() => {
            yearsRefetch();
            setCreatingHoliday(null);
          }}
        />
      )}
    </Container>
  );
};

export default Holidays;
