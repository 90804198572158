import styled from 'styled-components';
import TextInput from 'components/FormComponents/TextInput';
import WYSIWYG from 'components/FormComponents/WYSIWYG';

export const Input = styled(TextInput)`
  max-width: 316px;
  margin: 0 0 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const WYSIWYGInput = styled(WYSIWYG)`
  max-width: 580px;
  margin: 0 0 32px;
`;
