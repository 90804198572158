import { IconSimpleArrow } from 'components/IconsView';
import React, { useEffect } from 'react';
import {
  ArrowButton,
  Container,
  Content,
  Top,
  Year,
  YearButton,
} from './styles';
import classNames from 'classnames';

interface IProps {
  currentYear: number;
  handleYearSelected: (year: number) => void;
}

const YearModal = ({
  currentYear,
  handleYearSelected,
}: IProps): JSX.Element => {
  const [viewing, setViewing] = React.useState<{
    first: number;
    last: number;
    decade: number;
    range: number[];
  }>({
    first: currentYear,
    last: currentYear,
    decade: currentYear,
    range: [currentYear],
  });

  const getDecade = (year: number) => {
    const decade = Math.floor(year / 10) * 10;
    const decadeRange = [];
    for (let i = 0; i < 10; i++) {
      decadeRange.push(decade + i);
    }

    setViewing({
      first: decade - 1,
      last: decade + 10,
      decade: decade,
      range: decadeRange,
    });
  };

  useEffect(() => {
    getDecade(currentYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear]);

  const cycleYear = (direction: number) => {
    const newDecade = viewing.decade + direction * 10;
    getDecade(newDecade);
  };

  return (
    <Container>
      <Top>
        <ArrowButton type="button" side="left" onClick={() => cycleYear(-1)}>
          <IconSimpleArrow />
        </ArrowButton>
        <Year>
          {viewing?.first + 1} - {viewing?.last - 1}
        </Year>
        <ArrowButton type="button" side="right" onClick={() => cycleYear(1)}>
          <IconSimpleArrow />
        </ArrowButton>
      </Top>
      <Content>
        {viewing?.range.map((year) => (
          <YearButton
            className={classNames({
              active: year === currentYear,
              unactive: year === viewing.first || viewing.last === year,
            })}
            type="button"
            key={year}
            onClick={() => {
              handleYearSelected(year);
              getDecade(year);
            }}
          >
            {year}
          </YearButton>
        ))}
      </Content>
    </Container>
  );
};

export default YearModal;
