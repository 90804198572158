import React, { useEffect, useMemo, useRef, useState } from 'react';
import { listQuicklinksByPageId } from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Container } from './styles';
import PageTop from 'components/PageTop';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import Quicklinks from 'components/Quicklinks';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import IToast from 'types/ToastType';
import * as queries from './queries';
import { Page } from 'API';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const LIST_QUICKLINKS_BY_PAGE_ID = gql(listQuicklinksByPageId);
const VIEW_ITEMS = gql(mutations.viewItems);

interface ISimplePage {
  toast: IToast;
  id: string;
}

const MainPageContainer = ({ toast, id }: ISimplePage): JSX.Element => {
  const quicklinksSort = useRef<AccordionItems[]>([]);
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);
  // Just for pages that have the meta and copy & text sections
  const {
    loading,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: id,
    },
  });
  // For meta to check against slug

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const {
    data: quicklinkData,
    loading: quicklinkLoading,
    refetch: quicklinkRefetch,
  } = useQuery(LIST_QUICKLINKS_BY_PAGE_ID, {
    variables: {
      pageId: id,
    },
    fetchPolicy: 'network-only',
  });
  const quicklinks = useMemo(() => {
    if (quicklinkData) {
      return quicklinkData?.listQuicklinksByPageId?.items;
    }
    return [];
  }, [quicklinkData]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });
  const [sortItems] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Items sorted');
      quicklinkRefetch();
    },
    onError: () => {
      toast.error('Error sorting items');
    },
  });
  const [viewItems] = useMutation(VIEW_ITEMS, {
    onCompleted: () => {
      toast.success('Child Pages updated');
    },
    onError: () => {
      toast.error('Error updating pages');
    },
  });

  const checkIsDirty = (q: AccordionItems[]) => {
    // check if items were sorted or changed views
    if (q.length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
  };

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    console.log('update page', props);
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);
    await updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline?.toLowerCase() || '',
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    const sendToUpdateView: { id: string; status: string }[] = [];
    if (data?.pages?.items) {
      data.pages.items.map((child: Page) => {
        if (props.status && child.status !== props.status) {
          sendToUpdateView.push({
            id: child.id,
            status: props.status,
          });
        }
      });
    }

    if (sendToUpdateView.length > 0) {
      await viewItems({
        variables: { input: { tableName: 'Page', items: sendToUpdateView } },
      });
    }

    const temp = [...quicklinksSort.current];
    const sendToUpdate: { id: string; sort: number }[] = [];
    if (temp) {
      temp.forEach((quicklink, index) => {
        if (quicklink.index !== index) {
          sendToUpdate.push({
            id: quicklink.id || '',
            sort: index,
          });
        }
      });
    }

    if (sendToUpdate.length > 0) {
      await sortItems({
        variables: { input: { tableName: 'QuickLink', items: sendToUpdate } },
      });
      quicklinkRefetch();
    }
    refetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (loading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        data={data}
        slugList={slugList}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      {quicklinkLoading ? (
        <></>
      ) : (
        <Quicklinks
          pageID={data?.id}
          toast={toast}
          slugs={slugList}
          quicklinksSorted={(q) => {
            quicklinksSort.current = q;
            checkIsDirty(q);
          }}
          refetch={quicklinkRefetch}
          quicklinks={quicklinks}
        />
      )}
    </Container>
  );
};

export default MainPageContainer;
