export const listYears = /* GraphQL */ `
  query ListYears(
    $filter: ModelYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        date
        createdAt
        updatedAt
        holidays {
          items {
            id
            title
            sort
            disclaimer
            startDate
            endDate
            createdAt
            updatedAt
            yearID
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getYear = /* GraphQL */ `
  query GetYear($id: ID!) {
    getYear(id: $id) {
      id
      title
      sort
      date
      createdAt
      updatedAt
      holidays {
        items {
          id
          title
          sort
          disclaimer
          startDate
          endDate
          createdAt
          updatedAt
          yearID
        }
        nextToken
      }
    }
  }
`;
