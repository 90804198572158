import { Location, Member } from 'API';
/*import { nanoid } from 'nanoid';*/
import AccordionTypes from 'components/Accordion/Accordion.types';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import { ISorting } from 'types/SortingType';

interface IFormat {
  locations: Location[];
  accordionsSort: ISorting[];
  faqLocations: Location[];
  accordionIsOpen: any[];
  addMember: ({
    officeID,
    length,
  }: {
    officeID: string;
    length: number;
  }) => void;
  deleteMember: ({ id, type }: { id: string; type: string }) => void;
  activeMember: (id: string, newStatus: boolean, prev: boolean) => void;
  activeOffice: (temp: boolean) => void;
  getMember: (id: string) => void;
  updateLocation: ({
    id,
    officeId,
    name,
    hasChildren,
  }: {
    id: string;
    officeId: string;
    name: string;
    hasChildren: boolean;
  }) => void;
  activeLocation: (id: string, newStatus: boolean, prev: boolean) => void;
  deleteLocation: ({
    id,
    officeID,
    type,
  }: {
    id: string;
    officeID?: string;
    type: string;
  }) => void;
  sortMembers: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[],
    id?: string,
    parentIndex?: number
  ) => void;
}

export const formatDataForAccordion = ({
  locations,
  accordionsSort,
  accordionIsOpen,
  addMember,
  getMember,
  deleteMember,
  activeMember,
  sortMembers,
}: IFormat): AccordionTypes | null => {
  const formatLocation = (location: Location, index: number) => {
    const members: (Member | null)[] = location.office.members?.items || [];

    const accordionItems: AccordionItems[] = members
      .slice()
      .sort((x: Member | null, y: Member | null) => {
        return (
          (accordionsSort[index]?.items?.findIndex(
            (item) => item === (x?.id || '')
          ) || 0) -
          (accordionsSort[index]?.items?.findIndex(
            (item) => item === (y?.id || '')
          ) || 0)
        );
      })
      .map((m, i) => {
        return {
          id: m?.id,
          index: i,
          originalIndex: i,
          title: m?.name || '',
          secondaryTitle: m?.title || '',
          editFunc: () => {
            if (m) {
              getMember(m.id);
            }
          },
          deleteFunc: () => {
            if (m) {
              deleteMember({ id: m.id, type: 'member' });
            }
          },

          activeFunc: (newStatus: boolean) => {
            activeMember(m?.id || '', newStatus, m?.active || false);
          },
          active: m?.active || false,
        };
      });

    return {
      id: location.id,
      show: accordionIsOpen.includes(location.id),
      index: index,
      originalIndex: index,
      title: location.office?.name || '',
      active: location.active,
      onSortEnd: (
        {
          oldIndex,
          newIndex,
        }: {
          oldIndex: number;
          newIndex: number;
        },
        items?: any[],
        id?: string
      ) => {
        sortMembers({ oldIndex, newIndex }, items, id);
      },
      addFuncText: `Add Additional Team Member to ${location.office?.name}`,
      addFunc: () => {
        addMember({
          officeID: location.office.id,
          length: members.length || 0,
        });
      },
      accordionItems,
    };
  };

  return {
    accordionContainer: locations
      .filter((x: Location) => x.active)
      .sort((x: Location, y: Location) => {
        return (
          (accordionsSort.findIndex((i) => i.id === x.id) || 0) -
          (accordionsSort.findIndex((i) => i.id === y.id) || 0)
        );
      })
      .map(formatLocation),
  };
};
