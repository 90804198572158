import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';
import WYSIWYG from 'components/FormComponents/WYSIWYGModal';
import TextInput from 'components/FormComponents/TextInput';

export const Form = styled.form`
  min-width: 33%;
  display: grid;
  grid-row-gap: 32px;
`;

export const Input = styled(TextInput)`
  max-width: 316px;
  margin: 0 0 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const WYSIWYGInput = styled(WYSIWYG)`
  max-width: 580px;
  margin: 0 0 32px;

  .ql-toolbar,
  .quill {
    background-color: ${colors.white};
  }
`;

export const ButtonContainer = styled.div``;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 99px;
  bottom: 58px;
`;
