/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import {
  ArrowContainer,
  Button,
  Container,
  Content,
  Month,
  Top,
  WeekLabel,
  WeekLabelContainer,
} from './styles';
import Day from './Components/Day';
import Week from './Components/Week';
import calendar from 'calendar-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { IconSimpleArrow } from 'components/IconsView';
import classNames from 'classnames';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface Props {
  start: string;
  end: string;
  dateSelected: (date: string) => void;
  dateHovered?: (date: string) => void;
  dateRange?: string[];
  className?: string;
  allowDisabledArrow?: boolean;
  handleViewChange?: (month: number, year: number) => void;
  hoverDate?: string;
  hoverDateRange?: string[];
  minDate?: string;
  maxDate?: string;
  allowWeekends?: boolean;
}

interface DayI {
  customDate: string;
  date: Date;
  day: number;
  index: {
    day: number;
    week: number;
  };
  isInLastWeekOfPrimaryMonth: boolean;
  isInPrimaryMonth: boolean;
}

// match start and end dates to use single date mode

const CalendarView = ({
  dateSelected,
  dateHovered,
  start,
  end,
  dateRange,
  className,
  allowDisabledArrow,
  handleViewChange,
  hoverDate,
  hoverDateRange,
  minDate,
  maxDate,
  allowWeekends,
}: Props): JSX.Element => {
  const currentDate = dayjs.tz.guess();
  // eslint-disable-next-line
  const cal: any = calendar();
  const [startDate, setStartDate] = useState<string>(dayjs().format('l'));
  const [endDate, setEndDate] = useState<string>(dayjs().format('l'));
  const [viewing, setViewing] = useState({
    month: start
      ? dayjs(start).month()
      : minDate
      ? dayjs(minDate).month()
      : dayjs().month(),
    year: start
      ? dayjs(start).year()
      : minDate
      ? dayjs(minDate).year()
      : dayjs().year(),
  });
  const [monthDetails, setMonthDetails] = useState(
    cal.detailed(
      viewing.year,
      viewing.month,
      (data: {
        date: string | number | Date | dayjs.Dayjs | null | undefined;
      }) => {
        return Object.assign(
          {
            customDate: dayjs(data.date).format('l'),
          },
          data
        );
      }
    )
  );

  useEffect(() => {
    setStartDate(start);
  }, [start]);
  useEffect(() => {
    setEndDate(end);
  }, [end]);

  const cycleMonth = (dir: number) => {
    // change displayed month
    let updatedMonth = viewing.month + dir;
    let updatedYear = viewing.year;
    if (updatedMonth < 0) {
      updatedMonth = 11;
      updatedYear -= 1;
    } else if (updatedMonth > 11) {
      updatedMonth = 0;
      updatedYear += 1;
    }
    setViewing({
      month: updatedMonth,
      year: updatedYear,
    });

    setMonthDetails(
      cal.detailed(updatedYear, updatedMonth, (data: { date: string }) => {
        return Object.assign(
          {
            customDate: dayjs(data.date).format('l'),
          },
          data
        );
      })
    );

    if (handleViewChange) {
      handleViewChange(updatedMonth, updatedYear);
    }
  };
  const renderDaysOfWeek = (week: DayI[]) => {
    // render days in a week
    return week.map((day) => {
      const mDayDate = dayjs(day.date);
      const isToday = dayjs().isSame(mDayDate, 'date');
      const isEndDay = !!(
        endDate && dayjs(endDate).format('l') === day.customDate
      );

      const hoverDateRangeFormatted = hoverDateRange?.map((date) => {
        return dayjs(date);
      });
      // const isStartHover = dayjs.max(hoverDateRange)
      const isMinHover = hoverDateRangeFormatted
        ? dayjs.min(hoverDateRangeFormatted)
        : false;
      const isMaxHover = hoverDateRangeFormatted
        ? dayjs.max(hoverDateRangeFormatted)
        : false;

      return (
        <Day
          key={`${day.date.toISOString()}`}
          dayClicked={() => dateSelected(day.customDate)}
          dayHovered={() => dateHovered && dateHovered(day.customDate)}
          number={day.day}
          currentDate={currentDate}
          isToday={isToday}
          outsideViewingMonth={!day.isInPrimaryMonth}
          startDay={
            !!(startDate && dayjs(startDate).format('l') === day.customDate)
          }
          endDay={isEndDay}
          betweenDay={dateRange && dateRange.includes(day.customDate)}
          isWeekend={day.index.day === 0 || day.index.day === 6}
          isHovered={!!(hoverDate && hoverDate === day.customDate)}
          isHoveredBetween={
            hoverDateRange && hoverDateRange.includes(day.customDate)
          }
          isHoveredMin={
            !!(isMinHover && dayjs(isMinHover).format('l') === day.customDate)
          }
          isHoveredMax={
            !!(isMaxHover && dayjs(isMaxHover).format('l') === day.customDate)
          }
          allowWeekends={allowWeekends}
        />
      );
    });
  };

  const renderWeeksOfMonth = (daysOfMonthData: [DayI[]]) => {
    // render weeks in a month
    return daysOfMonthData.map((daysOfWeekData) => {
      return (
        <Week key={daysOfWeekData[0].date.toISOString()}>
          {renderDaysOfWeek(daysOfWeekData)}
          {/* {eventsLayer} */}
        </Week>
      );
    });
  };

  console.log('test');

  return (
    <Container
      data-testid="calendar"
      className={classNames('calendar', className)}
    >
      <Top>
        <Month>
          {dayjs().month(viewing.month).format('MMMM')} {viewing.year}
        </Month>
        <ArrowContainer>
          <Button
            type="button"
            side="left"
            disabled={
              (allowDisabledArrow &&
                dayjs().month() === viewing.month &&
                dayjs().year() === viewing.year) ||
              !!(
                minDate &&
                dayjs(minDate).isSame(
                  dayjs(
                    `${dayjs().month(viewing.month).format('MM')}-01-${
                      viewing.year
                    }`,
                    'MM-DD-YYYY'
                  ),
                  'month'
                )
              )
            }
            aria-label="arrow-left"
            onClick={() => cycleMonth(-1)}
          >
            <IconSimpleArrow />
          </Button>
          <Button
            type="button"
            side="right"
            aria-label="arrow-right"
            disabled={
              !!(
                maxDate &&
                dayjs(maxDate).isSame(
                  dayjs(
                    `${dayjs().month(viewing.month).format('MM')}-01-${
                      viewing.year
                    }`,
                    'MM-DD-YYYY'
                  ),
                  'month'
                )
              )
            }
            onClick={() => cycleMonth(1)}
          >
            <IconSimpleArrow />
          </Button>
        </ArrowContainer>
      </Top>
      <Content>
        <WeekLabelContainer>
          <WeekLabel>
            <span>S</span>
          </WeekLabel>
          <WeekLabel>
            <span>M</span>
          </WeekLabel>
          <WeekLabel>
            <span>T</span>
          </WeekLabel>
          <WeekLabel>
            <span>W</span>
          </WeekLabel>
          <WeekLabel>
            <span>T</span>
          </WeekLabel>
          <WeekLabel>
            <span>F</span>
          </WeekLabel>
          <WeekLabel>
            <span>S</span>
          </WeekLabel>
        </WeekLabelContainer>
        {renderWeeksOfMonth(monthDetails.calendar)}
      </Content>
    </Container>
  );
};

export default CalendarView;
