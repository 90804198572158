import styled from 'styled-components';
import { mq, size } from 'shared/mixins';
import { colors, customBezierEase } from 'shared/variables';
import { bodyCopy, eyebrow, resetButtonStyles } from 'shared/extends';

export const Container = styled.div``;

export const DateContainer = styled.div`
  ${bodyCopy};
  background: ${colors.white};
  border: 1px solid ${colors.greyMedium};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${size('100%', '50px')}

  &:hover {
    border-color: ${colors.brownMedium};
  }
  &.focus {
    border-color: ${colors.focus};
  }
  &.error {
    border-color: ${colors.highlight};
  }

  svg.icon-warning {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  margin: 0;
  outline: 0;
  padding: 0;

  &:hover {
    .icon-calendar path {
      fill: ${colors.highlight};
    }
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  transition: 0.3s ${customBezierEase};
  ${size('40px', '50px')}

  svg {
    fill: ${colors.primary};
  }
`;

export const Input = styled.input`
  ${bodyCopy};
  background: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  padding: 15px 16px 12px;
  transition: 0.3s ${customBezierEase};
  ${size('100%', '50px')};
`;

export const ModalContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.05);
  padding: 16px;
  z-index: 2;
  top: calc(100% + 10px);
  left: -1px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ${customBezierEase};
  width: 405px;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
`;

export const ModalBackground = styled.button`
  background: transparent;
  border: 0;
  border-radius: 0;
  position: fixed;
  top: 0;
  left: 0;
  ${size('100vw', '100vh')}
`;

export const Label = styled.label`
  width: 100%;
  display: inline-block;

  > span {
    ${eyebrow};
    color: ${colors.brownMedium};
    display: inline-block;
    margin-bottom: 9px;

    ${mq.tablet`
      margin-bottom: 6px;
    `}
  }

  &.error {
    > span {
      color: ${colors.highlight};
    }
  }
`;

export const ButtonCover = styled.button`
  ${resetButtonStyles};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;

  &:hover {
    ~ ${ArrowContainer} .icon-calendar path {
      fill: ${colors.highlight};
    }
  }
`;
