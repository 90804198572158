import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { Container } from './styles';
import { Page, PageStatus, QuickLink } from 'API';
import { listPages } from './queries';
import { formatDataForAccordion } from './helpers/format';
import AccordionTypes from 'components/Accordion/Accordion.types';
import SectionContainer from 'components/SectionContainer';
import Accordion from 'components/Accordion';
import AddSectionForm from './Forms/AddSectionForm/AddSectionForm';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { useHistory } from 'react-router-dom';
import AddPageForm from './Forms/AddPageForm/AddPageForm';
import { arrayMove } from 'react-sortable-hoc';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import Header from 'components/Header';
import DeleteQuicklinksForm from './Forms/DeleteQuicklinksForm/DeleteQuicklinksForm';
import UnsavedModalRoute from 'components/UnsavedModalRoute';
import IToast from 'types/ToastType';
import { SlugI } from 'types/SlugListType';
import { getSlugs } from 'utils/functions';
import LoaderView from 'components/LoaderView';

interface Props {
  toast: IToast;
}

const LIST_PAGES = gql(listPages);
const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const VIEW_ITEMS = gql(mutations.viewItems);

const AllPages = ({ toast }: Props): JSX.Element => {
  const [pageSorts, setPageSorts] = useState<{
    [key: string]: AccordionItems[];
  }>({});
  const [pageViews, setPageViews] = useState<{
    [key: string]: { newStatus: PageStatus; oldStatus: PageStatus };
  }>({});
  const [addingPage, setAddingPage] = useState<{
    length: number;
    pageId?: string;
  } | null>(null);
  const [addingSection, setAddingSection] = useState<{
    length: number;
    pageId?: string;
  } | null>(null);
  const [deletingPage, setDeletingPage] = useState<{
    id: string;
    type: string;
    pageIDs?: string[];
  } | null>(null);
  const [deleteQuicklinks, setDeleteQuicklinks] = useState<{
    quicklinks: (QuickLink | null)[];
    page: Page;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const history = useHistory();

  const [slugs, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const { data: headerData, refetch: headerRefetch } = useQuery(GET_PAGE, {
    variables: {
      id: 'all-pages',
    },
  });

  const header = useMemo(() => {
    return headerData?.getPage;
  }, [headerData]);

  const {
    data: pageData,
    loading: pageLoading,
    refetch: pageRefetch,
  } = useQuery(LIST_PAGES, {
    variables: {
      pageId: 'main',
    },
    fetchPolicy: 'cache-and-network',
  });
  const pages = useMemo(() => {
    return pageData?.listPagesByPageId?.items || [];
  }, [pageData]);

  const handleRefetch = () => {
    setAddingPage(null);
    pageRefetch();
    doFirst();
    headerRefetch();
  };

  useEffect(() => {
    setIsDirtyForm(
      Object.keys(pageSorts).length > 0 || Object.keys(pageViews).length > 0
    );
  }, [pageSorts, pageViews]);

  /* A callback function that is used to sort the sections. */
  const sortSection = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[],
      id?: string
    ) => {
      setPageSorts((prev) => {
        const newSort = { ...prev };
        if (id && items) {
          /* Checking to see if the array has been sorted. If it has, it will add it to the pageSorts
          object. If it hasn't, it will delete it from the pageSorts object. */
          const probableChange = arrayMove(items, oldIndex, newIndex);
          const differenceAry = probableChange.slice(1).map(function (n, i) {
            return n.index - probableChange[i].index;
          });
          const isDifference = differenceAry.some((value) => value !== 0);
          if (isDifference) {
            newSort[id] = probableChange;
          } else {
            delete newSort[id];
          }
          return newSort;
        }
        return newSort;
      });
    },
    []
  );

  /* A callback function that is used to sort the pages. */
  const sortPage = useCallback(
    async (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[],
      id?: string
    ) => {
      setPageSorts((prev) => {
        const newSort = { ...prev };
        if (id && items) {
          /* Checking to see if the array has been sorted. If it has, it will add it to the pageSorts
          object. If it hasn't, it will delete it from the pageSorts object. */
          const probableChange = arrayMove(items, oldIndex, newIndex);
          const differenceAry = probableChange.slice(1).map(function (n, i) {
            return n.index - probableChange[i].index;
          });
          const isDifference = differenceAry.some((value) => value !== 0);
          if (isDifference) {
            newSort[id] = probableChange;
          } else {
            delete newSort[id];
          }
          return newSort;
        }
        return newSort;
      });
    },
    []
  );

  const handleEditPage = useCallback(
    (id: string) => {
      if (history) {
        history.push(id);
      }
    },
    [history]
  );

  const handleActivePage = useCallback(
    async (
      id: string,
      newStatus: boolean,
      prevStatus: PageStatus,
      childrenPages?: { id: string; status: PageStatus }[]
    ) => {
      const statusNew = newStatus ? PageStatus.active : PageStatus.disabled;
      setPageViews((prev) => {
        const newView = { ...prev };
        if (statusNew === prevStatus) {
          delete newView[id];
        } else {
          newView[id] = { newStatus: statusNew, oldStatus: prevStatus };
        }

        if (childrenPages) {
          for (let i = 0; i < childrenPages?.length; i++) {
            const child = childrenPages[i];
            if (child.status === statusNew) {
              if (newView[child.id]) delete newView[child.id];
            } else {
              newView[child.id] = {
                newStatus: statusNew,
                oldStatus: child.status,
              };
            }
          }
        }
        return newView;
      });
    },
    []
  );

  /* Formatting the data for the accordion. */
  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!pages) return null;
    return formatDataForAccordion({
      pages,
      addSection: setAddingSection,
      updateSection: handleEditPage,
      deletePage: setDeletingPage,
      sortSection,
      activePage: handleActivePage,
      addPage: setAddingPage,
      updatePage: handleEditPage,
      sortPage,
      handleQuicklinks: setDeleteQuicklinks,
    });
  }, [handleEditPage, sortSection, pages, handleActivePage, sortPage]);

  const [pagesSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Pages sorted');
    },
    onError: () => {
      toast.error('Error sorting pages');
    },
  });

  const [viewItems] = useMutation(VIEW_ITEMS, {
    onCompleted: () => {
      toast.success('Pages updated');
    },
    onError: () => {
      toast.error('Error updating pages');
    },
  });

  const [updatePage] = useMutation(UPDATE_PAGE);

  /**
   * It takes the current state of the pageSorts object, and compares it to the original state of the
   * pageSorts object. If there are any differences, it will send the differences to the server to
   * update the database
   */
  const handleSave = async () => {
    const temp = { ...pageSorts };
    const sendToUpdate: { id: string; sort: number }[] = [];
    const tempView = { ...pageViews };
    const sendToUpdateView: { id: string; status: string }[] = [];

    if (temp) {
      Object.keys(temp).forEach((key) => {
        const parent = temp[key];
        if (parent.length > 0) {
          parent.forEach((page, index) => {
            if (page.index !== index) {
              sendToUpdate.push({
                id: page.id || '',
                sort: index,
              });
            }
          });
        }
      });
    }
    console.log('sendToUpdate', sendToUpdate);

    if (tempView) {
      Object.keys(tempView).forEach((key) => {
        const item = tempView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateView.push({
            id: key,
            status: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateView', sendToUpdateView);

    if (sendToUpdate.length > 0 || sendToUpdateView.length > 0) {
      updatePage({
        variables: {
          input: {
            id: 'all-pages',
            updatedAt: new Date().toISOString(),
          },
        },
      });
    }

    if (sendToUpdate.length > 0) {
      await pagesSort({
        variables: { input: { tableName: 'Page', items: sendToUpdate } },
      });
      setPageSorts({});
    }
    if (sendToUpdateView.length > 0) {
      await viewItems({
        variables: { input: { tableName: 'Page', items: sendToUpdateView } },
      });
    }

    //* RESET
    setPageViews({});
    handleRefetch();
    setIsDirtyForm(false);
  };

  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <Header
        hideManage={true}
        label="All Pages"
        submitAllForms={handleSave}
        updatedAt={header?.updatedAt || ''}
        showVisible={false}
        isDirtyForm={isDirtyForm}
        slug=""
      />
      <SectionContainer includePadding={false}>
        {pageLoading || !accordionData ? (
          <></>
        ) : (
          <Accordion {...accordionData} />
        )}
      </SectionContainer>
      {addingSection && (
        <AddSectionForm
          slugs={slugs}
          toggle={(refetch) => {
            setAddingSection(null);
            if (refetch) {
              handleRefetch();
            }
          }}
          {...addingSection}
          toast={toast}
        />
      )}

      {addingPage && (
        <AddPageForm
          {...addingPage}
          slugs={slugs}
          toast={toast}
          toggle={(refetch) => {
            setAddingPage(null);
            if (refetch) {
              handleRefetch();
            }
          }}
        />
      )}

      {deletingPage && (
        <DeleteForm
          toggle={() => {
            setDeletingPage(null);
            handleRefetch();
          }}
          {...deletingPage}
          toast={toast}
        />
      )}

      {deleteQuicklinks && (
        <DeleteQuicklinksForm
          toggle={(refetch) => {
            setDeleteQuicklinks(null);
            if (refetch) {
              handleRefetch();
            }
          }}
          toast={toast}
          {...deleteQuicklinks}
        />
      )}
      {isDirtyForm && (
        <UnsavedModalRoute
          content="Doing this will lose any unsaved changes. Are you sure you want to continue?"
          title="Are you sure you want to leave this unsaved?"
          isBlocked={isDirtyForm}
          type="page"
        />
      )}
    </Container>
  );
};

export default AllPages;
