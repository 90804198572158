import styled from 'styled-components';
import { colors } from '../../../shared/variables';
import { bodyCopy } from '../../../shared/extends';
import { size } from 'shared/mixins';
import { font, fontSize } from 'shared/typography';

export const Container = styled.div``;

export const TextareaContent = styled.div`
  position: relative;

  svg {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const Area = styled.textarea`
  ${bodyCopy};
  border: 0;
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  resize: none;
  outline: 1px solid ${colors.greyMedium};
  outline-offset: -1px;
  min-height: 190px;

  &.error {
    outline: 1px solid ${colors.highlight};
  }
  &.errorLimited {
    outline: 1px solid ${colors.highlight};

    &:focus {
      + svg {
        display: none;
      }
    }
  }
  &::placeholder {
    color: ${colors.brownLight};
    margin: 1rem;
    left: 10px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const MaxLength = styled.span`
  bottom: 15px;
  color: ${colors.brownLessLight};
  pointer-events: none;
  position: absolute;
  right: 8px;
  text-align: right;
  ${fontSize(8, 8, 1.75)};
  ${font('body')};
`;

export const Error = styled.p`
  ${bodyCopy};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;

  svg {
    position: static;
    transform: none;
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;
