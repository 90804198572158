import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { bodyCopy, font, fontSize } from 'shared/typography';
import Modal from 'containers/Modal';

export const ContainerModal = styled(Modal)`
  margin: 0 auto;
  max-width: 803px;

  p {
    color: ${colors.brownMedium};
  }
`;

export const Content = styled.div`
  gap: 40px;
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 16px;
    padding: 0;

    &:last-child {
      margin-bottom: 0%;
    }
  }
`;

export const PageLink = styled(Link)`
  color: ${colors.brownMedium};
  ${font('body', 'bold')}
  ${fontSize(14, 20, 0)}
`;

export const Warning = styled.p`
  ${bodyCopy};
  margin: 0;

  .bold {
    ${font('body', 'bold')}
  }
`;

export const ButtonContainer = styled.div``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  /* position: absolute;
  right: 99px;
  bottom: 58px; */
`;
