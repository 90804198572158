import { SearchI } from '../SuggestedSearch';

interface IFormat {
  searchs: SearchI[];
  searchsSort: string[];
  addSearch: ({ length }: { length: number }) => void;
  updateSearch: (id: string) => void;
  deleteSearch: ({ id }: { id: string }) => void;
  sortSearchs: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[]
  ) => void;
}

export const formatDataForAccordion = ({
  searchs,
  searchsSort,
  addSearch,
  updateSearch,
  deleteSearch,
  sortSearchs,
}: IFormat): any => {
  const formatSearch = (search: SearchI | any, index: number): any => {
    return {
      id: search.id,
      title: search.label,
      index: index,
      originalIndex: search.sort,
      sortable: true,
      editFunc: () => {
        updateSearch(search.id);
      },
      deleteFunc: () => {
        deleteSearch({ id: search.id });
      },
    };
  };
  return {
    accordionContainer: searchs
      ? searchs
          .slice()
          ?.sort((x: SearchI, y: SearchI) => {
            return searchsSort.indexOf(x.id) - searchsSort.indexOf(y.id);
          })
          .map(formatSearch)
      : [],
    ...(!searchs || searchs?.length < 5
      ? {
          addFuncText: 'Add Additional Search Suggestion',
          addFunc: () => {
            addSearch({ length: searchs ? searchs?.length || 0 : 0 });
          },
        }
      : {}),
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortSearchs({ oldIndex, newIndex }, items);
    },
  };
};
