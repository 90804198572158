import styled from 'styled-components';
import { mq, size } from '../../../../shared/mixins';
import { colors } from '../../../../shared/variables';
import { font, fontSize } from '../../../../shared/typography';

interface PropsI {
  betweenDate?: boolean;
  endDate?: boolean;
  startDate?: boolean;
  isToday?: boolean;
  isOutsideMonth?: boolean;
}

export const DayContainer = styled.div<PropsI>`
  align-items: center;
  color: ${colors.brownLight};
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  ${font('secondary')};
  ${fontSize(12, 20)};

  span {
    align-items: center;
    border: 1.5px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    z-index: 1;
    ${size('42px')};

    ${mq.phoneWide`
      ${size('30px')};
    `}
  }

  &.outside-viewing-month {
    color: transparent !important;
    pointer-events: none;
    background-color: transparent !important;

    &.hover-between,
    &.holiday-between,
    &.holiday-end {
      &:after {
        display: none !important;
      }
    }

    &.hover-start,
    &.holiday-start,
    &.holiday-end {
      span:before {
        display: none !important;
      }
    }

    + .hover-between {
      &:after {
        border-left: 2px solid ${colors.greyLightLight};
      }
    }
  }

  &.hover-between:not(.outside-viewing-month)
    + .outside-viewing-month.hover-between {
    &:after {
      border-left: 2px solid ${colors.greyLightLight};
      border-top: 0;
      border-bottom: 0;
      height: 100%;
      display: block !important;
    }
  }

  &.weekend {
    cursor: not-allowed;
    span {
      pointer-events: none;
    }
  }

  &.between-day {
    background: ${colors.greyMedium};
  }

  &:first-child {
    // &.between-day {
    //   background: transparent;
    //   position: relative;
    //   &:before {
    //     content: '';
    //     height: 100%;
    //     width: 50%;
    //     background: ${colors.primary};
    //     position: absolute;
    //     right: 0;
    //   }
    //   span {
    //     background: ${colors.primary};
    //     border-radius: 100% 0 0 100%;
    //   }
    // }

    &.end-day {
      &:before {
        // display: none;
      }

      span {
        border-radius: 100%;
      }
    }
  }

  &:last-child {
    // &.between-day {
    //   background: transparent;
    //   position: relative;
    //   &:before {
    //     content: '';
    //     height: 100%;
    //     width: 50%;
    //     background: ${colors.primary};
    //     position: absolute;
    //     left: 0;
    //   }
    //   span {
    //     background: ${colors.primary};
    //     border-radius: 0 100% 100% 0;
    //   }
    // }

    &.start-day {
      &:before {
        // display: none;
      }

      span {
        border-radius: 100%;
      }
    }
  }

  &.start-day {
    background: transparent;
    position: relative;
    &:before {
      content: '';
      height: 100%;
      width: 50%;
      background: ${colors.greyMedium};
      position: absolute;
      right: 0;
    }
    span {
      border-radius: 100%;
      background: ${colors.primary};
      color: ${colors.white};
    }
  }

  &.end-day {
    background: transparent;
    position: relative;
    &:before {
      content: '';
      height: 100%;
      width: 50%;
      background: ${colors.greyMedium};
      position: absolute;
      left: 0;
    }
    span {
      border-radius: 100%;
      background: ${colors.primary};
      color: ${colors.white};
    }
  }

  &.start-day.end-day {
    &:before {
      display: none;
    }

    span {
      border-radius: 100%;
    }
  }

  &.today {
    span {
      border-radius: 100%;
      outline: 2px solid ${colors.brownLight};
      outline-offset: -2px;
    }

    &.start-day,
    &.end-day {
      span {
        background: ${colors.primary};
        color: ${colors.white};
      }
    }

    &.holiday-start,
    &.holiday-end {
      span {
        &:before {
          display: none;
        }
      }
    }

    &.holiday-single {
      span {
        outline: 2px solid ${colors.brownLight};
      }
    }
  }

  &.holiday-single {
    color: ${colors.primary};

    span {
      border-radius: 100%;
      outline: 2px solid ${colors.primary};
      outline-offset: -2px;
    }

    &.hover-max,
    &.hover-min {
      span:before {
        display: none !important;
      }
    }
  }

  &.holiday-start {
    color: ${colors.primary};
    position: relative;
    &:after {
      border-top: 2px solid ${colors.primary};
      border-bottom: 2px solid ${colors.primary};
      content: '';
      position: absolute;
      right: 0;
      ${size('50%', 'calc(100% - 4px)')}
    }

    span {
      position: relative;

      &:before {
        border: 2px solid ${colors.primary};
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-radius: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        ${size('38px')};
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.holiday-end {
    color: ${colors.primary};
    position: relative;
    &:after {
      border-top: 2px solid ${colors.primary};
      border-bottom: 2px solid ${colors.primary};
      content: '';
      position: absolute;
      left: 0;
      ${size('50%', 'calc(100% - 4px)')}
    }

    span {
      position: relative;

      &:before {
        border: 2px solid ${colors.primary};
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 100%;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        ${size('38px')};
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.holiday-between {
    color: ${colors.primary};
    position: relative;
    &:after {
      border-top: 2px solid ${colors.primary};
      border-bottom: 2px solid ${colors.primary};
      content: '';
      position: absolute;
      left: 0;
      ${size('100%', 'calc(100% - 4px)')}
    }
  }

  &.hover-between {
    position: relative;
    &:after {
      border-top: 2px solid ${colors.greyLightLight};
      border-bottom: 2px solid ${colors.greyLightLight};
      position: relative;
      content: '';
      position: absolute;
      left: 0;
      ${size('100%', 'calc(100% - 4px)')};
    }

    &.hover-min:not(.start-day) {
      span {
        position: relative;

        &:before {
          content: '';
          border-top-left-radius: calc(100% + 2px);
          border-bottom-left-radius: calc(100% + 2px);
          border: 2px solid ${colors.greyLightLight};
          border-right: 0;
          position: absolute;
          left: 0;
          ${size('38px')};
        }
      }
      &:after {
        right: 0;
        left: auto;
        width: 50%;
      }

      &.today {
        span:before {
          display: none;
        }
      }

      &.hover-start {
        &:after {
          border-left: 0;
        }
      }
    }

    &.hover-max:not(.start-day) {
      span {
        position: relative;

        &:before {
          content: '';
          border-top-right-radius: calc(100% + 2px);
          border-bottom-right-radius: calc(100% + 2px);
          border: 2px solid ${colors.greyLightLight};
          border-left: 0;
          position: absolute;
          right: 0;
          ${size('38px')};
        }
      }
      &:after {
        width: 50%;
      }

      &.today {
        span:before {
          display: none;
        }
      }
    }

    &.start-day {
      &:after {
        width: 50%;
      }

      &.hover-min {
        &:after {
          left: auto;
          right: 0;
        }
      }
    }

    // endcap for hover range
    &.weekend:last-child {
      &:after {
        border-right: 2px solid ${colors.greyLightLight};
      }
    }
    &.weekend:first-child {
      &:after {
        border-left: 2px solid ${colors.greyLightLight};
      }
    }
  }
`;
