import SectionContainer from 'components/SectionContainer';
import React, { useState, useEffect } from 'react';
import { Input, WYSIWYGInput } from './styles';
import { validateURL, viewWYSIWYG } from 'utils/functions';

interface Props {
  headline?: string;
  body?: string;
  ctaLink?: string;
  ctaText?: string;
  methods: any;
  id?: string;
  dontDirty?: boolean;
  setDontDirty?: any;
}

const IntroCopy = ({
  headline,
  body,
  ctaLink,
  ctaText,
  methods,
  id,
  dontDirty,
  setDontDirty,
}: Props): JSX.Element => {
  const [bodyData, updateBodyData] = useState<any>();
  const [showRTE, updateShowRTE] = useState<boolean>(false);
  const [customError, setCustomError] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      const newContent = await viewWYSIWYG(body);
      setDontDirty(true);
      updateBodyData(newContent);
      updateShowRTE(true);
      setTimeout(() => {
        setDontDirty(false);
      }, 1200);
    };
    getData();
  }, [body, setDontDirty]);

  return (
    <SectionContainer
      title="Headline Copy &amp; Text"
      description="Click on the content you would like to edit."
    >
      <Input
        {...methods}
        label="Headline Text"
        id="headline"
        name="headline"
        required={true}
        hideRequired
        maxLength={50}
        placeholder="Enter Headline Text Here"
        defaultValue={headline}
        error={methods?.formState?.errors?.headline}
        wrapperClassName="form-input"
      />
      {showRTE ? (
        <WYSIWYGInput
          {...methods}
          label="Body Text"
          id="body-text"
          name="body"
          required={false}
          optional={true}
          placeholder="Enter Body Text Here"
          defaultValue={bodyData}
          dontDirty={dontDirty}
          setDontDirty={setDontDirty}
          // setValue={methods.setValue}
          error={methods?.formState?.errors?.body}
          wrapperClassName="form-input"
          disabled={id === 'page-404'}
          disclaimer={
            id === 'page-404'
              ? 'Editing this copy requires special permission. Reach out to a developer to edit this copy.'
              : null
          }
        />
      ) : (
        <></>
      )}
      <Input
        {...methods}
        label="CTA Text"
        id="ctaText"
        name="ctaText"
        required={false}
        optional={true}
        maxLength={50}
        placeholder="Enter CTA Text Here"
        defaultValue={ctaText}
        error={methods?.formState?.errors?.ctaText}
        wrapperClassName="form-input"
      />
      <Input
        {...methods}
        label="CTA Url"
        id="ctaLink"
        name="ctaLink"
        required={false}
        optional={true}
        placeholder="Enter CTA Url Here"
        defaultValue={ctaLink}
        error={!!(customError || methods?.formState?.errors?.ctaLink)}
        wrapperClassName="form-input"
        onBlur={(e) => {
          const value = e.target.value;
          if (value !== '') {
            setCustomError(!validateURL(value));
          } else {
            setCustomError(validateURL(value));
          }
        }}
        {...(customError ? { errorMessage: 'Enter a valid url' } : null)}
      />
    </SectionContainer>
  );
};

export default IntroCopy;
