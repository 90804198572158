import styled from 'styled-components';
import { Eyebrow, H3 as StyledH3, Body as StyledBody } from 'shared/typography';
import { colors } from 'shared/variables';

export const Container = styled.div`
  padding: 100px;
`;

export const Header = styled.div``;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.brownLight};
  margin: 10px 0px;
`;

export const TimeStamp = styled(StyledBody)``;

export const Label = styled(Eyebrow)`
  color: ${colors.brownMedium};
`;

export const H3 = styled(StyledH3)`
  color: ${colors.brownMedium};
  margin-top: 40px;
  margin-bottom: 7px;
`;

export const Body = styled(StyledBody)``;

export const AccordionWrapper = styled.div`
  margin-top: 25px;
`;
export const RadioGroup = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
`;
