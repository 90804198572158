const zIndexScale = {
  neg2: -200,
  neg1: -100,
  0: 0,
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
  10: 1000,
  max: 9999,
};

export const zIndex = {
  homeBackgroundSections: zIndexScale.neg2,
  fiveView: zIndexScale.neg1,
  homeSectionsContent: zIndexScale[1],
  carouselModal: zIndexScale[9],
  backToTop: zIndexScale[9],
  navigation: zIndexScale[10],
  transition: zIndexScale.max,
};
