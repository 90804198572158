import styled from 'styled-components';
import { bodyCopy, subHeadLarge } from 'shared/extends';
import { colors } from 'shared/variables';
import { size } from 'shared/mixins';
import Button from 'components/Button';

export const Form = styled.form`
  min-width: 33%;
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const LineBreak = styled.hr`
  width: 100%;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const TabButtonsContainer = styled.div`
  display: flex;
`;
export const TabButton = styled.label`
  pointer: cursor;
`;
export const TabButtonFake = styled.div`
  ${bodyCopy};
  background: ${colors.white};
  color: ${colors.brownLight};
  padding: 15px 0;
  text-align: center;
  ${size('158px', '50px')};
`;
export const TabButtonInput = styled.input`
  display: none;

  &:checked + ${TabButtonFake} {
    background: ${colors.primary};
    color: ${colors.white};
  }
`;

export const PreviewContainer = styled.div``;
export const Preview = styled.div`
  ${subHeadLarge};
  background: ${colors.greyMedium};
  border: 1px solid ${colors.greyMedium};
  color: ${colors.brownLight};
  padding: 23px 20px;

  &.error {
    border-color: ${colors.highlight};
  }
`;

export const ButtonContainer = styled.div``;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 99px;
  bottom: 58px;
`;
