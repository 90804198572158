import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { API, Storage } from 'aws-amplify';
import { SlugI } from 'types/SlugListType';
import { listPages as listPagesForSlug } from 'utils/queries';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListPagesQuery } from 'API';
// import { Page } from 'API';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

export const getDates = (startDate: string, endDate: string): string[] => {
  const dates = [];
  const currentDate = new Date(startDate);
  const endingDate = new Date(endDate);
  while (dayjs(currentDate).isSameOrBefore(endingDate)) {
    dates.push(dayjs(currentDate).format('l'));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

// eslint-disable-next-line
export const debounce = (func: (f: any) => void) => {
  // eslint-disable-next-line
  let timer: any;
  // eslint-disable-next-line
  return function (event: any) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 100, event);
  };
};

export const stopBackgroundScroll = (action: string): void => {
  const content: HTMLDivElement | null =
    document.querySelector('.body-content');
  if (!content) return;
  if (action === 'open') {
    content.style.overflow = 'hidden';
    content.dataset.top = `${content.scrollTop}`;
    content.scrollTop = 0; // go back to top to reset scroll position;
  } else {
    content.style.overflow = '';
    content.scrollTop = parseInt(content.dataset.top || '0'); // reset scroll
  }
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const cleanString = (text: string): string => {
  if (!text) {
    return '';
  }

  return text.replace(/<\/?[^>]+(>|$)/g, '').trim();
};

export const cleanFileName = (text: string): string => {
  if (!text) {
    return '';
  }

  return text.replace(/[^a-zA-Z0-9.]/g, '').trim();
};

export const validateCalendarLink = (url: string): boolean => {
  return /^(fiveanddone\.com)(.*)(\@group\.calendar\.google\.com)$/i.test(url);
};

export const validateMapLink = (url: string): boolean => {
  return (
    /^https:\/\/www\.google\.com\/maps\/d\/u\/0\/embed/i.test(url) ||
    /^https:\/\/www\.google\.com\/maps\/d\/embed/i.test(url)
  );
};

export const validateURL = (url: string): boolean => {
  return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(.*?)\.([a-zA-Z]{2,})\/?\S*$/i.test(
    url
  );
};

export const validatePhone = (phone: string): boolean => {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    phone
  );
};

export const validateEmail = (email: string): boolean => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const formatSlug = (value: string): string => {
  let splitSlug = value.split('-').filter((v) => v != '');
  splitSlug = splitSlug.filter((v) => !v.match(/^(&|and|or|the|a|is|an)$/));
  const slug = splitSlug.join('-');
  return slug;
};

export const handleSlugCheck = ({
  value,
  id,
  slugList,
  callbackFunc,
}: {
  value: string;
  id: string;
  slugList: SlugI[];
  callbackFunc: (value: boolean) => void;
}): void => {
  if (value) {
    if (
      slugList.findIndex((x: any) => {
        if (x.slug) {
          return x.slug.toLowerCase() === value.toLowerCase() && x.id !== id;
        }
        return -1;
      }) > -1
    ) {
      callbackFunc(true);
    } else {
      callbackFunc(false);
    }
  }
};

/**
 * It checks if the content is empty or not
 * @param {string} content - string - The content to check.
 * @returns A boolean value.
 */
export const checkWYSIWYG = (content: string): boolean => {
  switch (content.trim()) {
    case '':
    case '<p></p>':
    case '<p> </p>':
    case '<p><br></p>':
    case '<p><br /></p>':
      return true;
    default:
      return false;
  }
};

export const postWYSIWYG = (content: string): string => {
  if (content) {
    let frankenstein = '';
    console.log('content: ', content);
    const imageArray = content.split('<img');
    console.log('imageArray ', imageArray);
    console.log('length ', imageArray.length);
    imageArray.map((item: string, i: number) => {
      // if it's the first one keep it the same
      if (i === 0) {
        frankenstein += item;
      } else {
        // Get the key
        const startOfKey = item.lastIndexOf('public/');
        const endOfKey = item.indexOf('?');
        const key = item.slice(startOfKey + 7, endOfKey);

        // reform the img src with just the key
        const imageEnds = item.indexOf('">');
        const restOfItem = item.slice(imageEnds);
        const stitchedItem = `<img src="${key}${restOfItem}`;

        // attach it to the content
        frankenstein += stitchedItem;
      }
    });

    // return reformatted content
    return frankenstein;
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const viewWYSIWYG = async (content: string | undefined) => {
  if (content) {
    // console.log('content: ', content);
    let frankenstein = '';

    const imageArray = content.split('<img');
    // console.log('imageArray: ', imageArray);
    for (let i = 0; i < imageArray.length; i++) {
      const item = imageArray[i];
      if (i === 0) {
        frankenstein += item;
      } else {
        // get the key (' src="')
        const startOfKey = 6;
        const endOfKey = item.indexOf('">');
        const key = item.slice(startOfKey, endOfKey);

        // get the url from S3
        const url = await Storage.get(key);
        // console.log('url', url);
        // stitch the item back together
        const imageEnds = item.indexOf('">');
        // console.log('item: ', item);
        const restOfItem = item.slice(imageEnds);
        const stitchedItem = `<img src="${url}${restOfItem}`;
        // attach to the content
        frankenstein += stitchedItem;
        // console.log('frank: ', frankenstein);
      }
    }

    // return reformatted content
    return frankenstein;
  }
  return '';
};

export const getSlugs = async (): Promise<Array<SlugI>> => {
  let temp = [];
  const test: any = await API.graphql({
    query: listPagesForSlug,
  });
  temp = test.data.listPages.items || [];
  if (test?.data?.listPages?.nextToken) {
    const testRes: any = await API.graphql<GraphQLQuery<ListPagesQuery>>({
      query: listPagesForSlug,
      variables: { nextToken: test?.data?.listPages?.nextToken },
    });

    temp.push(...testRes.data.listPages.items);
  }
  return temp;
};
