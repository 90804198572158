import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from './styles';
import IToast from 'types/ToastType';
import Header from 'components/Header';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import LoaderView from 'components/LoaderView';
import { SlugI } from 'types/SlugListType';
import { getSlugs } from 'utils/functions';
import { formatDataForAccordion } from './helpers/format';
import { arrayMove } from 'react-sortable-hoc';
import SectionContainer from 'components/SectionContainer/SectionContainer';
import Accordion from 'components/Accordion/Accordion';
import SearchForm from './Forms/SearchForm/SearchForm';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);

interface IProps {
  toast: IToast;
}

export interface SearchI {
  id: string;
  linkTo: string;
  label: string;
  sort: number;
}

const SuggestedSearch = ({ toast }: IProps): JSX.Element => {
  const [slugs, setSlugs] = useState<SlugI[]>([]);
  const [searchsSort, setSearchsSort] = useState<string[]>([]);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [modalSearch, setModalSearch] = useState<{
    length?: number;
    data?: SearchI;
  } | null>(null);

  const [deletingSearch, setDeletingSearch] = useState<{
    id: string;
  } | null>(null);

  const [updatePage] = useMutation(UPDATE_PAGE);

  const { data: pageData, loading } = useQuery(GET_PAGE, {
    variables: { id: 'page-suggested-search' },
  });

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const searchs = useMemo(() => {
    if (data?.meta) {
      return JSON.parse(data.meta);
    }
    return null;
  }, [data]);

  useEffect(() => {
    setIsDirtyForm(false);
    if (searchsSort.length > 0) {
      // Find missing items
      const missingItems =
        searchsSort?.filter(
          (item: string) => !searchs.some((q: SearchI) => q.id === item)
        ) || [];

      // Find added items
      const addedItems =
        searchs.filter((item: SearchI) => !searchsSort.includes(item.id)) || [];

      const temp = [...searchsSort];
      if (addedItems.length > 0) {
        addedItems.map((item: SearchI) => {
          temp.push(item.id);
        });
      }
      if (missingItems.length > 0) {
        missingItems.map((item) => {
          const indexItem = temp.indexOf(item);
          if (indexItem > -1) {
            temp.splice(indexItem, 1);
          }
        });
      }

      console.log(temp);
      setSearchsSort(temp);
    } else {
      const temp: string[] = [];
      if (searchs) {
        searchs
          .slice()
          ?.sort((x: SearchI, y: SearchI) => {
            return (x.sort || 0) - (y.sort || 0);
          })
          .map((q: SearchI) => {
            temp.push(q.id);
          });
        console.log(temp);
        setSearchsSort(temp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchs]);

  const handleSave = async () => {
    console.log('save', searchsSort, searchs);

    let changeHappened = false;

    const temp = searchs?.map((item: SearchI) => {
      const itemIndex = searchsSort.findIndex((x) => x === item.id);
      if (item.sort === itemIndex) {
        return item;
      } else {
        changeHappened = true;
        return {
          ...item,
          sort: itemIndex,
        };
      }
    });

    console.log(changeHappened, temp);

    if (changeHappened) {
      await updatePage({
        variables: {
          input: {
            id: 'page-suggested-search',
            meta: JSON.stringify(temp),
          },
        },
      });

      toast.success('Suggested search sorting saved');
    }
  };

  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  //build slugs parent to child relationship
  const allSlugs: { value: string; label: string; key?: string }[] =
    useMemo(() => {
      const parents = slugs.filter(
        (x) => x.pageId === 'main' && x.id !== 'page-404'
      );
      const children = slugs.filter((x) => x.pageId !== 'main');
      const formedSlugs: { value: string; label: string; key?: string }[] = [];
      // breaking down slug building by the parent slug
      parents
        .filter((x) => x.slug && x.title)
        .map((parent) => {
          // add parent first
          formedSlugs.push({
            key: parent.id + Math.random(),
            // value: parent.slug || '',
            value: parent.id,
            label: parent.title || '',
          });

          // get all child slugs and check if they are visible pages
          const descendent = children.filter(
            (x) => x.pageId === parent.id && x.status === 'active'
          );

          // re-build their slugs
          descendent
            .filter((x) => x.slug && x.title)
            .map((child) => {
              formedSlugs.push({
                key: child.id + Math.random(),
                label: child.title || '',
                // value: `${parent.slug}/${child.slug}`,
                value: child.id,
              });
            });
        });
      return formedSlugs;
    }, [slugs]);

  // FUNCTIONS
  const addSearch = useCallback(({ length }: any) => {
    setModalSearch({ length });
  }, []);

  const updateSearch = useCallback(
    async (id: any) => {
      const search = searchs.find((x: SearchI) => x.id === id);
      if (search) {
        setModalSearch({ data: search });
      }
    },
    [searchs]
  );

  const sortSearchs = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[]
    ) => {
      let newSort: AccordionItems[] = [];
      if (items) {
        const probableChange = arrayMove(items, oldIndex, newIndex);
        const differenceAry = probableChange.slice().map(function (n, i) {
          return i - probableChange[i].originalIndex;
        });
        const isDifference = differenceAry.some((value) => value !== 0);
        if (isDifference) {
          newSort = probableChange;
        } else {
          newSort = [];
        }
      }

      const searchChange = arrayMove(searchsSort, oldIndex, newIndex);

      setSearchsSort(searchChange);
      // searchsSorted(newSort);

      const sortedContent: AccordionItems[] = [];

      newSort.map((item, i) => {
        if (item.index !== i) {
          sortedContent.push(item);
        }
      });

      setIsDirtyForm(sortedContent.length > 0);
    },
    [searchsSort]
  );

  const handleDeleting = async (id: string) => {
    const temp = searchs?.filter((x: SearchI) => x.id !== (id || ''));
    console.log('temp', temp);

    try {
      await updatePage({
        variables: {
          input: {
            id: 'page-suggested-search',
            meta: JSON.stringify(temp),
          },
        },
      });

      toast.success('Suggested search deleted');
    } catch (err) {
      toast.error('Error deleting suggested search');
    }
    setDeletingSearch(null);
  };

  const accordionData = useMemo(() => {
    return formatDataForAccordion({
      searchs,
      searchsSort,
      addSearch,
      updateSearch,
      deleteSearch: setDeletingSearch,
      sortSearchs,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchs, searchsSort]);

  if (loading) {
    <LoaderView />;
  }

  return (
    <Container>
      <Header
        hideManage={false}
        label="Suggested Search"
        submitAllForms={handleSave}
        updatedAt={data?.updatedAt || ''}
        showVisible={false}
        isDirtyForm={isDirtyForm}
        slug=""
      />

      <SectionContainer
        title="Add and Edit Suggested"
        description="Manage Suggested Search, max 5."
        includePadding={false}
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {modalSearch && (
        <SearchForm
          toggle={async () => {
            // await refetch();
            setModalSearch(null);
          }}
          toast={toast}
          slugs={allSlugs}
          searchs={searchs}
          {...modalSearch}
          handleDeleting={handleDeleting}
        />
      )}
      {deletingSearch && (
        <DeleteForm
          type="search"
          id={deletingSearch.id}
          toast={toast}
          toggle={async (refetch) => {
            if (refetch) {
              handleDeleting(deletingSearch.id);
            }
            setDeletingSearch(null);
          }}
        />
      )}
    </Container>
  );
};

export default SuggestedSearch;
