import React, { useEffect, useState } from 'react';
import AccordionItem from './AccordionItem';
import AccordionTypes from './Accordion.types';
import { Wrapper } from './styles';
import { AddItem } from './AccordionItem/styles';
import { IconAdd } from 'components/IconsView';
import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import classNames from 'classnames';
import AccordionItems from './AccordionItem/AccordionItem.types';

const SortableList: any = SortableContainer(
  ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
  }
);

const Accordion = ({
  accordionContainer,
  addFunc,
  addFuncText,
  key,
  onSortEnd,
  accordionIsOpen,
  setAccordionIsOpen,
  type,
  onDeleteRequest,
}: AccordionTypes): JSX.Element => {
  const [listData, setListData] = useState<any[]>([]);

  useEffect(() => {
    if (accordionContainer && accordionContainer.length > 0) {
      // for (const entry of accordionContainer) {
      //   entry.show = false;
      // }
      setListData(accordionContainer);
    } else {
      setListData([]);
    }
  }, [accordionContainer]);

  const onSort = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setListData((items) => {
      return arrayMove(items, oldIndex, newIndex);
    });
  };

  const switchShow = (index: number) => {
    const temp = listData.slice();
    const listItem = temp[index];
    const newShow = !listItem.show;
    listItem.show = newShow;
    if (accordionIsOpen && setAccordionIsOpen) {
      if (!accordionIsOpen.includes(listItem.id)) {
        setAccordionIsOpen([...accordionIsOpen, listItem.id]);
      } else if (accordionIsOpen.includes(listItem.id)) {
        let updatedAccordionIsOpen = accordionIsOpen.filter(
          (item) => item !== listItem.id
        );
        //remove children as well
        console.log(listItem);
        if (listItem.accordionItems.length > 0) {
          listItem.accordionItems.map((item: AccordionItems) => {
            updatedAccordionIsOpen = updatedAccordionIsOpen.filter(
              (openItem) => openItem !== item.id
            );

            if (item.accordionItems && item.accordionItems.length > 0) {
              item.accordionItems.map((subItem: AccordionItems) => {
                updatedAccordionIsOpen = updatedAccordionIsOpen.filter(
                  (openItem) => openItem !== subItem.id
                );
              });
            }
          });
        }
        setAccordionIsOpen(updatedAccordionIsOpen);
      }
    }

    /* Closing all the sub-accordions when the parent accordion is closed. */
    if (listItem.accordionItems && !newShow) {
      const items = [...listItem.accordionItems];
      items.map((item) => {
        item.show = false;
        if (item.accordionItems) {
          item.accordionItems.map((subItem: { show: boolean }) => {
            subItem.show = false;
          });
        }
      });
    }

    setListData([...temp]);
  };

  return (
    <Wrapper
      key={key}
      data-testid="accordion"
      className={classNames('accordion')}
    >
      <SortableList
        onSortEnd={(sortData: any) => {
          if (sortData.newIndex !== sortData.oldIndex) {
            // setDisableTable(true);
            onSort(sortData);
            if (onSortEnd) {
              onSortEnd(sortData, listData);
            }
          }
        }}
        useDragHandle
      >
        {listData?.map((item, index) => {
          return (
            <AccordionItem
              type={type}
              parent={true}
              sortable={!!onSortEnd}
              {...item}
              switchShow={() => switchShow(index)}
              key={index}
              index={index}
              accordionIsOpen={accordionIsOpen}
              setAccordionIsOpen={setAccordionIsOpen}
              onDeleteRequest={onDeleteRequest}
            />
          );
        })}
      </SortableList>
      {addFunc && addFuncText && (
        <AddItem
          tertiary={true}
          className="parent-add"
          type="button"
          onClick={addFunc}
        >
          <IconAdd />
          {addFuncText}
        </AddItem>
      )}
    </Wrapper>
  );
};

export default Accordion;
