import { eyebrowButton, resetButtonStyles } from 'shared/extends';
import { colors } from 'shared/variables';
import styled, { css } from 'styled-components';

export const IconWrapper = styled.div`
  display: inline-block;
  padding: 11px 18px;
  font-size: 0;
  line-height: 0;
  color: currentColor;
  /* transition: all 0.3s ease-in-out; */
  vertical-align: sub;
  svg {
    /* transition: fill 0.3s ease-in-out; */
    fill: currentColor;
  }
`;

export const Label = styled.span<{ hasIcon?: boolean }>`
  ${eyebrowButton};
  display: inline-block;
  color: ${colors.white};
  /* padding: 15px 38px; */
  padding: ${(props) => (props.hasIcon ? '15px 38px 15px 0px' : '15px 38px')};
  letter-spacing: 0px;
  transition: color 0.3s ease-in-out;
`;

const Secondary = css`
  background: #fff;
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  &[data-touchable='false']:hover {
    color: ${colors.highlight};
    border: 1px solid ${colors.highlight};
    background: #fff;

    ${Label} {
      color: ${colors.highlight};
    }
  }

  ${Label} {
    color: ${colors.primary};
  }
`;

const Tertiary = css`
  padding: 0;
  border: none;
  background: transparent;
  color: ${colors.primary};
  text-align: left;

  &[data-touchable='false']:hover {
    color: ${colors.highlight};
    background: transparent;
    border: none;

    svg {
      fill: ${colors.highlight};
    }

    ${Label} {
      color: ${colors.highlight};
    }
  }

  ${Label} {
    color: ${colors.primary};
  }
`;

const Delete = css`
  background: ${colors.highlight};
  color: white;

  &[data-touchable='false']:hover {
    background: ${colors.highlightDelete};
  }
`;

const Disabled = css`
  background: ${colors.greyLightLight};

  &[data-touchable='false']:hover {
    color: white;
    background: ${colors.greyLightLight};
    cursor: not-allowed;
    border: 1px solid transparent;
  }
`;

const DisabledSecondary = css`
  border-color: ${colors.greyLightLight};

  &[data-touchable='false']:hover {
    border-color: ${colors.greyLightLight};
    cursor: not-allowed;

    ${Label} {
      color: ${colors.greyLightLight};
    }
  }

  svg {
    fill: ${colors.greyLightLight};
  }

  ${Label} {
    color: ${colors.greyLightLight};
  }
`;

const DisabledTertiary = css`
  &[data-touchable='false']:hover {
    cursor: not-allowed;

    ${Label} {
      color: ${colors.greyLightLight};
    }
  }

  ${Label} {
    color: ${colors.greyLightLight};
  }
`;

const DisabledDelete = css`
  background: ${colors.greyMediumMedium};

  &[data-touchable='false']:hover {
    background: ${colors.greyMediumMedium};
    cursor: not-allowed;

    ${Label} {
      color: ${colors.greyLightLight};
    }
  }

  ${Label} {
    color: ${colors.greyLightLight};
  }
`;

interface IButton {
  secondary?: boolean;
  tertiary?: boolean;
  delete?: boolean;
  disabled?: boolean;
}

export const StyledButton = styled.button<IButton>`
  ${resetButtonStyles};
  ${eyebrowButton};
  color: white;
  background: ${colors.primary};
  border: 1px solid transparent;
  text-align: center;
  text-transform: uppercase;
  border-radius: none;
  padding: 0;
  display: inline-block;
  transition: all 0.3s ease-in-out !important;
  text-decoration: none;
  cursor: pointer;

  &[data-touchable='false']:hover {
    background: ${colors.highlight};
  }

  &:focus {
    transition: none;
    outline: 2px solid -webkit-focus-ring-color;
  }

  ${(props) => props.secondary && Secondary}
  ${(props) => props.tertiary && Tertiary}
  ${(props) => props.delete && Delete}
  ${(props) =>
    props.disabled &&
    !props.secondary &&
    !props.tertiary &&
    !props.delete &&
    Disabled}
  ${(props) => props.disabled && props.secondary && DisabledSecondary}
  ${(props) => props.disabled && props.tertiary && DisabledTertiary}
  ${(props) => props.disabled && props.delete && DisabledDelete}
`;
