import React from 'react';

import { Container, Label, Input } from './styles';
import PageTypeSelectorTypes from './PageTypeSelector.types';
import * as IconsView from 'components/IconsView';

const Radio = ({
  label,
  register,
  icon,
  ...radioProps
}: PageTypeSelectorTypes): JSX.Element => {
  const { id, name } = radioProps;

  if (!register) {
    return (
      <Container>
        <Input {...radioProps} type="radio" />
        <Label htmlFor={id}>
          {label}
          {icon === 'simple' ? (
            <IconsView.IconSimplePage />
          ) : icon === 'tabbed' ? (
            <IconsView.IconTabbedPage />
          ) : (
            <IconsView.IconOfficePage />
          )}
        </Label>
      </Container>
    );
  }

  return (
    <Container>
      <Input
        {...radioProps}
        {...register(name, { required: 'Select a template' })}
        type="radio"
      />
      <Label htmlFor={id}>
        {label}
        {icon === 'simple' ? (
          <IconsView.IconSimplePage />
        ) : icon === 'tabbed' ? (
          <IconsView.IconTabbedPage />
        ) : (
          <IconsView.IconOfficePage />
        )}
      </Label>
    </Container>
  );
};

export default Radio;
