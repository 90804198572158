import { memo, useState } from 'react';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  ModalContainer,
  SubmitButton,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import { useForm } from 'react-hook-form';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import IToast from 'types/ToastType';

export interface IFormValues {
  id?: string;
  pageId?: string;
  title?: string;
  body?: string;
  sort?: number;
  linkTitle?: string;
  linkTo?: string;
  externalLink?: string;
}

interface IAddSectionFormProps {
  mode: string;
  toggle: () => void;
  toast: IToast;
  phrase?: string;
  allPhrases?: any;
  onSubmitPhrases: (mode: string, value: string, oldValue?: string) => void;
}

const EventsFilteredPhraseForm = ({
  mode,
  toggle,
  toast,
  phrase,
  allPhrases,
  onSubmitPhrases,
}: IAddSectionFormProps): JSX.Element => {
  const [deletingPhrase, setDeletingPhrase] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'You have not entered a valid word or phrase.'
  );
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: { phrase },
  });

  return (
    <>
      <ModalContainer
        id="sectionModal"
        title={phrase ? 'Edit Phrase' : 'Add Filtered Phrase'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
        description="Edit the filtered phrase below."
      >
        <Form
          noValidate
          key="formModal"
          className="modalForm"
          onSubmit={handleSubmit(async (input) => {
            console.log('[handleSubmit] input: ', input);
            console.log('allPhrases: ', allPhrases);
            // const inputValue = input.phrase.replace(/^"(.+)"$/, '$1');
            const inputValue = input.phrase;
            let foundDupe = false;
            allPhrases.forEach((p: string) => {
              if (p.toLowerCase() === inputValue.toLowerCase()) {
                foundDupe = true;
              }
            });
            if (foundDupe) {
              setError('phrase', {
                type: 'manual',
                message:
                  'You have entered a word or phrase that already exists.',
              });
              setErrorMessage(
                'You have entered a word or phrase that already exists.'
              );
            } else {
              toggle();
              onSubmitPhrases(mode, inputValue, phrase);
            }
          })}
        >
          <TextInput
            key="phrase"
            id="phrase"
            name="phrase"
            label="Phrase"
            placeholder="Enter Phrase Here"
            register={register}
            setError={(value, error) => {
              console.log('error: ', error);
              setError('phrase', error);
            }}
            clearErrors={() => clearErrors('phrase')}
            error={!!errors?.phrase}
            setValue={setValue}
            errorMessage={errorMessage}
            required={true}
            hideRequired={true}
            onChange={() => {
              clearErrors('phrase');
            }}
            defaultValue={phrase || ''}
          />
          <ButtonContainer>
            <SubmitButton data-testid="submit section" type="submit">
              {phrase ? 'Save' : 'Add Filter'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {phrase ? (
              <DeleteButton
                type="button"
                onClick={() => setDeletingPhrase(true)}
                delete
              >
                Delete Phrase
              </DeleteButton>
            ) : null}
          </ButtonContainer>
        </Form>
      </ModalContainer>
      {checkUnsaved && (
        <UnsavedModal cancel={() => setCheckUnsaved(false)} close={toggle} />
      )}
      {deletingPhrase && phrase && (
        <DeleteForm
          type="phrase"
          id={phrase || ''}
          toast={toast}
          toggle={toggle}
          onPhraseDelete={() => {
            console.log('toggleDeletetoggleDeletetoggleDelete');
            setDeletingPhrase(false);
            onSubmitPhrases('delete', phrase);
          }}
        />
      )}
    </>
  );
};

export default memo(EventsFilteredPhraseForm);
