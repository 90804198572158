import React, { useEffect, useState, useRef } from 'react';
import {
  ArrowContainer,
  Button,
  ButtonCover,
  Container,
  DateContainer,
  Input,
  ModalContainer,
} from './styles';
import dayjs from 'dayjs';
import { IconCalendar, IconWarning } from 'components/IconsView';
import classNames from 'classnames';
import InputProps from './YearSelector.types';
import Label from '../Label';
import YearModal from './Components/YearModal/YearModal';
import { Error } from '../TextInput/styles';

const YearSelector = ({
  years,
  name,
  label,
  defaultValue,
  error,
  required,
  showRequired,
  className,
  register,
  setValue,
  optional,
  setError,
  clearErrors,
  selectYear,
}: InputProps): JSX.Element => {
  const ModalRef = useRef<HTMLHeadingElement>(null);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [openCalendar, setOpenCalendar] = useState(false);
  const bounceClickRef = useRef(false);

  const checkYear = (year: number) => {
    const yearInUse =
      years.findIndex((item) =>
        dayjs(item.date).isSame(dayjs().year(year), 'year')
      ) > -1;
    if (selectYear) {
      return (
        yearInUse &&
        !dayjs().year(year).isSame(dayjs().year(selectYear), 'year')
      );
    }
    return yearInUse;
  };

  useEffect(() => {
    if (!defaultValue) return;
    if (currentYear !== defaultValue) {
      setCurrentYear(defaultValue);
    }
    if (defaultValue) {
      setValue(name, `${defaultValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (defaultValue) {
      setValue(name, `${defaultValue}`);
    }
    if (checkYear(currentYear)) {
      setError(name, {
        type: 'manual',
        message: 'This year has already been added',
      });
    } else {
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const handleOpenCalendar = () => {
    setOpenCalendar((prev) => !prev);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleModalBackgroundClick = (e: any) => {
    if (!bounceClickRef.current) {
      bounceClickRef.current = true;
      if (!ModalRef.current?.contains(e.target as HTMLElement)) {
        handleOpenCalendar();
      }

      setTimeout(() => {
        bounceClickRef.current = false;
      }, 200);
    }
  };

  useEffect(() => {
    if (openCalendar) {
      document.addEventListener('click', handleModalBackgroundClick);
      return () => {
        document.removeEventListener('click', handleModalBackgroundClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCalendar]);

  return (
    <Container>
      {label && (
        <Label
          className={error ? 'error' : ''}
          name={name}
          required={showRequired}
          optional={optional}
        >
          {label}
        </Label>
      )}
      <DateContainer
        className={classNames('date', className, {
          error: error,
          focus: openCalendar,
        })}
      >
        <ButtonCover
          type="button"
          aria-label="open-calendar"
          onClick={() => {
            if (!openCalendar) {
              handleOpenCalendar();
            }
          }}
        />
        <Input
          aria-label={name}
          value={currentYear}
          name={name}
          data-testid={name}
          id={name}
          disabled
          placeholder="YYYY"
        />
        <ArrowContainer>
          <Button>
            <IconCalendar />
          </Button>
        </ArrowContainer>
        {openCalendar && (
          <>
            <ModalContainer
              ref={ModalRef}
              className={classNames('modal-year', { open: openCalendar })}
            >
              <YearModal
                currentYear={currentYear}
                handleYearSelected={(year) => {
                  setCurrentYear(year);
                  setValue(name, `${year}`);
                  setOpenCalendar(false);
                  if (checkYear(year)) {
                    setError(name, {
                      type: 'manual',
                      message: 'This year has already been added',
                    });
                  } else {
                    clearErrors(name);
                  }
                }}
              />
            </ModalContainer>
          </>
        )}
      </DateContainer>
      {error && (
        <Error className="error">
          <IconWarning />
          This year has already been added
        </Error>
      )}
    </Container>
  );
};

export default YearSelector;
