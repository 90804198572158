import React from 'react';
import {
  SubmitButton,
  CancelButton,
  ButtonContainer,
  ModalContainer,
} from './styles';

interface IDeactivateFormProps {
  toggle: (refetch?: boolean) => void;
  preventUnlock?: boolean;
}

const DeactivateForm = ({ toggle }: IDeactivateFormProps): JSX.Element => {
  return (
    <ModalContainer
      id="sectionModal"
      title={`Are you sure you want to DEACTIVATE this office?`}
      description="Warning: This cannot be undone"
      toggle={() => {
        toggle();
      }}
      // preventUnlock={preventUnlock}
    >
      <ButtonContainer>
        <SubmitButton
          type="button"
          className="ModalSubmit"
          delete
          onClick={async () => {
            toggle(true);
          }}
        >
          Yes, Deactivate
        </SubmitButton>
        <CancelButton
          type="button"
          className="Modalcancel"
          value="Cancel"
          tertiary
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </CancelButton>
      </ButtonContainer>
    </ModalContainer>
  );
};

export default DeactivateForm;
