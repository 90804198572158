import React, { useEffect, useCallback, useState, forwardRef } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import SectionContainer from 'components/SectionContainer';
import { useForm, SubmitHandler } from 'react-hook-form';
import TextInput from 'components/FormComponents/TextInput';
import PropTypes from './EventsFeed.types';
import * as queries from 'graphql/queries';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { Pacman, FormContainer, StatusContainer, Subtext } from './styles';
import { ReactComponent as Close } from 'images/icons/fnd_close.svg';
import { ReactComponent as Check } from 'images/icons/fnd_check.svg';
import { validateCalendarLink } from 'utils/functions';
import { debounce } from 'lodash';

const GET_EVENTS = gql(queries.getEvents);

interface EventsFeedProps {
  link: string;
  onSubmitLink: SubmitHandler<PropTypes>;
}

const EventsFeed = forwardRef(
  (
    { link, onSubmitLink }: EventsFeedProps,
    ref: React.Ref<HTMLFormElement>
  ): JSX.Element => {
    const [customError, setCustomError] = useState<boolean>(false);
    const {
      register,
      handleSubmit: handleSubmit2,
      setError,
      clearErrors,
      watch,
      setValue,
      formState: { errors },
    } = useForm();

    // get User token for auth request //////////////////////////////////////////
    const { user } = useAuthenticator((context) => [context.user]);
    const userToken = user?.attributes?.address;

    // Checking to see if there is Data associated with provided link /////////////
    const {
      data: eventsData,
      loading: eventsLoading,
      error: eventsError,
      refetch: eventsRefetch,
      networkStatus,
    } = useQuery(GET_EVENTS, {
      variables: {
        input: {
          accessToken: userToken,
          calID: link,
        },
      },
      notifyOnNetworkStatusChange: true,
      skip: !userToken,
    });

    const urlWatch = watch('url');

    // check to see if first 5 letters are fivea in the url //////////////////////
    const isValid = useCallback(
      (url: string) => {
        if (url && url.length > 5) {
          if (!validateCalendarLink(url)) {
            clearErrors('url');
            return true;
          }
        }
        return false;
      },
      [clearErrors]
    );
    // function that returns the status of GET_EVENTS query //////////////////////
    const getEventsStatus = (): JSX.Element => {
      if (errors.url) {
        return <Close />;
      }
      if (eventsLoading) {
        return <Pacman />;
      } else if (eventsError) {
        return <Close />;
      } else if (networkStatus === NetworkStatus.refetch) {
        return <Pacman />;
      } else if (eventsData?.getEvents) {
        return <Check />;
      }
      return <div>Looking</div>;
    };

    const debouncedFetch = React.useRef(
      debounce((calID: string) => {
        eventsRefetch({
          input: {
            calID: calID,
            accessToken: userToken,
          },
        });
      }, 750)
    ).current;

    React.useEffect(() => {
      return () => {
        debouncedFetch.cancel();
      };
    }, [debouncedFetch]);

    useEffect(() => {
      if (urlWatch) {
        debouncedFetch(urlWatch);
      }
    }, [
      errors.url,
      eventsRefetch,
      isValid,
      urlWatch,
      userToken,
      debouncedFetch,
    ]);

    const onSubmit = (data: PropTypes): void => {
      console.log(data);
      if (data.url) {
        onSubmitLink(data);
      }
    };

    return (
      <SectionContainer
        title="Events"
        description="Display company events/activities via Google Calendar Feed on the Intranet homepage."
        includePadding={true}
      >
        <FormContainer>
          <form noValidate onSubmit={handleSubmit2(onSubmit)} ref={ref}>
            <TextInput
              id="url"
              name="url"
              required={false}
              defaultValue={link}
              placeholder="Enter URL Here"
              label="Google Calendar feed URL"
              register={register}
              setValue={(name, v) => setValue(name, v)}
              setError={(url, error) => setError('url', error)}
              clearErrors={() => clearErrors('url')}
              error={!!(customError || errors?.url)}
              errorMessage="Enter Google Calendar ID that starts with fiveanddone.com, or ask Joel for help."
              onBlur={(e) => {
                const value = e.target.value;
                setCustomError(!validateCalendarLink(value));
              }}
            />

            <Subtext>
              This field only accepts URLs from https://calendar.google.com.
            </Subtext>
          </form>
          <StatusContainer>{getEventsStatus()}</StatusContainer>
        </FormContainer>
      </SectionContainer>
    );
  }
);
export default EventsFeed;
