import React, { useCallback, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { debounce } from 'lodash';
import Modal from 'containers/Modal';
import * as mutations from 'graphql/mutations';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';

import {
  Form,
  SubmitButton,
  CancelButton,
  DeleteButton,
  ButtonContainer,
  FormInputContainer,
  CustomTextInput,
} from '../styles';
import MigrateForm from 'components/Forms/MigrateForm/MigrateForm';
import { FAQsection } from 'API';
import { SectionDataI } from 'containers/FAQsContainer';

const CREATE_FAQ_SECTION = gql(mutations.createFAQsection);
const UPDATE_FAQ_SECTION = gql(mutations.updateFAQsection);
const DELETE_FAQ_SECTION = gql(mutations.deleteFAQsection);
const UPDATE_FAQ = gql(mutations.updateFAQ);

interface IAddSectionFormProps {
  toggle: (refetch?: boolean) => void;
  defaultValues?: FAQsection;
  toast: { success: (str: string) => void; error: (str: string) => void };
  length?: number;
  officeID?: string;
  sectionData: SectionDataI[];
}

export interface IFormValues {
  __typename?: string;
  id?: string;
  officeID?: string;
  faqs?: any;
  createdAt?: string;
  updatedAt?: string;
  title?: string;
  sort?: number;
}

const SectionForm = ({
  toggle,
  defaultValues,
  toast,
  length,
  sectionData,
}: IAddSectionFormProps): JSX.Element => {
  const [deletingSection, setDeletingSection] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: {
      id: defaultValues?.id || '',
      title: defaultValues?.title || '',
    },
  });

  // Toast handler
  const toastHandler = useCallback(
    (toastType: string, toastMessage: string) => {
      switch (toastType) {
        case 'success':
          toast.success(toastMessage);
          break;
        case 'error':
          toast.error(toastMessage);
          break;
        default:
          break;
      }
    },
    [toast]
  );

  // Make sure toast calls only happen every 0.5 seconds to avoid duplicate messages
  const debouncedToast = useMemo(
    () =>
      debounce((toastType: string, toastMessage: string) => {
        toastHandler(toastType, toastMessage);
      }, 500),
    [toastHandler]
  );

  const [createFAQsection] = useMutation<IFormValues>(CREATE_FAQ_SECTION, {
    onCompleted: () => {
      toast.success('Category created');
    },
    onError: () => {
      toast.error('Error creating category');
    },
  });
  const [updateFAQsection] = useMutation<IFormValues>(UPDATE_FAQ_SECTION, {
    onCompleted: () => {
      toast.success('Category updated');
    },
    onError: () => {
      toast.error('Error updating error');
    },
  });
  const [deleteFAQSection] = useMutation(DELETE_FAQ_SECTION, {
    onCompleted: () => {
      toast.success('FAQ Section deleted');
    },
    onError: () => {
      toast.error('Error deleting FAQ Section');
    },
  });

  const [updateFAQ] = useMutation(UPDATE_FAQ, {
    onCompleted: () => {
      debouncedToast('success', 'Question updated');
    },
    onError: () => {
      debouncedToast('error', 'Error updating question');
    },
  });

  const [titleFilled, setTitleFilled] = useState(false);

  const defaultTitleErrorMessage = 'Enter a category title';
  const [titleErrorMessage, setTitleErrorMessage] = useState(
    defaultTitleErrorMessage
  );

  let hasChildren = false;
  if (defaultValues?.faqs) {
    hasChildren = (defaultValues?.faqs?.items?.length || 0) > 0;
  }

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    async (input) => {
      const dupeTitle = sectionData?.some(
        (section) => section.title.toLowerCase() == input?.title?.toLowerCase()
      );

      if (dupeTitle) {
        setTitleErrorMessage(
          'This category name already exists. Please enter a different category name.'
        );
        setError('title', { type: 'manual', message: '' });
        return;
      }

      // Update FAQ Section logic
      if (defaultValues?.id) {
        await updateFAQsection({
          variables: {
            input,
          },
        });
      } else {
        // Create FAQ Section logic
        delete input.id;
        await createFAQsection({
          variables: { input: { ...input, sort: length } },
        });
      }

      toggle(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultValues,
      length,
      toggle,
      updateFAQsection,
      createFAQsection,
      sectionData,
    ]
  );

  // Migrate FAQ's from section to be deleted to another section
  const migrateChildrenFAQs = useCallback(
    async (migrateSectionId: string) => {
      const oldSection = sectionData.find((s) => s.id === defaultValues?.id);

      // Update Old sections FAQ's pointer to parent section
      await oldSection?.faqs.forEach(async (faq) => {
        await updateFAQ({
          variables: {
            input: {
              id: faq.id,
              question: faq.question,
              sectionID: migrateSectionId,
              answer: faq.answer,
              officeIDs: faq.officeIDs,
            },
          },
        });
      });

      // Once all are migrated, delete the old section
      await deleteFAQSection({
        variables: {
          input: {
            id: defaultValues?.id,
          },
        },
      });

      // Reset deleting status
      setDeletingSection(false);

      // Close modal
      toggle();
    },
    [defaultValues, deleteFAQSection, sectionData, toggle, updateFAQ]
  );

  return (
    <>
      <Modal
        id="sectionModal"
        title={
          defaultValues?.id
            ? `Edit Category: ${defaultValues?.title}`
            : 'Add Category'
        }
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(onSubmit)}
          className="modalForm"
        >
          <FormInputContainer>
            <CustomTextInput
              id="title"
              name="title"
              label="Category Title"
              required={true}
              hideRequired={true}
              register={register}
              setError={(name, error) => setError('title', error)}
              clearErrors={() => clearErrors('title')}
              errorMessage={titleErrorMessage}
              error={!!errors?.title}
              setValue={setValue}
              placeholder="Enter Category Title Here"
              maxLength={50}
              onChange={(e) => {
                if (e.target.value.length) {
                  setTitleFilled(true);
                } else {
                  setTitleFilled(false);
                }
              }}
              defaultValue={defaultValues?.title || ''}
            />
          </FormInputContainer>
          <ButtonContainer>
            <SubmitButton
              aria-label="Submit"
              key="submit"
              type="submit"
              className="ModalSubmit"
              disabled={!isDirty || !titleFilled}
            >
              {defaultValues?.id ? 'Save' : 'Create'}
            </SubmitButton>
            <CancelButton
              aria-label="Cancel"
              key="cancel"
              type="button"
              className="Modalcancel"
              value="Cancel"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {defaultValues?.id ? (
              <DeleteButton
                delete
                // disabled={hasChildren}
                type="button"
                onClick={() => setDeletingSection(true)}
              >
                Delete Category
              </DeleteButton>
            ) : null}
          </ButtonContainer>
        </Form>
      </Modal>

      {/* When section has FAQ's, FAQ's can't be orphaned, so migrate to another section */}
      {defaultValues?.id && deletingSection && hasChildren && (
        <MigrateForm
          preventUnlock={true}
          toggle={toggle}
          toggleDelete={() => setDeletingSection(false)}
          section={defaultValues}
          sectionData={sectionData}
          onMigrate={async (migrateSectionId) => {
            migrateChildrenFAQs(migrateSectionId);
          }}
        />
      )}

      {deletingSection && !hasChildren && (
        <DeleteForm
          preventUnlock={true}
          type="faqSection"
          id={defaultValues?.id ? defaultValues?.id : ''}
          toast={toast}
          toggle={toggle}
          sectionData={sectionData}
          toggleDelete={() => setDeletingSection(false)}
        />
      )}
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default SectionForm;
