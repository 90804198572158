import styled from 'styled-components';
import { colors, customBezierEase } from '../../../shared/variables';
import { bodyCopy, resetButtonStyles } from '../../../shared/extends';
import { size } from 'shared/mixins';

export const Container = styled.div`
  width: 100%;
`;

export const SelectContainer = styled.div`
  position: relative;
  background-color: ${colors.white};
  outline: 1px solid ${colors.white};
  outline-offset: -1px;

  &.error {
    outline-color: ${colors.highlight};
  }
  &.errorLimited {
    outline-color: ${colors.highlight};
  }

  svg.error-icon {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const Selected = styled.button`
  ${resetButtonStyles};
  ${bodyCopy};
  color: ${colors.brownMedium};
  cursor: pointer;
  padding: 15px 45px 12px 16px;
  width: 100%;
  height: 50px;
  z-index: 2;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  overflow: hidden;

  .errorLimited & {
    padding-left: 36px;

    .open & {
      padding-left: 16px;
    }
  }

  .open & {
    transition: 0.2s ${customBezierEase};
  }

  &:disabled {
    background-color: ${colors.greyMedium};
    outline: 1px solid ${colors.greyMedium};
  }

  &::-ms-expand {
    display: none;
  }

  span {
    ${bodyCopy};
    color: ${colors.brownMedium};
    position: relative;
    width: 100%;
    max-width: 100%;
    z-index: 1;
  }

  svg {
    fill: ${colors.primary};
    pointer-events: none;
    position: absolute;
    right: 18px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    ${size('15px', '10px')};

    .open & {
      // transform: translateY(-50%) rotate(180deg);
    }
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  .open & {
    opacity: 1;
    pointer-events: all;
  }
`;

export const Options = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 50px;
  outline: 1px solid ${colors.greyMedium};
  outline-offset: -1px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 50px 12px 0;
  background-color: ${colors.white};
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ${customBezierEase};

  .open & {
    opacity: 1;
    pointer-events: all;
    max-height: 275px;
  }
`;

export const OptionContainer = styled.div`
  max-height: 225px;
  overflow: auto;
`;

export const Option = styled.button`
  ${resetButtonStyles};
  ${bodyCopy};
  text-align: left;
  padding: 4px 8px;
  margin: 7px 0;
  display: block;
  background-color: ${colors.white};
  border-radius: 0;
  white-space: nowrap;
  width: 100%;
  transition: 0.2s ${customBezierEase};

  &.active,
  &:focus,
  &:hover {
    color: ${colors.white};
    outline: none;
    background: ${colors.primary};
  }
`;

export const Disclaimer = styled.span`
  ${bodyCopy};
  color: ${colors.brownLight};
  margin-top: 6px;
`;
