import Modal from 'containers/Modal';
import React, { useState } from 'react';
import { IProps } from './AddSectionForm.types';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { ErrorOption, useForm } from 'react-hook-form';
import { ButtonContainer, CancelButton, Form, SubmitButton } from './styles';
import TextInput from 'components/FormComponents/TextInput';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import slugify from 'slugify';
import { useHistory } from 'react-router-dom';
import { PageStatus } from 'API';
import { formatSlug, handleSlugCheck } from 'utils/functions';

const CREATE_PAGE = gql(mutations.createPage);

const AddSectionForm = ({
  toast,
  toggle,
  length,
  slugs,
}: IProps): JSX.Element => {
  const [slugTouch, setSlugTouch] = useState(false); // Used to check if slug has been touched
  const [customError, setCustomError] = useState<boolean>(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      title: '',
      slug: '',
      // description: '',
    },
  });

  const history = useHistory();

  const [createPage] = useMutation(CREATE_PAGE, {
    onCompleted: (page) => {
      toast.success('Page created');

      history.push(`/main/edit/${page.createPage.id}`);
      toggle(true);
    },
    onError: () => {
      toast.error('Error creating page');
    },
  });

  return (
    <>
      <Modal
        id="sectionModal"
        title={`Add a New Section`}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('Add Form', input);
            const id = `page-${slugify(input.title).toLowerCase()}`;
            await createPage({
              variables: {
                input: {
                  id,
                  sort: length,
                  pageId: 'main',
                  layout: 'main',
                  title: input.title,
                  label: input.title,
                  // description: input.description,
                  slug: input.slug,
                  status: PageStatus.disabled,
                },
              },
            });
          })}
        >
          <TextInput
            name="title"
            id="title"
            placeholder="Enter a title"
            maxLength={50}
            required={true}
            hideRequired={true}
            label="Section Title"
            register={register}
            data-testid="title"
            setValue={setValue}
            setError={(_title: any, error: ErrorOption) =>
              setError('title', error)
            }
            clearErrors={() => clearErrors('title')}
            error={!!errors?.title}
            errorMessage="Enter a title"
            disclaimer="This is the title that will be displayed on the top of the browser window."
            onChange={(e) => {
              /* This is a function that is called when the title input is changed. It checks if the slug
            has been touched. If it hasn't, it will take the value of the title input and slugify
            it. Then it will set the value of the slug input to the slugified value. */
              if (!slugTouch) {
                const value = e.target.value;
                let slug = slugify(value).toLowerCase();
                slug = formatSlug(slug);
                const slugInput = document.getElementById(
                  'slug'
                ) as HTMLInputElement;
                if (slugInput) {
                  setValue('slug', slug);
                  slugInput.value = slug;
                  clearErrors('slug');
                }
                handleSlugCheck({
                  value: slug,
                  id: '',
                  slugList: slugs,
                  callbackFunc: setCustomError,
                });
              }
              clearErrors('title');
            }}
          />
          <TextInput
            name="slug"
            id="slug"
            placeholder="Enter a slug"
            required={true}
            hideRequired={true}
            label="Slug"
            register={register}
            setError={(slug, error) => setError('slug', error)}
            clearErrors={() => clearErrors('slug')}
            error={!!(customError || errors?.slug)}
            errorMessage={
              (!!customError && 'Enter an unique slug') || 'Enter a slug'
            }
            setValue={setValue}
            pattern="[^a-z0-9-_]|-(?=-)"
            onBlur={(e) => {
              const value = e.target.value;
              setSlugTouch(true);
              handleSlugCheck({
                value,
                id: '',
                slugList: slugs,
                callbackFunc: setCustomError,
              });
            }}
          />
          {/* <TextInput
            name="description"
            id="description"
            placeholder="Enter Description Text Here"
            maxLength={50}
            optional={true}
            label="Description Text"
            register={register}
            setError={(description, error) => setError('description', error)}
            clearErrors={() => clearErrors('description')}
            error={!!errors?.description}
            errorMessage="Enter a description"
          /> */}
          <ButtonContainer>
            <SubmitButton
              data-testid="submit section"
              type="submit"
              disabled={
                !(watch('title')?.trim() && watch('slug')) || customError
              }
            >
              Create Section
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Modal>

      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default AddSectionForm;
