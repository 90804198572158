import React from 'react';
import { useLocation } from 'react-router-dom';

import Button from 'components/Button';
import { ModalContainer } from './styles';

interface IHaltFormProps {
  toggle: () => void;
  toggleDelete?: () => void;
  preventUnlock?: boolean;
}

const defaultDesc = `To delete this parent entry, please delete child entries.`;

const options: any = {
  '/page-holiday': `To delete this year, please delete events within.`,
  '/page-team-members': `To delete this office, please delete the team members within.`,
  // '/page-team-members': `To delete this office, please delete the team members within or move those team members to another section.`,
};

const HaltForm = ({
  toggle,
  toggleDelete,
  preventUnlock,
}: IHaltFormProps): JSX.Element => {
  const location = useLocation();
  return (
    <ModalContainer
      preventUnlock={preventUnlock}
      id="sectionModal"
      title="Can’t delete at this time"
      description={
        location && options[location.pathname]
          ? options[location.pathname]
          : defaultDesc
      }
      toggle={() => {
        if (toggleDelete) {
          toggleDelete();
        } else {
          toggle();
        }
      }}
    >
      <Button
        type="button"
        onClick={() => {
          if (toggleDelete) {
            toggleDelete();
          } else {
            toggle();
          }
        }}
      >
        Ok
      </Button>
    </ModalContainer>
  );
};

export default HaltForm;
