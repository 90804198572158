import { QuickLink } from 'API';

interface IFormat {
  quicklinks: QuickLink[];
  quicklinksSort: string[];
  addQuicklink: ({ length }: { length: number }) => void;
  updateQuicklink: (id: string) => void;
  deleteQuicklink: ({ id }: { id: string }) => void;
  sortQuicklinks: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[]
  ) => void;
}

export const formatDataForAccordion = ({
  quicklinks,
  quicklinksSort,
  addQuicklink,
  updateQuicklink,
  deleteQuicklink,
  sortQuicklinks,
}: IFormat): any => {
  console.log(quicklinks, quicklinksSort);
  // include any as type because I can't merge Quicklink to include order
  const formatQuicklink = (quicklink: QuickLink | any, index: number): any => {
    return {
      id: quicklink.id,
      title: quicklink.title,
      index: index,
      originalIndex: quicklink.sort,
      sortable: true,
      editFunc: () => {
        updateQuicklink(quicklink.id);
      },
      deleteFunc: () => {
        deleteQuicklink({ id: quicklink.id });
      },
    };
  };
  return {
    accordionContainer:
      quicklinks
        .slice()
        ?.sort((x: QuickLink, y: QuickLink) => {
          return quicklinksSort.indexOf(x.id) - quicklinksSort.indexOf(y.id);
        })
        .map(formatQuicklink) || [],
    addFuncText: 'Add Additional Quick Link',
    addFunc: () => {
      addQuicklink({ length: quicklinks.length || 0 });
    },
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortQuicklinks({ oldIndex, newIndex }, items);
    },
  };
};
