import styled from 'styled-components';
import { subHeadLarge } from 'shared/extends';
import { colors } from 'shared/variables';
import Button from 'components/Button';

export const Form = styled.form`
  min-width: 33%;
  /* width: 320px; */
  display: grid;
  grid-row-gap: 32px;
`;

export const TopSection = styled.div`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const LineBreak = styled.hr`
  width: 100%;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const PreviewContainer = styled.div``;
export const Preview = styled.div`
  ${subHeadLarge};
  background: ${colors.greyMedium};
  color: ${colors.brownLight};
  padding: 23px 20px;
`;

export const ButtonContainer = styled.div``;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 99px;
  bottom: 58px;
`;
export const PageTypeSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 12px;
  width: 480px;
`;
export const RadioError = styled.span`
  font-family: TT Commons, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #fb4640;
  margin-left: 8px;
`;
export const RadioErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
`;
