import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Authenticator, View, Image } from '@aws-amplify/ui-react';
import GlobalStyle from 'shared/globalStyles';
import Navigation from 'components/Navigation';
import { Main, LeftContainer, MainContainer } from 'pageStyles';
import TeamMembersContainer from 'containers/TeamMembers';
import View404 from 'components/View404';
import FAQsContainer from 'containers/FAQsContainer';
import CompanyPolicies from 'containers/CompanyPolicies';
import { useFeatures } from 'flagged';
import '@aws-amplify/ui-react/styles.css';
import toast, { Toaster } from 'react-hot-toast';
import AllPages from 'containers/AllPages';
import Holidays from 'containers/Holidays';
import Homepage from 'containers/Homepage';
import SimplePageContainer from 'containers/SimplePage';
import Manage404 from 'containers/Manage404/Manage404';
import FreelanceDirectory from 'containers/FreelanceDirectory';
import { Helmet } from 'react-helmet';
import TemplateContainer from 'containers/TemplateContainer';
import MainPageContainer from 'containers/MainPage';
import BackToTopView from 'components/BackToTopView';
import { gql, useQuery } from '@apollo/client';
import { listPagesForNavigation } from 'utils/queries';
import { Page } from 'API';
import styled from 'styled-components';
import { fontSize } from 'shared/typography';
import { colors } from 'shared/variables';
import ExpenseReport from 'containers/ExpenseReport';
import ManageOffices from 'containers/ManageOffices';
import SuggestedSearch from 'containers/SuggestedSearch/SuggestedSearch';
import LunchSpots from 'containers/LunchSpots/LunchSpots';

const LIST_PAGES = gql(listPagesForNavigation);

const Title = styled.h1`
  font-family: 'Surveyor Text, Helvetica, Arial, sans-serif';
  ${fontSize(24, 20, 0)};
  margin: 24px 0 0;
  color: ${colors.brownMedium};
`;

const AuthStateApp: FC = () => {
  const features = useFeatures();

  const component = {
    Header() {
      return (
        <View textAlign="left">
          <Image
            width="48px"
            alt="Five and Done logo"
            src="../images/fiveAndDone.png"
          />
          <Title>Intranet Admin</Title>
        </View>
      );
    },
  };

  const translateIdToFeature = (id: string) => {
    switch (id) {
      case 'page-accept-agreements':
        return 'agreements';
      case 'page-accounts':
        return 'accounts';
      case 'page-email-signature-builder':
        return 'email';
      case 'page-expense':
        return 'expense';
      case 'page-faq':
        return 'faqs';
      case 'page-freelance-directory':
        return 'freelancedir';
      case 'page-holiday':
        return 'holidaydates';
      case 'page-home':
        return 'homepage';
      case 'page-lunch-spots':
        return 'lunch';
      case 'page-team-members':
        return 'teammembers';
      case 'page-media-kit':
        return 'mediakit';
      case 'page-pto':
        return 'pto';
      case 'page-404':
        return '404';
      case 'page-manage-offices':
        return 'manageoffices';
      default:
        return 'unassigned';
    }
  };

  const { data: listPagesData, fetchMore } = useQuery(LIST_PAGES);
  const [allPages, setAllPages] = useState<Page[]>([]);

  useEffect(() => {
    const getAllPages = async () => {
      let pagesAll = listPagesData?.listPages?.items;
      if (listPagesData?.listPages?.nextToken) {
        const tempBugs = await fetchMore({
          variables: { nextToken: listPagesData?.listPages?.nextToken },
        });
        pagesAll = [...pagesAll, ...tempBugs?.data?.listPages?.items];
      }
      setAllPages(pagesAll);
    };
    getAllPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPagesData]);

  const pageData = useMemo(() => {
    const pageList: { label: string; link: string; feature: string }[] = [];
    allPages?.filter((item: Page) => {
      if (
        (!item.layout?.match(/^(plain|template|tabbed|main|officeTab)$/) ||
          item.id === 'page-home') &&
        item.id !== 'page-manage-offices'
      ) {
        pageList.push({
          label: item.label || item.title,
          link: `/${item.id}`,
          feature: translateIdToFeature(item.id),
        });
      }
    });
    return pageList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages]);

  const NAVIGATION_LINKS = {
    title: 'Intranet Admin',
    links: pageData
      .filter((l) => {
        if (l.feature === undefined) return true;
        return features[l.feature];
      })
      .slice()
      .sort((a, b) => {
        if (a?.label < b?.label) {
          return -1;
        } else if (a?.label > b?.label) {
          return 1;
        }
        return 0;
      })
      .sort((a) => {
        if (a?.label === '404') {
          return 1;
        }
        return 0;
      }),
  };

  const handleSignOut = async (signOut: () => void) => {
    signOut();
  };

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', documentHeight);
    documentHeight();

    return () => {
      window.removeEventListener('resize', documentHeight);
    };
  }, []);

  return (
    <Authenticator
      variation="modal"
      socialProviders={['google']}
      components={component}
    >
      {({ user, signOut }: any) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (!groups.includes('admin')) {
          handleSignOut(signOut);
        }
        return (
          <Router>
            <Main>
              <Helmet>
                <meta charSet="utf-8" />
                <title>Five & Done | Intranet Admin</title>
                <meta
                  name="description"
                  content="The Intranet to End All Intranets."
                />
              </Helmet>
              <GlobalStyle />
              <BackToTopView />
              <LeftContainer>
                <Navigation
                  {...NAVIGATION_LINKS}
                  user={user.attributes.name}
                  userPicture={user.attributes.picture}
                  signOut={() => handleSignOut(signOut)}
                />
              </LeftContainer>
              <MainContainer>
                <Switch>
                  <Route exact path="/">
                    <AllPages toast={toast} />
                  </Route>
                  <Route exact path="/page-home">
                    <Homepage toast={toast} />
                  </Route>
                  <Route exact path="/page-company-policies">
                    <CompanyPolicies />
                  </Route>
                  <Route exact path="/page-team-members">
                    <TeamMembersContainer toast={toast} />
                  </Route>
                  <Route exact path="/page-freelance-directory">
                    <FreelanceDirectory toast={toast} />
                  </Route>
                  <Route exact path="/page-holiday">
                    <Holidays toast={toast} />
                  </Route>
                  <Route exact path="/page-faq">
                    <FAQsContainer toast={toast} />
                  </Route>
                  <Route exact path="/page-pto">
                    <SimplePageContainer id="page-pto" toast={toast} />
                  </Route>
                  <Route exact path="/page-lunch-spots">
                    {features['v1.5'] ? (
                      <LunchSpots toast={toast} />
                    ) : (
                      <SimplePageContainer
                        id="page-lunch-spots"
                        toast={toast}
                      />
                    )}
                  </Route>
                  <Route exact path="/page-expense">
                    <ExpenseReport id="page-expense" toast={toast} />
                  </Route>
                  <Route exact path="/page-email-signature-builder">
                    <SimplePageContainer
                      id="page-email-signature-builder"
                      toast={toast}
                    />
                  </Route>
                  <Route exact path="/page-manage-offices">
                    <ManageOffices toast={toast} />
                  </Route>
                  <Route exact path="/page-suggested-search">
                    <SuggestedSearch toast={toast} />
                  </Route>

                  {/* PAGES - EDIT */}
                  <Route
                    path="/main/edit/:slug"
                    render={({ match }) => {
                      return (
                        <MainPageContainer
                          toast={toast}
                          id={match.params.slug}
                        />
                      );
                    }}
                  />
                  <Route
                    path="/page/edit/:slug"
                    render={({ match }) => {
                      return (
                        <TemplateContainer
                          toast={toast}
                          id={match.params.slug}
                        />
                      );
                    }}
                  />
                  <Route exact path="/page-404">
                    <Manage404 toast={toast} />
                  </Route>
                  <Route path="/404">
                    <View404 />
                  </Route>
                  <Route path="*">
                    <Redirect to="/404" push />
                  </Route>
                </Switch>
              </MainContainer>
            </Main>
            <Toaster />
          </Router>
        );
      }}
    </Authenticator>
  );
};

export default AuthStateApp;
