import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';

export const SubmitButton = styled(Button)`
  margin-top: 1rem;
`;

export const Pacman = styled.div`
  --primaryClr: $(colors.primary);
  --pacman-zise: 20px;
  margin-top: 15px;
  border-radius: 50%;
  margin: 0 auto;
  border-radius: 100em 100em 0 0;
  background: #f00;
  transform-origin: bottom;
  animation: eating-top 0.5s infinite;

  &,
  &::before {
    width: var(--pacman-zise);
    height: calc(var(--pacman-zise) / 2);
    background: ${colors.primary};
  }

  &::before {
    content: '';
    display: block;
    margin-top: calc(var(--pacman-zise) / 2);
    position: absolute;
    transform-origin: top;
    border-radius: 0 0 100em 100em;
    transform: rotate(80deg);
    animation: eating-bottom 0.5s infinite;
  }

  &::after {
    position: absolute;
    border-radius: 100em;
    content: '';
    display: block;
    height: 4px;
    width: 4px;
    margin-top: calc((var(--pacman-zise) / 2) - 2px);
    margin-left: calc((var(--pacman-zise) / 2) - 2px);
    transform-origin: center;
    animation: center 0.5s infinite, ball 0.5s -0.33s infinite linear;
  }

  @keyframes eating-top {
    0% {
      transform: rotate(-40deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-40deg);
    }
  }

  @keyframes eating-bottom {
    0% {
      transform: rotate(80deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(80deg);
    }
  }

  @keyframes center {
    0% {
      transform: rotate(40deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(40deg);
    }
  }

  @keyframes ball {
    0% {
      opacity: 0.7;
      box-shadow: 20px 0 0 0 ${colors.primary};
    }
    100% {
      box-shadow: 0px 0 0 0 ${colors.primary};
    }
  }
`;
export const FormContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 40px;
`;
export const StatusContainer = styled.div`
  padding-top: 2.5rem;
  text-align: center;
  width: 40px;
  padding-left: 10px;
`;
export const Subtext = styled.p`
  font-family: TT Commons, Helvetica, Arial, sans-serif;
  color: #8a816d;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
`;
