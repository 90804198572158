import React, { useMemo, useState, useRef, useEffect } from 'react';
import { IFreelance } from './FreelanceDirectory.types';
import { gql, useQuery, useMutation } from '@apollo/client';
import PageTop from 'components/PageTop';
import { Container } from './styles';
import SectionContainer from 'components/SectionContainer';
import { getPage } from 'graphql/queries';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import FreelancerForm from './FreelancerForm';
import { listPages } from 'utils/queries';
import { formatDataForAccordion } from './helpers/format';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_FREELANCERS = gql(queries.listFreelancers);
export const GET_FREELANCER = gql(queries.getFreelancer);
export const UPDATE_FREELANCER = gql(mutations.updateFreelancer);
// const SORT_ITEMS = gql(mutations.sortItemsV2);
export const LIST_PAGES = gql(listPages);
export const UPDATE_PAGE = gql(mutations.updatePage);

const FreelanceDirectory = ({ toast }: IFreelance): JSX.Element => {
  const [freelancerViews, setFreelancerViews] = useState<{
    [key: string]: { newStatus: boolean; oldStatus: boolean };
  }>({});
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [gettingFreelancer, setGettingFreelancer] = useState<null | string>(
    null
  );
  const [creatingFreelancer, setCreatingFreelancer] = useState<{
    length: number;
  } | null>(null);
  const [deletingFreelancer, setDeletingFreelancer] = useState(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const {
    loading: pageLoading,
    refetch: pageRefetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-freelance-directory',
    },
  });

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const {
    loading: freelanceLoading,
    refetch: freelanceRefetch,
    data: freelancersData,
  } = useQuery(LIST_FREELANCERS);

  const freelancers = useMemo(() => {
    return freelancersData?.listFreelancers?.items;
  }, [freelancersData]);

  const { data: freelanceData } = useQuery(GET_FREELANCER, {
    variables: {
      id: gettingFreelancer,
    },
    skip: typeof gettingFreelancer !== 'string',
  });

  const freelance = useMemo(() => {
    if (!gettingFreelancer) {
      return null;
    }
    return freelanceData?.getFreelancer || null;
  }, [freelanceData, gettingFreelancer]);

  useEffect(() => {
    // check if items were sorted or changed views
    if (Object.keys(freelancerViews).length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freelancerViews]);

  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!freelancers) return null;
    return formatDataForAccordion({
      freelancers,
      createFreelancer: (length) => setCreatingFreelancer({ length }),
      updateFreelancer: setGettingFreelancer,
      deleteFreelancer: setDeletingFreelancer,
    });
  }, [freelancers]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-freelance-directory',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    setFreelancerViews({});
    //* setting it to refetch gets old data
    // gifRefetch();
    pageRefetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        data={data}
        slugList={slugList}
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Add &amp; Edit Freelancers"
        description="Manage freelancer information to be displayed in the freelance directory."
      >
        {freelanceLoading || !accordionData ? (
          <></>
        ) : (
          <Accordion {...accordionData} />
        )}
      </SectionContainer>
      {freelance && (
        <FreelancerForm
          toggle={() => {
            freelanceRefetch();
            setGettingFreelancer(null);
          }}
          defaultValues={freelance}
          toast={toast}
        />
      )}
      {creatingFreelancer && (
        <FreelancerForm
          toggle={() => {
            freelanceRefetch();
            setCreatingFreelancer(null);
          }}
          length={freelancers?.length || 0}
          toast={toast}
        />
      )}
      {deletingFreelancer && (
        <DeleteForm
          id={deletingFreelancer}
          toggle={(refetch) => {
            setDeletingFreelancer(null);
            if (refetch) {
              freelanceRefetch();
            }
          }}
          type="freelancer"
          toast={toast}
        />
      )}
    </Container>
  );
};

export default FreelanceDirectory;
