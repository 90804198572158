import React from 'react';
import { FadeLoader } from 'react-spinners';
import { Container } from './styles';

const LoaderView = (): JSX.Element => {
  return (
    <Container>
      <FadeLoader />
    </Container>
  );
};

export default LoaderView;
