import React, { useEffect, forwardRef, useState } from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Form } from './styles';
import Header from 'components/Header';
import Meta from 'components/Meta';
import IntroCopy from 'components/IntroCopy';
import PageTopTypes from './PageTop.types';
import UnsavedModalRoute from 'components/UnsavedModalRoute';
import { Page } from 'API';
import { SlugI } from 'types/SlugListType';

interface Props {
  data?: Page;
  children?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | readonly React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >[];
  slugList: SlugI[];
  onSubmitUpdatePage: SubmitHandler<PageTopTypes>;
  submitAllForms: any;
  isDirtyForm: boolean;
  mileageRateIsDirty?: boolean;
  setIsDirtyForm: (val: boolean) => void;
}

const PageTop = forwardRef(
  (
    {
      data,
      children,
      slugList,
      onSubmitUpdatePage,
      submitAllForms,
      isDirtyForm,
      setIsDirtyForm,
      mileageRateIsDirty,
    }: Props,
    ref: React.Ref<HTMLFormElement>
  ): JSX.Element => {
    const methods = useForm({
      defaultValues: {
        status: data?.status || 'disabled',
        title: data?.title || '',
        slug: data?.slug || '',
        updatedAt: data?.updatedAt || '',
        // description: data?.description || '',
        headline: data?.headline || '',
        body: data?.body || '',
        ctaText: data?.ctaText || '',
        ctaLink: data?.ctaLink || '',
      },
    });
    const [visible, setVisible] = React.useState(
      data?.status === 'active' ? true : false
    );
    const [completeSlug, setCompleteSlug] = useState('');

    const [dontDirty, setDontDirty] = React.useState(false);

    useEffect(() => {
      if (
        // methods.formState.isDirty &&
        Object.keys(methods.formState.dirtyFields).length > 0
      ) {
        setIsDirtyForm(true);
      } else if (
        // (!methods.formState.isDirty) &&
        Object.keys(methods.formState.dirtyFields).length === 0
      ) {
        setIsDirtyForm(false);
        methods.reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods, methods.formState.isDirty]);

    useEffect(() => {
      if (data) {
        methods.register('status', { value: data?.status || 'disabled' });
        if (data.status === 'active') {
          setVisible(true);
        } else if (data.status === 'disabled') {
          setVisible(false);
        }

        let tempSlug = `/${data.slug}`;
        if (data.parentPage) {
          tempSlug = `/${data.parentPage.slug}${tempSlug}`;
        }
        setCompleteSlug(tempSlug);
      }
    }, [data, methods]);

    useEffect(() => {
      if (visible === true) {
        methods.setValue('status', 'active', {
          shouldDirty: data?.status !== (visible ? 'active' : 'disabled'),
        });
      } else if (visible === false) {
        methods.setValue('status', 'disabled', {
          shouldDirty: data?.status !== (visible ? 'active' : 'disabled'),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, methods, data]);

    const renderChildren = (): React.ReactNode => {
      if (!children) return null;
      const UpdatedChildren = React.Children.map(
        children,
        (child: React.ReactElement) => {
          return React.cloneElement(child, { methods: methods });
        }
      );
      return UpdatedChildren;
    };

    const checkPage = (): boolean => {
      if (!data) {
        return false;
      }
      if (data.id === 'page-home') {
        return false;
      }
      if (
        data.layout === 'plain' ||
        data.layout === 'tabbed' ||
        data.layout === 'officeTab'
      ) {
        return true;
      }
      if (data.layout === 'main') {
        return true;
      }
      return false;
    };

    return (
      <FormProvider {...methods}>
        {(isDirtyForm || methods.formState.isDirty) && (
          <UnsavedModalRoute
            content="Doing this will lose any unsaved changes. Are you sure you want to continue?"
            title="Are you sure you want to leave this unsaved?"
            type="page"
            isBlocked={isDirtyForm}
          />
        )}
        <Header
          submitAllForms={submitAllForms}
          label={data?.label || ''}
          updatedAt={data?.updatedAt || ''}
          isDirtyForm={isDirtyForm}
          mileageRateIsDirty={mileageRateIsDirty}
          visible={visible}
          setVisible={setVisible}
          showVisible={checkPage()}
          slug={completeSlug}
          parentVisible={
            data?.parentPage ? data?.parentPage?.status === 'active' : true
          }
        />
        <Form
          noValidate
          ref={ref}
          onSubmit={methods.handleSubmit(async (input) => {
            await onSubmitUpdatePage(input);
            setIsDirtyForm(false);
            setDontDirty(true);
            methods.reset({
              title: input?.title || '',
              slug: input?.slug || '',
              updatedAt: input?.updatedAt || '',
              // description: input?.description || '',
              headline: input?.headline || '',
              body: input?.body || '',
              ctaText: input?.ctaText || '',
              ctaLink: input?.ctaLink || '',
            });
            setTimeout(() => {
              setDontDirty(false);
            }, 1500);
          })}
        >
          <Meta
            title={data?.title}
            slug={data?.slug}
            // description={data?.description || ''}
            id={data?.id}
            methods={methods}
            slugList={slugList}
          />
          {data?.id !== 'page-404' && (
            <IntroCopy
              headline={data?.headline || ''}
              body={data?.body || ''}
              ctaText={data?.ctaText || ''}
              ctaLink={data?.ctaLink || ''}
              methods={methods}
              id={data?.id}
              dontDirty={dontDirty}
              setDontDirty={setDontDirty}
            />
          )}
          {renderChildren()}
        </Form>
      </FormProvider>
    );
  }
);

export default PageTop;
