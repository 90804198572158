import { Office } from 'API';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { ISortingParent } from 'types/SortingType';
import { SectionDataI, FAQDataI } from '..';

interface ISetDeleteForm {
  id: string;
  officeID?: string;
  type: string;
}
interface IFormat {
  sectionData: SectionDataI[];
  accordionsSort: ISortingParent[];
  accordionIsOpen: any[];
  offices: Office[];
  addQuestion: ({ length, id }: { length: number; id: string }) => void;
  editQuestion: (id: string) => void;
  addSection: (temp: boolean) => void;
  editSection: (id: string) => void;
  setDeleteForm: ({ id, officeID, type }: ISetDeleteForm) => void;
  sortItems: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[],
    id?: string,
    parentIndex?: number
  ) => void;
}

export const formatDataForAccordion = ({
  sectionData,
  accordionIsOpen,
  accordionsSort,
  offices = [],
  addSection,
  addQuestion,
  editSection,
  editQuestion,
  setDeleteForm,
  sortItems,
}: IFormat): AccordionTypes | null => {
  const formatFAQ = (faq: FAQDataI, index: number) => {
    // Get each office label, if it doesn't exist, set this FAQ to ALERT and display warning state
    const officeLabels: string[] = [];
    faq.officeIDs?.forEach((faqOfficeId) => {
      officeLabels.push(
        offices.find((office) => office.id === faqOfficeId)?.name || ''
      );
    });

    // Determine sub title based on matching offices
    const subTitle = officeLabels.length
      ? officeLabels.join(', ')
      : 'Not Displaying - No Location';

    return {
      id: faq.id,
      index: index,
      originalIndex: faq.sort,
      title: faq.question,
      subTitle,
      alert: !officeLabels.length,
      editFunc: () => {
        editQuestion(faq.id);
      },
      deleteFunc: () => {
        setDeleteForm({ id: faq.id, type: 'faq' });
      },
    };
  };

  const formatSection = (section: SectionDataI, index: number) => {
    return {
      id: section.id,
      index: index,
      originalIndex: index,
      title: section.title,
      show: accordionIsOpen.includes(section.id),
      editFunc: () => {
        editSection(section.id);
      },
      deleteFunc: () => {
        setDeleteForm({
          id: section.id,
          type: 'faqSection',
        });
      },
      onSortEnd: (
        {
          oldIndex,
          newIndex,
        }: {
          oldIndex: number;
          newIndex: number;
        },
        items?: any[],
        id?: string
      ) => {
        sortItems({ oldIndex, newIndex }, items, id, index);
      },
      addFunc: () => {
        addQuestion({
          length: section?.faqs?.length || 0,
          id: section.id,
        });
      },
      addFuncText: 'Add Additional FAQ',
      accordionItems: section.faqs
        ?.slice()
        ?.sort((x, y) => {
          return (
            (accordionsSort[index]?.items?.findIndex(
              (item) => item.id === x.id
            ) || 0) -
            (accordionsSort[index]?.items?.findIndex(
              (item) => item.id === y.id
            ) || 0)
          );
        })
        ?.map(formatFAQ),
    };
  };

  return {
    accordionContainer: sectionData
      .slice()
      ?.sort((x, y) => {
        return (
          (accordionsSort.findIndex((i) => i.id === x.id) || 0) -
          (accordionsSort.findIndex((i) => i.id === y.id) || 0)
        );
      })
      .map(formatSection),
    addFunc: () => {
      addSection(true);
    },
    addFuncText: 'Add New Category',
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortItems({ oldIndex, newIndex }, items, 'sections');
    },
  };
};
