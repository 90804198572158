import styled from 'styled-components';
import { mq, size } from '../../shared/mixins';
import { colors } from '../../shared/variables';
import { font, fontSize } from '../../shared/typography';
import { eyebrowRegular } from 'shared/extends';

export const Container = styled.div``;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  margin: 0 -6%;
`;

export const Month = styled.h3`
  ${eyebrowRegular};
  color: ${colors.brownMedium};
  margin: 0;
`;

export const Button = styled.button<{ side: string }>`
  border: 0;
  border-radius: 0;
  background: transparent;
  outline: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;

  svg {
    fill: ${colors.primary};
  }

  &:disabled {
    pointer-events: none;
    svg {
      fill: ${colors.greyLightLight};
    }
  }

  ${(props) =>
    props.side === 'left' &&
    `
    transform: scale(-1);
  `}

  &:hover {
    path {
      fill: ${colors.highlight};
    }
  }

  /* &:focus {
    path {
      fill: ${colors.brownLight};
    }
  } */
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${size('35px', '100%')}
`;

export const WeekLabelContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: space-between;
`;

export const WeekLabel = styled.div`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;

  span {
    align-items: center;
    color: ${colors.brownLessLight};
    display: flex;
    justify-content: center;

    ${font('body', 'regular')}
    ${fontSize(18, 22)}
    ${size('42px')}
  }
  ${mq.phoneWide`
    span {
      ${size('30px')};
    }
  `}
`;
