import React from 'react';
import { WeekContainer } from './styles';

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const Week = ({ children }: Props): JSX.Element => {
  return <WeekContainer>{children}</WeekContainer>;
};

export default Week;
