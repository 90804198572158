import React from 'react';
import { IProps } from './DeleteQuicklinksForm.types';
import {
  ButtonContainer,
  CancelButton,
  ContainerModal,
  Content,
  DeleteButton,
  List,
  PageLink,
  Warning,
} from './styles';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import { QuickLink } from 'API';

const DELETE_QUICKLINK = gql(mutations.deleteQuickLink);
const DELETE_PAGE = gql(mutations.deletePage);

const DeleteQuicklinksForm = ({
  toast,
  toggle,
  page,
  quicklinks,
}: IProps): JSX.Element => {
  const { title, layout, pages, id } = page;
  const [deleteQuicklink] = useMutation(DELETE_QUICKLINK);
  const [deletePage] = useMutation(DELETE_PAGE);

  const handleDelete = async () => {
    //* Delete all Quicklinks attached
    for (let i = 0; i < quicklinks.length; i++) {
      const q = quicklinks[i];
      await deleteQuicklink({ variables: { input: { id: q?.id } } });
    }
    //* Delete the template pages attached if a plain or tabbed page
    if (layout?.match(/^(plain|tabbed)$/) && pages?.items) {
      for (let i = 0; i < pages?.items.length; i++) {
        const p = pages?.items[i];
        await deletePage({ variables: { input: { id: p?.id } } });
      }
    }
    //* Delete the page
    await deletePage({ variables: { input: { id } } });
    toast.success('Page Deleted');
    toggle(true);
  };

  return (
    <>
      <ContainerModal
        id="sectionModal"
        title={`This page is referenced in ${quicklinks.length} quick link(s). Are you sure you want to DELETE this page?`}
        toggle={() => {
          toggle();
        }}
        description={`Quick links on the page(s) below reference ${title}. Please resolve by clicking on the links below and updating. `}
      >
        <Content>
          <List>
            {quicklinks.map((q: QuickLink | null) => {
              if (!q) {
                return null;
              }

              const parentPage = q.page;
              if (parentPage) {
                let link = '';
                if (parentPage.layout?.match(/^(plain|tabbed)$/)) {
                  link = `/page/edit/${parentPage.id}`;
                } else if (
                  parentPage.layout === 'main' &&
                  parentPage.id !== 'page-home'
                ) {
                  link = `/main/edit/${parentPage.id}`;
                } else {
                  link = `/${parentPage.id}`;
                }
                return (
                  <li key={parentPage.id}>
                    <PageLink to={link} target="_blank">
                      {parentPage.title}
                    </PageLink>
                  </li>
                );
              }
            })}
          </List>
          <Warning>
            Warning: Deleting {title} cannot be undone. Related and unresolved
            quick links on the affected pages above will be{' '}
            <span className="bold">permanently deleted.</span>
          </Warning>
          <ButtonContainer>
            <DeleteButton delete onClick={() => handleDelete()}>
              Yes, Delete
            </DeleteButton>
            <CancelButton tertiary onClick={() => toggle()}>
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Content>
      </ContainerModal>
    </>
  );
};

export default DeleteQuicklinksForm;
