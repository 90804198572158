import styled from 'styled-components';
import { colors } from 'shared/variables';
import { font, fontSize } from 'shared/typography';
import { size } from 'shared/mixins';
import { bodyCopy, bodyCopyBold, resetButtonStyles } from 'shared/extends';

export const Container = styled.div``;

export const Wrapper = styled.div`
  padding: 1em 0;
  display: grid;
  gap: 5px;
`;

export const WithImage = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  height: 158px;
  width: 158px;
  position: relative;
  z-index: 1;
  .AmpImage {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* --width: 100%; */
    --height: 100%;
    border-radius: 50%;
    z-index: -1;
    object-fit: cover;
  }

  .rect & {
    ${size('248px', '158px')};
    border-radius: 4px;

    .AmpImage {
      border-radius: 4px;
    }
  }
`;

export const WithoutImage = styled.div`
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 150ms ease;
  ${size('158px')};

  &:hover {
    border-color: ${colors.highlight};
    svg {
      fill: ${colors.highlight};
    }
    cursor: pointer;
  }
  &:focus {
    svg {
      fill: ${colors.focus};
    }
  }
  &.error {
    border-color: ${colors.highlight};
  }

  &:before {
    content: '';
    border-radius: 100%;
    position: absolute;
    border: 5px dashed ${colors.brownLight};
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }

  svg {
    fill: ${colors.brownLight};
    ${size('48px', '32px')};
    transition: all 150ms ease;
  }

  .rect & {
    ${size('248px', '158px')};
    border-radius: 0;

    &:before {
      border-radius: 0;
    }
  }
`;

export const DropboxLabel = styled.label`
  ${bodyCopyBold};
  color: ${colors.brownLight};
  text-align: center;
  padding-top: 12px;
`;

export const DropboxDisclaimer = styled.span`
  text-align: center;
  padding-top: 8px;
  color: ${colors.brownLight};
  width: 90px;
  ${font('body', 'normal')};
  ${fontSize(12, 12, 0)};
`;

export const Input = styled.input`
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  color: transparent;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
`;

export const ArrowIcon = styled.div`
  background-color: ${colors.primary};
  margin: 0 auto;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease;
  ${WithoutImage}:hover & {
    background: white;
    transition: all 150ms ease;
    svg path {
      fill: ${colors.greyMediumMedium};
    }
  }
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.greyMediumMedium};
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
  }

  .rect & {
    border-radius: 4px;
  }
`;

export const Close = styled.button`
  ${resetButtonStyles};
  position: absolute;
  border-radius: 100%;
  right: 0;
  top: 0;
  padding: 10px;
  border: 2px solid ${colors.primary};
  background-color: ${colors.white};
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 150ms ease;
  cursor: pointer;
  line-height: 0;
  z-index: 100;
  ${size('40px')};

  svg {
    position: relative;
    left: 1px;
    fill: ${colors.primary};
    ${size('16px')};
    transition: fill 150ms ease;
  }

  &:hover {
    border-color: ${colors.highlight};
    svg {
      fill: ${colors.highlight};
    }
  }

  .rect & {
    top: -7px;
    right: -7px;
  }
`;

export const Error = styled.p`
  ${bodyCopy};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;

export const BadUploads = styled.ul`
  margin: 16px 0 0;
  padding: 0;
  list-style: none;
`;

export const UploadError = styled.li`
  ${bodyCopy};
  color: ${colors.highlight};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    transform: translateY(5px);
    ${size('16px')};
  }

  span {
    font-weight: bold;
  }
`;

export const MissingError = styled.div`
  ${bodyCopy};
  color: ${colors.highlight};
  margin: 8px 0;
  font-weight: bold;

  > svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    transform: translateY(5px);
    ${size('16px')};
  }
`;

export const ClearError = styled.button`
  ${resetButtonStyles};
  ${size('20px')};
  margin-left: 8px;

  svg {
    fill: ${colors.highlight};
    ${size('8.5px')};
  }
`;

export const ImageFileName = styled.p`
  font-size: 14px;
  color: #8a816d;
`;
