/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEvents = /* GraphQL */ `query GetEvents($input: GetEventsInput) {
  getEvents(input: $input) {
    events {
      startDate
      endDate
      title
      id
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventsQueryVariables, APITypes.GetEventsQuery>;
export const getApplication = /* GraphQL */ `query GetApplication($id: ID!) {
  getApplication(id: $id) {
    id
    name
    image
    username
    password
    serial
    path
    website
    sort
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetApplicationQueryVariables,
  APITypes.GetApplicationQuery
>;
export const listApplications = /* GraphQL */ `query ListApplications(
  $filter: ModelApplicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      image
      username
      password
      serial
      path
      website
      sort
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListApplicationsQueryVariables,
  APITypes.ListApplicationsQuery
>;
export const getFAQ = /* GraphQL */ `query GetFAQ($id: ID!) {
  getFAQ(id: $id) {
    id
    question
    answer
    sectionID
    sort
    officeIDs
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFAQQueryVariables, APITypes.GetFAQQuery>;
export const listFAQS = /* GraphQL */ `query ListFAQS($filter: ModelFAQFilterInput, $limit: Int, $nextToken: String) {
  listFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      question
      answer
      sectionID
      sort
      officeIDs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFAQSQueryVariables, APITypes.ListFAQSQuery>;
export const getFAQsection = /* GraphQL */ `query GetFAQsection($id: ID!) {
  getFAQsection(id: $id) {
    id
    title
    sort
    faqs {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFAQsectionQueryVariables,
  APITypes.GetFAQsectionQuery
>;
export const listFAQsections = /* GraphQL */ `query ListFAQsections(
  $filter: ModelFAQsectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listFAQsections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      sort
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFAQsectionsQueryVariables,
  APITypes.ListFAQsectionsQuery
>;
export const getFreelancer = /* GraphQL */ `query GetFreelancer($id: ID!) {
  getFreelancer(id: $id) {
    id
    name
    email
    phone
    location
    portfolio
    design
    ux
    motion
    development
    photography
    video
    copywriting
    branding
    rate
    referredBy
    notes
    status
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFreelancerQueryVariables,
  APITypes.GetFreelancerQuery
>;
export const listFreelancers = /* GraphQL */ `query ListFreelancers(
  $filter: ModelFreelancerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreelancers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      phone
      location
      portfolio
      design
      ux
      motion
      development
      photography
      video
      copywriting
      branding
      rate
      referredBy
      notes
      status
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFreelancersQueryVariables,
  APITypes.ListFreelancersQuery
>;
export const getGIF = /* GraphQL */ `query GetGIF($id: ID!) {
  getGIF(id: $id) {
    id
    sort
    title
    image
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGIFQueryVariables, APITypes.GetGIFQuery>;
export const listGIFS = /* GraphQL */ `query ListGIFS($filter: ModelGIFFilterInput, $limit: Int, $nextToken: String) {
  listGIFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sort
      title
      image
      active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListGIFSQueryVariables, APITypes.ListGIFSQuery>;
export const getHoliday = /* GraphQL */ `query GetHoliday($id: ID!) {
  getHoliday(id: $id) {
    id
    title
    sort
    disclaimer
    startDate
    endDate
    createdAt
    updatedAt
    holidayType
    yearID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHolidayQueryVariables,
  APITypes.GetHolidayQuery
>;
export const listHolidays = /* GraphQL */ `query ListHolidays(
  $filter: ModelHolidayFilterInput
  $limit: Int
  $nextToken: String
) {
  listHolidays(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      sort
      disclaimer
      startDate
      endDate
      createdAt
      updatedAt
      holidayType
      yearID
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHolidaysQueryVariables,
  APITypes.ListHolidaysQuery
>;
export const getMember = /* GraphQL */ `query GetMember($id: ID!) {
  getMember(id: $id) {
    id
    title
    image
    name
    nameLow
    description
    department
    reportTo
    projects
    phone
    email
    active
    startDate
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    sort
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMemberQueryVariables, APITypes.GetMemberQuery>;
export const listMembers = /* GraphQL */ `query ListMembers(
  $filter: ModelMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      image
      name
      nameLow
      description
      department
      reportTo
      projects
      phone
      email
      active
      startDate
      officeID
      sort
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMembersQueryVariables,
  APITypes.ListMembersQuery
>;
export const getMileageRate = /* GraphQL */ `query GetMileageRate($id: ID!) {
  getMileageRate(id: $id) {
    id
    rate
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMileageRateQueryVariables,
  APITypes.GetMileageRateQuery
>;
export const listMileageRates = /* GraphQL */ `query ListMileageRates(
  $filter: ModelMileageRateFilterInput
  $limit: Int
  $nextToken: String
) {
  listMileageRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      rate
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMileageRatesQueryVariables,
  APITypes.ListMileageRatesQuery
>;
export const getOffice = /* GraphQL */ `query GetOffice($id: ID!) {
  getOffice(id: $id) {
    id
    name
    sort
    active
    group
    timezone
    members {
      nextToken
      __typename
    }
    pages {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOfficeQueryVariables, APITypes.GetOfficeQuery>;
export const listOffices = /* GraphQL */ `query ListOffices(
  $filter: ModelOfficeFilterInput
  $limit: Int
  $nextToken: String
) {
  listOffices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOfficesQueryVariables,
  APITypes.ListOfficesQuery
>;
export const getLocation = /* GraphQL */ `query GetLocation($id: ID!) {
  getLocation(id: $id) {
    id
    type
    sort
    active
    officeID
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationQueryVariables,
  APITypes.GetLocationQuery
>;
export const listLocations = /* GraphQL */ `query ListLocations(
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      sort
      active
      officeID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationsQueryVariables,
  APITypes.ListLocationsQuery
>;
export const getPage = /* GraphQL */ `query GetPage($id: ID!) {
  getPage(id: $id) {
    id
    label
    status
    createdAt
    updatedAt
    title
    slug
    description
    eyebrow
    headline
    body
    inlineBody
    ctaText
    ctaLink
    headlineLow
    bodyLow
    layout
    quicklinks {
      nextToken
      __typename
    }
    referencedQuicklinks {
      nextToken
      __typename
    }
    pageId
    pages {
      nextToken
      __typename
    }
    parentPage {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    local
    publishedAt
    image
    sort
    meta
    officeId
    office {
      id
      name
      sort
      active
      group
      timezone
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPageQueryVariables, APITypes.GetPageQuery>;
export const listPages = /* GraphQL */ `query ListPages(
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPagesQueryVariables, APITypes.ListPagesQuery>;
export const getPolicy = /* GraphQL */ `query GetPolicy($id: ID!) {
  getPolicy(id: $id) {
    id
    location
    title
    acknoledgement
    version
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPolicyQueryVariables, APITypes.GetPolicyQuery>;
export const listPolicies = /* GraphQL */ `query ListPolicies(
  $filter: ModelPolicyFilterInput
  $limit: Int
  $nextToken: String
) {
  listPolicies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      location
      title
      acknoledgement
      version
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPoliciesQueryVariables,
  APITypes.ListPoliciesQuery
>;
export const getQuickLink = /* GraphQL */ `query GetQuickLink($id: ID!) {
  getQuickLink(id: $id) {
    id
    pageId
    page {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    sort
    title
    body
    linkTitle
    externalLink
    linkId
    link {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuickLinkQueryVariables,
  APITypes.GetQuickLinkQuery
>;
export const listQuickLinks = /* GraphQL */ `query ListQuickLinks(
  $filter: ModelQuickLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuickLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pageId
      sort
      title
      body
      linkTitle
      externalLink
      linkId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuickLinksQueryVariables,
  APITypes.ListQuickLinksQuery
>;
export const getYear = /* GraphQL */ `query GetYear($id: ID!) {
  getYear(id: $id) {
    id
    title
    sort
    date
    createdAt
    updatedAt
    holidays {
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetYearQueryVariables, APITypes.GetYearQuery>;
export const listYears = /* GraphQL */ `query ListYears(
  $filter: ModelYearFilterInput
  $limit: Int
  $nextToken: String
) {
  listYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      sort
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListYearsQueryVariables, APITypes.ListYearsQuery>;
export const listApplicationByType = /* GraphQL */ `query ListApplicationByType(
  $type: String
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelApplicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listApplicationByType(
    type: $type
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      image
      username
      password
      serial
      path
      website
      sort
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListApplicationByTypeQueryVariables,
  APITypes.ListApplicationByTypeQuery
>;
export const listFAQBySection = /* GraphQL */ `query ListFAQBySection(
  $sectionID: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFAQFilterInput
  $limit: Int
  $nextToken: String
) {
  listFAQBySection(
    sectionID: $sectionID
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      question
      answer
      sectionID
      sort
      officeIDs
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFAQBySectionQueryVariables,
  APITypes.ListFAQBySectionQuery
>;
export const faqSectionByTitle = /* GraphQL */ `query FaqSectionByTitle(
  $title: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFAQsectionFilterInput
  $limit: Int
  $nextToken: String
) {
  faqSectionByTitle(
    title: $title
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      sort
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FaqSectionByTitleQueryVariables,
  APITypes.FaqSectionByTitleQuery
>;
export const listMembersByOffice = /* GraphQL */ `query ListMembersByOffice(
  $officeID: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMemberFilterInput
  $limit: Int
  $nextToken: String
) {
  listMembersByOffice(
    officeID: $officeID
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      image
      name
      nameLow
      description
      department
      reportTo
      projects
      phone
      email
      active
      startDate
      officeID
      sort
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMembersByOfficeQueryVariables,
  APITypes.ListMembersByOfficeQuery
>;
export const listLocationByType = /* GraphQL */ `query ListLocationByType(
  $type: String
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocationByType(
    type: $type
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      sort
      active
      officeID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationByTypeQueryVariables,
  APITypes.ListLocationByTypeQuery
>;
export const listLocationByOffice = /* GraphQL */ `query ListLocationByOffice(
  $officeID: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLocationFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocationByOffice(
    officeID: $officeID
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      sort
      active
      officeID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationByOfficeQueryVariables,
  APITypes.ListLocationByOfficeQuery
>;
export const listPagesByPageId = /* GraphQL */ `query ListPagesByPageId(
  $pageId: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPagesByPageId(
    pageId: $pageId
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPagesByPageIdQueryVariables,
  APITypes.ListPagesByPageIdQuery
>;
export const getPageBySlug = /* GraphQL */ `query GetPageBySlug(
  $slug: String
  $sortDirection: ModelSortDirection
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  getPageBySlug(
    slug: $slug
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageBySlugQueryVariables,
  APITypes.GetPageBySlugQuery
>;
export const listPagesByStatusAndHeadline = /* GraphQL */ `query ListPagesByStatusAndHeadline(
  $status: PageStatus
  $headline: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPagesByStatusAndHeadline(
    status: $status
    headline: $headline
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPagesByStatusAndHeadlineQueryVariables,
  APITypes.ListPagesByStatusAndHeadlineQuery
>;
export const listPagesByOffice = /* GraphQL */ `query ListPagesByOffice(
  $officeId: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPagesByOffice(
    officeId: $officeId
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPagesByOfficeQueryVariables,
  APITypes.ListPagesByOfficeQuery
>;
export const listPagesByLayout = /* GraphQL */ `query ListPagesByLayout(
  $layout: PageLayout
  $sortDirection: ModelSortDirection
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPagesByLayout(
    layout: $layout
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      local
      publishedAt
      image
      sort
      meta
      officeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPagesByLayoutQueryVariables,
  APITypes.ListPagesByLayoutQuery
>;
export const listQuicklinksByPageId = /* GraphQL */ `query ListQuicklinksByPageId(
  $pageId: ID
  $sort: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelQuickLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuicklinksByPageId(
    pageId: $pageId
    sort: $sort
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageId
      sort
      title
      body
      linkTitle
      externalLink
      linkId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuicklinksByPageIdQueryVariables,
  APITypes.ListQuicklinksByPageIdQuery
>;
export const listQuicklinksByLinkId = /* GraphQL */ `query ListQuicklinksByLinkId(
  $linkId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelQuickLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuicklinksByLinkId(
    linkId: $linkId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pageId
      sort
      title
      body
      linkTitle
      externalLink
      linkId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuicklinksByLinkIdQueryVariables,
  APITypes.ListQuicklinksByLinkIdQuery
>;
