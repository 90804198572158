import React, { useState, useCallback } from 'react';
import Button from 'components/Button';
import { LIST_ALL_POLICIES } from './queries';
import { useQuery } from '@apollo/client';
import { Policy } from 'API';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import Modal from 'containers/Modal';
import TextInput from 'components/FormComponents/TextInput';
import { useForm } from 'react-hook-form';
import {
  Container,
  Label,
  Divider,
  Header,
  TimeStamp,
  Top,
  ButtonGroup,
  H3,
  Body,
  AccordionWrapper,
  RadioGroup,
} from './styles';
import Radio from 'components/FormComponents/Radio';
import IFormValues from './CompanyPolicies.types';
import ImageInput from 'components/FormComponents/ImageInput';
import LoaderView from 'components/LoaderView';

const CompanyPolicies = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    data: dataPolicy,
    loading: loadingPolicy,
    error: errorPolicy,
  } = useQuery(LIST_ALL_POLICIES);

  // React Hook Form - hook
  const { register, handleSubmit, reset, setError, clearErrors } =
    useForm<IFormValues>();

  // Handle Modals
  const handleModalOpen = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen]
  );
  const handleDeleteModalOpen = useCallback(
    () => setDeleteOpen(!deleteOpen),
    [deleteOpen]
  );

  // Handle Add / Edit / Delete functions
  const defaultFormValues: IFormValues = {
    acknoledgement: undefined,
    content: undefined,
    id: undefined,
    title: undefined,
    updatedAt: undefined,
    version: undefined,
    location: undefined,
    first: 'test',
  };

  const editModalItem = useCallback(
    (item: string, index: number) => {
      const cardData = dataPolicy.listPolicies.items[index];
      reset(cardData);
      handleModalOpen();
    },
    [dataPolicy, handleModalOpen, reset]
  );

  const addModalItem = () => {
    reset(defaultFormValues);
    handleModalOpen();
  };

  // Modal Forms:
  const PolicyModal = {
    id: 'policyModal',
    title: 'Add New Policy',
    children: [
      <form
        noValidate
        key="policyFormModal"
        onSubmit={handleSubmit((data) => console.log(data))}
      >
        <p>Policy Title (Required)</p>
        <RadioGroup>
          <Radio
            label="All"
            id="All"
            name="location"
            value="All"
            register={register}
          />
          <Radio
            label="Orange County"
            id="OC"
            name="location"
            value="OC"
            register={register}
          />
          <Radio
            label="Dallas"
            id="Dallas"
            name="location"
            value="Dallas"
            register={register}
          />
        </RadioGroup>
        <TextInput
          // key="name"
          label="Policy Title (Required)"
          required
          id="title"
          name="title"
          // register={register}
          setError={(name, error) => setError('title', error)}
          clearErrors={() => clearErrors('title')}
        />
        <hr />
        <input type="checkbox" id="acknoledgement" name="acknoledgement" />
        <label htmlFor="switch">Acknowledgement (Required)</label>
        <TextInput
          key="Version"
          name="version"
          label="Version"
          id="Version"
          setError={(name, error) => setError('version', error)}
          clearErrors={() => clearErrors('version')}
        />
        <hr />

        <input key="submit" type="submit" />
      </form>,
    ],
  };
  const DeleteModal = {
    id: 'applicationDeleteModal',
    title: 'Delete',
    children: [
      <div key="deleteModal">
        <p>Are you sure you want to delete this Application?</p>
        <ButtonGroup>
          <Button
            key="submit"
            onClick={() => {
              console.log('submit');
              // onDelete();
            }}
          >
            Yes
          </Button>
          <Button
            key="cancel"
            onClick={() => {
              console.log('cancel');
              // handleDeleteModalOpen();
              // clearAllBodyScrollLocks();
            }}
          >
            No
          </Button>
        </ButtonGroup>
      </div>,
    ],
  };

  const formattedData: AccordionTypes = {
    addFunc: () => {
      addModalItem();
    },
    addFuncText: 'add another policy',
    accordionContainer: dataPolicy?.listPolicies?.items.map(
      (policy: Policy, index: number) => {
        return {
          key: policy.id,
          title: policy?.title,
          editFunc: () => {
            editModalItem(policy.id, index);
          },
          deleteFunc: () => {
            // deleteItem(policy.id);
          },
        };
      }
    ),
  };
  if (loadingPolicy) return <LoaderView />;
  if (errorPolicy) return <div>There was an error loading the content</div>;

  return (
    <Container>
      <Header>
        <Top>
          <Label>Company Policies</Label>
          <ButtonGroup>
            <Button>Save Changes</Button>
            <Button>Preview Page</Button>
            <Button>Preview Page</Button>
          </ButtonGroup>
        </Top>
        <Divider />
        <TimeStamp>Last saved on July 16, 2020</TimeStamp>
      </Header>

      <H3>Manage Content</H3>
      <Body>Click on the content you would like to edit.</Body>
      <ImageInput defaultValue="test" />

      <AccordionWrapper>
        <Accordion {...formattedData} />
      </AccordionWrapper>
      {modalOpen && (
        <Modal {...PolicyModal} toggle={handleModalOpen}>
          {PolicyModal.children}
        </Modal>
      )}
      {deleteOpen && (
        <Modal {...DeleteModal} toggle={handleDeleteModalOpen}>
          {DeleteModal.children}
        </Modal>
      )}
    </Container>
  );
};

export default CompanyPolicies;
