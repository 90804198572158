import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  Container,
  Deactivated,
  DeactivatedContainer,
  DefaultGroupContainer,
  Description,
  Dropdown,
  Form,
} from './styles';
import SectionContainer from 'components/SectionContainer';
import Accordion from 'components/Accordion';
import Header from 'components/Header';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import { Office } from 'API';
import {
  formatDataForActiveAccordion,
  formatDataForInactiveAccordion,
} from './helpers/format';
import { arrayMove } from 'react-sortable-hoc';
import OfficeForm from './Forms/OfficeForm/OfficeForm';
import DeactivateForm from './Forms/DeactivateForm/DeactivateForm';
import { useForm } from 'react-hook-form';
import LoaderView from 'components/LoaderView';

export type OfficeExtended = Office & { fakeActive: boolean };

interface IManageOffices {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
}

const LIST_OFFICES = gql(queries.listOffices);
const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const UPDATE_OFFICE_AND_PAGES = gql(mutations.updateOfficeAndConnectedPages);

const ManageOffices: FC<IManageOffices> = ({ toast }): JSX.Element => {
  const FormRef = useRef<HTMLFormElement | null>(null);
  const [offices, setOffices] = useState<OfficeExtended[]>([]);
  const [officeOptions, setOfficeOptions] = useState<
    { label: string; value: string | null }[]
  >([]);
  const [pageSorts, setPageSorts] = useState<AccordionItems[]>([]);
  const [pageViews, setPageViews] = useState<{
    [key: string]: { newStatus: boolean; oldStatus: boolean };
  }>({});
  const [accordionsSort, setAccordionsSort] = useState<string[]>([]);
  const [creatingOffice, setCreatingOffice] = useState(false);
  const [updatingOffice, setUpdatingOffice] = useState<{
    id: string;
    officeID: string;
    name: string;
    group: string;
    sort: number;
    active: boolean;
    timezone: string;
    hasChildren: boolean;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [saveStatus, setSaveStatus] = useState<{
    page: boolean;
    accordion: boolean;
  }>({ page: false, accordion: false });
  const [deactivatingOffice, setDeactivatingOffice] = useState<{
    id: string;
  } | null>(null);
  // const accordionData: AccordionTypes | null = useMemo(() => [
  //   if (!offices)
  // ])

  const { data: pageData, loading: pageLoading } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-manage-offices',
    },
  });

  const { data: officeData, refetch } = useQuery(LIST_OFFICES, {
    variables: {
      fetchPolicy: 'no-cache',
    },
  });
  useEffect(() => {
    const tempOfficeOptions: { label: string; value: string | null }[] = [];
    const tempOffices = officeData?.listOffices?.items?.map((item: Office) => {
      if (item.active) {
        tempOfficeOptions.push({ label: item.name || '', value: item.id });
      }
      return { ...item, fakeActive: item.active };
    });
    setOffices(tempOffices);
    setOfficeOptions(tempOfficeOptions);
  }, [officeData]);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const methods = useForm({
    defaultValues: {
      defaultGroup: data?.meta ? JSON.parse(data.meta)?.defaultGroup : null,
    },
  });

  useMemo(() => {
    if (data?.meta) {
      methods.setValue('defaultGroup', JSON.parse(data?.meta)?.defaultGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [pagesSort] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Offices sorted');
    },
    onError: () => {
      toast.error('Error sorting offices');
    },
  });

  const [updateOfficeAndConnectedPages] = useMutation(UPDATE_OFFICE_AND_PAGES, {
    onCompleted: () => {
      toast.success('Offices updated');
    },
    onError: () => {
      toast.error('Error updating offices');
    },
  });

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const watchGroup = methods.formState.isDirty;

  useEffect(() => {
    setIsDirtyForm(
      Object.keys(pageSorts).length > 0 ||
        Object.keys(pageViews).length > 0 ||
        !!watchGroup
    );
  }, [pageSorts, pageViews, watchGroup]);

  useEffect(() => {
    if (accordionsSort.length > 0) {
      // Find added items
      const addedItems =
        offices
          .filter((item) => item.fakeActive)
          .filter((item: any) => !accordionsSort.includes(item.id)) || [];

      const temp = [...accordionsSort];
      if (addedItems.length > 0) {
        addedItems.map((item: any) => {
          temp.push(item.id);
        });
      }

      setAccordionsSort(temp);
    } else {
      const temp: any = [];
      offices
        ?.slice()
        ?.filter((x) => x.active)
        ?.sort((x: Office, y: Office) => {
          return (x.sort || 0) - (y.sort || 0);
        })
        .map((q: any) => {
          temp.push(q.id);
        });
      setAccordionsSort(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offices]);

  const sortAccordions = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[]
    ) => {
      let newSort: AccordionItems[] = [];
      if (items) {
        const probableChange = arrayMove(items, oldIndex, newIndex);
        const differenceAry = probableChange.slice().map(function (n, i) {
          return i - probableChange[i].originalIndex;
        });
        const isDifference = differenceAry.some((value) => value !== 0);
        if (isDifference) {
          newSort = probableChange;
        } else {
          newSort = [];
        }
      }
      setPageSorts(newSort);

      const accordionChange = arrayMove(accordionsSort, oldIndex, newIndex);
      console.log(accordionChange);
      setAccordionsSort(accordionChange);
    },
    [accordionsSort]
    // [refetch]
  );

  const activeOffice = useCallback(
    async (id: string, newStatus: boolean, prevStatus: boolean) => {
      console.log('in active office func');
      setOffices((prev) => {
        const newOffices = [...prev];
        const officeIndex = newOffices.findIndex((o) => o.id === id);
        if (officeIndex > -1) {
          newOffices[officeIndex].fakeActive = newStatus;
        }
        return newOffices;
      });
      setPageViews((prev) => {
        const newView = { ...prev };
        if (newStatus === prevStatus) {
          delete newView[id];
        } else {
          newView[id] = { newStatus, oldStatus: prevStatus };
        }
        return newView;
      });

      setOfficeOptions((prev) => {
        const temp = [...prev];
        if (newStatus) {
          const option = offices.find((x: Office) => x.id === id);
          if (option) {
            temp.push({ label: option.name || '', value: option.id });
          }
        } else {
          const optionIndex = temp.findIndex((x) => x.value === id);
          if (optionIndex > -1) {
            temp.splice(optionIndex, 1);
          }
        }

        return temp
          ?.filter((obj, index) => {
            for (let i = 0; i < temp.length; i++) {
              if (i !== index && temp[i].value === obj.value) {
                return false;
              }
            }
            return true;
          })
          ?.sort(
            (
              x: { label: string; value: string | null },
              y: { label: string; value: string | null }
            ) => {
              return (
                accordionsSort.indexOf(x?.value || '') -
                accordionsSort.indexOf(y?.value || '')
              );
            }
          );
      });

      const newAccord = [...accordionsSort];

      if (newStatus) {
        newAccord.push(id);
      } else {
        const index = newAccord.findIndex((x) => x === id);
        newAccord.splice(index, 1);
      }
      setAccordionsSort(newAccord);

      if (!newStatus) {
        setTimeout(() => {
          methods.setValue('defaultGroup', newAccord[0]);
        }, 1000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offices, accordionsSort]
  );

  const activeAccordionData: AccordionTypes | null = useMemo(() => {
    if (!offices) return null;
    return formatDataForActiveAccordion({
      offices,
      accordionsSort,
      sortAccordions,
      createOffice: setCreatingOffice,
      updateOffice: setUpdatingOffice,
      activeOffice: (id) => setDeactivatingOffice({ id }),
    });
  }, [offices, accordionsSort, sortAccordions]);

  const inactiveAccordionData: AccordionTypes | null = useMemo(() => {
    if (!offices) return null;
    return formatDataForInactiveAccordion({
      offices,
      updateOffice: setUpdatingOffice,
      activeOffice,
      createOffice: () => console.log('create'),
      sortAccordions: () => console.log('sort'),
      accordionsSort,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offices, activeOffice]);

  const handleSave = async () => {
    if (FormRef.current) {
      FormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }

    const temp = [...pageSorts];
    const sendToUpdate: { id: string; sort: number }[] = [];
    const tempView = { ...pageViews };
    const sendToUpdateView: { id: string; active: boolean }[] = [];

    if (temp) {
      temp.forEach((page, index) => {
        if (page.index !== index) {
          sendToUpdate.push({
            id: page.id || '',
            sort: index,
          });
        }
      });
    }
    console.log('sendToUpdate', sendToUpdate);

    if (tempView) {
      Object.keys(tempView).forEach((key) => {
        const item = tempView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateView.push({
            id: key,
            active: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateView', sendToUpdateView);

    // if (sendToUpdate.length > 0 || sendToUpdateView.length > 0) {
    //   updatePage({
    //     variables: {
    //       input: {
    //         id: 'page-manage-offices',
    //         updatedAt: new Date().toISOString(),
    //       },
    //     },
    //   });
    // }

    if (sendToUpdate.length > 0) {
      await pagesSort({
        variables: { input: { tableName: 'Office', items: sendToUpdate } },
      });
    }
    if (sendToUpdateView.length > 0) {
      await updateOfficeAndConnectedPages({
        variables: {
          input: { items: sendToUpdateView },
        },
      });
    }

    //* RESET
    setPageSorts([]);
    setPageViews({});
    setIsDirtyForm(false);
    setSaveStatus((prev) => {
      return { ...prev, accordion: true };
    });
  };

  useEffect(() => {
    if (saveStatus.page && saveStatus.accordion) {
      refetch();
      setSaveStatus({ page: false, accordion: false });
    }
  }, [saveStatus, refetch]);

  const watchDefaultGroup = methods.watch('defaultGroup');
  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <Header
        hideManage={false}
        label="Office Management"
        submitAllForms={handleSave}
        updatedAt={data?.updatedAt || ''}
        showVisible={false}
        isDirtyForm={isDirtyForm || methods.formState.isDirty}
        slug=""
      />
      <Form
        ref={FormRef}
        noValidate
        onSubmit={methods.handleSubmit(async (input) => {
          console.log(input);
          updatePage({
            variables: {
              input: {
                id: 'page-manage-offices',
                meta: JSON.stringify({ defaultGroup: input.defaultGroup }),
              },
            },
          });

          setIsDirtyForm(false);
          methods.reset({
            defaultGroup: input?.defaultGroup,
          });

          setSaveStatus((prev) => {
            return { ...prev, page: true };
          });
        })}
      >
        <SectionContainer
          includePadding={false}
          title="Add & Edit Offices"
          description="Manage Offices for use throughout the system"
          // methods={methods}
        >
          {officeOptions.length > 0 ? (
            <DefaultGroupContainer>
              <Dropdown
                setValue={(name, value) =>
                  methods.setValue('defaultGroup', value, { shouldDirty: true })
                }
                setError={(name, error) =>
                  methods.setError('defaultGroup', error)
                }
                value={watchDefaultGroup}
                clearErrors={() => methods.clearErrors('defaultGroup')}
                id="defaultGroup"
                label="Default Group"
                hideRequired
                required={true}
                name="defaultGroup"
                options={
                  !watchDefaultGroup
                    ? [
                        { label: 'Choose an Office', value: null },
                        ...officeOptions,
                      ]
                    : officeOptions
                }
                sortBy={accordionsSort}
                // defaultOption={
                //   data?.meta ? JSON.parse(data?.meta)?.defaultGroup : null
                // }
                error={!!methods.formState?.errors?.defaultGroup}
              />
              <Description>
                Users in multiple groups will be assigned to this office
              </Description>
            </DefaultGroupContainer>
          ) : (
            <></>
          )}
          {!activeAccordionData ? (
            <></>
          ) : (
            <>
              <Accordion {...activeAccordionData} />
            </>
          )}
          <DeactivatedContainer>
            <Deactivated>Deactivated</Deactivated>
            {!inactiveAccordionData ? (
              <></>
            ) : (
              <>
                <Accordion {...inactiveAccordionData} />
              </>
            )}
          </DeactivatedContainer>
        </SectionContainer>
      </Form>
      {creatingOffice && (
        <OfficeForm
          toast={toast}
          length={offices?.length}
          offices={offices}
          memberLocations={[]}
          toggle={(refetchOffices?: boolean) => {
            if (refetchOffices) {
              refetch();
            }
            setCreatingOffice(false);
          }}
        />
      )}
      {updatingOffice && (
        <OfficeForm
          toast={toast}
          defaultValues={updatingOffice}
          offices={offices}
          toggle={async (getRefetch, deactivateOffice) => {
            if (getRefetch) {
              if (deactivateOffice) {
                activeOffice(updatingOffice.id, false, true);
              }

              await updateOfficeAndConnectedPages({
                variables: {
                  input: {
                    items: [
                      { id: updatingOffice.id, active: updatingOffice.active },
                    ],
                  },
                },
              });
              await refetch();
            }
            setUpdatingOffice(null);
          }}
          memberLocations={[]}
        />
      )}
      {deactivatingOffice && (
        <DeactivateForm
          toggle={async (getRefetch?: boolean) => {
            if (getRefetch) {
              activeOffice(deactivatingOffice.id, false, true);
              // await refetch();
            }
            setDeactivatingOffice(null);
          }}
          preventUnlock={true}
        />
      )}
    </Container>
  );
};

export default ManageOffices;
