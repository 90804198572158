import Button from 'components/Button';
import { bodyCopyBold } from 'shared/extends';
import { size } from 'shared/mixins';
import { colors, customBezierEase, padding } from 'shared/variables';
import styled from 'styled-components';

export const Form = styled.form``;

export const Container = styled.div`
  padding: 87px ${padding.desktop}px;
`;

export const AddButton = styled(Button)<{ tertiary: boolean }>`
  ${bodyCopyBold};
  padding: 0;

  span {
    display: flex;
    align-items: center;
    padding: 15px 0;
  }

  svg {
    transition: fill 0.2s ${customBezierEase};
    fill: ${colors.primary};
    margin-right: 8px;
    ${size('21px')};
  }

  &:hover {
    color: ${colors.highlight};

    svg {
      fill: ${colors.highlight};
    }
  }
`;
