export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      # description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      pageId
      parentPage {
        id
        slug
        status
      }
      # pages {
      #   items {
      #     id
      #     label
      #     status
      #     createdAt
      #     updatedAt
      #     title
      #     body
      #     bodyLow
      #     layout
      #     pageId
      #     sort
      #   }
      #   nextToken
      # }
      local
      publishedAt
      image
      sort
      meta
    }
  }
`;

export const listPagesByPageId = /* GraphQL */ `
  query ListPagesByPageId(
    $pageId: ID
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPagesByPageId(
      pageId: $pageId
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        status
        createdAt
        updatedAt
        title
        slug
        description
        eyebrow
        headline
        body
        inlineBody
        ctaText
        ctaLink
        headlineLow
        bodyLow
        layout
        pageId
        local
        publishedAt
        image
        sort
        meta
        officeId
        office {
          id
          name
          sort
        }
      }
      nextToken
    }
  }
`;
