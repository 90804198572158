import Modal from 'containers/Modal';
import React from 'react';
import { SubmitButton, CancelButton, ButtonContainer } from './styles';

interface IProps {
  close: () => void;
  cancel: () => void;
  type?: string;
  preventUnlock?: boolean;
}

const UnsavedModal = ({
  close,
  cancel,
  type,
  preventUnlock,
}: IProps): JSX.Element => {
  const description = () => {
    switch (type) {
      case 'page':
        return 'Doing this will delete any edits you’ve made to this page.';
      case 'faq':
        return 'You will lose any edits made to this element.';
      default:
        return 'Doing this will delete any edits you’ve made to this element.';
    }
  };

  return (
    <Modal
      preventUnlock={preventUnlock}
      id="sectionModal"
      title="Are you sure you want to leave this unsaved?"
      description={description()}
      toggle={() => {
        cancel();
      }}
    >
      <ButtonContainer>
        <SubmitButton
          type="button"
          className="ModalSubmit"
          onClick={async () => {
            close();
          }}
        >
          Yes, Close
        </SubmitButton>
        <CancelButton
          type="button"
          className="Modalcancel"
          value="Cancel"
          tertiary
          onClick={cancel}
        >
          Cancel
        </CancelButton>
      </ButtonContainer>
    </Modal>
  );
};

export default UnsavedModal;
