export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      local
      publishedAt
      image
      sort
      meta
      officeId
      office {
        id
        name
        sort
        active
        group
        timezone
        createdAt
        updatedAt
      }
    }
  }
`;
