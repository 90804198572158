import { colors, maxWidth, padding } from './variables';
import { font, fontSize } from './typography';

export const sectionTitleSmall = (): string => `
  color: ${colors.primary};
  text-transform: uppercase;
  ${font('secondary')}
  ${fontSize(11, 13, 140)}
`;

export const sectionTitle = (): string => `
  color: ${colors.brownMedium};
  margin: 0;
  ${font('primary', 'black')}
  ${fontSize(30, 36, -20)}
`;

export const h1 = (): string => `
  ${font('primary', 'black')};
  ${fontSize(65, 67, -0.2)};
  color: ${colors.brownLight};
`;

export const h2 = (): string => `
  ${font('primary', 'black')};
  color: ${colors.brownLight};
  ${fontSize(48, 52, -0.2)};
`;

export const h3 = (): string => `
  ${font('primary', 'black')};
  ${fontSize(24, 27, 0)};
  color: ${colors.brownLight};
`;

export const h4 = (): string => `
  ${font('primary', 'black')};
  ${fontSize(22, 24, 0)};
  color: ${colors.brownLight};
`;
export const h5 = (): string => `
  ${font('secondary', 'medium')};
  ${fontSize(20, 22, 0)};
  color: ${colors.brownLight};
`;
export const h6 = (): string => `
  ${font('secondary', 'medium')};
  ${fontSize(14, 16, 0)};
  color: ${colors.brownLight};
  text-transform: uppercase;
`;

export const bodyCopy = (): string => `
  color: ${colors.brownMedium};
  margin: 0;
  ${font('body')};
  ${fontSize(14, 20, 0)};
`;

export const bodyCopyLarge = (): string => `
  color: ${colors.brownMedium};
  margin: 0;
  ${font('body')};
  ${fontSize(20, 26, 0)};
`;

export const bodyCopyBold = (): string => `
  color: ${colors.brownMedium};
  margin: 0;
  ${font('bodyBold', 'black')};
  ${fontSize(14, 20, 0)};
`;

export const subHeadLarge = (): string => `
  color: ${colors.brownMedium};
  ${font('primary', 'black')};
  ${fontSize(24, 27, 0)}
`;

export const eyebrowButton = (): string => `
  color: ${colors.brownMedium};
  text-transform: uppercase;
  ${font('secondaryBold')};
  ${fontSize(12, 20, 0)};
`;
export const eyebrow = (): string => `
  color: ${colors.brownMedium};
  text-transform: uppercase;
  ${font('secondary')};
  ${fontSize(12, 20, 0)};
`;
export const eyebrowRegular = (): string => `
  color: ${colors.brownMedium};
  text-transform: uppercase;
  ${font('secondary')};
  ${fontSize(12, 20, 1.4)};
`;
export const eyebrowSmall = (): string => `
  color: ${colors.brownMedium};
  text-transform: uppercase;
  ${font('secondary')};
  ${fontSize(9, 17, 1.75)};
`;

export const copyContent = (): string => `
color: ${colors.brownMedium};
margin: 0;
${font('body')}
${fontSize(14, 20, 0)}

h1 {
  ${font('primary', 'black')}
  ${fontSize(65, 67, -0.2)}
}
h2 {
  ${font('primary', 'black')}
  ${fontSize(48, 52, -0.2)}
}
h3 {
  ${font('primary', 'black')}
  ${fontSize(24, 27, 0)}
}
h4 {
  ${font('primary', 'medium')}
  ${fontSize(20, 24, 0)}
}

a {
  color: ${colors.highlight}
}
`;

export const gridDesktop = (): string => `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;
  max-width: ${maxWidth - padding.desktop * 2}px;
`;

export const gridTablet = (): string => `
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  max-width: ${maxWidth - padding.tablet * 2}px;
`;

export const gridMobile = (): string => `
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  max-width: ${maxWidth - padding.mobile * 2}px;
`;

export const listStyles = (): string => `
  margin: 0;
  padding-left: 23px;
`;

export const resetButtonStyles = (): string => `
  background: transparent;
  // first one gets removed for some reason
  background: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

export const WYSIWYGStyles = (): string => `
ol {
  > ol {
    list-style-type: lower-alpha;

    > ol {
      list-style-type: lower-roman;

      > ol {
        list-style-type: decimal;

        > ol {
          list-style-type: lower-alpha;
        }
      }
    }
  }
}

ul {
  list-style-type: disc;
  ul {
    list-style-type: circle;
    ul {
      list-style-type: square;
      ul {
        list-style-type: disc;

        ul {
          list-style-type: circle;
        }
      }
    }
  }
}

ul > li.ql-indent-1 {
  list-style: circle;
  list-style-position: inside;
}

ul > li.ql-indent-2 {
  list-style: circle;
  list-style-position: inside;
}

ul > li.ql-indent-3 {
  list-style: circle;
  list-style-position: inside;
}

li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
ol li:not(.ql-direction-rtl),
ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
ol li.ql-direction-rtl,
ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
ol li[data-list="bullet"]:before {
  content: "•";
}
ol li[data-list="ordered"] {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
    list-9;
  counter-increment: list-0;
}
ol li[data-list="ordered"]:before {
  content: counter(list-0, decimal) '. ';
}
ol li[data-list="ordered"].ql-indent-1 {
  counter-increment: list-1;
}
ol li[data-list="ordered"].ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
ol li[data-list="ordered"].ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-2 {
  counter-increment: list-2;
}
ol li[data-list="ordered"].ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
ol li[data-list="ordered"].ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-3 {
  counter-increment: list-3;
}
ol li[data-list="ordered"].ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
ol li[data-list="ordered"].ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-4 {
  counter-increment: list-4;
}
ol li[data-list="ordered"].ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
ol li[data-list="ordered"].ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-5 {
  counter-increment: list-5;
}
ol li[data-list="ordered"].ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
ol li[data-list="ordered"].ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-6 {
  counter-increment: list-6;
}
ol li[data-list="ordered"].ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
ol li[data-list="ordered"].ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-7 {
  counter-increment: list-7;
}
ol li[data-list="ordered"].ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
ol li[data-list="ordered"].ql-indent-7 {
  counter-reset: list-8 list-9;
}
ol li[data-list="ordered"].ql-indent-8 {
  counter-increment: list-8;
}
ol li[data-list="ordered"].ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
ol li[data-list="ordered"].ql-indent-8 {
  counter-reset: list-9;
}
ol li[data-list="ordered"].ql-indent-9 {
  counter-increment: list-9;
}
ol li[data-list="ordered"].ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
`;
