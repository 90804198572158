import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SelectTypes from './Select.types';
import { IconSimpleArrowSmall, IconWarning } from 'components/IconsView';
import {
  Background,
  Container,
  Disclaimer,
  Option,
  OptionContainer,
  Options,
  SelectContainer,
  Selected,
} from './styles';
import Label from 'components/FormComponents/Label';

const Select = ({
  className,
  setValue,
  label,
  register,
  containerClassName,
  optional,
  options,
  error,
  errorLimited,
  setError,
  clearErrors,
  defaultOption,
  hideRequired,
  disclaimer,
  sortBy,
  customChangeEvent,
  ...buttonProps
}: SelectTypes): JSX.Element => {
  const { name = '', required, defaultValue, value } = buttonProps;
  const SelectRef = useRef<HTMLDivElement>(null);
  const OptionsRef = useRef<HTMLDivElement>(null);
  const [openWidth, setOpenWidth] = useState('100%');
  const [selected, setSelected] = useState('');
  const [availableOptions, setAvailableOptions] = useState<
    SelectTypes['options']
  >([]);

  useEffect(() => {
    if (defaultOption) {
      setSelected(defaultOption);
    } else if (defaultValue && options.length > 0) {
      const option = options?.find((x) => x.value === defaultValue);
      if (option) {
        setSelected(option.label);
      }
    } else {
      setSelected(options[0].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption, defaultValue, options]);

  useEffect(() => {
    setAvailableOptions(options.filter((x) => x.label !== selected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, options]);

  useEffect(() => {
    if (value) {
      const valueLabel = options.find((x) => x.value === value)?.label;
      if (valueLabel && valueLabel !== selected) {
        setSelected(`${valueLabel}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selected]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (defaultValue) {
      setValue(name, defaultValue as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const onValChange = useCallback(
    (v: any, vl: any) => {
      setValue(name, v, { shouldDirty: true });
      setSelected(vl);
      if (v) {
        clearErrors(name);
      }
      if (SelectRef.current) {
        SelectRef.current.classList.remove('open');
        setOpenWidth('100%');
      }

      // Useful when Select isnt tied to a form, and is just needed as a standalone component
      if (customChangeEvent) {
        customChangeEvent(v);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, setValue, error]
  );

  return (
    <Container ref={SelectRef} className={classNames('form-select', className)}>
      {label && (
        <Label
          name={name}
          optional={optional}
          required={required}
          hideRequired={hideRequired}
          className={error ? 'error' : ''}
        >
          {label}
        </Label>
      )}
      <SelectContainer
        className={classNames('select', {
          error: error,
          errorLimited: errorLimited,
        })}
      >
        <Selected
          style={{
            width: openWidth,
          }}
          id={name}
          data-testid={name}
          type="button"
          className="select-selected"
          onClick={() => {
            if (SelectRef.current) {
              SelectRef.current.classList.toggle('open');
              if (
                OptionsRef.current &&
                SelectRef.current.classList.contains('open')
              ) {
                setOpenWidth(`${OptionsRef?.current?.scrollWidth}px` || '100%');
              } else {
                setOpenWidth('100%');
              }
            }
          }}
        >
          <span>{selected}</span>
          <IconSimpleArrowSmall />
        </Selected>
        {errorLimited && <IconWarning className="error-icon" />}
        <Background
          onClick={() => {
            if (SelectRef.current) {
              SelectRef.current.classList.toggle('open');
              if (
                OptionsRef.current &&
                SelectRef.current.classList.contains('open')
              ) {
                setOpenWidth(`${OptionsRef?.current?.scrollWidth}px` || '100%');
              } else {
                setOpenWidth('100%');
              }
            }
          }}
        />
        <Options
          ref={OptionsRef}
          style={{
            width: openWidth,
          }}
          className={classNames('select-options')}
        >
          <OptionContainer>
            {availableOptions
              .sort((x: any, y: any) => {
                if (!sortBy) return 0;
                if (!x || !y) return 0;
                return sortBy.indexOf(x.value) - sortBy.indexOf(y.value);
              })
              .map((option) => (
                <Option
                  type="button"
                  tabIndex={openWidth === '100%' ? -1 : 0}
                  key={option.key || option.value}
                  onClick={() => onValChange(option.value, option.label)}
                >
                  {option.label}
                </Option>
              ))}
          </OptionContainer>
        </Options>
      </SelectContainer>
      {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
    </Container>
  );
};

export default Select;
