import DateSelector from 'components/FormComponents/DateSelector';
import { IconTrash } from 'components/IconsView';
import { MileageItem } from '../../index';
// import dayjs from 'dayjs';
// import React from 'react';
import { CurrencyInput, Row, TrashButton } from './styles';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface Props {
  id: string;
  methods: UseFormReturn<FieldValues, any, undefined>;
  handleTrash: (id: string) => void;
  // handleEmptyTrash: (arg: number) => void;
  moreThanOne: boolean;
  // index: number;
  defaultValues: MileageItem;
  mileageList: MileageItem[];
}

const RateRow = ({
  id,
  methods,
  handleTrash,
  moreThanOne,
  defaultValues,
  mileageList,
}: Props): JSX.Element => {
  console.log(`--- RENDER RATE ROW ${id} ---`);
  const [rateValue, setRateValue] = useState<
    string | number | readonly string[] | undefined
  >(defaultValues.rate);

  const getDateErrorMessage = () => {
    return methods?.formState?.errors?.[`${id}-date-sameDate`]
      ? 'No overlapping dates'
      : '';
  };

  useEffect(() => {
    methods.setValue(`${id}-rate`, defaultValues.rate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row id={id}>
      <DateSelector
        {...methods}
        allowWeekends={true}
        name={`${id}-date`}
        id={`${id}-date`}
        label="Effective Date"
        required={true}
        defaultValue={defaultValues.date}
        mileageList={mileageList}
        error={
          !!(
            methods.formState.errors?.[`${id}-date-sameDate`] ||
            methods.formState.errors?.[`${id}-date`]
          )
        }
        errorMessage={getDateErrorMessage()}
        handleChange={(value) => {
          console.log(`handle change for date ${id}:`, value);
          methods.clearErrors();

          const sameDateEntries = mileageList.filter((entry) => {
            return (
              dayjs(entry.date).format('MM/DD/YYYY') ===
                dayjs(value).format('MM/DD/YYYY') && entry.id !== id
            );
          });

          if (sameDateEntries.length > 0) {
            methods.setError(`${id}-date-sameDate`, {
              type: 'manual',
            });

            sameDateEntries.forEach((entry) => {
              methods.setError(`${entry.id}-date-sameDate`, {
                type: 'manual',
              });
            });
          }
        }}
      />

      <CurrencyInput
        {...methods}
        name={`${id}-rate`}
        id={`${id}-rate`}
        label="Rate"
        required={true}
        pattern="[^0-9.]"
        placeholder="0.000"
        value={rateValue}
        onChange={(e) => {
          const inputVal = e.target.value;

          // Regex to allow numbers with an optional decimal point, e.g., "1", "1.", "1.5"
          const validPattern = /^(\d+(\.\d*)?|\.\d*)$/;

          // Only update the value if it matches the pattern or is empty
          if (inputVal === '' || validPattern.test(inputVal)) {
            setRateValue(inputVal);
            methods.setValue(`${id}-rate`, parseFloat(inputVal));
          }
        }}
        error={!!methods.formState.errors?.[`${id}-rate`]}
        maxLength={5}
        hideLength
      />

      {moreThanOne && (
        <TrashButton
          type="button"
          data-testid="trash"
          onClick={() => {
            methods.clearErrors(id);
            handleTrash(id);
          }}
        >
          <IconTrash />
        </TrashButton>
      )}
    </Row>
  );
};

export default RateRow;
