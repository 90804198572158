import { colors, formWidth } from 'shared/variables';
import styled from 'styled-components';
import { Body, H4 } from 'shared/typography';
import { eyebrow } from 'shared/extends';

export const Container = styled.div`
  margin-top: 40px;
  grid-column: 1 / span 9;
  max-width: ${formWidth}px;
`;

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled(H4)`
  color: ${colors.brownMedium};
  margin: 0;
`;

export const SecondaryTitle = styled.p`
  ${eyebrow};
  color: ${colors.brownMedium};
  text-transform: uppercase;
`;

export const Description = styled(Body)`
  color: ${colors.brownLight};
  margin: 0;
`;

export const Section = styled.div`
  background: ${colors.white};
  padding: 32px 40px 48px;
  // max-width: 730px;
`;
