import React, {
  // memo,
  useEffect,
  useRef,
  FC,
  useCallback,
  createRef,
} from 'react';

import { createPortal } from 'react-dom';
import { ReactComponent as CloseX } from 'images/close-x.svg';
import ModalTypes from './Modal.types';
import { Wrapper, Header, Body, Close, Description } from './styles';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import classNames from 'classnames';

const Modal: FC<ModalTypes> = ({
  id,
  children,
  toggle,
  title,
  description,
  className,
  preventUnlock,
}) => {
  const el = useRef(document.createElement('div'));
  const modalContent = createRef<HTMLInputElement>();
  el.current.classList.add('modal-element');
  el.current.dataset.testid = 'modal';
  const closeOnOutsideClick = useCallback(
    (e: any) => {
      if (!modalContent.current?.contains(e.target)) {
        if (!preventUnlock) clearAllBodyScrollLocks();
        toggle();
      }
    },
    [modalContent, toggle, preventUnlock]
  );
  el.current.onclick = (e) => {
    closeOnOutsideClick(e);
  };

  const onCloseX = () => {
    if (!preventUnlock) clearAllBodyScrollLocks();
    toggle();
  };
  const escPress = useCallback(
    (e: { key: string }) => {
      if (e.key === 'Escape') {
        if (!preventUnlock) clearAllBodyScrollLocks();
        toggle();
      }
    },
    [toggle, preventUnlock]
  );

  useEffect(() => {
    const elCurrent = el.current;
    document.addEventListener('keydown', escPress);

    el.current.id = id;
    document.body.appendChild(el.current);

    return () => {
      if (!preventUnlock) clearAllBodyScrollLocks();
      document.removeEventListener('keydown', escPress);

      elCurrent?.parentElement?.removeChild(elCurrent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll lock
  useEffect(() => {
    if (el.current) {
      disableBodyScroll(el.current);
    }
  }, [el]);

  return createPortal(
    <Wrapper
      className={classNames('modal-content', className)}
      ref={modalContent}
      data-testid="modal-content"
    >
      <Header className="modal-header">
        <div className="title">{title}</div>
      </Header>
      <Description>{description}</Description>
      <Close
        onClick={() => {
          onCloseX();
        }}
        className="modal-close"
        aria-label="close"
      >
        <CloseX />
      </Close>
      <Body className="modal-body">{children}</Body>
    </Wrapper>,
    el.current
  );
};
export default Modal;
