import React, { useState } from 'react';
import { IProps } from './FreelancerForm.types';
import TextInput from 'components/FormComponents/TextInput';
import { useForm } from 'react-hook-form';
import Checkbox from 'components/FormComponents/Checkbox';
import TextArea from 'components/FormComponents/TextArea';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import Modal from 'containers/Modal';
import {
  ButtonContainer,
  CancelButton,
  SubmitButton,
  Form,
  CheckGroup,
  DeleteButton,
  FormInputContainers,
  CheckboxLabel,
} from './../styles';
import { validateEmail, validateURL } from 'utils/functions';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import classNames from 'classnames';

export const CREATE_FREELANCER = gql(mutations.createFreelancer);
export const UPDATE_FREELANCER = gql(mutations.updateFreelancer);
export const DELETE_FREELANCER = gql(mutations.deleteFreelancer);

const FreelancerForm = ({
  toast,
  defaultValues,
  toggle,
}: IProps): JSX.Element => {
  const [deletingFreelancer, setDeletingFreelancer] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const { id } = defaultValues || {};
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<any>({ defaultValues });

  const [createFreelancer] = useMutation(CREATE_FREELANCER, {
    onCompleted: () => {
      toast.success('Freelancer created');
    },
    onError: () => {
      toast.error('Error creating Freelancer');
    },
  });

  const [updateFreelancer] = useMutation(UPDATE_FREELANCER, {
    onCompleted: () => {
      toast.success('Freelancer updated');
    },
    onError: () => {
      toast.error('Error updating Freelancer');
    },
  });

  const getTextAreaValue = getValues('notes');

  console.log('errors', errors);

  return (
    <>
      <Modal
        id="sectionModal"
        title={id ? 'Edit Freelancer' : 'Add a New Freelancer'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(async (input) => {
            console.log('input:', input);
            console.log('errors', errors);
            if (input.email && !validateEmail(input.email)) {
              setError('email', { type: 'custom' });
            }
            if (input.portfolio && !validateURL(input.portfolio)) {
              setError('portfolio', { type: 'custom' });
            }
            if (
              (input.email && !validateEmail(input.email)) ||
              (input.portfolio && !validateURL(input.portfolio))
            ) {
              return;
            }
            setSaving(true);
            if (id) {
              delete input.__typename;
              delete input.createdAt;
              delete input.updatedAt;
              await updateFreelancer({
                variables: {
                  input: {
                    id,
                    ...input,
                  },
                },
              });
              toggle();
            } else {
              delete input.__typename;
              delete input.createdAt;
              delete input.updatedAt;
              await createFreelancer({
                variables: {
                  input: {
                    ...input,
                  },
                },
              });
              setSaving(false);
              setTimeout(() => {
                toggle();
              }, 500);
            }
          })}
          className="modalForm"
        >
          <FormInputContainers>
            <TextInput
              maxLength={50}
              id="name"
              // maxLength={50}
              required={true}
              hideRequired
              name="name"
              label="Name"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('name', error)}
              clearErrors={() => clearErrors('name')}
              error={!!errors?.name}
              errorMessage="Enter a name"
              defaultValue={defaultValues?.name || ''}
            />
            <TextInput
              maxLength={50}
              id="email"
              optional={true}
              // maxLength={50}
              // required={true}
              hideRequired
              name="email"
              label="Email"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('email', error)}
              clearErrors={() => clearErrors('email')}
              error={!!errors?.email}
              errorMessage="Enter a valid email"
              defaultValue={defaultValues?.email || ''}
              onBlur={(e) => {
                const value = e.target.value;
                if (value && !validateEmail(value)) {
                  setError('email', { type: 'custom' });
                } else {
                  clearErrors('email');
                }
              }}
            />
            <TextInput
              maxLength={50}
              optional={true}
              id="location"
              // maxLength={50}
              // required={false}
              name="location"
              label="Location"
              placeholder="Aliso Viejo, CA"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('location', error)}
              clearErrors={() => clearErrors('location')}
              error={!!errors?.location}
              errorMessage="Enter a location"
              defaultValue={defaultValues?.location || ''}
            />

            <TextInput
              id="portfolio"
              optional={true}
              // required={false}
              name="portfolio"
              label="Portfolio URL"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('portfolio', error)}
              clearErrors={() => clearErrors('portfolio')}
              error={!!errors?.portfolio}
              errorMessage="Enter a URL for the freelancer's portfolio"
              onBlur={(e) => {
                const value = e.target.value;
                if (value && !validateURL(value)) {
                  setError('portfolio', { type: 'custom' });
                } else {
                  clearErrors('portfolio');
                }
              }}
              defaultValue={defaultValues?.value || ''}
            />

            <div>
              <CheckboxLabel name="checkGroup" optional={true}>
                Capabilities
              </CheckboxLabel>
              <CheckGroup id="checkGroup">
                <Checkbox
                  setChecked={defaultValues?.design}
                  id="design"
                  // required={false}
                  name="design"
                  label="Design"
                  register={register}
                  setError={(name, error) => setError('design', error)}
                  clearErrors={() => clearErrors('design')}
                />
                <Checkbox
                  setChecked={defaultValues?.ux}
                  id="ux"
                  // required={false}
                  name="ux"
                  label="UX"
                  register={register}
                  setError={(name, error) => setError('ux', error)}
                  clearErrors={() => clearErrors('ux')}
                />
                <Checkbox
                  setChecked={defaultValues?.motion}
                  id="motion"
                  // required={false}
                  name="motion"
                  label="Motion"
                  register={register}
                  setError={(name, error) => setError('motion', error)}
                  clearErrors={() => clearErrors('motion')}
                />
                <Checkbox
                  setChecked={defaultValues?.development}
                  id="development"
                  // required={false}
                  name="development"
                  label="Development"
                  register={register}
                  setError={(name, error) => setError('development', error)}
                  clearErrors={() => clearErrors('development')}
                />
                <Checkbox
                  setChecked={defaultValues?.photography}
                  id="photography"
                  // required={false}
                  name="photography"
                  label="Photography"
                  register={register}
                  setError={(name, error) => setError('photography', error)}
                  clearErrors={() => clearErrors('photography')}
                />
                <Checkbox
                  setChecked={defaultValues?.video}
                  id="video"
                  // required={false}
                  name="video"
                  label="Video"
                  register={register}
                  setError={(name, error) => setError('video', error)}
                  clearErrors={() => clearErrors('video')}
                />
                <Checkbox
                  setChecked={defaultValues?.copywriting}
                  id="copywriting"
                  // required={false}
                  name="copywriting"
                  label="Copywriting"
                  register={register}
                  setError={(name, error) => setError('copywriting', error)}
                  clearErrors={() => clearErrors('copywriting')}
                />
                <Checkbox
                  setChecked={defaultValues?.branding}
                  id="branding"
                  // required={false}
                  name="branding"
                  label="Branding"
                  register={register}
                  setError={(name, error) => setError('branding', error)}
                  clearErrors={() => clearErrors('branding')}
                />
              </CheckGroup>
            </div>
            <TextInput
              id="rate"
              // required={false}
              optional={true}
              name="rate"
              label="Hourly Rate"
              placeholder="0.00"
              // pattern="^[0-9]*$"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('rate', error)}
              clearErrors={() => clearErrors('rate')}
              error={!!errors?.url}
              errorMessage="Enter numerical rate Here"
              defaultValue={defaultValues?.rate || ''}
              type="currency"
            />
            <TextInput
              id="referredBy"
              maxLength={50}
              // required={false}
              optional={true}
              name="referredBy"
              label="Referred By"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('referredBy', error)}
              clearErrors={() => clearErrors('referredBy')}
              error={!!errors?.url}
              errorMessage="Enter referred by Here"
              defaultValue={defaultValues?.referredBy || ''}
            />
            <TextArea
              formValue={getTextAreaValue}
              maxLength={150}
              // required={false}
              optional={true}
              name="notes"
              label="Additional Notes"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('notes', error)}
              clearErrors={() => clearErrors('notes')}
              error={!!errors?.notes}
              errorMessage="Enter notes here"
              defaultValue={defaultValues?.notes || ''}
            />
            <TextInput
              id="status"
              maxLength={50}
              // required={false}
              optional={true}
              name="status"
              label="Current Employer"
              register={register}
              setValue={setValue}
              setError={(name, error) => setError('status', error)}
              clearErrors={() => clearErrors('status')}
              error={!!errors?.url}
              errorMessage="Enter current employer here"
              defaultValue={defaultValues?.status || ''}
            />
          </FormInputContainers>
          <ButtonContainer>
            <div>
              <SubmitButton
                area-label="Submit"
                key="submit"
                type="submit"
                className={classNames('ModalSubmit', {
                  disabled:
                    !watch('name') ||
                    (watch('email') && !validateEmail(watch('email'))) ||
                    (watch('portfolio') && !validateURL(watch('portfolio'))),
                })}
              >
                {saving ? 'Saving...' : id ? 'Save' : 'Create'}
              </SubmitButton>
              <CancelButton
                aria-label="Cancel"
                key="cancel"
                type="button"
                className="Modalcancel"
                value="Cancel"
                tertiary
                onClick={() => {
                  if (isDirty) {
                    setCheckUnsaved(true);
                  } else {
                    toggle();
                  }
                }}
              >
                Cancel
              </CancelButton>
            </div>
            {id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingFreelancer(true)}
              >
                Delete Freelancer
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingFreelancer && (
        <DeleteForm
          preventUnlock={true}
          type="freelancer"
          id={defaultValues?.id ? defaultValues?.id : ''}
          toast={toast}
          toggle={toggle}
          toggleDelete={() => setDeletingFreelancer(false)}
        />
      )}
    </>
  );
};

export default FreelancerForm;
