import React from 'react';

export const formats = [
  'header',
  'bold',
  'italic',
  'strike',
  'underline',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

interface Props {
  id: string;
}

export const QuillToolbar = ({ id }: Props): JSX.Element => (
  <div id={id}>
    <select
      className="ql-header"
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value="2" />
      <option value="3" />
      <option value="4" />
      <option value="5" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" title="Underline" />
    <button className="ql-script" value="super" title="Superscript" />
    <button className="ql-script" value="sub" title="Subscript" />
    <button className="ql-clean" title="Clear formatting" />
    <button className="ql-list" value="ordered" title="ordered list" />
    <button className="ql-list" value="bullet" title="unordered list" />
    <button className="ql-indent" value="-1" title="outdent" />
    <button className="ql-indent" value="+1" title="indent" />
    <button className="ql-link" title="link" />
    <button className="ql-image" title="image" />
    <button className="ql-table" title="table" />
  </div>
);

export default QuillToolbar;
