import React from 'react';
import { Container } from './styles';
import classNames from 'classnames';

interface LabelProps {
  name?: string;
  children: string;
  optional?: boolean;
  required?: boolean;
  hideRequired?: boolean;
  className?: string;
}

const Label = ({
  className,
  name,
  children,
  optional = false,
  required = false,
  hideRequired = false,
}: LabelProps): JSX.Element => {
  if (!name) {
    <Container className={classNames(className)}>
      {children}
      {optional && <span className="optional">optional</span>}
    </Container>;
  }

  return (
    <Container className={classNames(className)} htmlFor={name}>
      {children}
      {required && !hideRequired && <span className="optional">required</span>}
      {optional && <span className="optional">optional</span>}
    </Container>
  );
};

export default Label;
