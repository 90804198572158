import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';
import TextInput from 'components/FormComponents/TextInput';
import WYSIWYG from 'components/FormComponents/WYSIWYGModal';

export const Form = styled.form`
  /* min-width: 33%; */
  width: 580px;
  display: grid;
  grid-row-gap: 32px;
`;
export const CustomTextInput = styled(TextInput)`
  max-width: 316px;
`;
export const CustomTextArea = styled(WYSIWYG)`
  max-width: 580px;
  margin: 0 0 32px;

  .ql-toolbar,
  .quill {
    background-color: ${colors.white};
  }
`;
export const FormInputContainer = styled.div`
  min-width: 33%;
  width: 320px;
`;
export const HR = styled.hr`
  border: none;
  border-top: 1px solid ${colors.greyLightLight};
`;
export const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)``;

export const DeleteButton = styled(Button)`
  margin-left: auto;
`;

export const Message = styled.div`
  margin: 0 4em;
  height: 50px;
  color: ${colors.primary};
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
`;
