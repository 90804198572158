import styled from 'styled-components';
import { colors } from 'shared/variables';
import { bodyCopy, font, fonts, fontSize } from 'shared/typography';
import { size } from 'shared/mixins';

export const Wrapper = styled.div`
  &.form-input {
    max-width: 316px;
    margin: 0 0 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Label = styled.label`
  ${fontSize(12)};
  text-transform: uppercase;
  font-weight: bold;
  color: ${colors.greyMediumMedium};
  font-family: ${fonts.secondary};
  display: flex;
  align-items: center;

  .optional {
    margin-left: 12px;
    ${fontSize(9, 17, 1.7)}
  }
`;

export const InputContent = styled.div`
  position: relative;

  svg.errorLimited {
    fill: ${colors.highlight};
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    ${size('16px')};
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.input`
  ${bodyCopy};
  height: 50px;
  border: none;
  padding: 0 1rem;
  outline: none;
  width: 100%;
  transition: all 150ms ease;
  border: 1px solid ${colors.greyMedium};
  &::placeholder {
    color: ${colors.brownLight};
    margin: 1rem;
    left: 10px;
  }
  &:disabled {
    color: ${colors.brownLight};
    background-color: ${colors.greyLightLight};

    &::placeholder {
      color: ${colors.greyMediumMedium};
      margin: 1rem;
      left: 10px;
    }
  }
  &:hover {
    border-color: ${colors.brownMedium};
  }
  &:focus {
    border-color: ${colors.focus};
  }
  &.error {
    border-color: ${colors.highlight};
  }
  &:required:invalid {
    /* border: 1px solid red; */
  }
`;

export const MaxLength = styled.span`
  position: absolute;
  bottom: 4px;
  color: ${colors.brownLessLight};
  text-align: right;
  right: 8px;
  ${font('secondary')};
  ${fontSize(8, 17, 1.75)};
`;

export const Error = styled.p`
  ${bodyCopy};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;

export const Disclaimer = styled.span`
  ${bodyCopy};
  color: ${colors.brownLight};
  margin-top: 6px;
`;
