import Modal from 'containers/Modal';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  margin: 0 auto;
  max-width: 803px;
`;

export const SelectWrapper = styled.div`
  margin-bottom: 50px;
  max-width: 320px;
`;
