import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import InputProps from './WYSIWYG.types';
import ReactQuill, { Quill } from 'react-quill-with-table';
import * as QuillTableUI from 'quill-table-ui';
import 'react-quill/dist/quill.snow.css';
import { Disclaimer, Editor, EditorContent, Error, Wrapper } from './styles';
import Label from '../Label';
import QuillToolbar, { formats } from './Toolbar';
import { Storage } from 'aws-amplify';
import { IconWarning } from 'components/IconsView';
import { cleanFileName } from 'utils/functions';
import { v4 as uuid } from 'uuid';

Quill.register(
  {
    'modules/tableUI': QuillTableUI.default,
  },
  true
);

const WYSIWYG = ({
  id,
  name,
  required,
  label,
  disabled,
  defaultValue = '',
  placeholder,
  register,
  setValue,
  className,
  wrapperClassName,
  error,
  errorMessage,
  optional,
  disclaimer,
  dontDirty,
}: InputProps): JSX.Element => {
  const QuillRef = useRef<any>(null);
  const [val, setVal] = useState(defaultValue);
  const [focus, setFocus] = useState(false);

  const handleChange = (value: string) => {
    setVal(value);
    if (setValue) {
      setValue(name, value, { shouldDirty: !dontDirty });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (defaultValue) {
      if (setValue) {
        setValue(name, defaultValue, { shouldDirty: false });
      }
      // parseWYSIWYG(defaultValue, false);
    }
    if (register) {
      register(name, {
        required,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  useEffect(() => {
    const quill = QuillRef.current;

    const setFocusOnClick = () => {
      if (QuillRef.current) {
        setFocus(quill?.getEditor()?.hasFocus());
      }
    };

    if (quill) {
      document.addEventListener('click', setFocusOnClick);
    }

    return () => {
      if (quill) {
        document.removeEventListener('click', setFocusOnClick);
      }
    };
  }, []);

  // after saving image insert those image urls into the editor
  const insertToEditor = (images: string[]) => {
    const range = QuillRef.current.getEditorSelection();
    if (range) {
      images.map((image) => {
        QuillRef.current.getEditor().insertEmbed(range.index, 'image', image);
      });
    }
  };

  // save images to server and get the new urls
  const saveToServer = async (files: File[]) => {
    const images = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.includes('image')) {
        const cleanName = cleanFileName(`${uuid()}-${file.name}`);

        const imageFile = await Storage.put(cleanName, file, {
          contentType: file.type,
        });

        const image = await Storage.get(imageFile.key);
        images.push(image);
      }
    }
    if (images.length > 0) {
      insertToEditor(images);
    }
  };

  // break into quill and handle images ourselves
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', () => {
      if (input.files) {
        const files = input.files;
        const fileArray = Array.from(files);
        saveToServer(fileArray);
      }
    });

    return () => {
      input.removeEventListener('change', () => {
        if (input.files) {
          const files = input.files;
          const fileArray = Array.from(files);
          saveToServer(fileArray);
        }
      });
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          image: imageHandler,
        },
      },
      table: true,
      tableUI: true,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Wrapper
      className={classNames(className, wrapperClassName, {
        disabled: disabled,
      })}
      tabIndex={disabled ? -1 : 0}
    >
      <Label name={name} className={error ? 'error' : ''} optional={optional}>
        {label}
      </Label>
      <EditorContent>
        <Editor
          className={classNames({
            error: error,
            disabled: disabled,
            focus: focus,
          })}
        >
          <QuillToolbar id={id} />
          <ReactQuill
            readOnly={disabled}
            ref={QuillRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={val}
            placeholder={placeholder}
            onChange={handleChange}
          />
        </Editor>
        {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
        {error && (
          <Error className="error">
            <IconWarning />
            {errorMessage || 'Fill out this field'}
          </Error>
        )}
      </EditorContent>
    </Wrapper>
  );
};

export default WYSIWYG;
