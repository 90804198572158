import classNames from 'classnames';
import React from 'react';
import { DayContainer } from './styles';

interface Props {
  number: number;
  isToday?: boolean;
  outsideViewingMonth?: boolean;
  dayClicked: () => void;
  dayHovered: () => void;
  startDay?: boolean;
  endDay?: boolean;
  betweenDay?: boolean;
  currentDate?: string;
  isWeekend?: boolean;
  isHovered?: boolean;
  isHoveredBetween?: boolean;
  isHoveredMin?: boolean;
  isHoveredMax?: boolean;
  allowWeekends?: boolean;
}

const Day = ({
  number,
  isToday,
  outsideViewingMonth,
  dayClicked,
  dayHovered,
  startDay,
  endDay,
  betweenDay,
  isWeekend,
  isHovered,
  isHoveredBetween,
  isHoveredMin,
  isHoveredMax,
  allowWeekends,
}: Props): JSX.Element => {
  const isValidWeekDay = (): boolean => {
    return !isWeekend;
  };

  return (
    <DayContainer
      className={classNames({
        today: isToday,
        'outside-viewing-month': outsideViewingMonth,
        'start-day': startDay,
        'end-day': endDay,
        'between-day': betweenDay,
        ...(allowWeekends ? null : { weekend: isWeekend }),
        'hover-start': isHovered,
        'hover-between': isHoveredBetween,
        'hover-min': isHoveredMin,
        'hover-max': isHoveredMax,
      })}
    >
      <span
        onClick={() => {
          if (isValidWeekDay() || allowWeekends) {
            dayClicked();
          }
        }}
        onMouseEnter={() => {
          if (isValidWeekDay()) {
            dayHovered();
          }
        }}
      >
        {number}
      </span>
    </DayContainer>
  );
};

export default Day;
