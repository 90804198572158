import styled from 'styled-components';
import { padding } from 'shared/variables';
import { Container as HeaderContainer } from 'components/Header/styles';

export const Container = styled.div`
  padding: 87px ${padding.desktop}px;
`;

export const Header = styled(HeaderContainer)`
  > div {
    display: flex;
    align-items: flex-end;
  }
`;

export const PageTypeSelectorWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 400px;
`;
