import styled from 'styled-components';
import { colors, customBezierEase } from 'shared/variables';
import { bodyCopy } from 'shared/extends';
import { size } from 'shared/mixins';

export const Container = styled.div`
  position: relative;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  span.label {
    padding-left: 12px;
    ${bodyCopy};
    color: ${colors.brownLight};
  }
`;

export const ToggleContainer = styled.div`
  background: ${colors.brownLight};
  border-radius: 12px;
  position: relative;
  display: inline-block;
  ${size('48px', '24px')};
`;

export const ToggleBall = styled.div`
  content: '';
  background: ${colors.white};
  top: 3px;
  left: 3px;
  border-radius: 100%;
  position: absolute;
  transition: all 0.2s ${customBezierEase};
  ${size('18px', '18px')};
`;

export const Input = styled.input`
  display: none;

  &:checked + ${ToggleContainer} {
    background: ${colors.primary};

    ${ToggleBall} {
      left: 27px;
    }
  }
`;
