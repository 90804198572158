import React from 'react';
import { createBrowserHistory } from 'history';
import {
  Container,
  ContainerInner,
  HeaderContainer,
  Header,
  Caption,
  Description,
} from './styles';
import Button from '../Button';

const View404 = (): JSX.Element => {
  const history = createBrowserHistory();

  return (
    <Container>
      <ContainerInner>
        <Caption small>Five &amp; Done - Admin</Caption>
        <HeaderContainer>
          <Header>404</Header>
          <Description>Page not found</Description>
        </HeaderContainer>
        <Button
          onClick={() =>
            history.location.key === 'default' ? history.go(-1) : history.go(-2)
          }
        >
          Back To Safety
        </Button>
      </ContainerInner>
    </Container>
  );
};

export default View404;
