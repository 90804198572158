import styled from 'styled-components';
import Button from 'components/Button';
import Modal from 'containers/Modal';

export const ModalContainer = styled(Modal)`
  max-width: 912px;
`;

export const Form = styled.form`
  min-width: 33%;
  display: grid;
  grid-row-gap: 32px;
`;

export const FormContent = styled.div`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const ButtonContainer = styled.div`
  margin-top: 8px;
  grid-column: span 2;
  display: flex;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  margin-left: auto;
`;
