import styled from 'styled-components';
import { colors } from 'shared/variables';
import { fonts, fontSize } from 'shared/typography';

export const Container = styled.div`
  align-items: center;
`;

export const Label = styled.label`
  color: ${colors.brownLight};
  background: ${colors.white};
  ${fontSize(11)};
  font-family: ${fonts.secondary};
  border: 2px solid ${colors.greyLightLight};
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 10px 10px 10px;
  min-height: 112px;
  transition: border-color 0.3s ease;

  svg {
    margin-top: 13px;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:focus,
  &:hover {
    + ${Label} {
      border: 2px solid ${colors.greyMediumMedium};
    }
  }

  &:checked {
    + ${Label} {
      border: 2px solid ${colors.primary};
    }
    /* &:disabled {
      + ${Label} {
        color: ${colors.greyMediumMedium};
      }
    } */
  }
`;
