import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Page, PageStatus } from 'API';
import PageTop from 'components/PageTop';
import IToast from 'types/ToastType';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import { SlugI } from 'types/SlugListType';
import SectionContainer from 'components/SectionContainer';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { arrayMove } from 'react-sortable-hoc';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TabModal from './Forms/TabModal';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, postWYSIWYG } from 'utils/functions';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

interface Props {
  data: Page;
  toast: IToast;
  handleRefetch: () => void;
  id: string;
  slugList: SlugI[];
}

const GET_PAGE = gql(queries.getPage);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const VIEW_ITEMS = gql(mutations.viewItems);
export const UPDATE_PAGE = gql(mutations.updatePage);

const TabbedPage = ({
  data,
  toast,
  handleRefetch,
  id,
  slugList,
}: Props): JSX.Element => {
  const [tabsSorts, setTabsSorts] = useState<AccordionItems[]>([]);
  const [tabsView, setTabsView] = useState<{
    [key: string]: { newStatus: PageStatus; oldStatus: PageStatus };
  }>({});
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [modalTabs, setModalTabs] = useState<{
    length?: number;
    parentId?: string;
    data?: Page;
  } | null>(null);
  // getPage (tab modal) keeps getting called after updating page so we add a call check to see if updatePage was even called before getPage is completed
  const [tabsShow, setTabsShow] = useState(false);
  const [tabsCalled, setTabsCalled] = useState(false);
  const [deletingTab, setDeletingTab] = useState<{
    id: string;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);

  const [getPage] = useLazyQuery(GET_PAGE, {
    onCompleted: ({ getPage: tab }) => {
      setModalTabs({ data: tab });
      // only show if tabsCalled was set true
      setTabsShow(tabsCalled);
    },
    onError: () => {
      toast.error('Error fetching tab');
    },
    fetchPolicy: 'network-only',
  });

  const [sortItems] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Tabs sorted');
      handleRefetch();
    },
    onError: () => {
      toast.error('Error sorting tabs');
    },
  });

  const [viewItems] = useMutation(VIEW_ITEMS, {
    onCompleted: () => {
      toast.success('Tabs updated');
      handleRefetch();
    },
    onError: () => {
      toast.error('Error updating Tabs');
    },
  });

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });
  useEffect(() => {
    // check if items were sorted or changed views
    if (tabsSorts.length > 0 || Object.keys(tabsView).length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsSorts, tabsView]);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    const temp = [...tabsSorts];
    const sendToUpdate: { id: string; sort: number }[] = [];
    if (temp) {
      temp.forEach((quicklink, index) => {
        if (quicklink.index !== index) {
          sendToUpdate.push({
            id: quicklink.id || '',
            sort: index,
          });
        }
      });
    }

    console.log('sendToUpdate', sendToUpdate);

    if (sendToUpdate.length > 0) {
      await sortItems({
        variables: { input: { tableName: 'Page', items: sendToUpdate } },
      });
    }
    setTabsSorts([]);

    const tempView = { ...tabsView };
    const sendToUpdateView: { id: string; status: PageStatus }[] = [];

    if (tempView) {
      Object.keys(tempView).forEach((key) => {
        const item = tempView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateView.push({
            id: key,
            status: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateView', sendToUpdateView);

    if (sendToUpdateView.length > 0) {
      await viewItems({
        variables: { input: { tableName: 'Page', items: sendToUpdateView } },
      });
    }
    setTabsView({});

    handleRefetch();
  };

  const submitAllForms = () => {
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const addTab = useCallback(
    (length: any) => {
      setModalTabs({ length, parentId: id });
      setTabsShow(true);
    },
    [id]
  );

  const updateTab = useCallback(
    (tabID: any) => {
      //* set tabs called to true so that getPage knows it was actually called
      setTabsCalled(true);
      getPage({ variables: { id: tabID } });
    },
    [getPage]
  );

  const deleteTab = useCallback((tabID: any) => {
    setDeletingTab({ id: tabID });
  }, []);

  const sortTabs = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[]
    ) => {
      let newSort: AccordionItems[] = [];
      if (items) {
        const probableChange = arrayMove(items, oldIndex, newIndex);
        const differenceAry = probableChange.slice().map(function (n, i) {
          return i - probableChange[i].index;
        });
        const isDifference = differenceAry.some((value) => value !== 0);
        if (isDifference) {
          newSort = probableChange;
        } else {
          newSort = [];
        }
      }

      setTabsSorts(newSort);
    },
    []
  );

  const activeTabs = useCallback(
    async (tabID: string, newStatus: boolean, prevStatus: PageStatus) => {
      const statusNew = newStatus ? PageStatus.active : PageStatus.disabled;
      await setTabsView((prev) => {
        const newView = { ...prev };
        if (statusNew === prevStatus) {
          delete newView[tabID];
        } else {
          newView[tabID] = { newStatus: statusNew, oldStatus: prevStatus };
        }
        return newView;
      });
    },
    []
  );

  const accordionData: AccordionTypes = useMemo(() => {
    if (!data) return null;
    return formatDataForAccordion({
      tabs: data?.pages?.items || [],
      addTab,
      updateTab,
      deleteTab,
      sortTabs,
      activeTabs,
    });
  }, [data, addTab, sortTabs, deleteTab, updateTab, activeTabs]);

  return (
    <>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        data={data}
        slugList={slugList}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Tabbed Content"
        description="Click on the content you would like to edit."
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {/* for some reason modalTabs.show doesnt trigger an update so we have to move the show check outside of an object */}
      {tabsShow && (
        <TabModal
          toggle={(refetch) => {
            setModalTabs(null);
            setTabsCalled(false);
            if (refetch) {
              handleRefetch();
            }
            setTabsShow(false);
          }}
          toast={toast}
          {...modalTabs}
        />
      )}
      {deletingTab && (
        <DeleteForm
          {...deletingTab}
          toggle={(refetch) => {
            setDeletingTab(null);
            if (refetch) {
              handleRefetch();
            }
          }}
          type="tab"
          toast={toast}
        />
      )}
    </>
  );
};

export default TabbedPage;
