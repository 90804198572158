import classNames from 'classnames';
import { IconArrowBoxy } from 'components/IconsView';
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { debounce } from 'utils/functions';

const BackToTopView = (): JSX.Element => {
  const [isActive, setIsActive] = useState(false);

  const handleScroll = () => {
    const scroll = window.scrollY;
    const quadViewPort = window.innerHeight * 4;
    if (scroll === 0) {
      setIsActive(false);
    } else if (!isActive && scroll > quadViewPort) {
      setIsActive(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll));

    return () => {
      window.removeEventListener('scroll', debounce(handleScroll));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    // Scroll to top logic
    scroll(0, 0);
  };

  return (
    <Container
      type="button"
      onClick={() => scrollToTop()}
      data-testid="back-to-top"
      data-is-active={isActive ? 'true' : null}
      className={classNames({ active: isActive })}
    >
      Back To Top
      <IconArrowBoxy />
    </Container>
  );
};

export default BackToTopView;
