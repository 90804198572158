// import { createPolicy } from 'graphql/mutations';
import { gql } from '@apollo/client';
// import { listPolicies } from 'graphql/queries';

export const LIST_ALL_POLICIES = gql(`
    query listAllPolicies {
      listPolicies {
        items {
          acknoledgement
          content
          id
          location
          title
          version
          updatedAt
        }
      }
    }
  `);

// export const CREATE_OFFICE = gql(createOffice);
// export const DELETE_OFFICE = gql(deleteOffice);
// export const UPDATE_OFFICE = gql(updateOffice);
// export const CREATE_MEMBER = gql(createMember);
// export const DELETE_MEMBER = gql(deleteMember);
// export const UPDATE_MEMBER = gql(updateMember);
