import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Page } from 'API';
import PageTop from 'components/PageTop';
import IToast from 'types/ToastType';
import { SlugI } from 'types/SlugListType';
import SectionContainer from 'components/SectionContainer';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import * as queries from './queries';
import * as mutations from 'graphql/mutations';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TabModal from './Forms/TabModal';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, postWYSIWYG } from 'utils/functions';
import { debounce } from 'lodash';

interface Props {
  data: Page;
  toast: IToast;
  handleRefetch: () => void;
  id: string;
  slugList: SlugI[];
}

const GET_PAGE = gql(queries.getPage);
const UPDATE_PAGE = gql(mutations.updatePage);
const CHECK_OFFICES_CREATE = gql(mutations.checkOfficesToCreateTemplates);

const OfficePage = ({
  data,
  toast,
  handleRefetch,
  id,
  slugList,
}: Props): JSX.Element => {
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [modalTabs, setModalTabs] = useState<{
    data: Page;
  } | null>(null);
  // getPage (tab modal) keeps getting called after updating page so we add a call check to see if updatePage was even called before getPage is completed
  const [tabsShow, setTabsShow] = useState(false);
  const [tabsCalled, setTabsCalled] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const checked = useRef(false);

  const [getPage] = useLazyQuery(GET_PAGE, {
    onCompleted: ({ getPage: tab }) => {
      setModalTabs({ data: tab });
      // only show if tabsCalled was set true
      setTabsShow(tabsCalled);
    },
    onError: () => {
      toast.error('Error fetching tab');
    },
    fetchPolicy: 'network-only',
  });

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const [checkOfficesToCreateTemplates, { loading }] = useMutation(
    CHECK_OFFICES_CREATE,
    {
      onCompleted: (check) => {
        if (check.checkOfficesToCreateTemplates.shouldRefetch) {
          handleRefetch();
        }
      },
    }
  );

  const callCheckOffice = debounce(() => {
    if (!checked.current && !loading) {
      checked.current = true;
      checkOfficesToCreateTemplates({
        variables: {
          input: {
            id: data.id,
          },
        },
      });
    }
  }, 1000);

  useEffect(() => {
    callCheckOffice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    handleRefetch();
  };

  const submitAllForms = () => {
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const updateTab = useCallback(
    (tabID: any) => {
      //* set tabs called to true so that getPage knows it was actually called
      setTabsCalled(true);
      getPage({ variables: { id: tabID } });
    },
    [getPage]
  );

  const accordionData: AccordionTypes = useMemo(() => {
    if (!data) return null;
    return formatDataForAccordion({
      tabs: data?.pages?.items || [],
      updateTab,
    });
  }, [data, updateTab]);

  return (
    <>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        data={data}
        slugList={slugList}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Tabbed Office Content"
        description="Click on the content you would like to edit."
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {/* for some reason modalTabs.show doesnt trigger an update so we have to move the show check outside of an object */}
      {tabsShow && modalTabs?.data && (
        <TabModal
          toggle={(refetch) => {
            setModalTabs(null);
            setTabsCalled(false);
            if (refetch) {
              handleRefetch();
            }
            setTabsShow(false);
          }}
          toast={toast}
          data={modalTabs.data}
        />
      )}
    </>
  );
};

export default OfficePage;
