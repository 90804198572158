import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

interface IGlobalErrorContext {
  globalError?: string | null | undefined;
  setError?: (error: string | null) => void;
  registerError?: (error: string | null) => void;
}

const GlobalErrorContext = createContext<IGlobalErrorContext>({
  globalError: null,
  setError: (error: string | null) => error,
  registerError: () => {},
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function GlobalErrorContextProvider({ children }: any): JSX.Element {
  const [globalError, setGlobalError] = useState<null | string>(null);
  const registerError = useCallback(
    // return error
    () => {
      setGlobalError('test');
    },
    [setGlobalError]
  );

  const value: any = useMemo(
    () => ({
      globalError,
      setGlobalError,
      registerError: registerError,
    }),
    [globalError, registerError, setGlobalError]
  );

  return (
    <GlobalErrorContext.Provider value={value}>
      {children}
    </GlobalErrorContext.Provider>
  );
}

export function useUnifiedError(): IGlobalErrorContext {
  const context = useContext(GlobalErrorContext);
  if (context === undefined || context === null) {
    throw new Error('Error must be present within context');
  }
  return context;
}
