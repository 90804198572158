import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getPage } from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Container } from './styles';
import PageTop from 'components/PageTop';
import { listPages as listSlugs } from 'utils/queries';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import { SlugI } from 'types/SlugListType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_PAGES = gql(listSlugs);
export const UPDATE_PAGE = gql(mutations.updatePage);

interface ISimplePage {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
  id: string;
}

const SimplePageContainer = ({ toast, id }: ISimplePage): JSX.Element => {
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  // Just for pages that have the meta and copy & text sections
  const {
    loading,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: id,
    },
    fetchPolicy: 'network-only',
  });

  // For meta to check against slug
  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
      refetch();
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (loading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        slugList={slugList}
        data={data}
        setIsDirtyForm={setIsDirtyForm}
        isDirtyForm={isDirtyForm}
      />
    </Container>
  );
};

export default SimplePageContainer;
