import React, { useState } from 'react';
import TextInput from 'components/FormComponents/TextInput';
import { useForm } from 'react-hook-form';
import Modal from 'containers/Modal';

import {
  Form,
  SubmitButton,
  CancelButton,
  DeleteButton,
  ButtonContainer,
  FormContent,
} from './styles';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import IToast from 'types/ToastType';
import slugify from 'slugify';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { Location, Office } from 'API';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import HaltForm from 'components/Forms/HaltForm/HaltForm';

export const CREATE_OFFICE = gql(mutations.createOffice);
export const CREATE_LOCATION = gql(mutations.createLocation);
export const UPDATE_OFFICE = gql(mutations.updateOffice);

interface IAddOfficeFormProps {
  toggle: (refetch?: boolean) => void;
  toast: IToast;
  defaultValues?: {
    id?: string;
    name?: string;
    officeId?: string;
    hasChildren?: boolean;
  };
  length?: number;
  offices?: Office[];
  faqLocations: Location[];
}

const OfficeForm = ({
  toggle,
  toast,
  defaultValues,
  length,
  offices,
  faqLocations,
}: IAddOfficeFormProps): JSX.Element => {
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [deletingOffice, setDeletingOffice] = useState(false);
  const [officeCreated, setOfficeCreated] = useState(false);
  const { id, officeId } = defaultValues || {};
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues,
  });

  const [createOffice] = useMutation(CREATE_OFFICE);

  const [createLocation] = useMutation(CREATE_LOCATION, {
    onCompleted: () => {
      if (officeCreated) {
        toast.success('Office created');
      }
      setOfficeCreated(false);
    },
    onError: () => {
      toast.error('Error creating office');
    },
  });

  const [updateOffice] = useMutation(UPDATE_OFFICE, {
    onCompleted: () => {
      toast.success('Office updated');
    },
    onError: () => {
      toast.error('Error updating office');
    },
  });

  const hasChildren = defaultValues?.hasChildren;

  return (
    <>
      <Modal
        id="sectionModal"
        title={officeId ? 'Edit Office' : 'Add Additional Office'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(async (input) => {
            if (officeId) {
              await updateOffice({
                variables: {
                  input: {
                    id: officeId,
                    name: input.name,
                  },
                },
              });
            } else {
              const tempOfficeID = slugify(input.name).toLowerCase();
              /* This is checking to see if the office already exists. If it does, it will not create a
              new office. */
              if (
                offices?.findIndex((item) => item.id === tempOfficeID) === -1
              ) {
                await createOffice({
                  variables: {
                    input: {
                      id: tempOfficeID,
                      name: input.name,
                    },
                  },
                });
              }

              await createLocation({
                variables: {
                  input: {
                    officeID: tempOfficeID,
                    sort: length,
                    active: true,
                    type: 'member',
                  },
                },
              });
              setOfficeCreated(true);

              // eslint-disable-next-line prettier/prettier
              if (faqLocations?.findIndex((item) => item.officeID === tempOfficeID) === -1) {
                await createLocation({
                  variables: {
                    input: {
                      officeID: tempOfficeID,
                      sort: length,
                      active: true,
                      type: 'faq',
                    },
                  },
                });
              }
            }
            toggle(true);
          })}
          className="modalForm"
        >
          <FormContent>
            <TextInput
              id="name"
              maxLength={50}
              required={true}
              hideRequired
              name="name"
              label="Office Name"
              placeholder="Enter Office Name Here"
              register={register}
              setError={(name, error) => setError('name', error)}
              clearErrors={() => clearErrors('name')}
              error={!!errors?.name}
              errorMessage="Enter a name"
              setValue={(name, v) => setValue(name, v)}
              defaultValue={defaultValues?.name || ''}
            />
          </FormContent>
          <ButtonContainer>
            <SubmitButton type="submit" className="ModalSubmit">
              {officeId ? 'Save' : 'Create Office'}
            </SubmitButton>
            <CancelButton
              type="button"
              className="Modalcancel"
              value="Cancel"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {officeId && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingOffice(true)}
              >
                Delete Office
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingOffice && hasChildren && (
        <HaltForm
          preventUnlock={true}
          toggle={() => toggle(true)}
          toggleDelete={() => setDeletingOffice(false)}
        />
      )}
      {deletingOffice && !hasChildren && id && officeId && (
        <DeleteForm
          preventUnlock={true}
          type="office"
          id={id}
          officeID={officeId}
          toast={toast}
          toggle={() => toggle(true)}
          toggleDelete={() => setDeletingOffice(false)}
        />
      )}
    </>
  );
};

export default OfficeForm;
