import styled from 'styled-components';
import { typography } from 'shared/typography';
import { colors } from 'shared/variables';
import Button from 'components/Button';
import Label from 'components/FormComponents/Label';

export const Container = styled.div`
  padding: 100px;
`;

export const Form = styled.form`
  min-width: 33%;

  display: grid;
  grid-row-gap: 32px;
`;

export const FormInputContainers = styled.div`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
  min-width: 33%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SubmitButton = styled(Button)`
  &.disabled {
    // pointer-events: none;
    background: ${colors.greyLightLight};
    color: ${colors.brownLessLight};

    &:hover {
      background: ${colors.greyLightLight};
      cursor: not-allowed;
    }
  }
`;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
`;

export const CheckboxLabel = styled(Label)`
  text-transform: uppercase;
  font-family: ${typography.type.secondary};
  font-weight: ${typography.weight.black};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: ${typography.fontSize.eyebrow};
  line-height: ${typography.lineHeight.eyebrow};
  color: ${colors.brownMedium};

  display: block;
  margin-bottom: 9px;
`;

export const CheckGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
