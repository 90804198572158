import Modal from 'containers/Modal';
import React, { useState } from 'react';
import { IProps } from './AddPageForm.types';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  Form,
  SubmitButton,
  PageTypeSelectorWrapper,
  LineBreak,
  RadioError,
  RadioErrorContainer,
  TopSection,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import slugify from 'slugify';
import { PageStatus, PageLayout } from 'API';
import { formatSlug, handleSlugCheck } from 'utils/functions';
import { useHistory } from 'react-router-dom';
import Label from 'components/FormComponents/Label';
import PageTypeSelector from 'components/FormComponents/PageTypeSelector';

const CREATE_PAGE = gql(mutations.createPage);
const CREATE_OFFICE_TEMPLATES = gql(mutations.createOfficeTemplates);

const AddPageForm = ({
  toast,
  toggle,
  length,
  slugs,
  pageId,
}: IProps): JSX.Element => {
  const [slugTouch, setSlugTouch] = useState(false); // Used to check if slug has been touched
  const [customError, setCustomError] = useState<boolean>(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm<any>({});

  const history = useHistory();

  const [createOfficeTemplates] = useMutation(CREATE_OFFICE_TEMPLATES, {
    onCompleted: (page) => {
      const { id } = page.createOfficeTemplates;
      history.push(`/page/edit/${id}`);
      toggle(true);
    },
    onError: () => {
      console.log('Template Create: Error creating page');
    },
  });
  const [createTemplatePage] = useMutation(CREATE_PAGE, {
    onCompleted: () => {
      toggle(true);
    },
    onError: () => {
      console.log('Template Create: Error creating page');
    },
  });
  const [createPage] = useMutation(CREATE_PAGE, {
    onCompleted: async (page) => {
      toast.success('Page created');
      const { id, layout, title } = page.createPage;
      if (layout === 'plain') {
        // if plain wait to create the template page and then move to the created page then
        await createTemplatePage({
          variables: {
            input: {
              sort: 0,
              pageId: id,
              status: PageStatus.active,
              layout: PageLayout.template,
              label: `${title} Template`,
              title: `${title} Template`,
              slug: slugify(`${title} Template`).toLowerCase(),
            },
          },
        });
      } else if (layout === 'officeTab') {
        toast.success('Creating Templates...');
        await createOfficeTemplates({
          variables: {
            input: {
              pageId: id,
            },
          },
        });
        history.push(`/page/edit/${id}`);
      } else {
        // if not plain then move to new page now
        history.push(`/page/edit/${id}`);
        toggle(true);
      }
    },
    onError: () => {
      toast.error('Error creating page');
    },
  });

  return (
    <>
      <Modal
        id="sectionModal"
        title={`Add a New Page`}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('Add Form', input);
            await createPage({
              variables: {
                input: {
                  ...input,
                  sort: length,
                  pageId: pageId,
                  status: PageStatus.disabled,
                  label: input.title,
                },
              },
            });
          })}
        >
          <TopSection>
            <TextInput
              label="Page Title"
              required
              hideRequired={true}
              id="title"
              name="title"
              register={register}
              setError={(title, e) => setError('title', e)}
              clearErrors={() => clearErrors('title')}
              error={!!errors?.title}
              errorMessage="Enter a title"
              maxLength={50}
              setValue={setValue}
              onChange={(e) => {
                /* This is a function that is called when the title input is changed. It checks if the slug
              has been touched. If it hasn't, it will take the value of the title input and slugify
              it. Then it will set the value of the slug input to the slugified value. */
                if (!slugTouch) {
                  const value = e.target.value;
                  let slug = slugify(value).toLowerCase();
                  slug = formatSlug(slug);
                  const slugInput = document.getElementById(
                    'slug'
                  ) as HTMLInputElement;
                  if (slugInput) {
                    setValue('slug', slug);
                    slugInput.value = slug;
                    clearErrors('slug');
                  }
                  handleSlugCheck({
                    value: slug,
                    id: '',
                    slugList: slugs,
                    callbackFunc: setCustomError,
                  });
                }
                clearErrors('title');
              }}
            />
            <TextInput
              label="Slug"
              required
              hideRequired={true}
              id="slug"
              name="slug"
              register={register}
              setError={(slug, e) => setError('slug', e)}
              clearErrors={() => clearErrors('slug')}
              error={!!(customError || errors?.slug)}
              errorMessage="Enter a unique slug"
              setValue={setValue}
              pattern="[^a-z0-9-_]|-(?=-)"
              onChange={(e) => {
                const value = e.target.value;
                setSlugTouch(true);
                handleSlugCheck({
                  value,
                  id: '',
                  slugList: slugs,
                  callbackFunc: setCustomError,
                });
              }}
            />
            {/* <TextInput
            label="Description Text (Optional)"
            id="description"
            name="description"
            register={register}
            setError={(name, e) => setError('description', e)}
            clearErrors={() => clearErrors('description')}
            error={!!errors?.description}
            errorMessage="Enter a description"
            maxLength={50}
            // setValue={setValue}
          /> */}
            <LineBreak />
          </TopSection>
          <div>
            <Label className={errors.layout ? 'error' : ''}>
              Page Template
            </Label>
            <PageTypeSelectorWrapper>
              <PageTypeSelector
                id="simple-page-type"
                label="Simple Page"
                register={register}
                icon="simple"
                name="layout"
                value={PageLayout.plain}
                required={true}
              />
              <PageTypeSelector
                id="tabbed-page-type"
                label="Tabbed Page"
                register={register}
                icon="tabbed"
                name="layout"
                value={PageLayout.tabbed}
                required={true}
              />
              <PageTypeSelector
                id="office-page-type"
                label="Office Page"
                register={register}
                icon="office"
                name="layout"
                value={PageLayout.officeTab}
                required={true}
              />
            </PageTypeSelectorWrapper>
            <RadioErrorContainer>
              {errors.layout && (
                <>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill="#FB4640"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.221 8.69828H7.77889C7.68415 8.69828 7.58942 8.61407 7.57889 8.52986L7.0631 5.59302V4.39301C7.0631 4.28775 7.15784 4.19302 7.2631 4.19302H8.73679C8.84205 4.19302 8.93678 4.28775 8.93678 4.39301V5.57196L8.421 8.52986C8.41047 8.61407 8.31573 8.69828 8.221 8.69828ZM7.98942 11.6667C7.45258 11.6667 6.99994 11.2141 6.99994 10.6772C6.99994 10.1299 7.45258 9.6667 7.98942 9.6667C8.53679 9.6667 8.99994 10.1299 8.99994 10.6772C8.99994 11.2141 8.53679 11.6667 7.98942 11.6667Z"
                    ></path>
                  </svg>
                  <RadioError>Select a template</RadioError>
                </>
              )}
            </RadioErrorContainer>
          </div>
          <ButtonContainer>
            <SubmitButton
              type="submit"
              disabled={
                !(watch('title')?.trim() && watch('slug') && watch('layout')) ||
                customError
              }
            >
              Create Page
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default AddPageForm;
