import Button from 'components/Button';
import styled from 'styled-components';

export const Form = styled.form`
  min-width: 33%;
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const ButtonContainer = styled.div``;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  right: 99px;
  bottom: 58px;
`;
