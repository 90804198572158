import TextInput from 'components/FormComponents/TextInput';
import WYSIWYG from 'components/FormComponents/WYSIWYG';
import styled from 'styled-components';

export const HiddenIDInput = styled(TextInput)`
  display: none;
`;

export const WYSIWYGInput = styled(WYSIWYG)`
  max-width: 580px;
`;

export const Form = styled.form``;

export const Container = styled.div``;
