import styled from 'styled-components';
import { Eyebrow, H1, H5 } from 'shared/typography';
import { colors } from 'shared/variables';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 115px;
`;

export const ContainerInner = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 35px;
`;

export const Caption = styled(Eyebrow)`
  color: ${colors.brownMedium};
`;

export const Header = styled(H1)`
  color: ${colors.brownMedium};
  margin: 0px;
`;

export const Description = styled(H5)`
  color: ${colors.brownMedium};
  margin: 0px 0px 0px 24px;
`;
