import React, { useEffect, useMemo, useState } from 'react';
import { getPage, listPagesByPageId } from './queries';
import { useQuery, gql } from '@apollo/client';
import { Container } from './styles';
import { Redirect } from 'react-router-dom';
import PlainPage from 'containers/TemplateContainer/Components/PlainPage';
import TabbedPage from './Components/TabbedPage/TabbedPage';
import OfficePage from './Components/OfficePage/OfficePage';
import { SlugI } from 'types/SlugListType';
import { getSlugs } from 'utils/functions';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
const LIST_PAGES_BY_PAGE_ID = gql(listPagesByPageId);

interface ITemplateContainer {
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
  id: string;
}

const TemplateContainer = ({ toast, id }: ITemplateContainer): JSX.Element => {
  const {
    loading,
    error,
    refetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: id,
    },
  });

  const {
    loading: attachedPageLoading,
    data: attachedPageData,
    refetch: attachedPageRefetch,
  } = useQuery(LIST_PAGES_BY_PAGE_ID, {
    variables: {
      pageId: id,
    },
  });

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return {
      ...pageData?.getPage,
      pages: { items: attachedPageData?.listPagesByPageId?.items || [] },
    };
  }, [pageData, attachedPageData]);

  if (loading || attachedPageLoading) {
    return <LoaderView />;
  }

  if (error) {
    console.error('Oops! Something went wrong 😡', error);
    toast.error('Oops! Something went wrong 😡');
    return <Redirect to={`/`} />;
  }

  const handleRefetch = () => {
    refetch();
    attachedPageRefetch();
  };

  return (
    <Container>
      {data?.layout === 'plain' && (
        <PlainPage
          handleRefetch={handleRefetch}
          toast={toast}
          data={data}
          slugList={slugList}
        />
      )}
      {data?.layout === 'tabbed' && (
        <TabbedPage
          handleRefetch={handleRefetch}
          toast={toast}
          id={id}
          data={data}
          slugList={slugList}
        />
      )}
      {data?.layout === 'officeTab' && (
        <OfficePage
          handleRefetch={handleRefetch}
          toast={toast}
          id={id}
          data={data}
          slugList={slugList}
        />
      )}
    </Container>
  );
};

export default TemplateContainer;
