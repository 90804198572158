import React, { useCallback, useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import {
  Container,
  WithImage,
  WithoutImage,
  Input,
  Close,
  Placeholder,
  DropboxLabel,
  DropboxDisclaimer,
  BadUploads,
  UploadError,
  ClearError,
  ImageFileName,
  MissingError,
} from './styles';
import { useDropzone } from 'react-dropzone';
import { IconCloudUpload, IconWarning, IconX } from 'components/IconsView';
import Label from '../Label';
import classNames from 'classnames';
import { BadMediaI, DropRejectsI } from './ImageInput.types';
import { formatBytes } from 'utils/functions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ImageInput = ({
  id,
  label,
  serverImage,
  setFile,
  optional,
  dropzoneDisclaimer,
  dropzoneLabel,
  fileTypes,
  fileMaxSize,
  error,
  errorMessage,
  clearErrors,
  type = 'circle',
  name,
  useBgImage,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): JSX.Element => {
  const [media, setMedia] = useState<string | null>(null);
  const [badMedia, setBadMedia] = useState<BadMediaI[]>([]);
  const [fileName, setFileName] = useState<string>('');

  const fileURL = (localFile: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(localFile);
      reader.onload = () => {
        setMedia(reader.result as string);
        resolve(reader.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
    });
  };

  useEffect(() => {
    if (serverImage) {
      setFileName(serverImage);
      // get image from S3 storage and return URL
      Storage.get(`${serverImage}`)
        .then((result) => {
          setMedia(result);
        })
        .catch((err) => console.error(err));
    }
  }, [serverImage]);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      console.log('onDrop');
      if (acceptedFiles.length > 0) {
        setFileName(acceptedFiles[0].path);
        fileURL(acceptedFiles[0]);
        setFile(acceptedFiles[0]);
        setBadMedia([]);

        if (error) {
          clearErrors(name);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [media, error]
  );

  const onDropRejected = (e: DropRejectsI[]) => {
    const newErrors: BadMediaI[] = [];
    e.map((item: DropRejectsI) => {
      newErrors.push({
        filename: item.file.name,
        filetype: item.file.type,
        error: item.errors[0].message,
        code: item.errors[0].code,
      });
    });

    setBadMedia((prev) => [...prev, ...newErrors]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypes || 'image/*, video/*',
    onDrop,
    onDropRejected,
    multiple: false,
    maxSize: fileMaxSize || Infinity,
  });

  return (
    <Container className={classNames(type)}>
      <Label name={id} optional={optional}>
        {label}
      </Label>
      {!media ? (
        <WithoutImage
          {...getRootProps()}
          className={classNames({ error: error })}
        >
          <Input id={id} {...getInputProps()} data-testid="file-input" />
          <IconCloudUpload />
          <DropboxLabel htmlFor={id}>
            {dropzoneLabel || 'Upload Image'}
          </DropboxLabel>
          <DropboxDisclaimer>
            {dropzoneDisclaimer || 'Images are allowed'}
          </DropboxDisclaimer>
        </WithoutImage>
      ) : (
        <WithImage
          {...getRootProps()}
          className={classNames({ error: error })}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <Close
            data-testid="remove-image"
            className="remove-image"
            aria-label="delete"
            onClick={(e) => {
              e.preventDefault();
              setMedia(null);
              setFileName('');
              setFile(null);
            }}
          >
            <IconX />
          </Close>
          <Placeholder>
            {useBgImage ? (
              <div
                style={{
                  backgroundImage: `url(${media})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center,center',
                  width: '158px',
                  height: '158px',
                }}
              />
            ) : (
              <img src={media} alt="test" />
            )}
          </Placeholder>
        </WithImage>
      )}
      {fileName ? <ImageFileName>{fileName}</ImageFileName> : ''}
      {error && (
        <MissingError>
          <>
            <IconWarning />
            <span>Missing image upload</span>
          </>
        </MissingError>
      )}
      {badMedia && badMedia.length > 0 && (
        <BadUploads>
          {badMedia.length > 1 ? (
            <UploadError key={`bad-upload-${badMedia[0].code}`}>
              <>
                <IconWarning />
                <span>Only one file may be uploaded</span>
                <ClearError
                  type="button"
                  aria-label="clear error"
                  data-testid={'clear-error'}
                  onClick={() =>
                    setBadMedia((prev) => {
                      const temp = [...prev];
                      temp.splice(0, temp.length);
                      return temp;
                    })
                  }
                >
                  <IconX />
                </ClearError>
              </>
            </UploadError>
          ) : (
            badMedia.map((m, index) => (
              <UploadError key={`bad-upload-${index}`}>
                <>
                  <IconWarning />
                  {m.filename} {'  -  '}
                  <span>
                    {m.code === 'file-too-large'
                      ? `Must be ${formatBytes(fileMaxSize)} or smaller`
                      : errorMessage || m.error}
                  </span>
                  <ClearError
                    type="button"
                    aria-label="clear error"
                    data-testid={'clear-error'}
                    onClick={() =>
                      setBadMedia((prev) => {
                        const temp = [...prev];
                        temp.splice(index, 1);
                        return temp;
                      })
                    }
                  >
                    <IconX />
                  </ClearError>
                </>
              </UploadError>
            ))
          )}
        </BadUploads>
      )}
    </Container>
  );
};
export default ImageInput;
