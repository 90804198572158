import { colors } from 'shared/variables';
import styled from 'styled-components';

export const Main = styled.div`
  display: grid;
  grid-template-columns: 233px 1fr;
`;

export const LeftContainer = styled.div`
  border-right: 1px solid ${colors.greyLightLight};
`;

export const MainContainer = styled.div`
  min-height: var(--doc-height);
`;
