import { createGlobalStyle } from 'styled-components';
import SurveyorTextBlackWoff2 from 'fonts/SurveyorText-Black.woff2';
import SurveyorTextBlackWoff from 'fonts/SurveyorText-Black.woff';
import SurveyorTextLightItalicWoff2 from 'fonts/SurveyorText-LightItalic.woff2';
import SurveyorTextLightItalicWoff from 'fonts/SurveyorText-LightItalic.woff';
import SurveyorTextMediumWoff2 from 'fonts/SurveyorText-Medium.woff2';
import SurveyorTextMediumWoff from 'fonts/SurveyorText-Medium.woff';
import BrandonGrotesqueBlackWoff from 'fonts/BrandonGrotesque-Black.woff';
import BrandonGrotesqueBlackWoff2 from 'fonts/BrandonGrotesque-Black.woff2';
import BrandonGrotesqueBoldWoff from 'fonts/BrandonGrotesque-Bold.woff';
import BrandonGrotesqueBoldWoff2 from 'fonts/BrandonGrotesque-Bold.woff2';
import TTCommonsMediumWoff from 'fonts/TTCommons-Medium.woff';
import TTCommonsMediumWoff2 from 'fonts/TTCommons-Medium.woff2';
import TTCommonsExtraBoldWoff from 'fonts/TTCommons-ExtraBold.woff';
import TTCommonsExtraBoldWoff2 from 'fonts/TTCommons-ExtraBold.woff2';
import BrandonGrotesqueMediumWoff from 'fonts/BrandonGrotesque-Medium.woff';
import BrandonGrotesqueMediumWoff2 from 'fonts/BrandonGrotesque-Medium.woff2';
import BrandonGrotesqueRegularWoff from 'fonts/BrandonGrotesque-Regular.woff';
import BrandonGrotesqueRegularWoff2 from 'fonts/BrandonGrotesque-Regular.woff2';
import EspaWoff from 'fonts/Espa.woff';
import EspaWoff2 from 'fonts/Espa.woff2';
import { colors } from './variables';
import bkgTextureWhite from 'images/bg_texture-dust-white.jpg';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Surveyor Text';
    src: local('Surveyor Text Black'), local('SurveyorText-Black'),
      url(${SurveyorTextBlackWoff2}) format('woff2'),
      url(${SurveyorTextBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Surveyor Text';
    src: local('Surveyor Text Light Italic'), local('SurveyorText-LightItalic'),
      url(${SurveyorTextLightItalicWoff2}) format('woff2'),
      url(${SurveyorTextLightItalicWoff}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Surveyor Text';
    src: local('Surveyor Text Medium'), local('SurveyorText-Medium'),
      url(${SurveyorTextMediumWoff2}) format('woff2'),
      url(${SurveyorTextMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brandon Grotesque';
    src: local('Brandon Grotesque Normal'), local('Brandon-Grotesque-Normal'),
      url(${BrandonGrotesqueRegularWoff2}) format('woff2'),
      url(${BrandonGrotesqueRegularWoff}) format('woff');
    font-weight: normal;
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brandon Grotesque Medium';
    src: local('Brandon Grotesque Medium'), local('Brandon-Grotesque-Medium'),
      url(${BrandonGrotesqueMediumWoff2}) format('woff2'),
      url(${BrandonGrotesqueMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brandon Grotesque Bold';
    src: local('Brandon Grotesque Bold'), local('Brandon-Grotesque-Bold'),
      url(${BrandonGrotesqueBoldWoff2}) format('woff2'),
      url(${BrandonGrotesqueBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Brandon Grotesque Black';
    src: local('Brandon Grotesque Black'), local('Brandon-Grotesque-Black'),
      url(${BrandonGrotesqueBlackWoff2}) format('woff2'),
      url(${BrandonGrotesqueBlackWoff}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons';
    src: local('TT Commons'), local('TT-Commons-Medium'),
      url(${TTCommonsMediumWoff2}) format('woff2'),
      url(${TTCommonsMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Commons Extra Bold';
    src: local('TT Commons Extra Bold'), local('TT-Commons-Extra-Bold'),
      url(${TTCommonsExtraBoldWoff2}) format('woff2'),
      url(${TTCommonsExtraBoldWoff}) format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Espa';
    src: local('Espa'), local('Espa'),
      url(${EspaWoff2}) format('woff2'),
      url(${EspaWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  body {
    background: url(${bkgTextureWhite}) 0 0 repeat, ${colors.greyLight};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 1.5;

    * {
      box-sizing: border-box;
    }
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }

  .icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
.modal-element {
  background-color: hsla(0, 0%, 0%, 0.25);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  grid-template-columns: minmax(auto, 920px);
  overflow-y: scroll;
  opacity: 1;
  transition: all 150ms ease;
  &.fade-in {
    opacity: 0;
  }
}`;

export default GlobalStyle;
