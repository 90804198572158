import styled from 'styled-components';
import { Eyebrow, H3 as StyledH3, Body as StyledBody } from 'shared/typography';
import { colors } from 'shared/variables';
import Select from 'components/FormComponents/Select/Select';

export const Container = styled.div`
  padding: 100px;
`;

export const Header = styled.div``;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.brownLight};
  margin: 10px 0px;
`;

export const TimeStamp = styled(StyledBody)``;

export const Label = styled(Eyebrow)`
  color: ${colors.brownMedium};
`;

export const H3 = styled(StyledH3)`
  color: ${colors.brownMedium};
  margin-top: 40px;
  margin-bottom: 7px;
`;

export const RadioGroup = styled.div`
  margin-top: 20px;
`;

export const Body = styled(StyledBody)``;

export const AccordionWrapper = styled.div`
  margin-top: 25px;
`;

export const DeactivatedContainer = styled.div`
  background: ${colors.white};

  .accordion {
    padding-top: 16px;
  }
`;

export const Deactivated = styled(Eyebrow)`
  color: ${colors.brownMedium};
  padding: 0 40px;
`;

export const Form = styled.form`
  /* min-width: 33%; */
  width: 100%;
  display: grid;
  grid-row-gap: 32px;

  > div {
    position: relative;
  }
`;

export const DefaultGroupContainer = styled.div`
  position: absolute;
  right: 0;
  /* transform: translateY(-100%);
  top: -24px; */
  top: -6px;
`;

export const Dropdown = styled(Select)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline-colors: ${colors.greyMedium};

  .select {
    width: 170px;
  }

  label {
    margin-bottom: 0;
    margin-right: 18px;
  }

  .select-selected {
    padding: 5px 34px 5px 10px;
    height: 30px;
  }

  svg {
    right: 13px;
  }

  &.open {
    .select-options {
      padding: 30px 3px 0;

      button:hover {
        background: transparent;
        color: ${colors.primary};
      }
    }
  }
`;

export const Description = styled(Body)`
  color: ${colors.brownLight};
  margin: 0;
`;
