import React, { useMemo, memo, useState, useEffect } from 'react';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Description,
  Form,
  Message,
  ModalContainer,
  PageLink,
  Preview,
  PreviewContainer,
  SubmitButton,
  Title,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import Select from 'components/FormComponents/Select';
import { QuickLink } from 'API';
import { useForm } from 'react-hook-form';
import { IconArrow } from 'components/IconsView';
import { Container as Label } from 'components/FormComponents/Label/styles';
import TextArea from 'components/FormComponents/TextArea';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import IToast from 'types/ToastType';
import { validateURL } from 'utils/functions';

const CREATE_QUICKLINK = gql(mutations.createQuickLink);
const UPDATE_QUICKLINK = gql(mutations.updateQuickLink);

export interface IFormValues {
  id?: string;
  pageId?: string;
  title?: string;
  body?: string;
  sort?: number;
  linkTitle?: string;
  linkTo?: string;
  externalLink?: string;
}

interface IAddSectionFormProps {
  toggle: () => void;
  toast: IToast;
  length?: number;
  pageId?: string;
  data?: QuickLink;
  slugs: { value: string; label: string; key?: string }[];
}

const QuicklinkForm = ({
  toggle,
  toast,
  length,
  pageId,
  data,
  slugs,
}: IAddSectionFormProps): JSX.Element => {
  const [deletingQuicklink, setDeletingQuicklink] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [customError, setCustomError] = useState<boolean>(false);
  const [preview, setPreview] = useState({
    title: '',
    body: '',
    linkTitle: '',
  });
  const formattedData = {
    ...data,
    ...(data?.externalLink
      ? { linkTo: 'external', externalLink: data?.externalLink }
      : { linkTo: data?.linkId }),
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: formattedData,
  });

  // mutations
  const [createQuickLink, { data: createData, error: createError }] =
    useMutation<IFormValues>(CREATE_QUICKLINK, {
      onCompleted: () => {
        toast.success('Quick link created');
        toggle();
      },
      onError: () => {
        toast.error('Error creating quick link');
      },
    });
  const [updateQuickLink, { data: updateData, error: updateError }] =
    useMutation<IFormValues>(UPDATE_QUICKLINK, {
      onCompleted: () => {
        toast.success('Quick link updated');
        toggle();
      },
      onError: () => {
        toast.error('Error updating quick link');
      },
    });

  useEffect(() => {
    setPreview({
      title: data?.title || '',
      body: data?.body || '',
      linkTitle: data?.linkTitle || '',
    });
  }, [data]);

  const options = useMemo(() => {
    const temps: { value: string | null; label: string; key?: string }[] = [
      ...slugs,
    ];
    temps.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    temps.unshift({ label: 'Choose a page', value: null });
    temps.push({ label: 'External Link', value: 'external', key: 'default' });
    return temps;
  }, [slugs]);

  // functions

  return (
    <>
      <>{console.log('isDirty:', isDirty)}</>
      <ModalContainer
        id="sectionModal"
        title={data?.id ? 'Edit Quick Link' : 'Add Quick Link'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
        description="Edit the text to be shown in this quicklink."
      >
        <Form
          noValidate
          key="formModal"
          className="modalForm"
          onSubmit={handleSubmit(async (input) => {
            const formatInput = {
              title: input.title,
              body: input.body,
              pageId: pageId,
              linkTitle: input.linkTitle,
              ...(input.linkTo === 'external'
                ? { externalLink: input.externalLink, linkId: 'linkId' }
                : { linkId: input.linkTo, externalLink: '' }),
            };
            console.log(formatInput);
            if (data?.id) {
              await updateQuickLink({
                variables: {
                  input: {
                    ...formatInput,
                    id: data?.id,
                  },
                },
              });
              toggle();
            } else {
              await createQuickLink({
                variables: {
                  input: {
                    ...formatInput,
                    sort: length,
                  },
                },
              });
              toggle();
            }
          })}
        >
          <TextInput
            key="title"
            id="title"
            name="title"
            label="Quick Link Title"
            placeholder="Enter Title Here"
            maxLength={50}
            register={register}
            setError={(title, error) => setError('title', error)}
            clearErrors={() => clearErrors('title')}
            error={!!errors?.title}
            setValue={setValue}
            errorMessage="Enter a title"
            required={true}
            hideRequired={true}
            onChange={(value) => {
              setPreview((prev) => {
                return { ...prev, title: value.target.value };
              });
              clearErrors('title');
            }}
            defaultValue={data?.title || ''}
          />
          <TextArea
            key="body"
            id="body"
            name="body"
            label="Quick Link Description Text"
            placeholder="Enter Description Here"
            maxLength={150}
            register={register}
            setError={(body, error) => setError('body', error)}
            clearErrors={() => clearErrors('body')}
            error={!!errors?.body}
            errorMessage="Enter a description"
            setValue={setValue}
            required={true}
            hideRequired={true}
            onChange={(value) => {
              setPreview((prev) => {
                return { ...prev, body: value.target.value };
              });
              clearErrors('body');
            }}
            defaultValue={data?.body || ''}
          />
          <TextInput
            key="linkTitle"
            id="linkTitle"
            name="linkTitle"
            label="Quick Link CTA Text"
            placeholder="Enter CTA Here"
            maxLength={50}
            register={register}
            setError={(linkTitle, error) => setError('linkTitle', error)}
            clearErrors={() => clearErrors('linkTitle')}
            error={!!errors?.linkTitle}
            setValue={setValue}
            errorMessage="Enter a CTA"
            required={true}
            hideRequired={true}
            onChange={(value) => {
              setPreview((prev) => {
                return { ...prev, linkTitle: value.target.value };
              });
              clearErrors('linkTitle');
            }}
            defaultValue={data?.linkTitle || ''}
          />
          <Select
            options={options}
            name="linkTo"
            error={!!errors?.linkTo}
            setError={setError}
            clearErrors={clearErrors}
            id="linkTo"
            key="linkTo"
            label="Quick Link CTA Links to"
            register={register}
            setValue={setValue}
            required={true}
            hideRequired={true}
            defaultValue={formattedData?.linkTo || undefined}
            defaultOption={null}
          />
          {watch('linkTo') === 'external' && (
            <TextInput
              key="externalLink"
              id="externalLink"
              name="externalLink"
              label="Enter External URL..."
              placeholder="Enter URL Here"
              register={register}
              setError={(externalLink, error) =>
                setError('externalLink', error)
              }
              clearErrors={() => clearErrors('externalLink')}
              error={!!(customError || errors?.externalLink)}
              setValue={setValue}
              errorMessage="Enter a URL"
              required={true}
              hideRequired={true}
              onChange={(value) => {
                setPreview((prev) => {
                  return { ...prev, externalLink: value.target.value };
                });
                clearErrors('externalLink');
              }}
              onBlur={(e) => {
                const value = e.target.value;
                setCustomError(!validateURL(value));
              }}
              defaultValue={data?.externalLink || ''}
            />
          )}
          <PreviewContainer>
            <Label>Preview</Label>
            <Preview>
              <Title>{preview.title}</Title>
              <Description>{preview.body}</Description>
              <PageLink>
                {preview.linkTitle} <IconArrow />
              </PageLink>
            </Preview>
            {createData && <Message>Created!</Message>}
            {updateData && <Message>Updated!</Message>}
            {updateError && <Message>Update Error!</Message>}
            {createError && <Message>Create Error!</Message>}
          </PreviewContainer>
          <ButtonContainer>
            <SubmitButton data-testid="submit section" type="submit">
              {data?.id ? 'Save' : 'Add Quicklink'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {data?.id ? (
              <DeleteButton
                type="button"
                onClick={() => setDeletingQuicklink(true)}
                delete
              >
                Delete Quick Link
              </DeleteButton>
            ) : null}
          </ButtonContainer>
        </Form>
      </ModalContainer>
      {checkUnsaved && (
        <UnsavedModal cancel={() => setCheckUnsaved(false)} close={toggle} />
      )}
      {deletingQuicklink && data && (
        <DeleteForm
          type="quicklink"
          id={data?.id}
          toast={toast}
          toggle={toggle}
          toggleDelete={() => setDeletingQuicklink(false)}
        />
      )}
    </>
  );
};

export default memo(QuicklinkForm);
