import React, { useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import Modal from 'containers/Modal';
import * as mutations from 'graphql/mutations';

import {
  Form,
  SubmitButton,
  CancelButton,
  ButtonContainer,
  CustomTextInput,
  CustomTextArea,
  DeleteButton,
} from '../styles';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { FAQ, Office } from 'API';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { checkWYSIWYG, postWYSIWYG } from 'utils/functions';
import Checkbox from 'components/FormComponents/Checkbox/Checkbox';
import {
  CheckGroup,
  CheckboxLabel,
} from 'containers/FreelanceDirectory/styles';

const CREATE_FAQ = gql(mutations.createFAQ);
const UPDATE_FAQ = gql(mutations.updateFAQ);

interface IQuestionFormProps {
  toggle: (refetch?: boolean) => void;
  defaultValues?: FAQ;
  toast: { success: (str: string) => void; error: (str: string) => void };
  length?: number;
  parentId?: string;
  offices: Office[];
}

export interface IFormValues {
  id?: string;
  sectionID?: string;
  question?: string;
  answer?: string;
  sort?: number;
}

const QuestionForm = ({
  toggle,
  defaultValues,
  toast,
  length,
  parentId,
  offices,
}: IQuestionFormProps): JSX.Element => {
  const [currentOffices, setCurrentOffices] = useState(
    defaultValues?.officeIDs || []
  );
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [deletingQuestion, setDeletingQuestion] = useState(false);
  const [isQuestionFilled, setIsQuestionFilled] = useState(
    defaultValues?.question ? true : false
  );
  const [isQuestionChanged, setIsQuestionChanged] = useState(false);
  const [isAnswerFilled, setIsAnswerFilled] = useState(
    defaultValues?.answer ? true : false
  );
  const [isAnswerChanged, setIsAnswerChanged] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const methods = useForm<any>({
    defaultValues: {
      id: defaultValues?.id || '',
      question: defaultValues?.question || '',
      answer: defaultValues?.answer || '',
      officeIDs: defaultValues?.officeIDs || '',
    },
  });
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = methods;

  const [createFAQ] = useMutation(CREATE_FAQ, {
    onCompleted: () => {
      toast.success('Question created');
    },
    onError: () => {
      toast.error('Error creating question');
    },
  });
  const [updateFAQ] = useMutation(UPDATE_FAQ, {
    onCompleted: () => {
      toast.success('Question updated');
    },
    onError: () => {
      toast.error('Error updating question');
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = useCallback(
    async (input) => {
      if (checkWYSIWYG(input.answer || '')) {
        setError('answer', {
          type: 'required',
          message: 'Fill out this field',
        });
        return;
      }

      const answerData = postWYSIWYG(input.answer || '');

      // CHECK SELECTED OFFICE CHECKBOXES
      const officeIDs: string[] = [];
      Object.entries(input).forEach(([name, value]) => {
        const hasCurrentOffice = currentOffices.find(
          (o) => `office-${o}` === name
        )
          ? true
          : false;
        if (name.startsWith('office-') && hasCurrentOffice === true) {
          const officeId = name.substring('office-'.length);
          const selectedOffice = offices.find(
            (office) => office.id === officeId
          );

          if (selectedOffice) {
            officeIDs.push(selectedOffice.id);
          }
        }
      });

      if (defaultValues?.id) {
        await updateFAQ({
          variables: {
            input: {
              id: input.id,
              question: input.question,
              sectionID: parentId,
              answer: answerData,
              officeIDs: officeIDs,
            },
          },
        });
      } else {
        delete input.id;
        await createFAQ({
          variables: {
            input: {
              question: input.question,
              sort: length || 0,
              sectionID: parentId,
              answer: answerData,
              officeIDs: officeIDs,
            },
          },
        });
      }
      toggle(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createFAQ,
      updateFAQ,
      defaultValues,
      toggle,
      parentId,
      length,
      currentOffices,
    ]
  );

  // Disable Save Button Logic
  useEffect(() => {
    // On Update Check
    if (defaultValues?.id) {
      // Check if any differences between starting offices and current selection
      const officeDefaults = defaultValues?.officeIDs || [];
      const copiedCurrentOffices = [...currentOffices];
      const copiedDefaultValues = [...officeDefaults];
      const isOfficeChanged =
        copiedCurrentOffices.sort().join(',') !==
        copiedDefaultValues.sort().join(',');

      setIsSubmitDisabled(
        (!isQuestionChanged && !isAnswerChanged && !isOfficeChanged) ||
          !isQuestionFilled ||
          !isAnswerFilled
      );
    } else {
      // On Create Check
      setIsSubmitDisabled(!isDirty || !isQuestionFilled || !isAnswerFilled);
    }
  }, [
    defaultValues,
    isAnswerChanged,
    isAnswerFilled,
    isDirty,
    isQuestionChanged,
    isQuestionFilled,
    currentOffices,
  ]);

  const checkUnsavedStatus = () => {
    // Check if any differences between starting offices and current selection
    const officeDefaults = defaultValues?.officeIDs || [];
    const isOfficeChanged =
      currentOffices.sort().join(',') !== officeDefaults.sort().join(',');

    if (isQuestionChanged || isAnswerChanged || isOfficeChanged) {
      setCheckUnsaved(true);
    } else {
      toggle();
    }
  };

  return (
    <>
      <Modal
        id="questionModal"
        title={defaultValues?.id ? 'Edit FAQ' : 'Add FAQ'}
        toggle={checkUnsavedStatus}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(onSubmit)}
          className="modalForm"
        >
          <div>
            <CheckboxLabel name="checkGroup">
              Applicable Locations
            </CheckboxLabel>
            <CheckGroup id="checkGroup">
              {offices
                .sort((a, b) => (a.sort || 0) - (b.sort || 0))
                .filter((o) => o.active)
                .map((office) => {
                  const isChecked =
                    defaultValues?.officeIDs?.some(
                      (defaultOffice) => office.id == defaultOffice
                    ) || false;

                  return (
                    <Checkbox
                      id={office.id}
                      label={office.name || ''}
                      name={`office-${office.id}` || ''}
                      register={register}
                      setChecked={isChecked}
                      onChange={(e) => {
                        // Add Office to current office tracker
                        if (e.target.checked) {
                          const temp = [...currentOffices];
                          temp.push(office.id);
                          setCurrentOffices(temp);
                        } else {
                          // Remove Office from current office tracker
                          const temp = currentOffices.filter(
                            (o) => o !== office.id
                          );
                          setCurrentOffices(temp);
                        }
                      }}
                    />
                  );
                })}
            </CheckGroup>
          </div>

          <CustomTextInput
            placeholder="Enter Question Here"
            id="question"
            name="question"
            label="Question"
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            setValue={methods.setValue}
            errorMessage={'This field is required.'}
            maxLength={50}
            required={true}
            hideRequired={true}
            error={!!errors?.question}
            defaultValue={defaultValues?.question || ''}
            onChange={(e) => {
              // Check if input has content
              if (e.target.value.length) {
                setIsQuestionFilled(true);
              } else {
                setIsQuestionFilled(false);
              }

              // Check if input has changed
              if (defaultValues?.question != e.target.value) {
                setIsQuestionChanged(true);
              } else {
                setIsQuestionChanged(false);
              }
            }}
          />
          <CustomTextArea
            {...methods}
            placeholder="Enter Answer Here"
            id="answer"
            name="answer"
            label="Answer"
            setValue={methods.setValue}
            defaultValue={defaultValues?.answer || ''}
            required={true}
            waitForToolbar={true}
            error={methods?.formState?.errors?.answer}
            onChange={(value: string) => {
              // Strip HTML and check clean string length
              const cleanString = value.replace(/<\/?[^>]+(>|$)/g, '');

              // Check if WYSIWYG has content
              if (cleanString.length) {
                setIsAnswerFilled(true);
              } else {
                setIsAnswerFilled(false);
              }

              // Check if WYSIWYG has changed
              if (defaultValues?.answer != value) {
                setIsAnswerChanged(true);
              } else {
                setIsAnswerChanged(false);
              }
            }}
          />
          <ButtonContainer>
            <SubmitButton
              aria-label="Submit"
              key="submit"
              type="submit"
              className="ModalSubmit"
              disabled={isSubmitDisabled}
            >
              {defaultValues?.id ? 'Save' : 'Create'}
            </SubmitButton>
            <CancelButton
              aria-label="Cancel"
              key="cancel"
              type="button"
              className="Modalcancel"
              value="Cancel"
              tertiary
              onClick={checkUnsavedStatus}
            >
              Cancel
            </CancelButton>
            {defaultValues?.id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingQuestion(true)}
              >
                Delete FAQ
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
          type="faq"
        />
      )}
      {deletingQuestion && (
        <DeleteForm
          preventUnlock={true}
          type="faq"
          id={defaultValues?.id || ''}
          toggle={() => toggle(true)}
          toggleDelete={() => setDeletingQuestion(false)}
          toast={toast}
        />
      )}
    </>
  );
};

export default QuestionForm;
