import Accordion from 'components/Accordion';
import SectionContainer from 'components/SectionContainer';
import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { QuickLink } from 'API';
import QuicklinkForm from './Forms/QuicklinkForm/QuicklinkForm';
import { formatDataForAccordion } from './helpers/format';
import { arrayMove } from 'react-sortable-hoc';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import UnsavedModalRoute from 'components/UnsavedModalRoute';
import IToast from 'types/ToastType';
import { SlugI } from 'types/SlugListType';

interface Props {
  pageID: string;
  toast: IToast;
  slugs: SlugI[];
  quicklinksSorted: (items: AccordionItems[]) => void;
  refetch: () => void;
  quicklinks: QuickLink[];
}

const Quicklinks = ({
  pageID,
  toast,
  slugs,
  quicklinksSorted,
  quicklinks,
  refetch,
}: Props): JSX.Element => {
  const [quicklinksSort, setQuicklinksSort] = useState<string[]>([]);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [modalQuicklinks, setModalQuicklinks] = useState<{
    length?: number;
    pageId?: string;
    data?: QuickLink;
  } | null>(null);

  const [deletingQuicklink, setDeletingQuicklink] = useState<{
    id: string;
  } | null>(null);

  useEffect(() => {
    setIsDirtyForm(false);
    if (quicklinksSort.length > 0) {
      // Find missing items
      const missingItems =
        quicklinksSort?.filter(
          (item: string) => !quicklinks.some((q) => q.id === item)
        ) || [];

      // Find added items
      const addedItems =
        quicklinks.filter((item) => !quicklinksSort.includes(item.id)) || [];

      const temp = [...quicklinksSort];
      if (addedItems.length > 0) {
        addedItems.map((item) => {
          temp.push(item.id);
        });
      }
      if (missingItems.length > 0) {
        missingItems.map((item) => {
          const indexItem = temp.indexOf(item);
          if (indexItem > -1) {
            temp.splice(indexItem, 1);
          }
        });
      }
      setQuicklinksSort(temp);
    } else {
      const temp: string[] = [];
      quicklinks
        .slice()
        ?.sort((x: QuickLink, y: QuickLink) => {
          return (x.sort || 0) - (y.sort || 0);
        })
        .map((q) => {
          temp.push(q.id);
        });
      setQuicklinksSort(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quicklinks]);

  //build slugs parent to child relationship
  const allSlugs: { value: string; label: string; key?: string }[] =
    useMemo(() => {
      const parents = slugs.filter(
        (x) => x.pageId === 'main' && x.id !== 'page-404'
      );
      const children = slugs.filter((x) => x.pageId !== 'main');
      const formedSlugs: { value: string; label: string; key?: string }[] = [];
      // breaking down slug building by the parent slug
      parents
        .filter((x) => x.slug && x.title)
        .map((parent) => {
          // add parent first
          formedSlugs.push({
            key: parent.id + Math.random(),
            // value: parent.slug || '',
            value: parent.id,
            label: parent.title || '',
          });

          // get all child slugs and check if they are visible pages
          const descendent = children.filter(
            (x) => x.pageId === parent.id && x.status === 'active'
          );

          // re-build their slugs
          descendent
            .filter((x) => x.slug && x.title)
            .map((child) => {
              formedSlugs.push({
                key: child.id + Math.random(),
                label: child.title || '',
                // value: `${parent.slug}/${child.slug}`,
                value: child.id,
              });
            });
        });
      return formedSlugs;
    }, [slugs]);

  // sort quicklinks by order
  const sortQuicklinks = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[]
    ) => {
      let newSort: AccordionItems[] = [];
      if (items) {
        const probableChange = arrayMove(items, oldIndex, newIndex);
        const differenceAry = probableChange.slice().map(function (n, i) {
          return i - probableChange[i].originalIndex;
        });
        const isDifference = differenceAry.some((value) => value !== 0);
        if (isDifference) {
          newSort = probableChange;
        } else {
          newSort = [];
        }
      }

      const quicklinkChange = arrayMove(quicklinksSort, oldIndex, newIndex);

      setQuicklinksSort(quicklinkChange);
      quicklinksSorted(newSort);

      const sortedContent: AccordionItems[] = [];

      newSort.map((item, i) => {
        if (item.index !== i) {
          sortedContent.push(item);
        }
      });

      setIsDirtyForm(sortedContent.length > 0);
    },
    [quicklinksSorted, quicklinksSort]
    // [refetch]
  );

  // FUNCTIONS
  const addQuicklink = useCallback(
    ({ length }: any) => {
      setModalQuicklinks({ length, pageId: pageID });
    },
    [pageID]
  );

  const updateQuicklink = useCallback(
    async (id: any) => {
      const quicklink = quicklinks.find((x) => x.id === id);
      if (quicklink) {
        setModalQuicklinks({ data: quicklink });
      }
    },
    [quicklinks]
  );

  const accordionData: AccordionTypes = useMemo(() => {
    // if (!quicklinks) return null;
    return formatDataForAccordion({
      quicklinks,
      quicklinksSort,
      addQuicklink,
      updateQuicklink,
      deleteQuicklink: setDeletingQuicklink,
      sortQuicklinks,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quicklinks, quicklinksSort]);

  return (
    <>
      {isDirtyForm && (
        <UnsavedModalRoute
          content="Doing this will lose any unsaved changes. Are you sure you want to continue?"
          title="Are you sure you want to leave this unsaved?"
          isBlocked={isDirtyForm}
        />
      )}
      <SectionContainer
        title="Quick Links"
        description="Select the specific quick links to display on the homepage. You can display up to 9 quicklinks and determine the order below. Drag and drop to rearrange, and click or select from the drop-down to change the link. Click edit to edit description text."
        includePadding={false}
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {modalQuicklinks && (
        <QuicklinkForm
          toggle={async () => {
            await refetch();
            setModalQuicklinks(null);
          }}
          toast={toast}
          slugs={allSlugs}
          {...modalQuicklinks}
        />
      )}
      {deletingQuicklink && (
        <DeleteForm
          type="quicklink"
          id={deletingQuicklink.id}
          toast={toast}
          toggle={async () => {
            await refetch();
            setDeletingQuicklink(null);
          }}
        />
      )}
    </>
  );
};

export default memo(Quicklinks);
