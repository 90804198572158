export const colors = {
  primary: '#F1B64F',
  highlight: '#FB4640',
  highlightDelete: '#E13E38',
  highlightBackground: '#FBE5E4',
  greyLight: 'rgba(246, 246, 242, 0.5);',
  greyMedium: '#EAEAE5',
  greyMediumMedium: '#7d7f7c',
  greyLightLight: '#E0DFD7',
  brownLight: '#8A816D',
  brownLessLight: '#B2AD9F',
  brownMedium: '#4D453D',
  white: '#FFFFFF',
  black: '#000000',
  primaryHover: '#D09D43',
  focus: '#007CEE',
};

export const MOUSE_EASE = 0.32;
export const SCROLL_EASE = 0.325;
export const customBezierEase = `cubic-bezier(0.075, 0.82, 0.165, 1)`;

export const padding = {
  listItem: {
    padding: 1.25,
    gap: 1,
  },
  mobile: 30,
  tablet: 75,
  desktop: 114,
};

export const border = {
  listItem: {
    border: `1px solid ${colors.greyLightLight}`,
  },
};

export const maxWidth = 1200;
export const pageWidth = 980;
export const formWidth = 730;

type BreakpointsI = {
  [key: string]: number;
};

export const breakpoints: BreakpointsI = {
  phone: 375,
  phoneWide: 480,
  phablet: 560,
  tabletSmall: 640,
  navMobile: 800,
  tablet: 768,
  searchTablet: 900,
  tabletWide: 1024,
  desktop: 1248,
  desktopWide: 1440,
};
