import TextInput from 'components/FormComponents/TextInput';
import { resetButtonStyles } from 'shared/extends';
import { colors } from 'shared/variables';
import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 17px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  line-height: normal;
  margin-bottom: 32px;
`;

export const TrashButton = styled.button`
  ${resetButtonStyles};
  margin-top: 29px;

  svg {
    fill: ${colors.primary};
  }

  &:hover {
    svg {
      fill: ${colors.highlight};
    }
  }
`;

export const CurrencyInput = styled(TextInput)`
  &::before {
    color: ${colors.brownLight};
    content: '$';
    position: absolute;
    margin-top: 43px;
    margin-left: 5px;
    z-index: 1;
  }
`;
