// import { Office, PageStatus } from 'API';
import { Office } from 'API';

interface IFormat {
  tabs: Office[];
  updateTab: (id: string) => void;
}

export const formatDataForAccordion = ({ tabs, updateTab }: IFormat): any => {
  const formatTab = (tab: Office | any, index: number): any => {
    console.log('tab: ', tab.name);
    return {
      id: tab.id,
      title: tab.name,
      index: index,
      originalIndex: index,
      sortable: false,
      editFunc: () => {
        updateTab(tab.id);
      },
    };
  };

  console.log(tabs);

  return {
    accordionContainer: tabs
      .slice()
      .sort((x: Office, y: Office) => {
        return (x.sort || 0) - (y.sort || 0);
      })
      .map(formatTab),
  };
};
