import Accordion from 'components/Accordion';
import SectionContainer from 'components/SectionContainer';
import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import AccordionTypes from 'components/Accordion/Accordion.types';
import EventsFilteredPhraseForm from './Forms/EventsFilteredPhrase/EventsFilteredPhraseForm';
import { formatDataForAccordion } from './helpers/format';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import UnsavedModalRoute from 'components/UnsavedModalRoute';
import IToast from 'types/ToastType';

interface Props {
  pageID: string;
  toast: IToast;
  refetch: () => void;
  phrases: string[];
  onSubmitPhrases: (mode: string, value: string, oldValue?: string) => void;
}

const EventsFilteredPhrase = ({
  pageID,
  toast,
  phrases,
  refetch,
  onSubmitPhrases,
}: Props): JSX.Element => {
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [modalPhrases, setModalPhrases] = useState<{
    mode: string;
    pageId: string;
    phrase?: string;
    allPhrases?: any;
  } | null>(null);

  const [deletingPhrase, setDeletingPhrase] = useState<string | null>(null);

  useEffect(() => {
    setIsDirtyForm(false);
  }, [phrases]);

  // FUNCTIONS
  const addPhrase = useCallback(
    ({ phrase }: any) => {
      setModalPhrases({
        mode: 'create',
        phrase,
        pageId: pageID,
        allPhrases: phrases,
      });
    },
    [pageID, phrases]
  );

  const updatePhrase = useCallback(
    async (value: string) => {
      const phrase = phrases.find((x) => x === value);
      if (phrase && pageID) {
        setModalPhrases({
          mode: 'edit',
          phrase,
          pageId: pageID,
          allPhrases: phrases,
        });
      }
    },
    [phrases, pageID]
  );

  const accordionData: AccordionTypes = useMemo(() => {
    return formatDataForAccordion({
      phrases,
      addPhrase,
      updatePhrase,
      deletePhrase: setDeletingPhrase,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phrases]);

  return (
    <>
      {isDirtyForm && (
        <UnsavedModalRoute
          content="Doing this will lose any unsaved changes. Are you sure you want to continue?"
          title="Are you sure you want to leave this unsaved?"
          isBlocked={isDirtyForm}
        />
      )}
      <SectionContainer
        title="Add and Edit Filtered Phrase"
        description="Exclude calendar events with specific keywords or phrases."
        includePadding={false}
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {modalPhrases && (
        <EventsFilteredPhraseForm
          toggle={async () => {
            await refetch();
            setModalPhrases(null);
          }}
          toast={toast}
          onSubmitPhrases={onSubmitPhrases}
          {...modalPhrases}
        />
      )}
      {deletingPhrase && (
        <DeleteForm
          type="phrase"
          id={deletingPhrase}
          toast={toast}
          toggle={async () => {
            await refetch();
            setDeletingPhrase(null);
          }}
          onPhraseDelete={() => {
            onSubmitPhrases('delete', deletingPhrase);
          }}
        />
      )}
    </>
  );
};

export default memo(EventsFilteredPhrase);
