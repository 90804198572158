import React, { useEffect, useState, useRef } from 'react';
import {
  ArrowContainer,
  ButtonCover,
  Container,
  DateContainer,
  IconContainer,
  Input,
  ModalContainer,
  Error,
} from './styles';
import dayjs from 'dayjs';
import { IconCalendar, IconWarning } from 'components/IconsView';
import CalendarView from 'components/CalendarView';
import classNames from 'classnames';
import InputProps from './DateSelector.types';
import Label from '../Label';

const DateSelector = ({
  label,
  containerClassName,
  defaultValue,
  error,
  showRequired,
  register,
  setValue,
  clearErrors,
  setError,
  optional,
  minDate,
  maxDate,
  dontDirty,
  mileageList,
  errorMessage,
  onChange,
  handleChange,
  allowWeekends,
  ...inputProps
}: InputProps): JSX.Element => {
  const { name = '', required } = inputProps;
  const ModalRef = useRef<HTMLHeadingElement>(null);
  const [date, setDate] = useState('');
  const [openCalendar, setOpenCalendar] = useState(false);
  const bounceClickRef = useRef(false);
  // const [filteredList, setFilteredList] = useState<any>([]);

  useEffect(() => {
    if (date !== defaultValue && defaultValue) {
      setDate(defaultValue || '');
    }
    if (defaultValue) {
      setValue(name, defaultValue, { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (register) {
      register(name, { required });
    }
    if (defaultValue) {
      setValue(name, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, name]);

  const handleDateChange = (value: string) => {
    // setValidDate(dayjs(value).isValid());
    setDate(value);
    if (handleChange) {
      handleChange(value);
    }

    // instead of waiting for the text input to blur well change it when its valid
    if (dayjs(value).isValid()) {
      setValue(name, value, { shouldDirty: true });
    } else {
      setError(name, { type: 'invalid' });
    }
  };

  const updateDate = (newDate: string) => {
    if (handleChange) {
      handleChange(newDate);
    }
    if (dayjs(newDate).isValid()) {
      setDate(newDate);
      setValue(name, newDate, { shouldDirty: true });

      if (error) {
        clearErrors(name);
      }
      setOpenCalendar((prev) => !prev);
    } else {
      setError(name, { type: 'invalid' });
    }
  };

  const handleOpenCalendar = () => {
    setOpenCalendar((prev) => !prev);
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleModalBackgroundClick = (e: any) => {
    if (!bounceClickRef.current) {
      bounceClickRef.current = true;
      if (!ModalRef.current?.contains(e.target as HTMLElement)) {
        handleOpenCalendar();
      }

      setTimeout(() => {
        bounceClickRef.current = false;
      }, 200);
    }
  };

  useEffect(() => {
    if (openCalendar) {
      document.addEventListener('click', handleModalBackgroundClick);
      return () => {
        document.removeEventListener('click', handleModalBackgroundClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCalendar]);

  return (
    <Container>
      {label && (
        <Label name={name} required={showRequired} optional={optional}>
          {label}
        </Label>
      )}
      <DateContainer
        className={classNames('date', containerClassName, {
          error: error,
          focus: openCalendar,
        })}
      >
        <ButtonCover
          type="button"
          aria-label="open-calendar"
          onClick={() => {
            if (!openCalendar) {
              handleOpenCalendar();
            }
          }}
        />
        <Input
          {...inputProps}
          aria-label={name}
          value={
            dayjs(date).isValid() ? dayjs(date).format('MM/DD/YYYY') : date
          }
          data-testid={name}
          onChange={(e) => handleDateChange(e.target.value)}
          onBlur={(e) => updateDate(e.target.value)}
          id={name}
          disabled
          onClick={handleOpenCalendar}
          placeholder="MM/DD/YYYY"
        />
        <ArrowContainer>
          <IconContainer>
            <IconCalendar />
          </IconContainer>
        </ArrowContainer>
        {openCalendar && (
          <>
            <ModalContainer
              ref={ModalRef}
              className={classNames('modal-calendar', { open: openCalendar })}
            >
              <CalendarView
                start={date || ''}
                end={date || ''}
                dateSelected={updateDate}
                minDate={minDate}
                maxDate={maxDate}
                allowWeekends={allowWeekends}
              />
            </ModalContainer>
          </>
        )}
      </DateContainer>
      <>
        {error && (
          <Error className="error">
            <IconWarning />
            {errorMessage || 'Fill out this field'}
          </Error>
        )}
      </>
    </Container>
  );
};

export default DateSelector;
