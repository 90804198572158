import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { IProps } from './Manage404.types';
import { Container } from './styles';
import { getPage } from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { useMutation, useQuery, gql } from '@apollo/client';
import PageTop from 'components/PageTop';
import SectionContainer from 'components/SectionContainer';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { formatDataForAccordion } from './helpers/format';
import Accordion from 'components/Accordion';
import GifForm from './Forms/GifForm/GifForm';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import { listPages } from 'utils/queries';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { cleanString, postWYSIWYG } from 'utils/functions';
import { arrayMove } from 'react-sortable-hoc';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(getPage);
export const LIST_GIFS = gql(queries.listGIFS);
export const GET_GIF = gql(queries.getGIF);
export const UPDATE_GIF = gql(mutations.updateGIF);
const SORT_ITEMS = gql(mutations.sortItemsV2);
export const LIST_PAGES = gql(listPages);
export const UPDATE_PAGE = gql(mutations.updatePage);
const VIEW_ITEMS = gql(mutations.viewItems);

// ! Removed the ability to sort and show/hide on purpose to reflect figma
// ! Check format.js to see removal
// ! Don't remove the functions on this page for when they add sort and hide/show back
const Manage404 = ({ toast }: IProps): JSX.Element => {
  const [gifSorts, setGifSorts] = useState<AccordionItems[]>([]);
  const [gifViews, setGifViews] = useState<{
    [key: string]: { newStatus: boolean; oldStatus: boolean };
  }>({});
  const pageFormRef = useRef<HTMLFormElement>(null);
  const [gettingGIF, setGettingGIF] = useState<null | string>(null);
  const [creatingGIF, setCreatingGIF] = useState<{ length: number } | null>(
    null
  );
  const [deletingGIF, setDeletingGIF] = useState(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);

  const {
    loading: pageLoading,
    refetch: pageRefetch,
    data: pageData,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-404',
    },
  });

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const {
    loading: gifLoading,
    refetch: gifRefetch,
    data: gifsData,
  } = useQuery(LIST_GIFS);

  const gifs = useMemo(() => {
    return gifsData?.listGIFS?.items;
  }, [gifsData]);

  const { data: gifData } = useQuery(GET_GIF, {
    variables: {
      id: gettingGIF,
    },
    skip: typeof gettingGIF !== 'string',
  });

  const gif = useMemo(() => {
    if (!gettingGIF) {
      return null;
    }
    return gifData?.getGIF || null;
  }, [gifData, gettingGIF]);

  const [sortItems] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('GIFs sorted');
    },
    onError: () => {
      toast.error('Error sorting pages');
    },
  });

  const [viewItems] = useMutation(VIEW_ITEMS, {
    onCompleted: () => {
      toast.success('GIFs updated');
    },
    onError: () => {
      toast.error('Error updating GIFs');
    },
  });

  const sortGIFs = useCallback(
    async (
      { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
      items?: any[]
    ) => {
      setGifSorts(() => {
        let newSort = [];
        if (items) {
          const probableChange = arrayMove(items, oldIndex, newIndex);
          const differenceAry = probableChange.slice().map(function (n, i) {
            return i - probableChange[i].index;
          });
          const isDifference = differenceAry.some((value) => value !== 0);
          if (isDifference) {
            newSort = probableChange;
          } else {
            newSort = [];
          }
        }
        return newSort;
      });
    },
    []
  );

  const handleActiveGIF = useCallback(
    async (id: string, newStatus: boolean, prevStatus: boolean) => {
      await setGifViews((prev) => {
        const newView = { ...prev };
        if (newStatus === prevStatus) {
          delete newView[id];
        } else {
          newView[id] = { newStatus, oldStatus: prevStatus };
        }
        return newView;
      });
    },
    []
  );

  useEffect(() => {
    // check if items were sorted or changed views
    if (gifSorts.length > 0 || Object.keys(gifViews).length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gifSorts, gifViews]);

  const accordionData: AccordionTypes | null = useMemo(() => {
    if (!gifs) return null;
    return formatDataForAccordion({
      gifs,
      createGIF: (length) => setCreatingGIF({ length }),
      updateGIF: setGettingGIF,
      activeGIF: handleActiveGIF,
      deleteGIF: setDeletingGIF,
      sortGIF: sortGIFs,
    });
  }, [gifs, handleActiveGIF, sortGIFs]);

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Page updated');
    },
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-404',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    const temp = [...gifSorts];
    const sendToUpdate: { id: string; sort: number }[] = [];
    if (temp) {
      temp.forEach((quicklink, index) => {
        if (quicklink.index !== index) {
          sendToUpdate.push({
            id: quicklink.id || '',
            sort: index,
          });
        }
      });
    }

    if (sendToUpdate.length > 0) {
      await sortItems({
        variables: { input: { tableName: 'GIF', items: sendToUpdate } },
      });
    }
    setGifSorts([]);

    const tempView = { ...gifViews };
    const sendToUpdateView: { id: string; active: boolean }[] = [];

    if (tempView) {
      Object.keys(tempView).forEach((key) => {
        const item = tempView[key];
        if (item.newStatus !== item.oldStatus) {
          sendToUpdateView.push({
            id: key,
            active: item.newStatus,
          });
        }
      });
    }
    console.log('sendToUpdateView', sendToUpdateView);

    if (sendToUpdateView.length > 0) {
      await viewItems({
        variables: {
          input: {
            tableName: 'GIF',
            items: sendToUpdateView,
            status: 'active',
          },
        },
      });
    }
    setGifViews({});
    //* setting it to refetch gets old data
    // gifRefetch();
    pageRefetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };
  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        data={data}
        slugList={[]}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Add & Edit GIF Backgrounds"
        description="Manage GIF images to be displayed on the background of the 404 page. GIFs will be selected at random upon activation."
      >
        {gifLoading || !accordionData ? (
          <></>
        ) : (
          <Accordion {...accordionData} />
        )}
      </SectionContainer>
      {gif && (
        <GifForm
          toggle={(refetch) => {
            if (refetch) {
              gifRefetch();
            }
            setGettingGIF(null);
          }}
          defaultValues={gif}
          toast={toast}
        />
      )}
      {creatingGIF && (
        <GifForm
          toggle={(refetch) => {
            if (refetch) {
              gifRefetch();
            }
            setCreatingGIF(null);
          }}
          length={gifs?.length || 0}
          toast={toast}
        />
      )}
      {deletingGIF && (
        <DeleteForm
          id={deletingGIF}
          toggle={(refetch) => {
            if (refetch) {
              gifRefetch();
            }
            setDeletingGIF(null);
          }}
          type="gif"
          toast={toast}
        />
      )}
    </Container>
  );
};

export default Manage404;
