import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PageTop from 'components/PageTop';
import { gql, useMutation } from '@apollo/client';
import { Page } from 'API';
import { SlugI } from 'types/SlugListType';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as mutations from 'graphql/mutations';
import { cleanString, postWYSIWYG, viewWYSIWYG } from 'utils/functions';
import SectionContainer from 'components/SectionContainer';
import { Form, WYSIWYGInput } from './style';

const UPDATE_PAGE = gql(mutations.updatePage);

interface Props {
  data: Page;
  toast: {
    success: (str: string) => void;
    error: (str: string) => void;
  };
  handleRefetch: () => void;
  slugList: SlugI[];
}

const PlainPage = ({ data, toast, slugList }: Props): JSX.Element => {
  const pageFormRef = useRef<HTMLFormElement>(null);
  const inputFormRef = useRef<HTMLFormElement>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [bodyData, setBodyData] = useState<any>();
  const [showRTE, updateShowRTE] = useState<boolean>(false);
  const [dontDirty, setDontDirty] = useState<boolean>(false);

  const methods = useForm();

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onError: () => {
      toast.error('Error updating page');
    },
  });

  const templateData = useMemo(() => {
    return data?.pages?.items[0];
  }, [data]);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = (props) => {
    // remove aws wysiwyg img src and leave behind only keys
    console.log('props', props);
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    delete props.templatePlain;

    updatePage({
      variables: {
        input: {
          id: data.id,
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });
    toast.success('Page updated');
  };

  const templateDirty = useCallback(() => {
    return methods?.formState?.isDirty;
  }, [methods]);

  useEffect(() => {
    // update pageTop to know when template body is changed
    if (templateDirty()) {
      if (!isDirtyForm) {
        setIsDirtyForm(true);
      }
    } else {
      setIsDirtyForm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, methods?.formState?.isDirty]);

  const submitAllForms = () => {
    // trigger submit function from refs
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
    if (inputFormRef.current) {
      inputFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  const inputOnSubmit: SubmitHandler<PageTopTypes> = (props): void => {
    const template = postWYSIWYG(props?.templatePlain || '');
    const templateClean = cleanString(template);

    updatePage({
      variables: {
        input: {
          id: templateData?.id,
          body: template,
          bodyLow: templateClean,
        },
      },
    });
  };

  useEffect(() => {
    const getData = async () => {
      setDontDirty(true);
      const newContent = await viewWYSIWYG(templateData?.body || '');
      setBodyData(newContent);
      updateShowRTE(true);
      setTimeout(() => {
        setDontDirty(false);
      }, 1200);
    };
    getData();
  }, [templateData]);

  return (
    <>
      <PageTop
        ref={pageFormRef}
        data={data}
        slugList={slugList}
        submitAllForms={submitAllForms}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={setIsDirtyForm}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        title="Inline Copy &amp; Text"
        description="Click on the content you would like to edit."
      >
        <Form
          noValidate
          onSubmit={methods.handleSubmit(inputOnSubmit)}
          ref={inputFormRef}
        >
          {showRTE ? (
            <WYSIWYGInput
              {...methods}
              label="Inline Body Text"
              id="templatePlain"
              name="templatePlain"
              required={false}
              optional={true}
              placeholder="Enter Body Text Here"
              defaultValue={bodyData}
              error={methods?.formState?.errors?.inlineBody}
              dontDirty={dontDirty}
            />
          ) : (
            <></>
          )}
        </Form>
      </SectionContainer>
    </>
  );
};

export default PlainPage;
