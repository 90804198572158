import React, { useState } from 'react';
import { IProps } from './GifForm.types';
import Modal from 'containers/Modal';
import {
  Form,
  SubmitButton,
  CancelButton,
  ButtonContainer,
  DeleteButton,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import { useForm } from 'react-hook-form';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import ImageInput from 'components/FormComponents/ImageInput';
import { Storage } from 'aws-amplify';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

export const CREATE_GIF = gql(mutations.createGIF);
export const UPDATE_GIF = gql(mutations.updateGIF);

const GifForm = ({
  toast,
  defaultValues,
  toggle,
  length,
}: IProps): JSX.Element => {
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deletingGIF, setDeletingGIF] = useState(false);
  const { id } = defaultValues || {};
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues,
  });

  const [createGIF] = useMutation(CREATE_GIF, {
    onCompleted: () => {
      toast.success('GIF created');
    },
    onError: () => {
      toast.error('Error creating GIF');
    },
  });

  const [updateGIF] = useMutation(UPDATE_GIF, {
    onCompleted: () => {
      toast.success('GIF updated');
    },
    onError: () => {
      toast.error('Error updating GIF');
    },
  });

  return (
    <>
      <Modal
        id="sectionModal"
        title={id ? 'Edit Background GIF' : 'Add a New Background GIF'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          onSubmit={handleSubmit(async (input) => {
            if (!input.image) {
              setError('image', { type: 'manual' });
              return;
            }
            setSaving(true);
            let uidFile: string | '' = defaultValues?.image;
            if (!input.image.name) {
              // image hasnt changed leave for now
            } else if (input.image.name !== defaultValues?.image) {
              uidFile = input.image?.name || '';
              if (input.image) {
                uidFile = `${Math.floor(Math.random() * 10000)}-${
                  input.image?.name
                }`;
                await Storage.put(uidFile, input.image, {
                  contentType: input.image?.type || '',
                });
              }
            }

            if (id) {
              await updateGIF({
                variables: {
                  input: {
                    id,
                    title: input.title,
                    image: uidFile,
                  },
                },
              });
            } else {
              await createGIF({
                variables: {
                  input: {
                    title: input.title,
                    image: uidFile,
                    active: true,
                    sort: length,
                  },
                },
              });
            }
            setSaving(false);
            setTimeout(() => {
              toggle(true);
            }, 500);
          })}
        >
          <ImageInput
            setFile={(file: File) => setValue('image', file)}
            required={true}
            serverImage={defaultValues?.image}
            label="Background GIF Image"
            error={!!errors?.image}
            errorMessage="File type not supported. Must be formatted in .gif."
            fileTypes="image/gif"
            fileMaxSize={5000000}
            dropzoneLabel="Upload Image"
            dropzoneDisclaimer=".GIF are allowed"
            clearErrors={() => clearErrors('image')}
            type="rect"
            name="image"
          />
          <TextInput
            id="title"
            maxLength={50}
            required
            hideRequired={true}
            name="title"
            label="CMS Display Name"
            placeholder="Enter GIF Name Here"
            register={register}
            setError={(title, error) => setError('title', error)}
            clearErrors={() => clearErrors('title')}
            error={!!errors?.title}
            errorMessage="Enter a title"
            disclaimer="For CMS purposes only; will not display on page."
            defaultValue={defaultValues?.title || ''}
            setValue={(name, value) => setValue(name, value)}
          />
          <ButtonContainer>
            <SubmitButton type="submit" className="ModalSubmit">
              {saving ? 'Saving...' : id ? 'Save' : 'Create'}
            </SubmitButton>
            <CancelButton
              type="button"
              className="Modalcancel"
              value="Cancel"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => {
                  setDeletingGIF(true);
                }}
              >
                Delete GIF
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {deletingGIF && (
        <DeleteForm
          preventUnlock={true}
          toggle={() => toggle(true)}
          toggleDelete={() => setDeletingGIF(false)}
          toast={toast}
          type="gif"
          id={defaultValues?.id}
        />
      )}
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default GifForm;
