import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';

export const Form = styled.form`
  min-width: 33%;
  display: grid;
  grid-row-gap: 32px;
`;

export const FormContent = styled.div`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const HR = styled.hr`
  border: none;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
  margin-right: auto;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
`;

export const Message = styled.div`
  margin: 0 4em;
  height: 50px;
  color: ${colors.primary};
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
`;
