import React from 'react';
import { Link } from 'react-router-dom';
import { StyledButton, Label, IconWrapper } from './styles';
import ButtonTypes from './Button.types';
import isTouchDevice from 'is-touch-device';

const isExternalLink = (url: string) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

const Button = ({
  Icon,
  href,
  iconClass,
  children,
  ...buttonProps
}: ButtonTypes): JSX.Element => {
  const touchable = isTouchDevice();

  const hasIcon = Icon !== undefined;
  // External Link
  if (href && isExternalLink(href)) {
    return (
      <StyledButton as={'a'} href={href} data-touchable={touchable}>
        {Icon && (
          <IconWrapper className={iconClass}>
            <Icon />
          </IconWrapper>
        )}
        {children && <Label hasIcon={hasIcon}>{children}</Label>}
      </StyledButton>
    );
  }

  // Internal Link
  if (href && !isExternalLink(href)) {
    return (
      <StyledButton as={Link} to={href} data-touchable={touchable}>
        {Icon && (
          <IconWrapper className={iconClass}>
            <Icon />
          </IconWrapper>
        )}
        {children && <Label hasIcon={hasIcon}>{children}</Label>}
      </StyledButton>
    );
  }

  // Regular Button
  return (
    <StyledButton {...buttonProps} data-touchable={touchable}>
      {Icon && (
        <IconWrapper className={iconClass}>
          <Icon />
        </IconWrapper>
      )}
      {children && <Label hasIcon={hasIcon}>{children}</Label>}
    </StyledButton>
  );
};

export default Button;
