import styled from 'styled-components';
import { colors, padding } from 'shared/variables';
import { bodyCopyBold } from 'shared/extends';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 40px;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;
export const AddItem = styled.div`
  ${bodyCopyBold};
  color: ${colors.highlight};
  padding: ${padding.listItem.padding}rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
`;
