import { Freelancer } from 'API';
import AccordionTypes from 'components/Accordion/Accordion.types';
interface IFormat {
  freelancers: Freelancer[];
  createFreelancer: (length: number) => void;
  updateFreelancer: (id: string) => void;
  deleteFreelancer: (id: any) => void;
}

export const formatDataForAccordion = ({
  freelancers,
  createFreelancer,
  updateFreelancer,
  deleteFreelancer,
}: IFormat): AccordionTypes | null => {
  const formatFreelancer = (freelancer: Freelancer, index: number) => {
    return {
      id: freelancer.id,
      index: index,
      originalIndex: index,
      title: freelancer.name || '',
      key: `person.id${Math.random()}`,
      editFunc: () => {
        updateFreelancer(freelancer.id);
      },
      deleteFunc: () => {
        deleteFreelancer(freelancer.id);
      },
    };
  };

  return {
    accordionContainer: freelancers
      .slice()
      ?.sort((x: Freelancer, y: Freelancer) => {
        if ((x?.name?.toLowerCase() || '') < (y?.name?.toLowerCase() || '')) {
          return -1;
        }
        if ((x?.name?.toLowerCase() || '') > (y?.name?.toLowerCase() || '')) {
          return 1;
        }
        return 0;
      })
      ?.map(formatFreelancer),
    addFunc: () => {
      createFreelancer(freelancers?.length || 0);
    },
    addFuncText: 'Add Additional Freelancers',
  };
};
