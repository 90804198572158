import { Link, NavLink } from 'react-router-dom';
import { eyebrow } from 'shared/extends';
import { size } from '../../shared/mixins';
import { Body, font, fontSize } from 'shared/typography';
import { colors, customBezierEase } from 'shared/variables';
import styled, { keyframes } from 'styled-components';
import { IconBlob } from 'components/IconsView';

export const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 40px 35px 0px;
`;

export const LogoWrapper = styled.div`
  &:hover {
    div {
      visibility: visible;
    }
  }
`;

export const LogoHoverWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  left: 7px;
  top: 20px;

  svg {
    overflow: visible;
    ${size('103px', '103px')}
    stroke: ${colors.highlight};
  }
`;

export const Title = styled(Body)`
  margin-top: 18px;
  text-transform: uppercase;
  color: ${colors.brownMedium};
  padding-bottom: 25px;
  font-weight: bold;
`;

export const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin-right: 10px;
`;

export const StyledLink = styled(NavLink)`
  ${eyebrow};
  color: ${colors.primary};
  margin-bottom: 16px;
  text-decoration: none;
  transition: 0.3s ${customBezierEase};

  &.active {
    color: ${colors.brownLight};
    pointer-events: none;
  }

  &:hover {
    color: ${colors.highlight};
  }
`;

export const Nav = styled.nav`
  ${StyledLink} {
    display: block;
  }
`;

export const CatPage = styled.h6`
  color: ${colors.brownMedium};
  margin: 0 0 16px;
  ${font('primary', 'black')};
  ${fontSize(16, 20)};
`;

export const UserContainer = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyLightLight};
  margin-bottom: 48px;

  svg {
    flex: 0 0 auto;
  }
`;

export const UserInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

export const UserLabel = styled(Body).attrs({ bold: true })`
  text-transform: uppercase;
  word-break: break-word;
  color: ${colors.brownLight};
`;

export const UserLogout = styled.a`
  color: ${colors.primary};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: left;
  ${font('secondary')};
  ${fontSize(10, 20, 1.4)};
  transition: 0.3s ${customBezierEase};

  &:hover {
    color: ${colors.highlight};
  }
`;

export const BlobContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 50%;
  top: 50%;
  opacity: 1;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.75s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 10;
  ${size('250px')}

  &.is-active {
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const Blob = styled(IconBlob)`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  position: absolute;
  ${size('100%')}

  path {
    stroke: ${colors.highlight};
  }
`;

const twistAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const HomeLink = styled(Link)`
  position: relative;
  display: inline-block;

  &:hover {
    ${BlobContainer} {
      transform: translate(-50%, -50%) scale(1);
    }
    ${Blob} {
      animation: ${twistAnimation} 30s infinite alternate-reverse linear;
    }
  }
`;
