import { Page, PageLayout, PageStatus } from 'API';
import Modal from 'containers/Modal';
import React, { useState } from 'react';
import IToast from 'types/ToastType';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  Input,
  SubmitButton,
  WYSIWYGInput,
} from './styles';
import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import { checkWYSIWYG, cleanString, postWYSIWYG } from 'utils/functions';
import slugify from 'slugify';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

interface Props {
  data?: Page;
  toggle: (refetch?: boolean) => void;
  toast: IToast;
  parentId?: string;
  length?: number;
}

const CREATE_PAGE = gql(mutations.createPage);
const UPDATE_PAGE = gql(mutations.updatePage);

const TabModal = ({
  data,
  toggle,
  toast,
  length,
  parentId,
}: Props): JSX.Element => {
  const [deletingTab, setDeletingTab] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const methods = useForm<any>({
    defaultValues: {
      title: data?.title,
      body: data?.body,
      id: data?.id,
    },
  });
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = methods;

  const [createPage] = useMutation(CREATE_PAGE, {
    onCompleted: () => {
      toast.success('Tab created');
      toggle(true);
    },
    onError: () => {
      toast.error('Error creating tab');
    },
  });

  const [updatePage] = useMutation(UPDATE_PAGE, {
    onCompleted: () => {
      toast.success('Tab updated');
      toggle(true);
    },
    onError: () => {
      toast.error('Error updating tab');
    },
  });

  return (
    <>
      <Modal
        id="sectionModal"
        title={data?.id ? 'Edit Tab' : 'Add New Tab'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('Add Form', input);

            if (checkWYSIWYG(input.body)) {
              setError('body', {
                type: 'required',
                message: 'Fill out this field',
              });
              return;
            }

            delete input.__typename;
            delete input.quicklinks;
            delete input.pages;
            const shortBody = postWYSIWYG(input.body);
            const cleanShortBody = cleanString(shortBody);
            if (data?.id) {
              await updatePage({
                variables: {
                  input: {
                    id: data.id,
                    ...input,
                    updatedAt: new Date().toISOString(),
                    label: input.title,
                    body: shortBody,
                    bodyLow: cleanShortBody.toLowerCase(),
                  },
                },
              });
            } else {
              await createPage({
                variables: {
                  input: {
                    ...input,
                    label: input.title,
                    body: shortBody,
                    bodyLow: cleanShortBody.toLowerCase(),
                    sort: length || 0,
                    layout: PageLayout.template,
                    status: PageStatus.active,
                    slug: slugify(`${input.title} Template`).toLowerCase(),
                    pageId: parentId || null,
                  },
                },
              });
            }
          })}
        >
          <Input
            name="title"
            label="Tab Title"
            register={register}
            setError={setError}
            setValue={methods.setValue}
            clearErrors={clearErrors}
            error={!!errors?.title}
            errorMessage="This is a required field"
            placeholder="Enter Tab Title Here"
            maxLength={50}
            required={true}
            hideRequired={true}
            defaultValue={data?.title}
            disclaimer="This is the label for the tab that will be displayed on the page"
          />
          <WYSIWYGInput
            {...methods}
            label="Tab Content"
            id="template"
            name="body"
            required={true}
            // hideRequired={true}
            placeholder="Enter Tab Content Here"
            defaultValue={data?.body || ''}
            setValue={methods.setValue}
            error={methods?.formState?.errors?.body}
            waitForToolbar={true}
          />
          <ButtonContainer>
            <SubmitButton type="submit">
              {data?.id ? 'Save' : 'Create Tab'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {data?.id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingTab(true)}
              >
                Delete
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingTab && (
        <DeleteForm
          preventUnlock={true}
          type="page"
          id={data?.id || ''}
          toast={toast}
          toggle={(refetch) => {
            if (refetch) {
              toggle(refetch);
            }
            setDeletingTab(false);
          }}
          toggleDelete={() => setDeletingTab(false)}
        />
      )}
    </>
  );
};

export default TabModal;
