import { Page, PageStatus } from 'API';

interface IFormat {
  tabs: (Page | null)[];
  addTab: (length: number) => void;
  updateTab: (id: string) => void;
  deleteTab: (id: string) => void;
  sortTabs: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[]
  ) => void;
  activeTabs: (id: string, newStatus: boolean, prev: PageStatus) => void;
}

export const formatDataForAccordion = ({
  tabs,
  addTab,
  updateTab,
  deleteTab,
  sortTabs,
  activeTabs,
}: IFormat): any => {
  const formatTab = (tab: Page | any, index: number): any => {
    return {
      id: tab.id,
      title: tab.title,
      index: index,
      originalIndex: index,
      sortable: true,
      editFunc: () => {
        updateTab(tab.id);
      },
      deleteFunc: () => {
        deleteTab(tab.id);
      },
      active: tab.status === 'active',
      activeFunc: (newActive: boolean) => {
        activeTabs(tab.id, newActive, tab.status);
      },
    };
  };

  console.log(tabs);

  return {
    accordionContainer: tabs
      .slice()
      .sort((x: Page | null, y: Page | null) => {
        return (x?.sort || 0) - (y?.sort || 0);
      })
      .map(formatTab),
    addFuncText: 'Add New Tab',
    addFunc: () => {
      addTab(tabs.length || 0);
    },
    onSortEnd: (
      {
        oldIndex,
        newIndex,
      }: {
        oldIndex: number;
        newIndex: number;
      },
      items?: any[]
    ) => {
      sortTabs({ oldIndex, newIndex }, items);
    },
  };
};
