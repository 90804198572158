import { gql } from '@apollo/client';
export const listLocationByType = /* GraphQL */ `
  query Query {
    listLocationByType(type: "faq") {
      items {
        id
        sort
        officeID
        office {
          name
          id
          faqSections {
            items {
              id
              officeID
              sort
              title
              faqs {
                items {
                  id
                  answer
                  sort
                  question
                }
              }
              sort
            }
          }
        }
      }
    }
  }
`;

export const LIST_FAQ_BY_TYPE = gql(`
query Query {
  listLocationByType(type: "faq") {
    items {
      id
      sort
      officeID
      office {
        name
        id
        faqSections {
          items {
            id
            officeID
            sort
            title
            faqs {
              items {
                id
                answer
                sort
                question
              }
            }
            sort
          }
        }
      }
    }
  }
}
`);

export const getFAQsection = /* GraphQL */ `
  query GetFAQsection($id: ID!) {
    getFAQsection(id: $id) {
      id
      title
      sort
      createdAt
      updatedAt
      faqs {
        items {
          id
          question
        }
      }
    }
  }
`;

export const listFAQSections = /* GraphQL */ `
  query ListFAQsections(
    $filter: ModelFAQsectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQsections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        sort
        createdAt
        updatedAt
        faqs {
          items {
            id
            question
            answer
            sort
            officeIDs
          }
        }
      }
      nextToken
    }
  }
`;
