import {
  createMember,
  createOffice,
  deleteMember,
  deleteOffice,
  updateMember,
  updateOffice,
} from 'graphql/mutations';
import { getMember } from 'graphql/queries';
import { gql } from '@apollo/client';

export const LIST_LOCATION_BY_TYPE_MEMBER = gql(`
  query listLocationByType {
    listLocationByType(type: "member") {
      items {
        id
        officeID
        sort
        active
        type
        office {
          id
          name
          members {
            items {
              id
              name
              title
              officeID
              sort
              email
              description
              active
              phone
              image
            }
          }
        }
      }
    }
  }
`);
export const LIST_LOCATION_BY_TYPE_FAQ = gql(`
  query listLocationByType {
    listLocationByType(type: "faq") {
      items {
        id
        officeID
        sort
        active
        type
        office {
          id
          name
          members {
            items {
              id
              name
              title
              officeID
              sort
              email
              description
              active
              phone
              image
            }
          }
        }
      }
    }
  }
`);

export const CREATE_OFFICE = gql(createOffice);
export const DELETE_OFFICE = gql(deleteOffice);
export const UPDATE_OFFICE = gql(updateOffice);
export const CREATE_MEMBER = gql(createMember);
export const DELETE_MEMBER = gql(deleteMember);
export const UPDATE_MEMBER = gql(updateMember);
export const GET_MEMBER = gql(getMember);
