import React, { useRef } from 'react';
import { Container, Error } from './styles';
import classNames from 'classnames';
import CheckboxTypes from './Checkbox.types';
import { IconWarning } from 'components/IconsView';

const Checkbox = ({
  label,
  setChecked,
  name = '',
  errorMessage,
  register,
  error,
  className,
  ...checkboxProps
}: CheckboxTypes): JSX.Element => {
  const { required, id, onChange } = checkboxProps;
  const ContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={ContainerRef} className={classNames(className)}>
      <input
        {...checkboxProps}
        className={classNames({ error: error })}
        {...register(name, { required })}
        type="checkbox"
        defaultChecked={setChecked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <span></span> {label}
      </label>
      {error && (
        <Error className="error">
          <IconWarning />
          {errorMessage || 'Fill out this field'}
        </Error>
      )}
    </Container>
  );
};

export default Checkbox;
