import Modal from 'containers/Modal';
import React, { useState } from 'react';
import { IProps } from './AddYearForm.types';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  Form,
  LineBreak,
  Preview,
  PreviewContainer,
  SubmitButton,
  TabButton,
  TabButtonFake,
  TabButtonInput,
  TabButtonsContainer,
} from './styles';
import { gql, useMutation } from '@apollo/client';
import { createYear } from 'graphql/mutations';
import Label from 'components/FormComponents/Label';
import dayjs from 'dayjs';
import YearSelector from 'components/FormComponents/YearSelector';
import classNames from 'classnames';
import { Error } from 'components/FormComponents/TextInput/styles';
import { IconWarning } from 'components/IconsView';

const CREATE_YEAR = gql(createYear);

const AddYearForm = ({ toast, toggle, length, years }: IProps): JSX.Element => {
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [type, setType] = useState('auto');
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({ defaultValues: { year: null } });
  const upcomingYear = dayjs().year() + 1;
  const checkUpcomingYear = years.find((item) =>
    dayjs().add(1, 'year').isSame(dayjs(item.title), 'year')
  );

  const [addYear] = useMutation(CREATE_YEAR, {
    onCompleted: () => {
      toast.success('Year created');
      toggle();
    },
    onError: () => {
      toast.error('Error creating year');
    },
  });

  const checkYear = (year: number) => {
    return (
      years.findIndex((item) =>
        dayjs(item.date).isSame(dayjs().year(year), 'year')
      ) > -1
    );
  };

  return (
    <>
      <Modal
        id="sectionModal"
        title={'Add Year'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('input', input);
            const chosenYear =
              type === 'auto' ? dayjs().year() + 1 : input.year;
            if (checkYear(parseInt(chosenYear))) {
              setError('year', {
                type: 'manual',
                message: 'This year has already been added',
              });
              return;
            }

            await addYear({
              variables: {
                input: {
                  title: chosenYear,
                  date: dayjs().year(chosenYear).toISOString(),
                  sort: length,
                },
              },
            });
          })}
        >
          <TabButtonsContainer>
            <TabButton htmlFor="auto">
              <TabButtonInput
                type="radio"
                id="auto"
                name="type"
                value="auto"
                onChange={() => setType('auto')}
                defaultChecked={true}
              />
              <TabButtonFake>Upcoming Year</TabButtonFake>
            </TabButton>
            <TabButton htmlFor="manual">
              <TabButtonInput
                type="radio"
                id="manual"
                name="type"
                value="manual"
                onChange={() => setType('manual')}
              />
              <TabButtonFake>Select Manually</TabButtonFake>
            </TabButton>
          </TabButtonsContainer>
          <LineBreak />
          {type === 'auto' ? (
            <PreviewContainer>
              <Label
                className={classNames({
                  error: checkUpcomingYear,
                })}
                name="preview"
              >
                Preview
              </Label>
              <Preview
                className={classNames({
                  error: checkUpcomingYear,
                })}
              >
                {upcomingYear}
              </Preview>
              {checkUpcomingYear ? (
                <Error className="error">
                  <IconWarning />
                  This year has already been added
                </Error>
              ) : null}
            </PreviewContainer>
          ) : (
            <YearSelector
              years={years}
              label="Select the Year"
              name="year"
              required
              register={register}
              setError={(title, error) => setError('year', error)}
              clearErrors={() => clearErrors('year')}
              error={!!errors?.year}
              setValue={(name, value) =>
                setValue('year', value, { shouldDirty: true })
              }
              errorMessage="Select a year"
              defaultValue={dayjs().year()}
            />
          )}
          <ButtonContainer>
            <SubmitButton type="submit">Create</SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
    </>
  );
};

export default AddYearForm;
