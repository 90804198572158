import Button from 'components/Button';
import styled from 'styled-components';

export const Form = styled.form`
  min-width: 33%;
  display: grid;
  grid-row-gap: 32px;
`;

export const FormContent = styled.div`
  width: 320px;
  display: grid;
  grid-row-gap: 32px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
  margin-right: auto;
`;

export const DeleteButton = styled(Button)`
  align-self: end;
`;
