import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button';
import { ModalContainer, SelectWrapper } from './styles';
import Select from 'components/FormComponents/Select/Select';
import { SectionDataI } from 'containers/FAQsContainer';
import { FAQsection } from 'API';
import { Body } from 'shared/typography';
import styled from 'styled-components';
import { colors } from 'shared/variables';

interface MigrateFormI {
  toggle: () => void;
  toggleDelete?: () => void;
  preventUnlock?: boolean;
  section: FAQsection;
  sectionData: SectionDataI[];
  onMigrate: (migrateSectionId: string) => void;
}

interface OptionsI {
  value: string;
  label: string;
}

export const Description = styled(Body)`
  display: block;
  color: ${colors.brownLight};
  margin-bottom: 30px;
`;

// Form used in FAQ Section deletion
const MigrateForm = ({
  toggle,
  toggleDelete,
  preventUnlock,
  section,
  sectionData,
  onMigrate,
}: MigrateFormI): JSX.Element => {
  const [migrateSectionId, setMigrateSectionId] = useState('');
  const [sortedOptions, setSortedOptions] = useState<OptionsI[]>([]);

  // Sort categories and get id and label of all other categories
  const getOptions = useCallback(() => {
    const sortedSectionData = sectionData
      .sort((a, b) => a.sort - b.sort)
      .filter((a) => a.id != section?.id);
    const sectionMap = sortedSectionData.map((sect) => {
      return {
        value: sect.id,
        label: sect.title,
      };
    });
    return sectionMap;
  }, [sectionData, section]);

  useEffect(() => {
    const options = getOptions();
    if (options.length) {
      setMigrateSectionId(options[0].value);
      setSortedOptions(options);
    }
  }, [getOptions]);

  const hasOptions = sortedOptions.length;

  return (
    <ModalContainer
      preventUnlock={preventUnlock}
      id="sectionModal"
      title={
        hasOptions
          ? `Are you sure you want to DELETE the ${section.title} Category?`
          : `Unable to delete ${section.title}.`
      }
      description={hasOptions ? 'Warning: This cannot be undone.' : ''}
      toggle={() => {
        if (toggleDelete) {
          toggleDelete();
        } else {
          toggle();
        }
      }}
    >
      {hasOptions ? (
        <SelectWrapper>
          <Select
            label={'Choose destination for existing questions'}
            options={sortedOptions}
            setValue={() => {}}
            setError={() => {}}
            clearErrors={() => {}}
            customChangeEvent={setMigrateSectionId}
          />
        </SelectWrapper>
      ) : (
        <Description>
          This category contains questions. Create an additional category and
          then re-attempt to delete {section.title} in order to migrate those
          existing questions.
        </Description>
      )}
      {hasOptions ? (
        <>
          <Button
            type="button"
            onClick={() => {
              onMigrate(migrateSectionId);
            }}
          >
            Yes, Migrate and Delete
          </Button>
          <Button
            type="button"
            tertiary
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button
          type="button"
          onClick={() => {
            toggle();
          }}
        >
          Close
        </Button>
      )}
    </ModalContainer>
  );
};

export default MigrateForm;
