import Modal from 'containers/Modal';
import React, { useState } from 'react';
import { IProps } from './UpdateYearForm.types';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  SubmitButton,
} from './styles';

import { gql, useMutation } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import dayjs from 'dayjs';
import YearSelector from 'components/FormComponents/YearSelector';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import HaltForm from 'components/Forms/HaltForm/HaltForm';

const UPDATE_YEAR = gql(mutations.updateYear);

const UpdateYearForm = ({
  toast,
  toggle,
  defaultValues,
  years,
}: IProps): JSX.Element => {
  const { id } = defaultValues || {};
  const [deletingYear, setDeletingYear] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({ defaultValues });

  const checkYear = (year: number) => {
    return (
      years.findIndex((item) =>
        dayjs(item.date).isSame(dayjs().year(year), 'year')
      ) > -1 && !dayjs().year(year).isSame(dayjs(defaultValues?.date), 'year')
    );
  };

  const [updateYear] = useMutation(UPDATE_YEAR, {
    onCompleted: () => {
      toast.success('Year updated');
      toggle();
    },
    onError: () => {
      toast.error('Error updating year');
    },
  });

  let hasChildren = false;
  if (defaultValues?.holidays?.items) {
    hasChildren = (defaultValues?.holidays?.items?.length || 0) > 0;
  }

  return (
    <>
      <Modal
        id="sectionModal"
        title={'Edit Year'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          onSubmit={handleSubmit(async (input) => {
            console.log('input', input);
            const chosenYear = `${input.year}`;

            if (checkYear(parseInt(chosenYear))) {
              setError('year', {
                type: 'manual',
                message: 'This year has already been added',
              });
              return;
            }

            await updateYear({
              variables: {
                input: {
                  id,
                  title: chosenYear,
                  date: dayjs(chosenYear, 'YYYY').toISOString(),
                },
              },
            });
          })}
        >
          <YearSelector
            selectYear={parseInt(defaultValues?.title || '')}
            years={years}
            label="Select the Year"
            name="year"
            defaultValue={parseInt(defaultValues?.title || '')}
            register={register}
            setError={(title, error) => setError('year', error)}
            clearErrors={() => clearErrors('year')}
            error={!!errors?.year}
            setValue={(name, value) =>
              setValue('year', value, { shouldDirty: true })
            }
            errorMessage="Select a year"
          />
          <ButtonContainer>
            <SubmitButton type="submit">Save</SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              type="button"
              // disabled={hasChildren}
              delete
              onClick={() => setDeletingYear(true)}
            >
              Delete Year
            </DeleteButton>
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingYear && hasChildren && (
        <HaltForm
          preventUnlock={true}
          toggle={toggle}
          toggleDelete={() => setDeletingYear(false)}
        />
      )}
      {deletingYear && !hasChildren && (
        <DeleteForm
          preventUnlock={true}
          type="year"
          id={defaultValues?.id ? defaultValues?.id : ''}
          toast={toast}
          toggle={toggle}
          toggleDelete={() => setDeletingYear(false)}
        />
      )}
    </>
  );
};

export default UpdateYearForm;
