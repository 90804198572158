import { GIF } from 'API';
import AccordionTypes from 'components/Accordion/Accordion.types';
interface IFormat {
  gifs: GIF[];
  createGIF: (length: number) => void;
  updateGIF: (id: string) => void;
  activeGIF: (id: string, newStatus: boolean, prev: boolean) => void;
  deleteGIF: (id: any) => void;
  sortGIF: (
    {
      oldIndex,
      newIndex,
    }: {
      oldIndex: number;
      newIndex: number;
    },
    items?: any[]
  ) => void;
}

export const formatDataForAccordion = ({
  gifs,
  createGIF,
  updateGIF,
  deleteGIF,
}: IFormat): AccordionTypes | null => {
  const formatGIF = (gif: GIF, index: number) => {
    return {
      id: gif.id,
      index: index,
      originalIndex: index,
      title: gif.title || '',
      active: gif.active,
      editFunc: () => {
        updateGIF(gif.id);
      },
      // activeFunc: (newActive: boolean) => {
      //   activeGIF(gif.id, newActive, gif.active || false);
      // },
      deleteFunc: () => {
        deleteGIF(gif.id);
      },
    };
  };

  return {
    accordionContainer: gifs
      .slice()
      ?.sort((x: GIF, y: GIF) => {
        if ((x?.title?.toLowerCase() || '') < (y?.title?.toLowerCase() || '')) {
          return -1;
        }
        if ((x?.title?.toLowerCase() || '') > (y?.title?.toLowerCase() || '')) {
          return 1;
        }
        return 0;
      })
      ?.map(formatGIF),
    addFunc: () => {
      createGIF(gifs?.length || 0);
    },
    addFuncText: 'Add Additional GIFs',
    // onSortEnd: (
    //   {
    //     oldIndex,
    //     newIndex,
    //   }: {
    //     oldIndex: number;
    //     newIndex: number;
    //   },
    //   items?: any[]
    // ) => {
    //   sortGIF({ oldIndex, newIndex }, items);
    // },
  };
};
