import styled from 'styled-components';
import { colors } from 'shared/variables';
import { font, fontSize } from 'shared/typography';
import Button from 'components/Button';
import { bodyCopy } from 'shared/extends';
import { size } from 'shared/mixins';
import Modal from 'containers/Modal';

export const ModalContainer = styled(Modal)`
  max-width: 912px;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(2, minmax(316px, 1fr));
  grid-column-gap: 96px;
  grid-row-gap: 32px;
`;
export const HR = styled.hr`
  border: none;
  border-top: 1px solid ${colors.greyLightLight};
`;

export const ButtonContainer = styled.div`
  margin-top: 8px;
  grid-column: span 2;
  display: flex;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)`
  margin-left: 15px;
`;

export const DeleteButton = styled(Button)`
  margin-left: auto;
`;

export const Message = styled.div`
  margin: 0 4em;
  height: 50px;
  color: ${colors.primary};
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
`;

export const Preview = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.greyLightLight};
  padding: 23px 63px 26px 20px;
  overflow-wrap: break-word;
`;

export const PreviewContainer = styled.div`
  grid-column: 2;
  grid-row: 1 / span 5;
`;

export const Title = styled.h4`
  grid-column: 1 / span 3;
  color: ${colors.brownLight};
  margin: 0 0 5px;
  ${font('primary', 'black')};
  ${fontSize(24, 27)};
`;

export const Description = styled.p`
  ${bodyCopy};
  grid-column: 1 / span 3;
  color: ${colors.brownLight};
  margin: 0 0 10px;
`;

export const PageLink = styled.span`
  ${bodyCopy};
  grid-column: 1 / span 3;
  align-items: center;
  color: ${colors.highlight};
  display: flex;
  margin: 5px 0 0;
  text-decoration: none;
  display: block;

  svg {
    fill: ${colors.highlight};
    margin-left: 9px;
    ${size('12px', '5px')};
  }
`;
