import SectionContainer from 'components/SectionContainer';
import TextInput from 'components/FormComponents/TextInput';
import React from 'react';
import { handleSlugCheck } from 'utils/functions';
import { SlugI } from 'types/SlugListType';

interface Props {
  title?: string;
  slug?: string;
  // description?: string;
  id?: string;
  methods: any;
  slugList: SlugI[];
}

const Meta = ({
  title,
  slug,
  // description,
  id,
  methods,
  slugList,
}: Props): JSX.Element => {
  // const [customError, setCustomError] = useState<boolean | null>(null);

  return (
    <SectionContainer
      title="Meta"
      description="Click on the content you would like to edit."
    >
      <TextInput
        register={methods.register}
        setError={methods.setError}
        clearErrors={methods.clearErrors}
        setValue={methods.setValue}
        label="Page Title"
        id="title"
        name="title"
        required={true}
        hideRequired={true}
        maxLength={50}
        placeholder="Enter Page Title Text Here"
        defaultValue={title}
        error={methods.formState?.errors?.title}
        wrapperClassName="form-input"
      />
      <TextInput
        register={methods.register}
        setError={methods.setError}
        clearErrors={methods.clearErrors}
        setValue={methods.setValue}
        label="Slug"
        id="slug"
        name="slug"
        required={true}
        hideRequired={true}
        placeholder="slug"
        defaultValue={slug}
        error={
          methods.formState?.errors?.slug ||
          methods.formState?.errors?.slugValid
        }
        errorMessage="Enter a unique slug"
        onBlur={(e) => {
          const value = e.target.value;
          handleSlugCheck({
            value,
            slugList,
            id: id || '',
            callbackFunc: (v) => {
              if (v) {
                methods.setError('slugValid', {
                  type: 'manual',
                  message: 'Enter a unique slug',
                });
              } else {
                methods.clearErrors('slugValid');
              }
            },
          });
        }}
        pattern="[^a-z0-9-_]|-(?=-)"
        wrapperClassName="form-input"
        disabled={id === 'page-home' || id === 'page-404'}
      />
      {/* <TextInput
        register={methods.register}
        setError={methods.setError}
        clearErrors={methods.clearErrors}
        setValue={methods.setValue}
        label="Description Text"
        id="description"
        name="description"
        required={false}
        optional={true}
        hideRequired={true}
        maxLength={50}
        placeholder="Enter Description Text Here"
        defaultValue={description}
        error={methods.formState?.errors?.description}
        wrapperClassName="form-input"
      /> */}
    </SectionContainer>
  );
};

export default Meta;
