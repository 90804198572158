import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import InputProps from './TextInput.types';
import {
  Wrapper,
  Input,
  MaxLength,
  InputContent,
  InputContainer,
  Error,
  Disclaimer,
} from './styles';
import { IconWarning } from 'components/IconsView';
import Label from '../Label';

const TextInput = ({
  className,
  label,
  wrapperClassName,
  register,
  error,
  errorMessage,
  optional,
  setError,
  clearErrors,
  disclaimer,
  validate,
  pattern,
  hideRequired,
  hideLength,
  onBlur,
  setValue,
  type,
  ...inputProps
}: InputProps): JSX.Element => {
  const { onChange, maxLength, required, name = 'text' } = inputProps;
  const defaultValue = inputProps.defaultValue as string;
  const InputRef = useRef<HTMLInputElement | any>(null);

  const WrapperRef = useRef<HTMLDivElement>(null);
  const [currentLength, setCurrentLength] = useState(0);

  const saveValue = (v: string) => {
    if (setValue) {
      setValue(name, v.trim());
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setCurrentLength(defaultValue.length);
      // saveValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const formatMoney = (v: number) => {
    const format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const vFormat = format.format(v);
    return vFormat.replaceAll('$', '').replaceAll(',', '');
  };

  const handleChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentLength(el.target.value.length);
    if (pattern) {
      const patternRegex = new RegExp(pattern, 'g');
      let value = el.target.value;
      value = value.replace(patternRegex, '');
      if (inputProps.id) {
        const input = document.getElementById(
          inputProps.id
        ) as HTMLInputElement;
        if (input) {
          input.value = value;
          saveValue(value);
        }
      }
    } else {
      saveValue(el.target.value);
    }
    if (onChange) {
      onChange(el);
    }
  };

  const renderMaxLength = useCallback((): JSX.Element => {
    return (
      <MaxLength>
        {currentLength || 0}/{maxLength}
      </MaxLength>
    );
  }, [currentLength, maxLength]);

  const handleBlur = (el: React.FocusEvent<HTMLInputElement>) => {
    setCurrentLength(el.target.value.length);
    if (pattern) {
      const patternRegex = new RegExp(pattern, 'g');
      let value = el.target.value;
      value = value.replace(patternRegex, '');
      if (inputProps.id) {
        const input = document.getElementById(
          inputProps.id
        ) as HTMLInputElement;
        if (input) {
          input.value = value;
          saveValue(value);
        }
      }
    } else {
      saveValue(el.target.value);
    }
    if (type === 'currency') {
      const v = formatMoney(parseInt(el.target.value));
      if (inputProps.id) {
        const input = document.getElementById(
          inputProps.id
        ) as HTMLInputElement;
        if (input) {
          input.value = v;
          saveValue(v);
        }
      }
    }
    if (onBlur) {
      onBlur(el);
    }
    if (onChange) {
      onChange(el);
    }
    const v = el.target.value;
    if (required && v && (!validate || validate(v))) {
      clearErrors(name);
    }

    if (required && (!v || v.length === 0)) {
      setError(name, { type: 'manual', message: 'This field is required' });
    }
    if (validate && !validate(v)) {
      setError(name, { type: 'manual', message: 'Please enter a valid value' });
    }
  };

  if (!register) {
    return (
      <Wrapper ref={WrapperRef} className={classNames(wrapperClassName)}>
        {label && (
          <Label
            name={name}
            optional={optional}
            className={error ? 'error' : ''}
          >
            {label}
          </Label>
        )}
        <Input
          {...inputProps}
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper
      ref={WrapperRef}
      className={classNames('form-text-input', className, wrapperClassName)}
    >
      {label && (
        <Label
          name={name}
          optional={optional}
          required={required}
          hideRequired={hideRequired}
          className={error ? 'error' : ''}
        >
          {label}
        </Label>
      )}
      <InputContent>
        <InputContainer>
          <Input
            {...inputProps}
            className={classNames({ error: error })}
            {...register(name, {
              required,
              onBlur: handleBlur,
              onChange: handleChange,
            })}
            type={type === 'currency' ? 'number' : type || 'text'}
            data-testid={name}
            ref={InputRef}
          />
          {!hideLength && maxLength && renderMaxLength()}
        </InputContainer>
        {disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
        {error && (
          <Error className="error">
            <IconWarning />
            {errorMessage || 'Fill out this field'}
          </Error>
        )}
      </InputContent>
    </Wrapper>
  );
};

export default TextInput;
