import React, { useMemo, memo, useState } from 'react';
import * as mutations from 'graphql/mutations';
import { useMutation, gql } from '@apollo/client';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  FormContent,
  ModalContainer,
  SubmitButton,
} from './styles';
import TextInput from 'components/FormComponents/TextInput';
import Select from 'components/FormComponents/Select';
import { useForm } from 'react-hook-form';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';
import IToast from 'types/ToastType';
import { SearchI } from 'containers/SuggestedSearch/SuggestedSearch';
import { v4 as uuidv4 } from 'uuid';

const UPDATE_PAGE = gql(mutations.updatePage);

export interface IFormValues {
  id?: string;
  pageId?: string;
  title?: string;
  body?: string;
  sort?: number;
  linkTitle?: string;
  linkTo?: string;
  externalLink?: string;
}

interface IAddSectionFormProps {
  toggle: () => void;
  toast: IToast;
  length?: number;
  data?: SearchI;
  slugs: { value: string; label: string; key?: string }[];
  searchs: SearchI[];
  handleDeleting: (id: string) => void;
}

const SearchForm = ({
  toggle,
  toast,
  length,
  data,
  slugs,
  searchs,
  handleDeleting,
}: IAddSectionFormProps): JSX.Element => {
  const [deletingSearch, setDeletingSearch] = useState(false);
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues: data,
  });

  // mutations
  const [updatePage] = useMutation<IFormValues>(UPDATE_PAGE, {
    onCompleted: () => {
      if (data?.id) {
        toast.success('Updated search suggestion');
      } else {
        toast.success('Created search suggestion');
      }
      toggle();
    },
    onError: () => {
      if (data?.id) {
        toast.error('Error updating search suggestion');
      } else {
        toast.error('Error creating search suggestion');
      }
    },
  });

  const options = useMemo(() => {
    const temps: { value: string | null; label: string; key?: string }[] = [
      ...slugs,
    ];
    temps.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    temps.unshift({ label: 'Choose a page', value: null });
    temps.push({ label: 'External Link', value: 'external', key: 'default' });
    return temps;
  }, [slugs]);

  // functions

  return (
    <>
      <ModalContainer
        id="sectionModal"
        title={data?.id ? 'Edit Search Suggestion' : 'Add Search Suggestion'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
        description="Edit the link below."
      >
        <Form
          noValidate
          key="formModal"
          className="modalForm"
          onSubmit={handleSubmit(async (input) => {
            console.log('input', input);
            if (data?.id) {
              const temp = searchs || [];
              const searchIndex = temp.findIndex(
                (x: SearchI) => x.id === data.id
              );
              if (searchIndex > -1) {
                temp[searchIndex] = {
                  ...temp[searchIndex],
                  label: input.label,
                  linkTo: input.linkTo,
                };
                console.log(temp);
                await updatePage({
                  variables: {
                    input: {
                      id: 'page-suggested-search',
                      meta: JSON.stringify(temp),
                    },
                  },
                });
                toggle();
              }
            } else {
              const temp = searchs || [];
              temp.push({
                id: uuidv4(),
                sort: length || 0,
                label: input.label,
                linkTo: input.linkTo,
              });
              await updatePage({
                variables: {
                  input: {
                    id: 'page-suggested-search',
                    meta: JSON.stringify(temp),
                  },
                },
              });
              toggle();
            }
          })}
        >
          <FormContent>
            <TextInput
              key="label"
              id="label"
              name="label"
              label="Suggestion Label"
              placeholder="Enter Title Here"
              maxLength={50}
              register={register}
              setError={(label, error) => setError('label', error)}
              clearErrors={() => clearErrors('label')}
              error={!!errors?.label}
              setValue={setValue}
              errorMessage="Enter a label"
              required={true}
              hideRequired={true}
              defaultValue={data?.label || ''}
            />
            <Select
              options={options}
              name="linkTo"
              error={!!errors?.linkTo}
              setError={setError}
              clearErrors={clearErrors}
              id="linkTo"
              key="linkTo"
              label="Page to suggest"
              register={register}
              setValue={setValue}
              required={true}
              hideRequired={true}
              defaultOption={null}
              defaultValue={data?.linkTo || undefined}
            />
          </FormContent>
          <ButtonContainer>
            <SubmitButton data-testid="submit section" type="submit">
              {data?.id ? 'Save' : 'Add Suggestion'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {data?.id ? (
              <DeleteButton
                type="button"
                onClick={() => setDeletingSearch(true)}
                delete
              >
                Delete Suggestion
              </DeleteButton>
            ) : null}
          </ButtonContainer>
        </Form>
      </ModalContainer>
      {checkUnsaved && (
        <UnsavedModal cancel={() => setCheckUnsaved(false)} close={toggle} />
      )}
      {deletingSearch && data && (
        <DeleteForm
          type="search"
          id={data.id}
          toast={toast}
          toggle={async (refetch) => {
            if (refetch) {
              handleDeleting(data.id);
              toggle();
            } else {
              setDeletingSearch(false);
            }
          }}
        />
      )}
    </>
  );
};

export default memo(SearchForm);
