import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as queries from 'graphql/queries';
import PageTop from 'components/PageTop';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { listPages } from 'utils/queries';
import { SlugI } from 'types/SlugListType';
import { Container } from './styles';
import * as mutations from 'graphql/mutations';
import { formatDataForAccordion } from './helpers/format';
import SectionContainer from 'components/SectionContainer';
import Accordion from 'components/Accordion';
import AccordionTypes from 'components/Accordion/Accordion.types';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import TabModal from './Form/TabModal';
import IToast from 'types/ToastType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(queries.getPage);
export const LIST_PAGES = gql(listPages);
const UPDATE_PAGE = gql(mutations.updatePage);
const LIST_QUICKLINKS_BY_PAGE_ID = gql(queries.listQuicklinksByPageId);
const SORT_ITEMS = gql(mutations.sortItemsV2);
const LIST_OFFICES = gql(queries.listOffices);

interface ILunchSpots {
  toast: IToast;
  // handleRefetch: () => void;
}
// interface Inputs {
//   url?: string;
// }

const LunchSpots = ({ toast }: ILunchSpots): JSX.Element => {
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  // const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);
  const quicklinksSort = useRef<AccordionItems[]>([]);
  // refs for child forms:
  const pageFormRef = useRef<HTMLFormElement>(null);
  const linkFormRef = useRef<HTMLFormElement>(null);

  const [modalTabs, setModalTabs] = useState<{
    data: any;
  } | null>(null);
  const [tabsShow, setTabsShow] = useState(false);
  const [tabOfficeEditId, setTabOfficeEditId] = useState(null);
  // const [tabsCalled, setTabsCalled] = useState(false);

  // All Requests //////////////////////////////////////////////////////////////
  const {
    data: pageData,
    loading: pageLoading,
    refetch,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-lunch-spots',
    },
  });

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const { refetch: quicklinkRefetch } = useQuery(LIST_QUICKLINKS_BY_PAGE_ID, {
    variables: {
      pageId: 'page-lunch-spots',
    },
    fetchPolicy: 'network-only',
  });

  const { data: officeData } = useQuery(LIST_OFFICES, {
    onCompleted: ({ listOffices: tab }) => {
      setModalTabs({ data: tab.items });
    },
  });

  const officeDataItems = useMemo(() => {
    return officeData?.listOffices?.items?.filter((item: any) => item.active);
  }, [officeData]);

  console.log('data: ', data);
  console.log('officeDataItems: ', officeDataItems);

  // All mutations //////////////////////////////////////////////////////////////
  const [updatePage] = useMutation(UPDATE_PAGE, {
    onError: () => {
      toast.error('Error updating page');
    },
  });
  const [sortItems] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Items sorted');
      quicklinkRefetch();
    },
    onError: () => {
      toast.error('Error sorting items');
    },
  });

  const updateTab = useCallback((tabID: any) => {
    console.log('[updateTab] tabID: ', tabID);
    setTabOfficeEditId(tabID);
    setTabsShow(true);
  }, []);

  const accordionData: AccordionTypes = useMemo(() => {
    if (!officeDataItems) return null;
    return formatDataForAccordion({
      tabs: officeDataItems || [],
      updateTab,
    });
  }, [officeDataItems, updateTab]);
  console.log('accordionData: ', accordionData);

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    console.log('props', props);
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-lunch-spots',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    const temp = [...quicklinksSort.current];
    const sendToUpdate: { id: string; sort: number }[] = [];
    if (temp) {
      temp.forEach((quicklink, index) => {
        if (quicklink.index !== index) {
          sendToUpdate.push({
            id: quicklink.id || '',
            sort: index,
          });
        }
      });
    }

    if (sendToUpdate.length > 0) {
      await sortItems({
        variables: { input: { tableName: 'QuickLink', items: sendToUpdate } },
      });
      quicklinkRefetch();
    }

    toast.success('Page updated');
    refetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (linkFormRef.current) {
      linkFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        data={data}
        slugList={slugList}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          // setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <SectionContainer
        includePadding={false}
        title="Tabbed Maps Content"
        description="Click on the content you would like to edit."
      >
        <Accordion {...accordionData} />
      </SectionContainer>
      {tabsShow && modalTabs?.data && (
        <TabModal
          toggle={() => {
            setTabsShow(false);
          }}
          toast={toast}
          data={modalTabs.data?.find(
            (office: any) => office.id === tabOfficeEditId
          )}
        />
      )}
    </Container>
  );
};

export default LunchSpots;
