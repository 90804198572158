import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { colors, customBezierEase } from 'shared/variables';
import { eyebrow } from 'shared/extends';
import { font, fontSize } from 'shared/typography';

export const Container2 = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 40px 35px 0px;
`;

export const UserContainer2 = styled.div`
  padding: 0 0 24px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.greyLightLight};
  margin-bottom: 24px;

  svg {
    flex: 0 0 auto;
  }
`;

export const StyledLink2 = styled(NavLink)`
  ${eyebrow};
  color: ${colors.brownLight};
  margin-bottom: 16px;
  text-decoration: none;
  transition: 0.3s ${customBezierEase};
  display: block;
  text-transform: none;
  ${font('secondaryNav', 'normal')};
  ${fontSize(16, 20)};
  -webkit-font-smoothing: auto;

  &.active {
    color: ${colors.primary};
    pointer-events: none;
  }

  &:hover {
    color: ${colors.highlight};
  }
`;

export const CatPage2 = styled.h6`
  color: ${colors.primary};
  margin: 24px 0 12px 0;
  text-transform: uppercase;
  ${font('secondaryNav', '500')};
  ${fontSize(9, 17)};
  -webkit-font-smoothing: auto;
`;
