import React from 'react';

import { Container, Label, Input } from './styles';
import RadioTypes from './Radio.types';

const Radio = ({ label, register, ...radioProps }: RadioTypes): JSX.Element => {
  const { id, name, required } = radioProps;

  if (!register) {
    return (
      <Container>
        <Input {...radioProps} type="radio" />
        <Label htmlFor={id}>{label}</Label>
      </Container>
    );
  }
  return (
    <Container>
      <Input {...radioProps} type="radio" {...register(name, { required })} />
      <Label htmlFor={id}>{label}</Label>
    </Container>
  );
};

export default Radio;
