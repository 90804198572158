import { mq } from 'shared/mixins';
import styled from 'styled-components';

export const WeekContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: space-between;
  ${mq.phoneWide`
    padding: 6px 0;
  `}
`;
