import styled from 'styled-components';
import { colors } from 'shared/variables';
import { fonts, fontSize, bodyCopy } from 'shared/typography';
import { size } from 'shared/mixins';

export const Container = styled.div`
  label {
    display: flex;
    align-items: center;
    color: ${colors.brownMedium};
    position: relative;
    ${fontSize(14)};
    font-family: ${fonts.body};
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  label > span {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    border: 2px solid ${colors.primary};
    margin-right: 15px;
    border-radius: 0;
    transition: all 150ms;
    background-color: white;
    cursor: pointer;
  }

  input:checked + label > span {
    border: 11px solid ${colors.primary};
    /* animation: bounce 250ms; */
  }

  input:checked + label > span::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 5px;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms forwards;
  }
  input:disabled + label {
    color: ${colors.greyMediumMedium};
  }
  input:disabled + label > span {
    border: 2px solid ${colors.greyLightLight};
    &::before {
      border-color: ${colors.greyLightLight};
    }
  }
  input:focus + label > span {
    outline: 2px solid ${colors.brownLight};
  }

  @keyframes checked-box {
    0% {
      width: 0;
      height: 0;
      /* border-color: ${colors.brownMedium}; */
      transform: translate(0, 0) rotate(45deg);
    }
    33% {
      width: 4px;
      height: 0;
      /* border-color: ${colors.brownMedium}; */
      transform: translate(0, 0) rotate(45deg);
    }
    100% {
      width: 4px;
      height: 8px;
      /* border-color: ${colors.brownMedium}; */
      transform: translate(0, -8px) rotate(45deg);
    }
  }

  /* @keyframes bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  } */
`;

export const Error = styled.p`
  ${bodyCopy};
  color: ${colors.highlight};
  display: flex;
  align-items: center;
  margin: 6px 0 0;
  margin-right: auto;

  svg {
    fill: ${colors.highlight};
    margin-right: 8px;
    ${size('12px')};
  }
`;
