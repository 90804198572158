import styled from 'styled-components';
import { colors } from 'shared/variables';
import Button from 'components/Button';
import TextInput from 'components/FormComponents/TextInput';
import Select from 'components/FormComponents/Select';

export const Form = styled.form`
  /* min-width: 33%; */
  width: 100%;
  display: grid;
  grid-row-gap: 32px;
`;
export const CustomTextInput = styled(TextInput)`
  div > div {
    max-width: 316px;
  }
`;
export const CustomSelectInput = styled(Select)`
  max-width: 316px;
`;
export const FormInputContainer = styled.div`
  min-width: 33%;
  width: 320px;
`;
export const HR = styled.hr`
  border: none;
  border-top: 1px solid ${colors.greyLightLight};
`;
export const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
`;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)``;

export const DeactivateButton = styled(Button)`
  margin-left: auto;
`;
