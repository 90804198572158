import Button from 'components/Button';
import Modal from 'containers/Modal';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  margin: 0 auto;
  max-width: 803px;
`;
export const ButtonContainer = styled.div``;
export const SubmitButton = styled(Button)``;
export const CancelButton = styled(Button)``;
