import Select from 'components/FormComponents/Select';
import Modal from 'containers/Modal';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CancelButton,
  DeleteButton,
  Form,
  FormContent,
  SubmitButton,
} from './styles';
import IToast from 'types/ToastType';
import { useMutation, gql } from '@apollo/client';
import * as mutations from 'graphql/mutations';
import { Member } from 'API';
import TextInput from 'components/FormComponents/TextInput';
import { validateEmail } from 'utils/functions';
import ImageInput from 'components/FormComponents/ImageInput';
import { Storage } from 'aws-amplify';
import UnsavedModal from 'components/UnsavedModal/UnsavedModal';
import slugify from 'slugify';
import DeleteForm from 'components/Forms/DeleteForm/DeleteForm';

const UPDATE_MEMBER = gql(mutations.updateMember);
const CREATE_MEMBER = gql(mutations.createMember);

interface IMemberFormProps {
  toggle: (refetch?: boolean) => void;
  defaultValues?: Member;
  toast: IToast;
  length?: number;
  officeID?: string;
}

const MemberForm = ({
  toggle,
  length,
  toast,
  defaultValues,
  officeID,
}: IMemberFormProps): JSX.Element => {
  const [checkUnsaved, setCheckUnsaved] = useState(false);
  const [deletingMember, setDeletingMember] = useState(false);
  const [customEmailError, setCustomEmailError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<any>({
    defaultValues,
  });

  const [createMember] = useMutation(CREATE_MEMBER, {
    onCompleted: () => {
      toast.success('Member created');
    },
    onError: () => {
      toast.error('Error creating member');
    },
  });

  const [updateMember] = useMutation(UPDATE_MEMBER, {
    onCompleted: () => {
      toast.success('Member updated');
    },
    onError: () => {
      toast.error('Error updating member');
    },
  });

  //* if you update this list update the frontend one too
  const options = useMemo(() => {
    return [
      {
        label: 'Select a Department',
        value: null,
      },
      {
        label: 'Business Analysis',
        value: 'business-analysis',
        key: 'business-analysis',
      },
      {
        label: 'Design',
        value: 'design',
        key: 'design',
      },
      {
        label: 'Development',
        value: 'development',
        key: 'development',
      },
      {
        label: 'Executive Management',
        value: 'executive-management',
        key: 'executive-management',
      },
      {
        label: 'Executive Vendor',
        value: 'executive-vendor',
        key: 'executive-vendor',
      },
      {
        label: 'Information Technology',
        value: 'information-technology',
        key: 'information-technology',
      },
      {
        label: 'Office Management',
        value: 'office-management',
        key: 'office-management',
      },
      {
        label: 'Photography',
        value: 'photography',
        key: 'photography',
      },
      {
        label: 'Project Management',
        value: 'project-management',
        key: 'project-management',
      },
      {
        label: 'Quality Assurance',
        value: 'quality-assurance',
        key: 'quality-assurance',
      },
      {
        label: 'User Experience',
        value: 'user-experience',
        key: 'user-experience',
      },
    ];
  }, []);

  console.log('---defaultValues: ', defaultValues);

  return (
    <>
      <Modal
        id="sectionModal"
        title={defaultValues?.id ? 'Edit Team Member' : 'Add Team Member'}
        toggle={() => {
          if (isDirty) {
            setCheckUnsaved(true);
          } else {
            toggle();
          }
        }}
      >
        <Form
          noValidate
          key="formModal"
          className="modalForm"
          onSubmit={handleSubmit(async (input) => {
            let deleteImage = false;
            let uidFile: string | '' = input.image?.name || '';

            console.log('---input: ', input);
            console.log('---input.image: ', input.image);
            console.log('---uidFile: ', uidFile);

            if (input.image === 'DELETE_ME') {
              deleteImage = true;
            }

            if (uidFile) {
              uidFile = `${Math.floor(Math.random() * 10000)}-${slugify(
                `${input.image?.name}`
              )}`;
              await Storage.put(uidFile, input.image, {
                contentType: input.image?.type || '',
              });
            }

            if (defaultValues?.id) {
              delete input.createdAt;
              delete input.updatedAt;
              delete input.office;
              delete input.__typename;
              delete input.image;
              const variables: any = {
                input: {
                  id: defaultValues.id,
                  ...input,
                  nameLow: input.name.toLowerCase(),
                },
              };
              if (uidFile) {
                variables.input.image = uidFile;
              }
              if (deleteImage) {
                variables.input.image = null;
              }
              await updateMember({
                variables,
              });
            } else {
              delete input.image;
              await createMember({
                variables: {
                  input: {
                    ...input,
                    nameLow: input.name.toLowerCase(),
                    sort: length || 0,
                    officeID: officeID || '',
                    active: true,
                    image: uidFile,
                  },
                },
              });
            }
            toggle(true);
          })}
        >
          <FormContent>
            <ImageInput
              name="image"
              setFile={(file: File) => {
                console.log('setFile file', file);
                if (file === null) {
                  setValue('image', 'DELETE_ME', { shouldDirty: true });
                } else {
                  setValue('image', file, { shouldDirty: true });
                }
              }}
              optional={true}
              serverImage={defaultValues?.image || ''}
              label="Team Member Image"
              error={!!errors?.image}
              errorMessage="Upload a valid image"
              fileTypes="image/jpeg, image/png"
              dropzoneDisclaimer="PNG and JPG up to 5MB are allowed"
              clearErrors={() => clearErrors('image')}
              useBgImage
            />
            <TextInput
              id="name"
              maxLength={50}
              required={true}
              hideRequired={true}
              name="name"
              label="Name"
              placeholder="Enter Member Name Here"
              register={register}
              setError={(name, error) => setError('name', error)}
              clearErrors={() => clearErrors('name')}
              error={!!errors?.name}
              errorMessage="Enter a name"
              defaultValue={defaultValues?.name || ''}
              setValue={setValue}
            />
            <TextInput
              id="title"
              maxLength={50}
              required={true}
              hideRequired={true}
              name="title"
              label="Title"
              placeholder="Enter Member Title Here"
              register={register}
              setError={(name, error) => setError('title', error)}
              clearErrors={() => clearErrors('title')}
              error={!!errors?.title}
              errorMessage="Enter a title"
              defaultValue={defaultValues?.title || ''}
              setValue={setValue}
            />
            <TextInput
              id="email"
              maxLength={50}
              required={true}
              hideRequired={true}
              name="email"
              label="Email"
              placeholder="Enter Email Here"
              register={register}
              setError={(name, error) => setError('email', error)}
              clearErrors={() => clearErrors('email')}
              error={!!(errors?.email || customEmailError)}
              errorMessage="Enter a email"
              onBlur={(e) => {
                const value = e.target.value;
                setCustomEmailError(!validateEmail(value));
              }}
              defaultValue={defaultValues?.email || ''}
              setValue={setValue}
            />
            <Select
              options={options}
              name="department"
              error={!!errors?.department}
              setError={setError}
              clearErrors={clearErrors}
              id="department"
              key="department"
              label="Department"
              register={register}
              setValue={setValue}
              required={true}
              hideRequired={true}
              defaultValue={defaultValues?.department || undefined}
              defaultOption={null}
            />
          </FormContent>
          <ButtonContainer>
            <SubmitButton type="submit">
              {defaultValues?.id ? 'Save' : 'Create'}
            </SubmitButton>
            <CancelButton
              type="button"
              tertiary
              onClick={() => {
                if (isDirty) {
                  setCheckUnsaved(true);
                } else {
                  toggle();
                }
              }}
            >
              Cancel
            </CancelButton>
            {defaultValues?.id && (
              <DeleteButton
                type="button"
                delete
                onClick={() => setDeletingMember(true)}
              >
                Delete Member
              </DeleteButton>
            )}
          </ButtonContainer>
        </Form>
      </Modal>
      {checkUnsaved && (
        <UnsavedModal
          preventUnlock={true}
          cancel={() => setCheckUnsaved(false)}
          close={toggle}
        />
      )}
      {deletingMember && (
        <DeleteForm
          preventUnlock={true}
          type="member"
          id={defaultValues?.id || ''}
          toast={toast}
          toggle={() => toggle(true)}
          toggleDelete={() => setDeletingMember(false)}
        />
      )}
    </>
  );
};

export default MemberForm;
