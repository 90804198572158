import { Page } from 'API';

interface IFormat {
  tabs: (Page | null)[];
  updateTab: (id: string) => void;
}

export const formatDataForAccordion = ({ tabs, updateTab }: IFormat): any => {
  const formatTab = (tab: Page | any, index: number): any => {
    return {
      id: tab.id,
      title: tab.office.name,
      index: index,
      originalIndex: index,
      editFunc: () => {
        updateTab(tab.id);
      },
    };
  };

  console.log(tabs);

  return {
    accordionContainer: tabs
      .slice()
      .filter((x) => x?.status === 'active')
      .sort((x: Page | null, y: Page | null) => {
        return (x?.office?.sort || 0) - (y?.office?.sort || 0);
      })
      .map(formatTab),
  };
};
