import React from 'react';

import { Container, Input, Label, ToggleBall, ToggleContainer } from './styles';
import ToggleTypes from './Toggle.types';

const Toggle = ({
  label,
  register,
  ...toggleProps
}: ToggleTypes): JSX.Element => {
  const { id, name } = toggleProps;

  if (!register) {
    return (
      <Container>
        <Label htmlFor={id} className="label">
          <Input {...toggleProps} type="checkbox" />
          <ToggleContainer>
            <ToggleBall />
          </ToggleContainer>
          <span className="label">{label}</span>
        </Label>
      </Container>
    );
  }
  return (
    <Container>
      <Label htmlFor={id} className="label">
        <Input type="checkbox" {...register({ name })} />
        <ToggleContainer>
          <ToggleBall />
        </ToggleContainer>
        <span className="label">{label}</span>
      </Label>
    </Container>
  );
};

export default Toggle;
