import { IconWarning } from 'components/IconsView';
import React, { useCallback, useState, useEffect } from 'react';
import {
  Container,
  Area,
  TextareaContent,
  InputContainer,
  MaxLength,
  Error,
} from './styles';
import Label from 'components/FormComponents/Label';
import classNames from 'classnames';
import InputProps from './TextArea.types';

const TextArea = ({
  label,
  containerClassName,
  optional,
  error,
  errorMessage,
  errorLimited,
  setError,
  register,
  clearErrors,
  validate,
  formValue,
  hideRequired,
  onBlur,
  setValue,
  className,
  ...inputProps
}: InputProps): JSX.Element => {
  const {
    onChange,
    maxLength,
    name = 'textarea',
    required,
    placeholder,
  } = inputProps;
  const defaultValue = inputProps.defaultValue as string;

  const [currentLength, setCurrentLength] = useState(0);
  useEffect(() => {
    if (defaultValue) {
      setCurrentLength(defaultValue.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const saveValue = (v: string) => {
    if (setValue) {
      setValue(name, v);
    }
  };

  const handleChange = (el: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentLength(el.target.value.length);

    saveValue(el.target.value);

    if (onChange) {
      onChange(el);
    }
  };

  const renderMaxLength = useCallback((): JSX.Element => {
    return (
      <MaxLength>
        {currentLength || 0}/{maxLength}
      </MaxLength>
    );
  }, [currentLength, maxLength]);

  const handleBlur = (el: React.FocusEvent<HTMLTextAreaElement>) => {
    setCurrentLength(el.target.value.length);

    saveValue(el.target.value);

    if (onBlur) {
      onBlur(el);
    }
    if (onChange) {
      onChange(el);
    }
    const v = el.target.value;
    if (required && v && (!validate || validate(v))) {
      clearErrors(name);
    }

    if (required && (!v || v.length === 0)) {
      setError(name, { type: 'manual', message: 'This field is required' });
    }
    if (validate && !validate(v)) {
      setError(name, { type: 'manual', message: 'Please enter a valid value' });
    }
  };

  return (
    <Container
      className={classNames('form-textarea', containerClassName, className)}
    >
      {label && (
        <Label
          name={name}
          optional={optional}
          required={required}
          hideRequired={hideRequired}
          className={error ? 'error' : ''}
        >
          {label}
        </Label>
      )}
      <TextareaContent>
        <InputContainer>
          <Area
            {...inputProps}
            {...register(name, {
              required,
              onBlur: handleBlur,
              onChange: handleChange,
            })}
            data-testid={name}
            className={classNames({ error: error, errorLimited: errorLimited })}
            placeholder={!errorLimited ? placeholder : ''}
            aria-label={label}
          />
          {maxLength && renderMaxLength()}
        </InputContainer>
        {/* {errorLimited && <IconWarning />} */}
        {error && (
          <Error className="error">
            <IconWarning />
            {errorMessage || 'Fill out this field'}
          </Error>
        )}
      </TextareaContent>
    </Container>
  );
};

export default TextArea;
