import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as queries from 'graphql/queries';
import Quicklinks from 'components/Quicklinks';
import PageTop from 'components/PageTop';
import { SubmitHandler } from 'react-hook-form';
import PageTopTypes from 'components/PageTop/PageTop.types';
import { listPages } from 'utils/queries';
import { SlugI } from 'types/SlugListType';
import { Container } from './styles';
import * as mutations from 'graphql/mutations';
import { useQuery, gql, useMutation } from '@apollo/client';
import { cleanString, getSlugs, postWYSIWYG } from 'utils/functions';
import AccordionItems from 'components/Accordion/AccordionItem/AccordionItem.types';
import EventsFeed from 'components/EventsFeed';
import EventsFilteredPhrase from 'components/EventsFilteredPhrase';
import IToast from 'types/ToastType';
import LoaderView from 'components/LoaderView';

export const GET_PAGE = gql(queries.getPage);
export const LIST_PAGES = gql(listPages);
const UPDATE_PAGE = gql(mutations.updatePage);
const LIST_QUICKLINKS_BY_PAGE_ID = gql(queries.listQuicklinksByPageId);
const SORT_ITEMS = gql(mutations.sortItemsV2);

interface IHomepage {
  toast: IToast;
}
interface Inputs {
  url?: string;
}

const Homepage = ({ toast }: IHomepage): JSX.Element => {
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [isDirtyPageTop, setIsDirtyPageTop] = useState(false);
  const quicklinksSort = useRef<AccordionItems[]>([]);
  // refs for child forms:
  const pageFormRef = useRef<HTMLFormElement>(null);
  const linkFormRef = useRef<HTMLFormElement>(null);
  // All Requests //////////////////////////////////////////////////////////////
  const {
    data: pageData,
    loading: pageLoading,
    refetch,
  } = useQuery(GET_PAGE, {
    variables: {
      id: 'page-home',
    },
  });

  const [slugList, setSlugs] = useState<SlugI[]>([]);
  const doFirst = async () => {
    const temp = await getSlugs();
    setSlugs(temp);
  };
  useEffect(() => {
    doFirst();
  }, []);

  const data = useMemo(() => {
    return pageData?.getPage;
  }, [pageData]);

  const { data: quicklinkData, refetch: quicklinkRefetch } = useQuery(
    LIST_QUICKLINKS_BY_PAGE_ID,
    {
      variables: {
        pageId: 'page-home',
      },
      fetchPolicy: 'network-only',
    }
  );
  const quicklinks = useMemo(() => {
    if (quicklinkData) {
      return quicklinkData?.listQuicklinksByPageId?.items;
    }
    return [];
  }, [quicklinkData]);

  console.log('data: ', data);

  // All mutations //////////////////////////////////////////////////////////////
  const [updatePage] = useMutation(UPDATE_PAGE, {
    onError: () => {
      toast.error('Error updating page');
    },
  });
  const [sortItems] = useMutation(SORT_ITEMS, {
    onCompleted: () => {
      toast.success('Items sorted');
      quicklinkRefetch();
    },
    onError: () => {
      toast.error('Error sorting items');
    },
  });

  // All submit functions ///////////////////////////////////////////////////////
  const onSubmitLink: SubmitHandler<Inputs> = (props) => {
    const eventsCalPhrases = JSON.parse(data.meta)?.eventsCalPhrases || false;
    const meta: any = {};
    if (props.url) {
      meta.calLink = props.url;
    }
    if (eventsCalPhrases) {
      meta.eventsCalPhrases = eventsCalPhrases;
    }
    updatePage({
      variables: {
        input: {
          id: 'page-home',
          meta: JSON.stringify(meta),
        },
      },
    });
  };

  const onSubmitPhrases: any = (
    mode: string,
    value: string,
    oldValue: string
  ) => {
    console.log('[onSubmitPhrases]', mode, value, oldValue);
    const eventsCalPhrases = JSON.parse(data.meta)?.eventsCalPhrases || false;
    const calLink = JSON.parse(data.meta)?.calLink || false;
    const meta: any = {};

    // Strip out quotes
    // value = value.replace(/^"(.+)"$/, '$1');

    if (value) {
      if (eventsCalPhrases) {
        if (mode === 'edit') {
          const updatedPhrases = eventsCalPhrases;
          updatedPhrases.forEach((p: string, index: number) => {
            if (p === oldValue) {
              updatedPhrases[index] = value;
            }
          });
          meta.eventsCalPhrases = updatedPhrases;
        } else if (mode === 'delete') {
          const updatedPhrases: any = [];
          eventsCalPhrases.forEach((p: string) => {
            if (p !== value) {
              updatedPhrases.push(p);
            }
          });
          meta.eventsCalPhrases = updatedPhrases;
        } else {
          const newPhrases = eventsCalPhrases;
          newPhrases.push(value);
          meta.eventsCalPhrases = newPhrases;
        }
      } else {
        meta.eventsCalPhrases = [value];
      }
    }
    if (calLink) {
      meta.calLink = calLink;
    }
    updatePage({
      variables: {
        input: {
          id: 'page-home',
          meta: JSON.stringify(meta),
        },
      },
    });
  };

  const checkIsDirty = (q: AccordionItems[]) => {
    // check if items were sorted or changed views
    if (q.length > 0) {
      setIsDirtyForm(true);
    } else {
      // if not then check if PageTop is dirty too
      if (!isDirtyPageTop) {
        // if not then it was just the accordion that was dirty so update isDirty to false now
        setIsDirtyForm(false);
      }
    }
  };

  const onSubmitUpdatePage: SubmitHandler<PageTopTypes> = async (props) => {
    console.log('props', props);
    // remove aws wysiwyg img src and leave behind only keys
    const shortBody = postWYSIWYG(props.body);
    const cleanShortBody = cleanString(shortBody);

    updatePage({
      variables: {
        input: {
          id: 'page-home',
          ...props,
          updatedAt: new Date().toISOString(),
          label: props.title,
          headlineLow: props?.headline && props?.headline.toLowerCase(),
          body: shortBody,
          bodyLow: cleanShortBody.toLowerCase(),
        },
      },
    });

    const temp = [...quicklinksSort.current];
    const sendToUpdate: { id: string; sort: number }[] = [];
    if (temp) {
      temp.forEach((quicklink, index) => {
        if (quicklink.index !== index) {
          sendToUpdate.push({
            id: quicklink.id || '',
            sort: index,
          });
        }
      });
    }

    if (sendToUpdate.length > 0) {
      await sortItems({
        variables: { input: { tableName: 'QuickLink', items: sendToUpdate } },
      });
      quicklinkRefetch();
    }

    toast.success('Page updated');
    refetch();
  };

  const submitAllForms = () => {
    // trigger submit function from refs
    if (linkFormRef.current) {
      linkFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
    if (pageFormRef.current) {
      pageFormRef.current.dispatchEvent(
        new Event('submit', { bubbles: true, cancelable: true })
      );
    }
  };

  if (pageLoading) {
    return <LoaderView />;
  }

  return (
    <Container>
      <PageTop
        ref={pageFormRef}
        submitAllForms={submitAllForms}
        data={data}
        slugList={slugList}
        onSubmitUpdatePage={onSubmitUpdatePage}
        setIsDirtyForm={(isDirty: boolean) => {
          setIsDirtyForm(isDirty);
          setIsDirtyPageTop(isDirty);
        }}
        isDirtyForm={isDirtyForm}
      />
      <Quicklinks
        pageID={data?.id}
        toast={toast}
        slugs={slugList}
        quicklinksSorted={(q) => {
          quicklinksSort.current = q;
          checkIsDirty(q);
        }}
        quicklinks={quicklinks}
        refetch={quicklinkRefetch}
      />
      <EventsFeed
        ref={linkFormRef}
        link={JSON.parse(data.meta)?.calLink}
        onSubmitLink={onSubmitLink}
      />
      <EventsFilteredPhrase
        pageID={data?.id}
        toast={toast}
        phrases={JSON.parse(data.meta)?.eventsCalPhrases || []}
        onSubmitPhrases={onSubmitPhrases}
        refetch={quicklinkRefetch}
      />
    </Container>
  );
};

export default Homepage;
