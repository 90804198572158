import React, { useState, useEffect } from 'react';
import { Prompt, useLocation, useHistory } from 'react-router-dom';
import Modal from 'containers/Modal';
import isTouchDevice from 'is-touch-device';
import { CustomButton, CustomModalButtons } from './styles';
import { UnSavedModalProps } from './UnsavedModalRoute.types';
// import { useUnifiedError } from 'utils/useGlobalError';

const UnsavedModalRoute = ({
  content,
  isBlocked = false,
  showModalDefault = false,
  type,
}: UnSavedModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(showModalDefault);
  const touchable = isTouchDevice();
  const location = useLocation();
  const history = useHistory();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  // const { globalError, registerError } = useUnifiedError();

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation: any) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
    // if (registerError) {
    //   registerError(null);
    // }
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event: { returnValue: string }) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isBlocked, content, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <Modal
          toggle={closeModal}
          title="Are you sure you want to leave this unsaved?"
          description={
            type === 'page'
              ? 'Doing this will delete any edits you’ve made to this page.'
              : 'Doing this will delete any edits you’ve made to this element.'
          }
          id="test"
        >
          <CustomModalButtons>
            <CustomButton
              data-touchable={touchable}
              onClick={handleConfirmNavigationClick}
            >
              Yes, close
            </CustomButton>
            <CustomButton
              data-touchable={touchable}
              onClick={closeModal}
              tertiary={true}
            >
              Cancel
            </CustomButton>
          </CustomModalButtons>
        </Modal>
      )}
    </>
  );
};

export default UnsavedModalRoute;
