export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      label
      status
      createdAt
      updatedAt
      title
      slug
      # description
      eyebrow
      headline
      body
      inlineBody
      ctaText
      ctaLink
      headlineLow
      bodyLow
      layout
      quicklinks {
        nextToken
      }
      referencedQuicklinks {
        nextToken
      }
      pageId
      pages {
        nextToken
        items {
          id
          status
        }
      }
      parentPage {
        id
        status
        slug
      }
      local
      publishedAt
      image
      sort
      meta
    }
  }
`;
