import styled from 'styled-components';
import { size } from 'shared/mixins';
import { colors } from 'shared/variables';
import { font, fontSize } from 'shared/typography';
import { eyebrowRegular, resetButtonStyles } from 'shared/extends';

export const Container = styled.div``;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Year = styled.h3`
  ${eyebrowRegular};
  color: ${colors.brownMedium};
  margin: 0;
  flex: 1 0 auto;
  text-align: center;
`;

export const ArrowButton = styled.button<{ side: string }>`
  border: 0;
  border-radius: 0;
  background: transparent;
  outline: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  align-items: center;

  svg {
    fill: ${colors.primary};
  }

  &:disabled {
    pointer-events: none;
    svg {
      fill: ${colors.greyLightLight};
    }
  }

  ${(props) =>
    props.side === 'left' &&
    `
    transform: scale(-1);
  `}

  &:hover {
    path {
      fill: ${colors.highlight};
    }
  }
  /* &:focus {
    path {
      fill: ${colors.brownLight};
    }
  } */
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${size('35px', '100%')}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;
`;

export const YearButton = styled.button`
  ${resetButtonStyles};
  color: ${colors.brownLight};
  height: 42px;
  ${font('secondary', 'bold')};
  ${fontSize(14, 20, 0)};

  &.active {
    background-color: ${colors.primary};
    color: ${colors.white};
  }

  &.unactive {
    color: ${colors.greyLightLight};
  }
  &:disabled {
    color: ${colors.greyLightLight};
    cursor: not-allowed;
    pointer-events: none;
  }

  &.active.unactive {
    background-color: #f2d299;
    color: ${colors.white};
  }
`;
